import React from 'react'
import { InformationPopover } from './InformationPopover'
import { Vault20Filled, Vault20Regular } from '@fluentui/react-icons'
import { Threat } from '../../../api/schemas/schema'
import { t } from '../../../i18n/i18n'
import TextOutput from '../inputs/TextOutput'
import { makeStyles, tokens } from '@fluentui/react-components'
const useStyles = makeStyles({
    bodyWrapper: {
        marginTop: tokens.spacingVerticalXL,
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalXL,
    },
})


type ThreatPopoverProps = {
   threat: Threat
}

export const ThreatPopover = ({ threat }: ThreatPopoverProps) => {
const classes = useStyles()

   const body = () => {
      return (
         <div className={classes.bodyWrapper}>
            <TextOutput label={t('Name')} text={threat.name} />
            <TextOutput label={t('Description')} text={threat.description} />
            <TextOutput label={t('UnderlyingMotivationAndStand')} text={threat.underlyingMotivation} />
         </div>
      )
   }

   return (
      <InformationPopover
         btnLabel={t('CurrentThreat')}
         popoverLabel={t('CurrentThreat')}
         input={body()}
         regularIcon={<Vault20Regular />}
         filledIcon={<Vault20Filled />}
      />
   )
}
