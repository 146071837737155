import React, { useEffect, useState } from 'react'
import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { AddCircle24Regular } from '@fluentui/react-icons'
import { RiskAnalysis, RiskAnalysisStatus } from '../../api/schemas/schema'
import { ModalRisk } from './SimplifiedRiskAnalysis/ModalRisk'
import { Text } from '../common/text/Text'
import { t } from '../../i18n/i18n'
import { getSimplifiedRiskIdentifyingColumns } from '../../helpers/riskAnalysisHelper'
import { List } from '../common/list/List'
import { InfoMessageBar } from '../common/other/InfoMessageBar'
import { CardHeader } from '../common/header/CardHeader'
import { IItemState } from '../../interfaces/IItemState'
const useStyles = makeStyles({
   btn: {
      '@media screen and (max-width: 1007px)': {
         width: '100%',
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   text: {
      ...shorthands.margin(tokens.spacingHorizontalS, 0),
   },
})
type RiskIdentifyingProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
}
export const RiskIdentifying = ({ setRiskAnalysisState, analysis }: RiskIdentifyingProps) => {
   const classes = useStyles()
   const [openModal, setOpenModal] = useState<boolean>(false)
   const [selectedItem, setSelectedItem] = useState<any>(null)
   const disabled = analysis.status === RiskAnalysisStatus.Determined || analysis.status === RiskAnalysisStatus.Completed
   const [risks, setRisks] = useState<any>([])

   const handleRowClick = (item: any) => {
      setSelectedItem(item)
      setOpenModal(true)
   }

   const handleNewClick = () => {
      setSelectedItem(null)
      setOpenModal(true)
   }

   const phoneBody = (risk: any) => {
      return (
         <>
            <div>
               <Text size={400} className={classes.text}>
                  {risk?.scenario}
               </Text>
               <Text size={400} className={classes.text} weight={'bold'}>
                  {risk?.protectedAsset?.name}
               </Text>
            </div>
         </>
      )
   }

   useEffect(() => {
      if (analysis.simplifiedRisks.length > 0) {
         setRisks(analysis.simplifiedRisks)
      } else if (analysis.consequenceAndProbabilityRisks.length > 0) {
         setRisks(analysis.consequenceAndProbabilityRisks)
      }
   }, [analysis])

   const title = t('RiskIdentifying')
   return (
      <>
         <CardHeader
            title={title}
            action={
               <Button className={classes.btn} onClick={handleNewClick} appearance="primary" icon={<AddCircle24Regular />} disabled={disabled}>
                  {t('NewRisk')}
               </Button>
            }
         />
         {risks === 0 ? (
            <InfoMessageBar title={t('NoRisksIdentifiedYet')} />
         ) : (
            <List columns={getSimplifiedRiskIdentifyingColumns()} items={risks} onRowClick={handleRowClick} renderPhoneBodyItem={phoneBody} />
         )}

         <ModalRisk
            setRiskAnalysisState={setRiskAnalysisState}
            analysis={analysis}
            open={openModal}
            setOpen={setOpenModal}
            selectedItem={selectedItem}
            disabled={disabled}
         />
      </>
   )
}
