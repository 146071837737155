import React from 'react'
import { ConsequenceAndProbabilityRisk } from '../../../api/schemas/schema'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { ConsequenceScaleLabel } from './ConsequenceScaleLabel'
import { BadgeConsequenceProbability } from './BadgeConsequenceProbability'

type AnalyseRiskPhoneItemPrpos = {
   item: ConsequenceAndProbabilityRisk
}
const useStyles = makeStyles({
   item: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
   badgesWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
   },
   wrapper: {
      display: 'flex',
      justifyContent: 'center',
      gap: tokens.spacingHorizontalXS,
   },
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 2,
   },
})
export const AnalyseRiskPhoneItem = ({ item }: AnalyseRiskPhoneItemPrpos) => {
   const classes = useStyles()
   return (
      <div className={classes.item}>
         <div className={classes.badgesWrapper}>
            <span className={classes.wrapper}>
               <ConsequenceScaleLabel typeLabel="L" />
               <BadgeConsequenceProbability valuation={item.consequenceLifeHealth} />
            </span>
            <span className={classes.wrapper}>
               <ConsequenceScaleLabel typeLabel="E" />
               <BadgeConsequenceProbability valuation={item.consequenceEconomy} />
            </span>
            <span className={classes.wrapper}>
               <ConsequenceScaleLabel typeLabel="M" />
               <BadgeConsequenceProbability valuation={item.consequenceEnviroment} />
            </span>
            <span className={classes.wrapper}>
               <ConsequenceScaleLabel typeLabel="V" />
               <BadgeConsequenceProbability valuation={item.consequenceBrand} />
            </span>
            <span className={classes.wrapper}>
               <Text weight="bold" size={400}>
                  S
               </Text>
               <BadgeConsequenceProbability valuation={item.probability} />
            </span>
         </div>
         <span style={{ height: `calc(${tokens.lineHeightBase400} * 2)` }}>
            <Text className={classes.ellipsis} size={400}>
               {item.scenario}
            </Text>
         </span>
      </div>
   )
}
