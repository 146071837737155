import React, { useState } from 'react'
import { Button, makeStyles, Table, TableBody, TableHeader, TableHeaderCell, TableRow, tokens } from '@fluentui/react-components'
import { IGroup } from '../../../interfaces/IGroup'
import { IGroupedTableColumnDefintion } from '../../../interfaces/IGroupedTableColumnDefintion'
import { ISummaryField } from '../../../interfaces/ISummaryField'
import { SummaryRow } from '../../common/list/SummaryRow'
import { ChevronDown24Regular, ChevronRight24Regular } from '@fluentui/react-icons'
import { CostEvaluationListRow } from './CostEvaluationListRow'
import { Cost } from '../../../api/schemas/schema'

const useStyles = makeStyles({
   chevronColWidth: {
      width: '70px',
      cursor: 'pointer',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         marginTop: tokens.spacingVerticalXS,
         width: '30px',
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
})

type CostEvaluationListProps = {
   columns: IGroupedTableColumnDefintion<Cost>[]
   groups: IGroup<Cost>[]
   onCellClick?: (item: Cost, columnKey: keyof Cost) => void
   summaryFields: ISummaryField[]
   summaryRowClassName: string
   renderPhoneRow: (item: Cost) => JSX.Element
   renderPhoneHeader: (item: IGroup<Cost>) => JSX.Element
   className?: string
}

export const CostEvaluationList = ({
   columns,
   groups,
   onCellClick,
   summaryFields,
   summaryRowClassName,
   renderPhoneHeader,
   renderPhoneRow,
}: CostEvaluationListProps) => {
   const [allExpanded, setAllExpanded] = useState<boolean>(false)
   const classes = useStyles()
   return (
      <div>
         <SummaryRow summaryFields={summaryFields} className={summaryRowClassName} />
         <Table>
            <TableHeader className={classes.hideInPhone}>
               <TableRow>
                  <TableHeaderCell className={classes.chevronColWidth}>
                     <Button
                        size="large"
                        aria-label="expand-all"
                        appearance="transparent"
                        icon={allExpanded ? <ChevronDown24Regular /> : <ChevronRight24Regular />}
                        onClick={() => setAllExpanded((prev) => !prev)}
                     />
                  </TableHeaderCell>
                  {columns.map((column) => (
                     <TableHeaderCell style={{ fontWeight: tokens.fontWeightBold, width: column.colWidth }} key={column.columnId}>
                        {column.renderHeaderCell()}
                     </TableHeaderCell>
                  ))}
               </TableRow>
            </TableHeader>
            <TableBody>
               {groups.map((group) => (
                  <CostEvaluationListRow
                     key={group.label}
                     onCellClick={onCellClick}
                     group={group}
                     columns={columns}
                     allExpanded={allExpanded}
                     chevronCellClass={classes.chevronColWidth}
                     renderPhoneRow={renderPhoneRow}
                     renderPhoneHeader={renderPhoneHeader}
                  />
               ))}
            </TableBody>
         </Table>
      </div>
   )
}
