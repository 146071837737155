import React from 'react'
import { RiskLevel, RiskStatus, SWOTType } from '../../api/schemas/schema'
import { Badge, makeStyles, mergeClasses, Text } from '@fluentui/react-components'
import { translateEnumValue } from '../../helpers/enumHelper'
import { getSwotBadgeStyle } from '../../helpers/swotHelper'
import { getRiskAssessmentBadgeStyle, getRiskLevelBadgeStyle } from '../../helpers/riskAnalysisHelper'
import { t } from '../../i18n/i18n'

const useStyles = makeStyles({
   swotBadge: {
      minWidth: '70px',
   },
   riskAssessmentBadge: {
      minWidth: '90px',
      minHeight: '25px',
   },
   riskLevelBadge: {
      minWidth: '110px',
      minHeight: '25px',
   },
   badgeLarge: {
      minWidth: '120px',
      minHeight: '25px',
   },
   mediumBadge: {
      width: 'auto',
      minHeight: '25px',
   },
})

type SwotTypeBadgeProps = {
   type: SWOTType
   className?: string
}

export const SwotTypeBadge = ({ type, className }: SwotTypeBadgeProps) => {
   const classes = useStyles()

   return (
      <Badge size="large" appearance="filled" shape="rounded" className={mergeClasses(classes.swotBadge, className ?? '')} style={getSwotBadgeStyle(type)}>
         {translateEnumValue(SWOTType, type)}
      </Badge>
   )
}

type RiskAssessmentBadgeProps = {
   status: RiskStatus
   className?: string
   largeBadge?: boolean
   mediumBadge?: boolean
}
export const RiskAssessmentBadge = ({ status, className, largeBadge, mediumBadge }: RiskAssessmentBadgeProps) => {
   const classes = useStyles()

   return (
      <Badge
         appearance="filled"
         shape="rounded"
         className={mergeClasses(largeBadge ? classes.badgeLarge : mediumBadge ? classes.mediumBadge : classes.riskAssessmentBadge, className ?? '')}
         style={getRiskAssessmentBadgeStyle(status)}
      >
         {translateEnumValue(RiskStatus, status)}
      </Badge>
   )
}

type RiskLevelBadgeProps = {
   riskLevel: RiskLevel
   className?: string
   largeBadge?: boolean
   mediumBadge?: boolean
}
export const RiskLevelBadge = ({ riskLevel, className, largeBadge, mediumBadge }: RiskLevelBadgeProps) => {
   const classes = useStyles()
   if (riskLevel === null || riskLevel === undefined) {
      return <Text italic>{t('NoValueSet')}</Text>
   }
   return (
      <Badge
         appearance="filled"
         shape="rounded"
         className={mergeClasses(largeBadge ? classes.badgeLarge : mediumBadge ? classes.mediumBadge : classes.riskLevelBadge, className ?? '')}
         style={getRiskLevelBadgeStyle(riskLevel)}
      >
         {translateEnumValue(RiskLevel, riskLevel)}
      </Badge>
   )
}
