import React from 'react'
import { t } from '../../../i18n/i18n'
import { DashboardInfoCard } from '../DashboardInfoCard'
import { Activity } from '../../../api/schemas/schema'
import { List } from '../../common/list/List'
import { getDashboardActiviyColumns, getSortedLatestActivities } from '../../../helpers/dashboardHelper'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { ActivityStatusBadge } from '../ActivityStatusBadge'
import { formatDate } from '../../../helpers/stringHelper'
const useStyles = makeStyles({
   phoneBodyWrapper: {
      display: 'flex',
   },
   textColumn: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: tokens.spacingHorizontalS,
   },
})

type ActivitesCardProps = {
   setActivityModalState: React.Dispatch<
      React.SetStateAction<{
         isOpen: boolean
         selectedActivity?: Activity
      }>
   >
   latestActivities: Activity[]
}

export const ActivitesCard = ({ setActivityModalState, latestActivities }: ActivitesCardProps) => {
   const classes = useStyles()

   return (
      <>
         {latestActivities?.length === 0 ? (
            <DashboardInfoCard
               title={t('PlanYourCalendar')}
               description={t('PlanYourCalendarDescription')}
               buttonText={t('CreateActivity')}
               onClick={() => setActivityModalState({ isOpen: true })}
            />
         ) : (
            <>
               <Text size={600} weight="semibold">
                  {t('AnnualCalendar')}
               </Text>
               <List
                  columns={getDashboardActiviyColumns()}
                  items={getSortedLatestActivities(latestActivities)}
                  renderPhoneBodyItem={(item) => (
                     <div className={classes.phoneBodyWrapper}>
                        <ActivityStatusBadge item={item} />
                        <div className={classes.textColumn}>
                           <Text size={400}>{formatDate(item.startDate)}</Text>
                           <Text size={400}>{item.title}</Text>
                        </div>
                     </div>
                  )}
                  columnSizingOptions={{
                     status: { idealWidth: 10, minWidth: 5 },
                     title: { idealWidth: 500, minWidth: 150 },
                     startDate: { idealWidth: 250, minWidth: 100 },
                     endDate: { idealWidth: 250, minWidth: 100 },
                  }}
                  resizableColumns
               />
            </>
         )}
      </>
   )
}
