import { DemandComplianceModules, GeneralModules, Organisation, RiskManagementModules, SubscriptionLevel } from '../api/schemas/schema'

export const isExtendedLevelModuleActivated = (organisation: Organisation) => {
   const extendedLevelModule = organisation.modules?.find((mod) => mod.demandComplianceModule === DemandComplianceModules.EXTENDED_LEVEL)
   return extendedLevelModule?.isActivated ?? false
}

export const isGeneralModuleActivated = (organisation: Organisation, module: GeneralModules) => {
   const selectedModule = organisation.modules?.find((mod) => mod.generalModule === module)
   return selectedModule?.isActivated ?? false
}
export const isDemandModuleActivated = (organisation: Organisation, module: DemandComplianceModules) => {
   const selectedModule = organisation.modules?.find((mod) => mod.demandComplianceModule === module)
   return selectedModule?.isActivated ?? false
}
export const isRiskModuleActivated = (organisation: Organisation, module: RiskManagementModules) => {
   const selectedModule = organisation.modules?.find((mod) => mod.riskManagementModule === module)
   return selectedModule?.isActivated ?? false
}

export const isSubscriptionLevel = (organisation: Organisation, subscriptionLevel: SubscriptionLevel) => {
   return organisation.subscription === subscriptionLevel ? true : false
}

export const isSubscriptionLevelBaseOrBig = (subscription: SubscriptionLevel) => {
   return subscription === SubscriptionLevel.BASE || subscription === SubscriptionLevel.BIG
}
