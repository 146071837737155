import React from 'react'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { Title } from '../common/text/Title'
const useStyles = makeStyles({
   divider: {
      marginTop: tokens.spacingVerticalS,
   },
   header: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   marginTop: {
      marginTop: tokens.spacingVerticalL,
   },
   secondaryAction: {
      float: 'right',
   },
})
type PhoneHeaderProps = {
   title: string
   action?: JSX.Element
   secondaryAction?: boolean
}
export const PhoneHeader = ({ title, action, secondaryAction }: PhoneHeaderProps) => {
   const classes = useStyles()
   return (
      <header className={classes.header}>
         <Title as="h3">{title}</Title>
         {secondaryAction && <div className={classes.secondaryAction}>{action ?? null}</div>}
         <Divider className={classes.divider} appearance="brand" />
         {!secondaryAction && <div className={classes.marginTop}>{action ?? null}</div>}
      </header>
   )
}
