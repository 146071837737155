import React from 'react'
import { Activity } from '../../api/schemas/schema'
import { tokens } from '@fluentui/react-components'

type ActivityStatusBadgeProps = {
   item: Activity
}
export const ActivityStatusBadge = ({ item }: ActivityStatusBadgeProps) => {
   const todaysDate = new Date()
   const startDate = new Date(item.startDate)
   const endDate = new Date(item.endDate)

   const getBgColor = () => {
      if (startDate > todaysDate) {
         return '#566577'
      } else if (startDate <= todaysDate && endDate !== null && endDate >= todaysDate) {
         return '#13A10E'
      } else if (startDate <= todaysDate && item.endDate === null) {
         return '#FFB900'
      } else if (startDate <= todaysDate && endDate !== null && endDate < todaysDate) {
         return '#D13438'
      }
   }
   return <div style={{ backgroundColor: getBgColor(), width: '4px', minHeight: '30px', borderRadius: tokens.borderRadiusXLarge }}></div>
}
