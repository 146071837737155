import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../common/modal/Modal'
import { Cost, FinancialYear, FinancialYearStatus } from '../../api/schemas/schema'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { t } from '../../i18n/i18n'
import { useForm } from 'react-hook-form'
import { DataContext } from '../../context/DataContext'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { Button, Divider, Dropdown } from '@fluentui/react-components'
import { PostDropdown } from './PostDropdown'
import { Field } from '../common/inputs/Field'
import { IItemState } from '../../interfaces/IItemState'
import { fetchPosts, saveCost } from '../../helpers/financialYearHelper'

type ModalCostProps = {
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
   financialYear: FinancialYear
   close: () => void
   open: boolean
   selectedCost?: Cost
}
export const ModalCost = ({ setFinancialYearState, financialYear, close, open, selectedCost }: ModalCostProps) => {
   const dataContext = useContext(DataContext)
   const { posts, branch } = dataContext.state
   const costForm = useForm<Cost>({ disabled: financialYear.status === FinancialYearStatus.Completed })
   const {
      control,
      reset,
      watch,
      handleSubmit,
      formState: { isSubmitting, disabled },
   } = costForm
   useEffect(() => {
      if (!posts) {
         ;(async () => await fetchPosts(dataContext))()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   useEffect(() => {
      if (selectedCost) {
         reset(selectedCost)
      } else {
         reset({ branch: branch })
      }
   }, [selectedCost, reset, branch])
   const onSubmit = async (data: Cost) => {
      await saveCost(setFinancialYearState, dataContext, data, financialYear.id, reset, close)
   }
   return (
      <Modal title={selectedCost ? t('EditCost') : t('AddCost')} open={open}>
         <form onSubmit={handleSubmit(onSubmit)}>
            {posts ? (
               <Grid>
                  <GridItem size="12/12">
                     <Divider appearance="brand" />
                  </GridItem>
                  <GridItem size="12/12">
                     <Field label={t('Branch')} required>
                        <Dropdown size="large" disabled value={watch('branch') ? watch('branch').name : ''}></Dropdown>
                     </Field>
                  </GridItem>
                  <GridItem size="12/12">
                     <PostDropdown costForm={costForm} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledMoneyInput label={t('Budget')} control={control} name={'budget'} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledMoneyInput label={t('Result')} control={control} name={'result'} />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('Description')} control={control} name={'description'} multiline />
                  </GridItem>
                  <GridItem size="12/12">
                     <ModalActions>
                        <Button
                           onClick={() => {
                              reset()
                              close()
                           }}
                        >
                           {disabled ? t('Close') : t('Cancel')}
                        </Button>
                        {!disabled && (
                           <Button appearance="primary" disabled={isSubmitting} type="submit">
                              {t('Save')}
                           </Button>
                        )}
                     </ModalActions>
                  </GridItem>
               </Grid>
            ) : null}
         </form>
      </Modal>
   )
}
