import React, { useContext, useState } from 'react'
import { makeStyles, tokens, ComboboxProps, Combobox, Option, mergeClasses } from '@fluentui/react-components'
import { DemandAnalysis, Process, UserAccount } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { Text } from '../common/text/Text'
import { Person20Regular } from '@fluentui/react-icons'
import { UseFormReturn } from 'react-hook-form'
import { getFullName } from '../../helpers/accountHelper'
import { Field } from '../common/inputs/Field'
const useStyles = makeStyles({
   processField: {
      position: 'relative',
      gridTemplateColumns: '1fr 1fr',
      columnGap: tokens.spacingHorizontalM,
      '& label': {
         textAlign: 'right',
      },
      '@media screen and (max-width: 1007px)': {
         '& label': {
            textAlign: 'left',
         },
         gridTemplateColumns: '1fr',
      },
   },
   comboBox: {
      '@media screen and (max-width: 1007px)': {
         width: '100%',
      },
   },
   proccessFieldIconPadding: {
      '& input': {
         paddingLeft: tokens.spacingHorizontalXXL,
      },
   },
   flex: {
      display: 'flex',
      alignItems: 'center',
      gap: tokens.spacingHorizontalS,
   },
   icon: {
      position: 'absolute',
      zIndex: 1,
      top: '8px',
      marginLeft: '4px',
      '@media screen and (max-width: 1007px)': {
         top: '48px',
      },
   },
})
type ProcessOwnerPickerFormProps = {
   demandAssessmentForm: UseFormReturn<DemandAnalysis, any, undefined>
   processes: Process[]
   process: string
}
export const ProcessOwnerPicker = ({ demandAssessmentForm, processes, process }: ProcessOwnerPickerFormProps) => {
   const { setValue, watch } = demandAssessmentForm
   const dataContext = useContext(DataContext)
   const { userAccount, userAccounts } = dataContext.state
   const classes = useStyles()
   const [matchingOptions, setMatchingOptions] = useState<UserAccount[]>(userAccounts)
   const processObj = processes.find((x) => x.text === process)
   const processOwners = watch('processOwners') ?? []
   const processOwner = processOwners.find((po) => po.process.text === process)
   const titleOrFreeText = processOwner
      ? processOwner.userAccount
         ? getFullName(processOwner.userAccount)
         : processOwner.ownerFreeText
      : getFullName(userAccount)
   const poOrUserAcc = processOwner ? (processOwner.userAccount ? processOwner.userAccount.id : processOwner.ownerFreeText) : userAccount.id
   const [input, setInput] = useState<string>(titleOrFreeText)
   const onInput: ComboboxProps['onChange'] = (event) => {
      const value = event.target.value
      setValue(
         'processOwners',
         processOwners.map((x) =>
            x.process.text === process
               ? {
                    ...x,
                    processId: processObj.id,
                    process: processObj,
                    userAccountId: null,
                    userAccount: null,
                    ownerFreeText: value,
                 }
               : x
         )
      )
      setInput(value)
      let matches = userAccounts
      if (value.length > 0) {
         matches = userAccounts.filter((userAccount) => getFullName(userAccount).toLowerCase().indexOf(value.toLowerCase()) === 0)
      }
      setMatchingOptions(matches)
   }

   return (
      <Field
         label={process}
         className={mergeClasses(classes.processField, processOwner && processOwner.userAccount ? classes.proccessFieldIconPadding : '')}
         orientation="horizontal"
      >
         <div>
            {processOwner && processOwner.userAccount ? <Person20Regular className={classes.icon} /> : null}
            <Combobox
               key={process}
               className={classes.comboBox}
               defaultSelectedOptions={[poOrUserAcc]}
               defaultValue={getFullName(userAccount)}
               value={input}
               freeform
               onChange={onInput}
               onOptionSelect={(e, data) => {
                  const processOwners = watch('processOwners') ?? []
                  const userAccount = userAccounts.find((ua) => ua.id === data.optionValue)

                  setValue(
                     'processOwners',
                     processOwners.map((x) =>
                        x.process.text === process
                           ? {
                                ...x,
                                processId: processObj.id,
                                process: processObj,
                                userAccountId: data.optionValue,
                                userAccount,
                                ownerFreeText: null,
                             }
                           : x
                     )
                  )
                  setInput(getFullName(userAccount))
               }}
            >
               {matchingOptions.map((account) => (
                  <Option key={account.id} value={account.id} text={getFullName(account)}>
                     <span className={classes.flex}>
                        <Person20Regular />
                        <Text>{getFullName(account)}</Text>
                     </span>
                  </Option>
               ))}
            </Combobox>
         </div>
      </Field>
   )
}
