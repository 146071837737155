import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Dropdown, makeStyles, mergeClasses, Option, tokens } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { useSearchParams } from 'react-router-dom'
import { enumValuesToOptions, isValidEnumValue } from '../../helpers/enumHelper'
import { CostCategory, FinancialYear } from '../../api/schemas/schema'
import { ArrowExport20Regular } from '@fluentui/react-icons'
import { exportCostsToExcel } from '../../helpers/excelHelper'

const useStyles = makeStyles({
   filterWrapper: {
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      '@media screen and (max-width: 1007px)': {
         gap: tokens.spacingHorizontalS,
      },
      '@media screen and (max-width: 640px)': {
         flexDirection: 'column',
         width: '100%',
      },
   },
   dropdown: {
      '@media screen and (max-width: 1007px)': {
         width: '50%',
      },
      '@media screen and (max-width: 640px)': {
         width: '100%',
      },
   },
   exportBtn: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
   },
})
type CostFiltersProps = {
   financialYear: FinancialYear
   className?: string
}
export enum CostTypeFilter {
   Budget,
   Result,
}
export const CostFilters = ({ financialYear, className }: CostFiltersProps) => {
   const classes = useStyles()
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedCategory = searchParams.get('category')
   const selectedCostType = searchParams.get('type')

   const updateParam = useCallback(
      (key: string, value: string | null) => {
         if (value) {
            searchParams.set(key, value)
         } else {
            searchParams.delete(key)
         }
         setSearchParams(searchParams)
      },
      [searchParams, setSearchParams]
   )
   useEffect(() => {
      if (selectedCategory && !isValidEnumValue(CostCategory, selectedCategory)) {
         updateParam('category', null)
      }
      if (selectedCostType && !isValidEnumValue(CostTypeFilter, selectedCostType)) {
         updateParam('type', null)
      }
   }, [selectedCategory, selectedCostType, updateParam])

   const categoryOptions = useMemo(() => enumValuesToOptions(CostCategory), [])
   const typeOptions = useMemo(() => enumValuesToOptions(CostTypeFilter), [])
   return (
      <div className={mergeClasses(classes.wrapper, className ?? '')}>
         <div className={mergeClasses(classes.filterWrapper, className ?? '')}>
            <Dropdown
               aria-label={t('FilterByCategory')}
               className={classes.dropdown}
               placeholder={t('FilterByCategory')}
               value={selectedCategory ?? null}
               selectedOptions={selectedCategory ? [selectedCategory] : []}
               onOptionSelect={(e, data) => updateParam('category', data.optionValue)}
               clearable
            >
               {categoryOptions.map((x) => (
                  <Option key={x.text} value={x.value}>
                     {x.text}
                  </Option>
               ))}
            </Dropdown>
            <Dropdown
               aria-label={t('FilterOnBudgetOrResult')}
               className={classes.dropdown}
               placeholder={t('FilterOnBudgetOrResult')}
               value={selectedCostType ?? null}
               selectedOptions={selectedCostType ? [selectedCostType] : []}
               onOptionSelect={(e, data) => updateParam('type', data.optionValue)}
               clearable
            >
               {typeOptions.map((x) => (
                  <Option key={x.text} value={x.value}>
                     {x.text}
                  </Option>
               ))}
            </Dropdown>
         </div>
         <Button appearance="outline" icon={<ArrowExport20Regular />} className={classes.exportBtn} onClick={() => exportCostsToExcel(financialYear)}>
            {t('ExportToExcel')}
         </Button>
      </div>
   )
}
