import React, { useEffect, useState } from 'react'
import { t } from '../../i18n/i18n'
import { SummaryRow } from '../common/list/SummaryRow'
import { List } from '../common/list/List'
import { getCostIdentifyingColumns, getCostSummaryFields } from '../../helpers/riskEconomyHelper'
import { Button, makeStyles, Text, tokens } from '@fluentui/react-components'
import { Cost, FinancialYear, FinancialYearStatus } from '../../api/schemas/schema'
import { formatDate, formatToSwedishCrowns } from '../../helpers/stringHelper'
import { BadgeCostCategory } from './BadgeCostCategory'
import { useSearchParams } from 'react-router-dom'
import { CostFilters } from './CostFilters'
import { InfoMessageBar } from '../common/other/InfoMessageBar'
import { AddCircle24Regular } from '@fluentui/react-icons'
import { ModalCost } from './ModalCost'
import { IItemState } from '../../interfaces/IItemState'
import { filterCosts } from '../../helpers/financialYearHelper'
import { CardHeader } from '../common/header/CardHeader'

type CostIdentifyingProps = {
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
   financialYear: FinancialYear
}
const useStyles = makeStyles({
   phoneItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
   phoneMoneyOutputWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '50%',
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   hideInDesktop: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
      },
   },
   addBtn: {
      '@media screen and (max-width: 1007px)': {
         width: '100%',
      },
   },
})
export const CostIdentifying = ({ setFinancialYearState, financialYear }: CostIdentifyingProps) => {
   const classes = useStyles()
   const { costs } = financialYear
   const [filteredItems, setFilteredItems] = useState<Cost[]>(costs)
   const [searchParams] = useSearchParams()
   const selectedCategory = searchParams.get('category')
   const selectedCostType = searchParams.get('type')
   const [modalOpen, setModalOpen] = useState<boolean>(false)
   const [selectedCost, setSelectedCost] = useState<Cost>(null)

   useEffect(() => {
      setFilteredItems(filterCosts(costs, selectedCategory, selectedCostType))
   }, [costs, selectedCategory, selectedCostType])
   const onRowClick = (item: Cost) => {
      setSelectedCost(item)
      setModalOpen(true)
   }
   return (
      <>
         <CardHeader
            title={t('IdentifyCosts')}
            action={
               <Button className={classes.addBtn} appearance="primary" icon={<AddCircle24Regular />} onClick={() => setModalOpen(true)} disabled={financialYear.status === FinancialYearStatus.Completed}>
                  {t('AddCost')}
               </Button>
            }
         >
            <CostFilters className={classes.hideInPhone} financialYear={financialYear} />
         </CardHeader>
         <SummaryRow summaryFields={getCostSummaryFields(filteredItems)} />
         <CostFilters className={classes.hideInDesktop} financialYear={financialYear} />
         <div>
            {costs.length === 0 ? (
               <InfoMessageBar title={t('TimeToAddYourFirstCost')} />
            ) : (
               <List
                  columns={getCostIdentifyingColumns()}
                  items={filteredItems}
                  columnSizingOptions={{
                     date: { minWidth: 80, idealWidth: 150 },
                     category: { minWidth: 150, idealWidth: 200 },
                     post: { minWidth: 150, idealWidth: 300 },
                     description: { minWidth: 400, idealWidth: 1200 },
                     budget: { minWidth: 100, idealWidth: 200 },
                     result: { minWidth: 100, idealWidth: 200 },
                  }}
                  resizableColumns
                  onRowClick={onRowClick}
                  renderPhoneBodyItem={(item, index) => {
                     return (
                        <div className={classes.phoneItem} key={item.id}>
                           <span className={classes.spaceBtw}>
                              <Text size={400} weight="bold">
                                 {formatDate(item.created)}
                              </Text>
                              <BadgeCostCategory category={item.post.costCategory} />
                           </span>
                           <Text size={400}>{item.post.name ?? '-'}</Text>
                           <span className={classes.phoneMoneyOutputWrapper}>
                              <Text size={400} weight="bold">{`${t('Budget')} `}</Text>
                              <Text size={400}>{formatToSwedishCrowns(item.budget ?? 0) + ' kr'}</Text>
                           </span>
                           <span className={classes.phoneMoneyOutputWrapper}>
                              <Text size={400} weight="bold">{`${t('Result')} `}</Text>
                              <Text size={400}>{formatToSwedishCrowns(item.result ?? 0) + ' kr'}</Text>
                           </span>
                        </div>
                     )
                  }}
               />
            )}
         </div>
         <ModalCost
            setFinancialYearState={setFinancialYearState}
            financialYear={financialYear}
            close={() => {
               setModalOpen(false)
               setSelectedCost(null)
            }}
            selectedCost={selectedCost}
            open={modalOpen}
         />
      </>
   )
}
