import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { Button, Divider } from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { RiskAnalysis, RiskAnalysisStatus, SWOT, SWOTType } from '../../../api/schemas/schema'
import { enumValuesToOptions } from '../../../helpers/enumHelper'
import { ControlledDropDown } from '../../common/inputs/ControlledDropdown'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { GridItem } from '../../common/grid/GridItem'
import { Grid } from '../../common/grid/Grid'
import { api } from '../../../helpers/apiHelper'
import { DataContext } from '../../../context/DataContext'
import { handleMessage } from '../../../helpers/stateHelper'
import { useSearchParams } from 'react-router-dom'
import { isValidSwotType } from '../../../helpers/swotHelper'
import { IItemState } from '../../../interfaces/IItemState'

type ModalSWOTProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
   open: boolean
   close: () => void
   selectedItem: SWOT
}
export const ModalSWOT = ({ setRiskAnalysisState, analysis, open, close, selectedItem }: ModalSWOTProps) => {
   const {
      control,
      reset,
      handleSubmit,
      setValue,
      formState: { disabled, isSubmitting },
   } = useForm<SWOT>({ disabled: analysis.status === RiskAnalysisStatus.Determined })
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const [searchParams] = useSearchParams()
   const selectedType = searchParams.get('type')

   useEffect(() => {
      if (selectedItem) reset(selectedItem)
      else reset({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedItem])
   useEffect(() => {
      if (!open) {
         reset({})
      } else if (selectedType && !selectedItem && isValidSwotType(Number(selectedType))) {
         setValue('type', Number(selectedType))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [open, selectedItem, selectedType])

   const onSubmit = async (data: SWOT) => {
      try {
         let riskAnalysis: RiskAnalysis
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id
         if (data.id) {
            riskAnalysis = (await api(accessToken).updateSwotForRiskAnalysis(analysis.id, data)).data
         } else {
            data.createdBy = userAccount
            data.createdById = userAccount.id
            riskAnalysis = (await api(accessToken).addSwotToRiskAnalysis(analysis.id, data)).data
         }
         close()
         setRiskAnalysisState((prev) => ({ ...prev, item: riskAnalysis }))
         reset()
         handleMessage(dataContext.setRootState, 'success', t(data.id ? 'ItemSuccessfullyUpdated' : 'ItemSuccessfullySaved', { Item: t('SWOT') }))
      } catch (error) {
         handleMessage(dataContext.setRootState, 'error', t(data.id ? 'CouldNotUpdateItem' : 'CouldNotCreateItem', { Item: t('SWOT') }))
      }
   }
   return (
      <Modal open={open} title={t('Identify')} modalType="modal">
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
               <GridItem size="12/12">
                  <Divider appearance="brand" />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledDropDown control={control} label={t('SWOT')} name={'type'} options={enumValuesToOptions(SWOTType)} required />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledTextInput control={control} label={t('Title')} name={'title'} required />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledTextInput control={control} label={t('Description')} name={'description'} multiline required />
               </GridItem>
               <GridItem size="12/12">
                  <ModalActions>
                     <Button
                        onClick={() => {
                           close()
                           reset()
                        }}
                     >
                        {disabled ? t('Close') : t('Cancel')}
                     </Button>
                     {!disabled && (
                        <Button type="submit" appearance="primary" disabled={isSubmitting}>
                           {t('Save')}
                        </Button>
                     )}
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
