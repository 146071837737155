import React from 'react'
import { TableColumnDefinition } from '@fluentui/react-components'
import { Activity, DemandAnalysisDashboardDTO } from '../api/schemas/schema'
import { t } from 'i18next'
import { ActivityStatusBadge } from '../components/dashboard/ActivityStatusBadge'
import { IDataContext } from '../interfaces/IDataContext'
import { handleMessage } from './stateHelper'
import { api } from './apiHelper'
import { formatDate } from './stringHelper'

export const getLatestActivities = async (dataContext: IDataContext, setActivities: React.Dispatch<React.SetStateAction<Activity[]>>) => {
   const { accessToken, organisation } = dataContext.state
   try {
      const activites = (await api(accessToken).getLatestActivites(organisation.id)).data

      if (activites) {
         setActivities(activites)
      }
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotFetchItem', { Item: t('Activities') }))
   }
}

export const getDemandAnalysisDashboardData = async (dataContext: IDataContext, setDashboardData: React.Dispatch<React.SetStateAction<DemandAnalysisDashboardDTO>>) => {
   const { accessToken, organisation } = dataContext.state
   try {
      const data = (await api(accessToken).getDemandAnalysisDashboardData(organisation.id)).data

      if (data) {
         setDashboardData(data)
      }
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotFetchItem', { Item: t('OverviewData') }))
   }
}

export const getSortedLatestActivities = (activities: Activity[]) => {
   const today = new Date()

   const activeActivities = activities
      .filter((activity) => activity.endDate && new Date(activity.endDate) >= today)
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

   if (activeActivities.length >= 3) {
      return activeActivities.slice(0, 3)
   }

   const remaining = 3 - activeActivities.length

   const inactiveActivities = activities
      .filter((activity) => activity.endDate && new Date(activity.endDate) < today)
      .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())

   return activeActivities.concat(inactiveActivities.slice(0, remaining))
}

export const getDashboardActiviyColumns = (): TableColumnDefinition<Activity>[] => [
   {
      columnId: 'status',
      renderHeaderCell: () => '',
      renderCell: (item) => <ActivityStatusBadge item={item} />,
      compare: (a, b) => -1,
   },
   {
      columnId: 'title',
      renderHeaderCell: () => '',
      renderCell: (item) => item.title,
      compare: (a, b) => a.title.localeCompare(b.title),
   },
   {
      columnId: 'startDate',
      renderHeaderCell: () => t('StartDate'),
      renderCell: (item) => formatDate(item.startDate),
      compare: (a, b) => a.startDate.localeCompare(b.startDate),
   },
   {
      columnId: 'endDate',
      renderHeaderCell: () => t('EndDate'),
      renderCell: (item) => formatDate(item.endDate),
      compare: (a, b) => a.title.localeCompare(b.endDate),
   },
]
