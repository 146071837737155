import React from 'react'
import { MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'

type InfoMessageBarProps = {
   title: string
   message?: string
}
export const InfoMessageBar = ({ title, message }: InfoMessageBarProps) => {
   return (
      <MessageBar intent={'info'}>
         <MessageBarBody>
            <MessageBarTitle>{title}</MessageBarTitle>
            {message ?? null}
         </MessageBarBody>
      </MessageBar>
   )
}
