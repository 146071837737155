import React, { useState } from 'react'
import { makeStyles, mergeClasses, Text, tokens } from '@fluentui/react-components'
import { getTypeLanguageString, groupSWOTsByType, SWOTColors } from '../../../helpers/swotHelper'
import { SWOT, SWOTType } from '../../../api/schemas/schema'
import { t } from 'i18next'

const useStyles = makeStyles({
   strengthSquare: {
      border: `5px solid ${SWOTColors.Strength}`,
      position: 'relative',
   },
   weaknessSquare: {
      border: `5px solid ${SWOTColors.Weakness}`,
      position: 'relative',
   },
   opportunitySquare: {
      border: `5px solid ${SWOTColors.Opportunity}`,
      position: 'relative',
   },
   threatSquare: {
      border: `5px solid ${SWOTColors.Threat}`,
      position: 'relative',
   },
   strengthBg: { backgroundColor: `${SWOTColors.Strength}` },
   weaknessBg: { backgroundColor: `${SWOTColors.Weakness}` },
   opportunityBg: { backgroundColor: `${SWOTColors.Opportunity}` },
   threatBg: { backgroundColor: `${SWOTColors.Threat}` },
   gridWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gap: tokens.spacingHorizontalS,
      height: '100%',
   },
   infoSquare: {
      display: 'flex',
      flexDirection: 'column',
      padding: tokens.spacingHorizontalL,
      minHeight: '225px',
      borderRadius: tokens.borderRadiusMedium,
   },
   alignRight: {
      alignItems: 'end',
   },
   letterSquare: {
      width: '75px',
      height: '75px',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: tokens.colorBrandBackground,
      fontSize: '64px',
   },
   topRight: { top: '-10px', right: '-10px' },
   topLeft: { top: '-10px', left: '-10px' },
   bottomRight: { bottom: '-10px', right: '-10px' },
   bottomLeft: { bottom: '-10px', left: '-10px' },
   textColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalM,
   },
   typeTitle: {
      margin: 0,
      marginBottom: tokens.spacingVerticalL,
   },
   textRight: {
      textAlign: 'right',
      paddingLeft: '0px',
   },
})

type AnalysEvaluateGraphViewProps = {
   swots: SWOT[]
}

export const AnalysEvaluateGraphView = ({ swots }: AnalysEvaluateGraphViewProps) => {
   const classes = useStyles()

   const getSWOTTypeBorderClass = (type: SWOTType): string => {
      switch (type) {
         case SWOTType.Strength:
            return classes.strengthSquare
         case SWOTType.Weakness:
            return classes.weaknessSquare
         case SWOTType.Opportunity:
            return classes.opportunitySquare
         case SWOTType.Threat:
            return classes.threatSquare
         default:
            throw new Error(`Unsupported SWOTType: ${type}`)
      }
   }

   const getSWOTTypeBackgroundClass = (type: SWOTType): string => {
      switch (type) {
         case SWOTType.Strength:
            return classes.strengthBg
         case SWOTType.Weakness:
            return classes.weaknessBg
         case SWOTType.Opportunity:
            return classes.opportunityBg
         case SWOTType.Threat:
            return classes.threatBg
         default:
            throw new Error(`Unsupported SWOTType: ${type}`)
      }
   }

   const getPositionClass = (type: SWOTType): string => {
      switch (type) {
         case SWOTType.Strength:
            return classes.bottomRight
         case SWOTType.Weakness:
            return classes.bottomLeft
         case SWOTType.Opportunity:
            return classes.topRight
         case SWOTType.Threat:
            return classes.topLeft
      }
   }

   const isOdd = (value: number): boolean => {
      return value % 2 !== 0
   }

   const [groupedItems] = useState(() => groupSWOTsByType(swots))

   const swotOrder = Object.keys(SWOTType).filter((key) => isNaN(Number(key)))
   return (
      <section className={classes.gridWrapper}>
         {swotOrder.map((typeKey, groupIndex) => {
            const type = SWOTType[typeKey as keyof typeof SWOTType]
            const items = groupedItems[typeKey] || []
            const positionClass = getPositionClass(type)

            return (
               <article key={typeKey} className={mergeClasses(classes.infoSquare, getSWOTTypeBorderClass(type), isOdd(groupIndex) ? classes.alignRight : '')}>
                  <Text as="h3" size={500} weight="semibold" className={classes.typeTitle}>
                     {getTypeLanguageString(typeKey)}
                  </Text>

                  <div className={classes.textColumn}>
                     {items.length > 0 ? (
                        items.slice(0, 5).map((item, itemIndex) => (
                           <Text size={400} className={isOdd(groupIndex) ? classes.textRight : ''} key={itemIndex}>
                              {item.title}
                           </Text>
                        ))
                     ) : (
                        <Text italic>{t('NoTypeIdentified', { type: getTypeLanguageString(typeKey).toLowerCase() })}</Text>
                     )}
                  </div>
                  <Text weight="bold" size={900} className={mergeClasses(classes.letterSquare, getSWOTTypeBackgroundClass(type), positionClass)}>
                     {typeKey[0]}
                  </Text>
               </article>
            )
         })}
      </section>
   )
}
