import React from 'react'
import { tokens } from '@fluentui/react-components'
import { PieChart, Pie, Cell } from 'recharts'

const RADIAN = Math.PI / 180
const pieSlices = [
   { name: 'Red', value: 20, color: tokens.colorPaletteRedBackground3 },
   { name: 'Orange', value: 20, color: '#DE6C1C' },
   { name: 'Yellow', value: 20, color: tokens.colorPaletteMarigoldBackground3 },
   { name: 'Light green', value: 20, color: '#7FA207' },
   { name: 'Green', value: 20, color: tokens.colorPaletteLightGreenBackground3 },
]

const arrowNeedle = (value: number, pieSlices: any[], centerX: number, centerY: number, innerRadius: number, arrowColor:string) => {
   let total = pieSlices.reduce((sum, v) => sum + v.value, 0)

   const angle = 180.0 * (1 - value / total)
   const angleRadian = -RADIAN * angle

   const needleLength = innerRadius * 0.9
   const triangleWidth = 25
   const triangleHeight = 20
   const circleRadius = 10

   const xTriangleTip = centerX + needleLength * Math.cos(angleRadian)
   const yTriangleTip = centerY + needleLength * Math.sin(angleRadian)
   const xTriangleBaseCenter = xTriangleTip - triangleHeight * Math.cos(angleRadian)
   const yTriangleBaseCenter = yTriangleTip - triangleHeight * Math.sin(angleRadian)

   // Perpendicular vector for triangle base
   const halfTriangleBaseWidth = triangleWidth / 2
   const perpX = -Math.sin(angleRadian) * halfTriangleBaseWidth
   const perpY = Math.cos(angleRadian) * halfTriangleBaseWidth

   // Left and right points of triangle base
   const xLeft = xTriangleBaseCenter + perpX
   const yLeft = yTriangleBaseCenter + perpY
   const xRight = xTriangleBaseCenter - perpX
   const yRight = yTriangleBaseCenter - perpY

   return [
      <circle key="needle-base" cx={centerX} cy={centerY - 5} r={circleRadius} fill={arrowColor} />,
      <line key="needle-line" x1={centerX} y1={centerY - 5} x2={xTriangleBaseCenter} y2={yTriangleBaseCenter} stroke={arrowColor} strokeWidth={5} />,
      <path key="needle-triangle" d={`M${xLeft},${yLeft} L${xTriangleTip},${yTriangleTip} L${xRight},${yRight} Z`} fill={arrowColor} />,
   ]
}

type MeterChartProps = {
   needleValue: number
   className?: string
}
export const MeterChart = ({ needleValue, className }: MeterChartProps) => {
   const arrowColor = tokens.colorNeutralStrokeDisabled
   const centerX = 155
   const centerY = 150
   const innerRadius = 80
   const outerRadius = 120

   return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={className ? className : ''}>
         <PieChart width={310} height={155}>
            <Pie
               data={pieSlices}
               startAngle={180}
               endAngle={0}
               cx={centerX - 5}
               cy={centerY}
               innerRadius={innerRadius}
               outerRadius={outerRadius}
               stroke="none"
               dataKey={'value'}
            >
               {pieSlices.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
               ))}
            </Pie>
            {arrowNeedle(needleValue, pieSlices, centerX, centerY, innerRadius, arrowColor)}
         </PieChart>
      </div>
   )
}
