import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Layout } from './Layout'
import { Text } from '../components/common/text/Text'
import { t } from '../i18n/i18n'
import { makeStyles, Tab, tokens } from '@fluentui/react-components'
import { Navigation } from '../components/common/navigation/Navigation'
import { useSearchParams } from 'react-router-dom'
import { ISettingsState } from '../interfaces/ISettingsState'
import { getSettingsColumnsByTab, getSettingsNoItemsTextByTab, getSettingsTitleByTab, isValidSettingsTab, SettingsTabs } from '../helpers/settingsHelper'
import { api } from '../helpers/apiHelper'
import { DataContext } from '../context/DataContext'
import { SettingsOverview } from '../components/common/overview/SettingsOverview'
import { Branch, Process, ProtectedAssetGoalType } from '../api/schemas/schema'
import { formatToSwedishCrowns } from '../helpers/stringHelper'
import { handleMessage } from '../helpers/stateHelper'
const useStyles = makeStyles({
   versionText: {
      position: 'absolute',
      bottom: '-70px',
      right: '10px',
      '@media screen and (max-width: 1007px)': {
         bottom: '-100px',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '30%',
         },
         ':nth-child(2)': {
            width: '30%',
            minWidth: '300px',
         },
         ':nth-child(3)': {
            width: '15%',
            minWidth: '150px',
         },
         ':nth-child(4)': {
            width: '15%',
            minWidth: '150px',
         },
         ':nth-child(5)': {
            width: '15%',
            minWidth: '150px',
         },
      },
   },
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 1,
      '@media screen and (max-width: 1007px)': {
         WebkitLineClamp: 2,
      },
   },
   phoneItem: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
      },
      flexDirection: 'column',
      padding: `0 ${tokens.spacingHorizontalS}`,
      borderBottom: `1px solid ${tokens.colorBrandStroke2}`,
      paddingBottom: tokens.spacingVerticalL,
      '& > *': {
         marginBottom: tokens.spacingVerticalXXS,
      },
   },
})

export const Settings = () => {
   const classes = useStyles()
   const packageJson = require('../../package.json')
   const appVersion = packageJson.version
   const dataContext = useContext(DataContext)
   const { accessToken, organisation, protectedAssetGoals } = dataContext.state
   const [settingsState, setSettingsState] = useState<ISettingsState>({
      loading: true,
      selectedItem: null,
      filteredItems: [],
      allItems: [],
   })
   const { loading, filteredItems, allItems } = settingsState
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const selectedProcess = searchParams.get('process')
   const processes = useMemo(() => {
      const usedProcesses = new Set(allItems.map((asset) => asset.process).filter(Boolean))
      return [t('AllProcesses'), ...Array.from(usedProcesses)]
   }, [allItems])
   const [allProcesses, setAllProcesses] = useState<Process[]>([])
   const [allBranches, setAllBranches] = useState<Branch[]>([])

   const setTab = (tab: string) => {
      searchParams.set('tab', tab)
      setSearchParams(searchParams)
   }

   const setProcess = (process: string) => {
      searchParams.set('process', process)
      setSearchParams(searchParams)
   }

   const phoneBody = (item: any, index: number, onRowClick: (item: any) => void) => (
      <div className={classes.phoneItem} key={index} onClick={() => onRowClick(item)}>
         <Text size={400} weight="semibold">
            {item.name}
         </Text>
         <Text size={400}>{`${formatToSwedishCrowns(item.valuation)} kr`}</Text>
         <Text size={400}>{item.process}</Text>
         <Text size={400} className={classes.ellipsis}>
            {item.description}
         </Text>
      </div>
   )

   useEffect(() => {
      ;(async () => {
         try {
            const [processesResponse, branchesResponse] = await Promise.all([
               api(accessToken).getAllProcesss(),
               api(accessToken).getBranchesByOrganisationId(organisation.id),
            ])

            setAllProcesses(processesResponse.data)
            setAllBranches(branchesResponse.data)
         } catch (error) {
            handleMessage(dataContext.setRootState, 'error', t('SomethingWentWrong'), t('CouldNotFetchData'))
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [accessToken, organisation.id])

   useEffect(() => {
      if (!selectedTab || !isValidSettingsTab(selectedTab)) {
         setTab(SettingsTabs.ProtectedAssets)
      }
      if (!selectedProcess) {
         setProcess(t('AllProcesses'))
      }
      const selectedTabItems =
         selectedTab === SettingsTabs.ProtectedAssets
            ? protectedAssetGoals.filter((p) => p.type === ProtectedAssetGoalType.ProtectedAsset)
            : selectedTab === SettingsTabs.OrganisationalGoals
            ? protectedAssetGoals.filter((p) => p.type === ProtectedAssetGoalType.OrganisationalGoal)
            : allItems

      const filteredItems =
         selectedProcess === t('AllProcesses') || selectedProcess === null
            ? selectedTabItems
            : selectedTabItems.filter((asset) => asset.process === selectedProcess)

      setSettingsState((prev) => ({ ...prev, loading: false, allItems: selectedTabItems, filteredItems: filteredItems }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [protectedAssetGoals, selectedTab, selectedProcess])

   return (
      <Layout breadCrumbItems={[{ path: '/settings', title: t('SettingsAndRegister'), active: true }]}>
         <Navigation activeDesktopTab="/settings" />
         <SettingsOverview
            setSettingsState={setSettingsState}
            setProcess={setProcess}
            tabs={
               <>
                  <Tab value={SettingsTabs.ProtectedAssets}>{t('ProtectedAssets')}</Tab>
                  <Tab value={SettingsTabs.OrganisationalGoals}>{t('OrganisationalGoals')}</Tab>
                  {/* <Tab value={SettingsTabs.Branches}>{t('Branches')}</Tab>
                  <Tab value={SettingsTabs.Roles}>{t('Roles')}</Tab> */}
               </>
            }
            columns={getSettingsColumnsByTab(selectedTab)}
            items={filteredItems}
            noItemsText={getSettingsNoItemsTextByTab(selectedTab)}
            title={getSettingsTitleByTab(selectedTab)}
            rowClassName={classes.row}
            loading={loading}
            processes={processes}
            allProcesses={allProcesses}
            allBranches={allBranches}
            phoneBody={phoneBody}
         />

         <Text size={200} className={classes.versionText}>
            Version: {appVersion}
         </Text>
      </Layout>
   )
}
