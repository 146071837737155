import { MessageBar, MessageBarBody, MessageBarTitle, Button, makeStyles, tokens, Text } from '@fluentui/react-components'
import { ArrowCircleDownUp20Regular, LockClosed20Regular } from '@fluentui/react-icons'
import React from 'react'
import { getFilteredSWOTs, getSWOTAnalyseEvaluateColumns } from '../../../helpers/swotHelper'
import { SectionedList } from '../../common/list/SectionedList'
import { AnalysEvaluateGraphView } from './AnalysEvaluateGraphView'
import { RiskAnalysis, SWOT } from '../../../api/schemas/schema'
import { useSearchParams } from 'react-router-dom'
import { SwotTypeBadge } from '../RiskBadges'
import { t } from '../../../i18n/i18n'

const useStyles = makeStyles({
   phoneButtons: {
      display: 'none',
      justifyContent: 'space-between',
      '& > * ': {
         fontSize: tokens.fontSizeBase200,
         minWidth: '48%',
      },
      '@media screen and (max-width: 640px)': {
         display: 'flex',
      },
   },
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 1,
      '@media screen and (max-width: 640px)': {
         WebkitLineClamp: 2,
      },
   },
   phoneItemHeader: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   marginBottom: {
      marginBottom: tokens.spacingVerticalL,
   },
   marginRight: {
      marginRight: tokens.spacingHorizontalM,
   },
})

type AnalyseEvaluateListProps = {
   analysis: RiskAnalysis
   graphView: boolean
   disabled: boolean
   toggleModal: (modalName: string, isOpen: boolean) => void
   toggleCheckbox: (swot: SWOT, openModal?: boolean) => SWOT
   setSelectedSwot: React.Dispatch<React.SetStateAction<SWOT>>
}

export const AnalyseEvaluateList = ({ analysis, graphView, disabled, toggleModal, toggleCheckbox, setSelectedSwot }: AnalyseEvaluateListProps) => {
   const [searchParams] = useSearchParams()
   const selectedType = searchParams.get('type')
   const classes = useStyles()

   const phoneBody = (item: SWOT) => {
      return (
         <>
            <span className={classes.phoneItemHeader}>
               <div className={classes.marginBottom}>
                  <Text weight="bold" className={classes.marginRight}>
                     {item.order ?? ''}
                  </Text>
                  <Text weight="bold">{item.title}</Text>
               </div>
               <SwotTypeBadge type={item.type} />
            </span>
            <Text className={classes.ellipsis}>{item.description}</Text>
         </>
      )
   }

   const onRowClick = (swot: SWOT) => {
      setSelectedSwot(swot)
      toggleModal('evaluateModal', true)
   }

   const onActionRequiredClick = (swot: SWOT, columnKey: keyof SWOT) => {
      if (columnKey === 'actionRequired' && !disabled) {
         swot = toggleCheckbox(swot, true)
      }
      onRowClick(swot)
   }

   return (
      <>
         {analysis.swoTs.length < 1 ? (
            <MessageBar>
               <MessageBarBody>
                  <MessageBarTitle>{t('TimeToAddYourFirstSWOT')}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         ) : getFilteredSWOTs(analysis.swoTs, selectedType).length === 0 && !graphView ? (
            <MessageBar>
               <MessageBarBody>
                  <MessageBarTitle>{t('NoSWOTMatchingSelectedFilter')}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         ) : graphView ? (
            <AnalysEvaluateGraphView swots={analysis.swoTs} />
         ) : (
            <>
               <div className={classes.phoneButtons}>
                  <Button
                     onClick={() => {
                        toggleModal('determineModal', true)
                     }}
                     appearance="outline"
                     icon={<LockClosed20Regular />}
                     disabled={disabled}
                  >
                     {t('DetermineAnalysis')}
                  </Button>
                  <Button
                     onClick={() => {
                        toggleModal('changeOrderModal', true)
                     }}
                     appearance="outline"
                     icon={<ArrowCircleDownUp20Regular />}
                     disabled={disabled}
                  >
                     {t('ChangeOrder')}
                  </Button>
               </div>
               <SectionedList
                  columns={getSWOTAnalyseEvaluateColumns(classes)}
                  items={getFilteredSWOTs(analysis.swoTs, selectedType)}
                  groupKey={'type'}
                  onCellClick={onActionRequiredClick}
                  renderPhoneBody={phoneBody}
                  onRowClick={onRowClick}
               />
            </>
         )}
      </>
   )
}
