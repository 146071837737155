import React from 'react'
import { ClipboardLink20Regular, Open20Regular, ClipboardLink20Filled } from '@fluentui/react-icons'
import { Link } from '@fluentui/react-components'
import { Text } from '../../../common/text/Text'
import { DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { t } from '../../../../i18n/i18n'
import { InformationPopover } from '../../../common/popover/InformationPopover'
import { RTFOutput } from '../../../common/outputs/RTFOutput'

type ConnectedDemandPopoverProps = {
   item: DemandAnalysisQuestion
}
export const ConnectedDemandPopover = ({ item }: ConnectedDemandPopoverProps) => {
   return (
      <InformationPopover
         btnLabel={t('OriginDemand')}
         popoverLabel={t('OriginDemandForTheQuestion')}
         regularIcon={<ClipboardLink20Regular />}
         filledIcon={<ClipboardLink20Filled />}
         input={<RTFOutput text={item.demandText !== '' ? item.demandText : t('NoAvailableInformation')} />}
         link={
            <span>
               {item.demandUrl ? (
                  <>
                     <Text size={300}>
                        {t('ReadMore')}:{' '}
                        <Link href={item.demandUrl} appearance="default">
                           {item.demandUrlText} <Open20Regular />
                        </Link>
                     </Text>
                  </>
               ) : (
                  <>
                     <Text block={false} weight="semibold">
                        {t('Source')}:
                     </Text>{' '}
                     <Text block={false}>{item.demandSource}</Text>
                  </>
               )}
            </span>
         }
      />
   )
}
