/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalActions } from '../common/modal/Modal'
import { DataContext } from '../../context/DataContext'
import { t } from '../../i18n/i18n'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { Button, Divider, Text } from '@fluentui/react-components'
import { Activity } from '../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { getDefaultValues, onActivityDelete, onActivitySubmit } from '../../helpers/calendarHelper'
import { ActivityForm } from './ActivityForm'

type ModalActivityProps = {
   modalState: {
      isOpen: boolean
      selectedActivity?: Activity
   }
   setActivities: React.Dispatch<React.SetStateAction<Activity[]>>
   setModalState: React.Dispatch<
      React.SetStateAction<{
         isOpen: boolean
         selectedActivity?: Activity
      }>
   >
}

export const ModalActivity = ({ modalState, setActivities, setModalState }: ModalActivityProps) => {
   const dataContext = useContext(DataContext)
   const selectedActivity = modalState?.selectedActivity
   const [deleteActivity, setDeleteActivity] = useState(false)
   const activityForm = useForm<Activity>({})
   const {
      reset,
      handleSubmit,
      formState: { isSubmitting },
   } = activityForm

   useEffect(() => {
      getDefaultValues(reset, selectedActivity)
   }, [selectedActivity, reset])

   const onClose = () => {
      setModalState({ isOpen: false, selectedActivity: undefined })
      reset()
   }

   const onSubmit = async (data: Activity) => {
      onActivitySubmit(dataContext, data, setActivities, onClose, modalState?.selectedActivity)
   }

   return (
      <>
         <Modal title={t(deleteActivity ? 'DeleteActivity' : selectedActivity ? 'EditActivity' : 'PlanActivity')} modalType="modal" open={modalState.isOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Grid>
                  <GridItem size="12/12">
                     <Divider appearance="brand" />
                  </GridItem>
                  {deleteActivity ? (
                     <>
                        <GridItem size="12/12">
                           <Text weight="regular">{t('AreYouSureYouWantToDeleteActivity')}</Text>
                        </GridItem>
                        <GridItem size="12/12">
                           <ModalActions>
                              <Button
                                 onClick={() => {
                                    setDeleteActivity(false)
                                 }}
                                 disabled={isSubmitting}
                              >
                                 {t('Cancel')}
                              </Button>

                              <Button
                                 appearance="primary"
                                 onClick={() => {
                                    onActivityDelete(dataContext, selectedActivity?.id, setActivities, onClose)
                                 }}
                                 disabled={isSubmitting}
                              >
                                 {t('Delete')}
                              </Button>
                           </ModalActions>
                        </GridItem>
                     </>
                  ) : (
                     <ActivityForm activityForm={activityForm} modalState={modalState} setDeleteActivity={setDeleteActivity} onClose={onClose} />
                  )}
               </Grid>
            </form>
         </Modal>
      </>
   )
}
