import React from 'react'
import { FinancialYearTabs } from '../../helpers/riskEconomyHelper'
import { Tab } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import {
   DataTrending20Filled,
   DataTrending20Regular,
   Info20Filled,
   Info20Regular,
   MoneyCalculator20Filled,
   MoneyCalculator20Regular,
   TaskListSquareLtr20Filled,
   TaskListSquareLtr20Regular,
} from '@fluentui/react-icons'

type FinancialYearNavLinksProps = {
   id: string
   activeTab: string
   actionTab: JSX.Element
}
export const FinancialYearNavLinks = ({ id, activeTab, actionTab }: FinancialYearNavLinksProps) => {
   const baseLink = `/risk-economy/${id}`
   return (
      <>
         <Tab
            value={`${baseLink}?tab=${FinancialYearTabs.Info}`}
            icon={activeTab === FinancialYearTabs.Info ? <Info20Filled /> : <Info20Regular />}
            aria-label={t('Information')}
         />
         <Tab
            value={`${baseLink}?tab=${FinancialYearTabs.IdentifyCosts}`}
            icon={activeTab === FinancialYearTabs.IdentifyCosts ? <MoneyCalculator20Filled /> : <MoneyCalculator20Regular />}
            aria-label={t('IdentifyCosts')}
         />
         <Tab
            value={`${baseLink}?tab=${FinancialYearTabs.Evaluation}`}
            icon={activeTab === FinancialYearTabs.Evaluation ? <DataTrending20Filled /> : <DataTrending20Regular />}
            aria-label={t('Evaluation')}
         />
         <Tab
            value={`${baseLink}?tab=${FinancialYearTabs.ActionPlan}`}
            icon={activeTab === FinancialYearTabs.ActionPlan ? <TaskListSquareLtr20Filled /> : <TaskListSquareLtr20Regular />}
            aria-label={t('ActionPlan')}
         />
         {actionTab}
      </>
   )
}
