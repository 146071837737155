import React from 'react'
import { t } from '../../i18n/i18n'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { IComplianceState } from '../../interfaces/IComplianceState'
import { Activity, DemandAnalysisDashboardDTO, FinancialYear, RiskAnalysis } from '../../api/schemas/schema'
import { IListState } from '../../interfaces/IListState'
import { DashboardComplianceCards } from './DashboardComplianceCards'
import { DashboardCard } from './DashboardCard'
import { DashboardInfoCard } from './DashboardInfoCard'

type DashboardBaseExtendedProps = {
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   setActivityModalState: React.Dispatch<
      React.SetStateAction<{
         isOpen: boolean
         selectedActivity?: Activity
      }>
   >
   setRiskManagementState: React.Dispatch<React.SetStateAction<IListState<RiskAnalysis>>>
   setRiskEconomyState: React.Dispatch<React.SetStateAction<IListState<FinancialYear>>>
   dashboardData: DemandAnalysisDashboardDTO
}

export const DashboardBaseExtended = ({
   setComplianceState,
   setActivityModalState,
   setRiskManagementState,
   setRiskEconomyState,
   dashboardData,
}: DashboardBaseExtendedProps) => {
   return (
      <Grid gap="XXXL">
         <GridItem size="6/12">
         {/* TODO: Insert data when time to create overview for  base and extended subscription */}
            <DashboardComplianceCards setComplianceState={setComplianceState} dashboardData={null} /> 
         </GridItem>

         <GridItem size="6/12">
            <DashboardCard color="beige">
               <DashboardInfoCard
                  title={t('DoARiskAssessment')}
                  description={t('DoARiskAssessmentDescription')}
                  buttonText={t('CreateRiskAssessment')}
                  onClick={() => setRiskManagementState((prev) => ({ ...prev, open: true }))}
               />
            </DashboardCard>
         </GridItem>

         <GridItem size="6/12">
            <DashboardCard color="beige" bigCard>
               <DashboardInfoCard
                  title={t('CreateNewFinancialYear')}
                  description={t('CreateNewFinancialYearDescription')}
                  buttonText={t('CreateFinancialYear')}
                  onClick={() => setRiskEconomyState((prev) => ({ ...prev, open: true }))}
               />
            </DashboardCard>
         </GridItem>

         <GridItem size="6/12">
            <DashboardCard color="beige" bigCard>
               <DashboardInfoCard
                  title={t('PlanYourCalendar')}
                  description={t('PlanYourCalendarDescription')}
                  buttonText={t('CreateActivity')}
                  onClick={() => setActivityModalState({ isOpen: true })}
               />
            </DashboardCard>
         </GridItem>
      </Grid>
   )
}
