import React from 'react'
import {
   MessageBar,
   MessageBarBody,
   MessageBarTitle,
   TabList,
   TableColumnDefinition,
   makeStyles,
   mergeClasses,
   shorthands,
   tokens,
} from '@fluentui/react-components'
import { useSearchParams } from 'react-router-dom'
import { Text } from '../text/Text'
import { Card } from '../card/Card'
import { Loader } from '../spinner/Loader'
import { CustomList } from '../list/CustomList'

const useStyles = makeStyles({
   beigeRounded: {
      backgroundColor: '#FFFDF5',
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
      boxShadow: tokens.shadow8,
   },
   mobileList: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         flexDirection: 'column',
         ...shorthands.gap(tokens.spacingHorizontalS),
         ...shorthands.margin(tokens.spacingHorizontalS, 0),
      },
   },
   mobileListElement: {
      ...shorthands.padding(0, tokens.spacingHorizontalXL),
      height: 'auto',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   tabsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      '@media screen and (max-width: 640px)': {
         flexDirection: 'column',
         justifyItems: 'flex-start',
         gap: tokens.spacingVerticalXL,
      },
   },
})

type OverviewProps<T extends {}> = {
   tabs: JSX.Element | JSX.Element[]
   button: JSX.Element
   columns: TableColumnDefinition<T>[]
   onRowClick: (item: T) => void
   items: T[]
   title: string
   loading: boolean
   keyValue: keyof T
   noItemsText: string
   rowClassName: string
   renderMenuButton?: (item: T) => JSX.Element 
}
export const Overview = <T extends {}>({ tabs, button, onRowClick, items, columns, title, loading, keyValue, noItemsText, rowClassName, renderMenuButton}: OverviewProps<T>) => {
   const classes = useStyles()
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')

   return (
      <>
         <div className={classes.tabsWrapper}>
            <TabList selectedValue={selectedTab} onTabSelect={(e, data) => setSearchParams({ tab: data.value as string })}>
               {tabs}
            </TabList>
            {button}
         </div>

         {loading ? (
            <Loader />
         ) : items.length > 0 ? (
            <>
               <Card className={mergeClasses(classes.hideInPhone)} title={title}>
                  <CustomList columns={columns} items={items} onRowClick={onRowClick} rowClassName={rowClassName} />
               </Card>
               <section className={classes.mobileList}>
                  {items.map((item: T, i) => {
                     return (
                        <span key={i} className={mergeClasses(classes.mobileListElement, classes.beigeRounded)} onClick={() => onRowClick(item)}>
                           <Text>{item[keyValue] as string}</Text>
                           {renderMenuButton && renderMenuButton(item)}
                        </span>
                     )
                  })}
               </section>
            </>
         ) : (
            <MessageBar>
               <MessageBarBody>
                  <MessageBarTitle>{noItemsText}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         )}
      </>
   )
}
