import React from 'react'
import { t } from 'i18next'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { DashboardInfoCard } from './DashboardInfoCard'
import { IComplianceState } from '../../interfaces/IComplianceState'
import { DashboardCard } from './DashboardCard'
import { ComplianceMeter } from './Meters/ComplianceMeter'
import { DemandAnalysisDashboardDTO } from '../../api/schemas/schema'
const useStyles = makeStyles({
   demandCardWrapper: {
      height: '100%',
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
   demandInfoWrapper: {
      position: 'relative',
      height: '100%',
   },
   infoCard: {
      margin: 'auto',
      '@media screen and (min-width: 1007px)': {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         zIndex: 2,
      },
   },
   card: {
      height: '300px',
      '@media screen and (max-width: 1007px)': {
         height: 'auto',
         minHeight: '52px',
         padding: tokens.spacingVerticalM,
      },
   },
})

type DashboardComplianceCardsProps = {
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   dashboardData: DemandAnalysisDashboardDTO
}

export const DashboardComplianceCards = ({ setComplianceState, dashboardData }: DashboardComplianceCardsProps) => {
   const classes = useStyles()

   return (
      <div className={classes.demandInfoWrapper}>
         {(dashboardData === null || dashboardData === undefined) && (
            <DashboardInfoCard
               className={classes.infoCard}
               title={t('DoADemandAssessment')}
               description={t('DoADemandAssessmentDescription')}
               buttonText={t('CreateDemandAssessment')}
               onClick={() => setComplianceState((prev) => ({ ...prev, open: true }))}
            />
         )}
         <Grid gap="XXXL" className={mergeClasses(classes.demandCardWrapper, dashboardData === null ? classes.noneInPhone : '')}>
            <GridItem size="4/12">
               <DashboardCard color="blue" className={classes.card}>
                  {dashboardData && <ComplianceMeter title={t('ComplianceOfDemands')} percentage={dashboardData.compliancePercentage} difference={dashboardData.complianceDifference} />}
               </DashboardCard>
            </GridItem>
            <GridItem size="4/12">
               <DashboardCard color="blue" className={classes.card}>
                  {dashboardData && <ComplianceMeter title={t('RiskManagement')} percentage={dashboardData.riskManagementPercentage} difference={dashboardData.riskManagementDifference} />}
               </DashboardCard>
            </GridItem>
            <GridItem size="4/12">
               <DashboardCard color="blue" className={classes.card}>
                  {dashboardData && <ComplianceMeter title={t('RiskEconomy')} percentage={dashboardData.riskEconomyPercentage} difference={dashboardData.riskEconomyDifference} />}
               </DashboardCard>
            </GridItem>
         </Grid>
      </div>
   )
}
