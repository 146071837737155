import React, { useContext, useState } from 'react'
import {
   Home24Filled,
   Home24Regular,
   Book24Regular,
   Book24Filled,
   ShieldError24Regular,
   ShieldError24Filled,
   Settings24Filled,
   Settings24Regular,
   Calendar24Regular,
   Calendar24Filled,
   Calculator24Filled,
   Calculator24Regular,
} from '@fluentui/react-icons'
import { Tab, Tooltip } from '@fluentui/react-components'

import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import { isSubscriptionLevelBaseOrBig } from '../../../helpers/organisationHelper'
import { INavLink, NavigationLink } from '../../../interfaces/INavigationLink'

type BaseNavLinksProps = {
   active: '/' | '/compliance' | '/risk-management' | '/settings' | '/calendar' | '/risk-economy'
}
export const BaseNavLinks = ({ active }: BaseNavLinksProps) => {
   const data = useContext(DataContext)
   const {
      navigationExpanded,
      organisation: { subscription },
   } = data.state
   const [showTooltip, setShowTooltip] = useState<NavigationLink>(null)
   const navLinks: INavLink[] = [
      {
         value: NavigationLink.Overview,
         label: t('Overview'),
         icon: { filled: <Home24Filled />, regular: <Home24Regular /> },
      },
      {
         value: NavigationLink.AnnualCalendar,
         label: t('AnnualCalendar'),
         icon: { filled: <Calendar24Filled />, regular: <Calendar24Regular /> },
      },
      {
         value: NavigationLink.ComplianceOfDemands,
         label: t('ComplianceOfDemands'),
         icon: { filled: <Book24Filled />, regular: <Book24Regular /> },
      },
      {
         value: NavigationLink.RiskManagement,
         label: t('RiskManagement'),
         icon: { filled: <ShieldError24Filled />, regular: <ShieldError24Regular /> },
      },
      {
         value: NavigationLink.RiskEconomy,
         label: t('RiskEconomy'),
         icon: { filled: <Calculator24Filled />, regular: <Calculator24Regular /> },
         accessCheck: isSubscriptionLevelBaseOrBig(subscription),
      },
      {
         value: NavigationLink.Settings,
         label: t('Settings'),
         icon: { filled: <Settings24Filled />, regular: <Settings24Regular /> },
      },
   ]
   return (
      <>
         {navLinks.map((navLink) =>
            navLink.accessCheck || navLink.accessCheck === undefined ? (
               <Tooltip
                  key={navLink.value}
                  onVisibleChange={(e, data) => setShowTooltip(data.visible && !navigationExpanded ? navLink.value : null)}
                  visible={showTooltip === navLink.value && !navigationExpanded}
                  content={navLink.label}
                  relationship="label"
                  positioning="after"
                  showDelay={500}
               >
                  <Tab value={navLink.value} icon={active === navLink.value ? navLink.icon.filled : navLink.icon.regular}>
                     {navigationExpanded && navLink.label}
                  </Tab>
               </Tooltip>
            ) : null
         )}
      </>
   )
}
