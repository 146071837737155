import React, { useContext, useEffect } from 'react'
import { t } from '../../../i18n/i18n'
import { Cost, CostCategory, FinancialYear, FinancialYearStatus } from '../../../api/schemas/schema'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { IItemState } from '../../../interfaces/IItemState'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { Button, Divider, makeStyles, Text, tokens } from '@fluentui/react-components'
import { BadgeCostCategory } from '../BadgeCostCategory'
import { DeviationSummary } from '../DeviationSummary'
import { formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { useForm } from 'react-hook-form'
import { ControlledCheckbox } from '../../common/inputs/ControlledCheckbox'
import { ControlledMoneyInput } from '../../common/inputs/ControlledMoneyInput'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { DataContext } from '../../../context/DataContext'
import { updateCostOnFinancialYear } from '../../../helpers/riskEconomyHelper'
import TextOutput from '../../common/inputs/TextOutput'
import { translateEnumValue } from '../../../helpers/enumHelper'

const useStyles = makeStyles({
   textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
   },
})

type ModalEvaluateCostProps = {
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
   cost: Cost
   financialYear: FinancialYear
   isOpen: boolean
   close: () => void
   checkboxHasLocalState: boolean
   toggleCheckbox: (cost: Cost, openModal?: boolean) => Cost
}

export const ModalEvaluateCost = ({
   financialYear,
   setFinancialYearState,
   cost,
   isOpen,
   close,
   checkboxHasLocalState,
   toggleCheckbox,
}: ModalEvaluateCostProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { userAccount } = dataContext.state
   const {
      control,
      handleSubmit,
      reset,
      watch,
      setValue,
      formState: { isSubmitting, disabled },
   } = useForm<Cost>({ disabled: financialYear.status === FinancialYearStatus.Completed })

   const onClose = () => {
      if (checkboxHasLocalState) {
         toggleCheckbox(cost)
      }
      reset()
      close()
   }

   useEffect(() => {
      if (cost) {
         reset(cost)
      }
   }, [cost, reset])

   const onSubmit = async (data: Cost) => {
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id
      data.proposedBy = userAccount
      data.proposedById = userAccount.id

      await updateCostOnFinancialYear(setFinancialYearState, dataContext, data, financialYear.id)
      close()
   }

   return (
      <Modal title={t('ProposedAction')} modalType="modal" open={isOpen}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
               <GridItem size="12/12">
                  <Divider appearance="brand" />
               </GridItem>
               {cost ? (
                  <>
                     <GridItem size="12/12" className={classes.textWrapper}>
                        <Text size={400} weight="semibold">
                           {t('Category')}
                        </Text>
                        <BadgeCostCategory category={cost.post.costCategory} />
                     </GridItem>
                     <GridItem size="12/12">
                        <TextOutput label={t('Post')} text={cost.post.name ?? translateEnumValue(CostCategory, cost.post.costCategory)} />
                     </GridItem>
                     <GridItem size="12/12">
                        <TextOutput label={t('Description')} text={cost.description} />
                     </GridItem>

                     <GridItem size="4/12">
                        <TextOutput label={t('Budget')} text={`${formatToSwedishCrowns(cost?.budget ?? 0)} kr`} />
                     </GridItem>

                     <GridItem size="4/12">
                        <TextOutput label={t('Result')} text={`${formatToSwedishCrowns(cost?.result ?? 0)} kr`} />
                     </GridItem>

                     <GridItem size="4/12" className={classes.textWrapper}>
                        <Text size={400} weight="semibold">
                           {t('Deviation')}
                        </Text>
                        <DeviationSummary cost={cost} />
                     </GridItem>

                     <GridItem size="12/12">
                        <Divider appearance="brand" />
                     </GridItem>

                     <GridItem size="12/12">
                        {watch('actionRequired') !== undefined && (
                           <ControlledCheckbox
                              onChange={(e, data) => {
                                 setValue('actionRequired', Boolean(data.checked))
                                 if (!data.checked) {
                                    setValue('proposedAction', null)
                                    setValue('estimatedCost', null)
                                 }
                              }}
                              label={t('CreateProposedAction')}
                              control={control}
                              name={'actionRequired'}
                              checked={watch('actionRequired')}
                           />
                        )}
                     </GridItem>
                     {watch('actionRequired') && (
                        <>
                           <GridItem size="12/12">
                              <ControlledTextInput
                                 control={control}
                                 name={'proposedAction'}
                                 label={t('ProposedAction')}
                                 multiline
                                 required
                              />
                           </GridItem>
                           <GridItem size="12/12">
                              <ControlledMoneyInput control={control} name={'estimatedCost'} label={t('EstimatedCost')}  />
                           </GridItem>
                        </>
                     )}
                     <GridItem size="12/12">
                        <ControlledTextInput control={control} name={'comment'} label={t('Comment')} multiline />
                     </GridItem>
                  </>
               ) : null}
               <GridItem size="12/12">
                  <ModalActions>
                     <Button
                        onClick={() => {
                           onClose()
                        }}
                     >
                        {disabled ? t('Close') : t('Cancel')}
                     </Button>
                     {!disabled && (
                        <Button type="submit" appearance="primary" disabled={isSubmitting}>
                           {t('Save')}
                        </Button>
                     )}
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
