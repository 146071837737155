import React, { useContext, useState } from 'react'
import { DataClassification, RiskAnalysis, RiskAnalysisStatus } from '../../api/schemas/schema'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { Text } from '../common/text/Text'
import { Persona, makeStyles, tokens } from '@fluentui/react-components'
import { translateEnumValue, getRiskAnalysisTypeString } from '../../helpers/enumHelper'
import { t } from '../../i18n/i18n'
import { getFullName } from '../../helpers/accountHelper'
import { DataContext } from '../../context/DataContext'
import { InformationCard } from '../common/card/InformationCard'
import { ModalEditRiskInformation } from './ModalEditRiskInformation'
import { IItemState } from '../../interfaces/IItemState'
import { formatDate } from '../../helpers/stringHelper'
import { isSubscriptionLevelBaseOrBig } from '../../helpers/organisationHelper'
type InformationProps = {
   analysis: RiskAnalysis
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
}
const useStyles = makeStyles({
   infoWrapper: {
      '& > p': {
         marginTop: '0.5em',
         marginBottom: '0.5em',
      },
      '& > :last-child': {
         marginBottom: '1.5em',
         '& > span:last-child': {
            fontSize: tokens.fontSizeBase400,
         },
      },
   },
   showOnlyLarge: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   editButton: {
      display: 'flex',
      alignItems: 'center',
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   transparentInPhone: {
      padding: `${(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL)}`,
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      '@media screen and (max-width: 1007px)': {
         boxShadow: 'none',
         padding: 0,
         backgroundColor: 'transparent',
      },
   },
})
export const RiskInformation = ({ analysis, setAnalysisState }: InformationProps) => {
   const classes = useStyles()
   const { subscription } = useContext(DataContext).state.organisation
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleEditButtonClick = () => {
      setIsModalOpen(true)
   }

   const closeModal = () => {
      setIsModalOpen(false)
   }

   return (
      <>
         <InformationCard setState={setAnalysisState} handleEditButtonClick={handleEditButtonClick} disabled={analysis.status !== RiskAnalysisStatus.Ongoing}>
            <Grid>
               <GridItem size="4/12">
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('TypeOfRiskAssesment')}</Text>
                     <Text>{getRiskAnalysisTypeString(analysis.type)}</Text>
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('StartOfAssesment')}</Text>
                     {analysis.startDate ? <Text>{formatDate(analysis.startDate)}</Text> : <Text italic>{t('NoDateSet')}</Text>}
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('EndOfAssesment')}</Text>
                     {analysis.endDate ? <Text>{formatDate(analysis.endDate)}</Text> : <Text italic>{t('NoDateSet')}</Text>}
                  </section>
                  {isSubscriptionLevelBaseOrBig(subscription) && (
                     <>
                        <section className={classes.infoWrapper}>
                           <Text weight="bold">{t('RiskOwner')}</Text>
                           <Persona textAlignment="center" name={getFullName(analysis.analysisOwner)} primaryText={getFullName(analysis.analysisOwner)} />
                        </section>
                        <section className={classes.infoWrapper}>
                           <Text weight="bold">{t('AnalysisLeader')}</Text>
                           {analysis.analysisLeaderId ? (
                              <Persona textAlignment="center" name={getFullName(analysis.analysisLeader)} primaryText={getFullName(analysis.analysisLeader)} />
                           ) : (
                              <Text italic>{t('NoValueSet')}</Text>
                           )}
                        </section>
                     </>
                  )}
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('DataClassification')}</Text>
                     {analysis.dataClassification !== undefined ? (
                        <Text>{translateEnumValue(DataClassification, analysis.dataClassification)}</Text>
                     ) : (
                        <Text italic>{t('NoValueSet')}</Text>
                     )}
                  </section>
               </GridItem>

               <GridItem size="8/12">
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('Purpose/Scope')}</Text>
                     <Text>{analysis.purposeScope}</Text>
                  </section>

                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('Limitations')}</Text>
                     <Text>{analysis.limitation}</Text>
                  </section>

                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('Comment')}</Text>
                     <Text>{analysis.comment}</Text>
                  </section>
               </GridItem>
            </Grid>
         </InformationCard>
         <ModalEditRiskInformation analysis={analysis} isOpen={isModalOpen} closeModal={closeModal} setAnalysisState={setAnalysisState} />
      </>
   )
}
