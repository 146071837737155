import { ILanguage } from '../../interfaces/ILanguage'

export const sv: ILanguage = {
   '365Robust': '365Robust',
   Of: 'av',

   // Authentication
   WelcomeTo: 'Välkommen till',
   SignIn: 'Logga in',
   SignOut: 'Logga ut',
   Dashboard: 'Dashboard',
   NotAuthorized: 'Ej behörig',

   // Organisation
   OrganisationalData: 'Organisationsuppgifter',
   OrganisationNumber: 'Organisationsnummer',
   CompanyName: 'Företagsnamn',
   OrganisationSize: 'Organisationsstorlek',
   OrganisationType: 'Organisationsform',
   SNI: 'SNI',
   All: 'Alla',
   OrganisationDetails: 'Organisationsuppgifter',
   InvoiceDetails: 'Fakturauppgifter',
   Invocing: 'Fakturering',

   // User
   AccountInformation: 'Användaruppgifter',
   Account: 'Konto',
   FirstName: 'Förnamn',
   LastName: 'Efternamn',
   OrganizationalAffiliation: 'Organisationstillhörighet',
   JobTitle: 'Jobbtitel',
   UserType: 'Användartyp',
   Roles: 'Roller',

   // Button
   Next: 'Nästa',
   Add: 'Lägg till',
   Finish: 'Avsluta',
   Cancel: 'Avbryt',
   Continue: 'Fortsätt',
   Save: 'Spara',
   Create: 'Skapa',
   Update: 'Uppdatera',
   Delete: 'Radera',
   ReadMore: 'Läs mer',
   Close: 'Stäng',
   Complete: 'Fastställ',
   Determine: 'Fastställ',
   Back: 'Tillbaka',
   ExportToExcel: 'Exportera till excel',

   // Roles
   ProcessOwner: 'Processägare',
   Owner: 'Ägare',
   RiskOwner: 'Riskägare',
   AnalysisLeader: 'Analysledare',

   // Billing
   BillingData: 'Fakturauppgifter',
   InvoiceReference: 'Fakturareferens',
   InvoiceAddress: 'Fakturaadress',
   PostalCode: 'Postnummer',
   County: 'Postort',
   PhoneNumber: 'Telefonnummer',
   Email: 'E-post',

   // Branch
   Branch: 'Verksamhet',
   Branches: 'Verksamheter',
   BranchName: 'Namn på verksamhet',
   PropertyDesignation: 'Fastighetsbeteckning',
   OrganisationForm: 'Organisationsform',
   SNICodes: 'SNI-koder',
   BranchDescription: 'Verksamhetsbeskrivning',
   BranchValuation: 'Värdering verksamhet',
   DateForValuation: 'Datum för värdering',
   BranchResponsible: 'Verksamhetsansvarig',
   VisitingAdress: 'Besöksadress',
   KeyFigures: 'Nyckeltal',

   // ProtectedAssets
   ProtectedAssets: 'Skyddsvärda tillgångar',
   ProtectedAsset: 'Skyddsvärd tillgång',
   Asset: 'Tillgång',
   'ProtectedAsset/OrganisationalGoal': 'Skyddsvärd tillgång/Organisationens mål',
   BranchAffiliation: 'Verksamhetstillhörighet',
   DescriptionOfAsset: 'Beskrivning av tillgång',
   Description: 'Beskrivning',
   ProtectedAssetValuation: 'Värdering av tillgång',
   AddedAssets: 'Tillagda tillgångar',
   NewDemandAssessment: 'Ny kravbedömning',
   OngoingDemandAssessments: 'Pågående kravbedömningar',
   ConcludedDemandAssessments: 'Avslutade kravbedömningar',
   AccessAffectsFollowingProcesses: 'Tillgång påverkar följande processer',
   MotivationAndStand: 'Motivering och ställningstagande',
   TimeToCreateYourFirstItem: 'Dags att skapa din första {{Item}}',
   NoConcludedItems: 'Det finns inga avslutade {{Item}}',
   OngoingItem: 'Pågående {{Item}}',
   ConcludedItem: 'Avslutade {{Item}}',
   NewItem: 'Ny {{Item}}',
   EditItem: 'Redigera {{Item}}',
   DeleteItem: 'Radera {{Item}}',
   NameOfItem: 'Namn på {{Item}}',
   DescriptionOfItem: 'Beskrivning av {{Item}}',
   ValuationOfItem: 'Värdering av {{Item}}',
   Valuation: 'Värdering',
   ValuationDate: 'Värderingsdatum',
   Name: 'Namn',

   // Organisational Goals
   NewOrganisationalGoal: 'Nytt organisationsmål',
   OrganisationalGoals: 'Organisationens mål',
   OrganisationalGoal: 'Organisationsmål',
   TheGoal: 'Målet',

   // StartUpWizard
   CheckAndVerifyOrganisationData: 'Kontrollera och komplettera organisationsuppgifter',
   CheckAndVerifyUserData: 'Kontrollera och komplettera användaruppgifterna',
   User: 'Användare',
   OrganisationAffiliation: 'Organisationstillhörighet',

   // ComplianceOfDemands
   ComplianceOfDemands: 'Kravefterlevnad',
   LeadershipPerspective: 'Ledningsperspektiv',
   Subject: 'Ämne',
   DataClassification: 'Informationsklassning',
   StartOfAssessment: 'Start av bedömning',
   EndOfAssessment: 'Avslut av bedömning',
   Comment: 'Kommentar',
   CreateAssessment: 'Skapa bedömning',
   Process: 'Process',
   ProcessAffiliation: 'Processtillhörighet',
   CreatingDemandAssessment: 'Skapar upp kravbedömning...',
   DemandAssessmentCreated: 'Din kravbedömning är nu skapad',
   ThisCanTakeAMoment: 'Detta kan ta en stund. Håll enheten öppen',
   ClickContinueToGoToAssessment: "Klicka på 'Fortsätt' för att öppna din bedömning",
   Information: 'Information',
   DemandAnalysis: 'Kravanalys',
   ActionPlan: 'Åtgärdsplan',
   DemandAssessment: 'Kravbedömning',
   BranchScope: 'Verksamhetsomfattning',
   CompleteDemandAssessment: 'Avsluta kravbedömning',
   DeleteDemandAssessment: 'Radera kravbedömning',
   CompleteDemandAssessmentConfirmationMessage: 'Är du säker på att du vill avsluta kravbedömningen? Det går inte att ångra sig när den har blivit avslutad.',
   DeleteDemandAssessmentConfirmationMessage: 'Är du säker på att du vill radera kravbedömningen? Det går inte att ångra sig när den har blivit raderad.',
   AllAnswered: 'Besvarade',
   NoConcludedDemandAssessments: 'Det finns inga avslutade kravbedömningar',
   TimeToCreateYourFirstDemandAssessment: 'Dags att skapa din första kravbedömning',
   AllProcesses: 'Alla processer',
   'No.': 'Nr',
   Question: 'Fråga',
   CompleteDemandAnalysisConfirmationText:
      'Är du säker på att du vill fastställa kravanalysen för {{process}}? Svaret på frågorna kan ej ändras och åtgärdsplaner skapas upp.',
   EditDemandAssessment: 'Redigera kravbedömning',
   Edit: 'Redigera',
   ProposedAction: 'Förslag till åtgärd',
   BackgroundInformationForTheQuestion: 'Bakgrundsfakta till frågan',
   BackgroundInformation: 'Bakgrundsfakta',
   TipFrom365Robust: '365Robust tipsar',
   OriginDemandForTheQuestion: 'Ursprungskrav till frågan',
   OriginDemand: 'Ursprungskrav',
   Source: 'Källa',
   DemandAssessmentCompleted: 'Kravbedömning avslutad',
   CouldNotCompleteDemandAssessment: 'Kunde ej avsluta kravbedömningen',
   CouldNotUpdateDemandAssessment: 'Kunde inte uppdatera kravbedömnignen',
   AssessmentName: 'Namn på bedömning',
   IncludeExtendedLevel: 'Inkludera utökad nivå',
   NoAvailableInformation: 'Det finns ingen tillgänglig information för tillfället',
   EstimatedCost: 'Uppskattad kostnad för åtgärd',
   EstimatedCostShort: 'Uppskattad kostnad',
   TotalEstimatedCost: 'Total uppskattad kostnad',
   ProcessOwnersForDemandAssessment: 'Processägare för kravanalysen',
   ProcessOwnerForDemandAssessmentText:
      'Välj befintlig användare eller skriv in fritt namn. Aktiva användare kommer kunna besvara frågor. Namn utan användare kommer endast administratörer kunna besvara.',
   ExtendedLevel: 'Utökad nivå',
   NotIncluded: 'Ej inkluderat',
   Included: 'Inkluderat',
   ProcessWithProcessowner: 'Process med processägare',

   // Report
   Report: 'Rapport',
   AnsweredQuestionReport: 'Besvarade frågor - Rapport',
   AnsweredQuestions: 'Besvarade frågor',
   TotalNumber: 'Totalt antal',
   NumberOfAnswered: 'Antal besvarade',
   Chart: 'Diagram',
   List: 'Lista',

   // Actionplans
   NoActionPlansCreatedForDemandAssessment: 'Kravanalysen per process måste fastställas innan en åtgärdsplan skapas för processen',
   NoActionPlansCreatedForSWOT: 'En riskhanteringsplan skapas upp när SWOT-analysen fastställs',
   NoActionPlansCreatedForRiskAnalysis: 'En riskhanteringsplan skapas upp när riskanalysen fastställs',
   NoActionPlansRequiredForThisItem: 'Inga åtgärder behöver vidtas i denna {{Item}}.',
   DemandAssessmentCanBeCompleted: 'Kravbedömningen kan nu avslutas',
   RiskAssessmentCanBeCompleted: 'Riskbedömningen kan nu avslutas',
   ComplianceLevel: 'Efterlevnadsnivå',
   DecisionOnAction: 'Beslut om åtgärd',
   NoActionShallBeTaken: 'Inga åtgärder ska vidtas',
   ResponsibleOfAction: 'Ansvarig för åtgärd',
   Priority: 'Prioritet',
   ID: 'ID',
   AnsweredBy: 'Besvarad av',
   StartAction: 'Påbörja åtgärd',
   CompleteAction: 'Avsluta åtgärd',
   ActionDecidedBy: 'Åtgärd beslutad av',
   NoActionsTaken: 'Inga åtgärder genomfördes',
   ImplementedActions: 'Genomförda åtgärder',
   ComplianceLevelAfterCompletedAction: 'Kravefterlevnad efter utförd åtgärd',
   SpecifyCompliance: 'Ange efterlevnad',
   CalculatedOutcome: 'Beräknat utfall',
   DecisionStatus: 'Beslutsstatus',
   DecidedMulti: 'Beslutade',
   TotalNumberOfActions: 'Totalt antal åtgärder',
   Deadline: 'Klart senast',
   PerProcessAndComplianceLevel: 'Per process & efterlevnadsnivå',
   PerComplianceLevel: 'Per efterlevnadsnivå',
   ActionPlanReport: 'Åtgärdsplan - Rapport',
   NumberOfActions: 'Antal åtgärder',
   AnalysedBy: 'Analyserad av',
   ProposedBy: 'Förslag av',

   // Status
   RequiredField: 'Vänligen, fyll i detta fält',
   SomethingWentWrong: 'Något gick fel',
   NoMatchingQuestonsFound: 'Inga matchande frågor hittades',
   FetchFailed: 'Hämtning misslyckades',
   ModifiedBy: 'Senast ändrad av',
   Modified: 'Senast ändrad',
   CreatedBy: 'Skapad av',
   CreatedDate: 'Skapad',
   ItemSuccessfullyCreated: '{{Item}} skapades korrekt',
   ItemSuccessfullySaved: '{{Item}} sparades korrekt',
   ItemSuccessfullyUpdated: '{{Item}} uppdaterades korrekt',
   ItemSuccessfullyDeleted: '{{Item}} raderades',
   CouldNotFetchItem: '{{Item}} kunde inte hämtas',
   CouldNotCreateItem: '{{Item}} kunde inte skapas',
   CouldNotUpdateItem: '{{Item}} kunde inte uppdateras',
   CouldNotDeleteItem: '{{Item}} kunde inte raderas',
   CouldNotFetchData: 'Data kunde inte hämtas',
   Status: 'Status',
   NoDateSet: 'Inget datum satt',
   NoValueSet: 'Inget värde satt',
   ValidationFailed: 'Validering misslyckades',
   DemandAnalysisCompleted: 'Kravanalysen är fastställd',
   CouldNotCompleteDemandAnalysis: 'Något gick fel när kravanalysen skulle fastställas',
   NotAnswered: 'Ej besvarad',
   Answered: 'Besvarad',
   Determined: 'Fastställd',
   NotDecided: 'Ej beslutad',
   Decided: 'Beslutad',
   NotStarted: 'Ej påbörjad',
   Started: 'Påbörjad',
   Completed: 'Avslutad',
   NotToBeAdressed: 'Åtgärdas ej',
   ErrorMessage: 'Felmeddelande',
   NoDataCreatedYet: 'Ingen data skapad ännu.',
   Ongoing: 'Pågående',
   Concluded: 'Avslutade',
   EndDateMustBeAfterStartDate: 'Slutdatum måste vara efter startdatum.',
   EndDateMustBeAfterOrTheSameAsStartDate: 'Slutdatum måste vara efter eller samma som startdatum.',
   AnAccountMustBeSelected: 'Ett konto måste väljas',

   // Riskmanagement:
   CreateAssesment: 'Skapa bedömning',
   RiskAssesment: 'Riskbedömning',
   RiskAnalysis: 'Riskanalys',
   RiskManagement: 'Riskhantering',
   NewRiskAssesment: 'Ny riskbedömning',
   TypeOfRiskAssesment: 'Typ av riskanalys',
   NameOfAssesment: 'Namn på bedömning',
   'Purpose/Scope': 'Syfte/Omfattning',
   Limitations: 'Avgränsningar',
   StartOfAssesment: 'Start av bedömning',
   EndOfAssesment: 'Avslut av bedömning',
   SimplifiedRiskAnalysis: 'Förenklad riskanalys',
   ConsequenceAndProbabilityAnalysis: 'Konsekvens- & sannolikhetsanalys',
   SimplifiedConsequenceProbabilityAnalysis: 'Förenklad konsekvens- och sannolikhetsanalys',
   SWOTAnalysis: 'SWOT-analys',
   Type: 'Typ',
   NoConcludedRiskAssesments: 'Det finns inga avslutade riskbedömningar',
   TimeToCreateYourFirstRiskAssesment: 'Dags att skapa din första riskbedömning',
   OngoingRiskAssesments: 'Pågående riskbedömningar',
   ConcludedRiskAssesments: 'Avslutade riskbedömningar',
   CreatingRiskAssesment: 'Skapar riskbedömning',
   RiskAssesmentCreated: 'Din riskbedömning är nu skapad',
   RiskIdentifying: 'Riskidentifiering',
   'RiskAnalysis&Evaluation': 'Riskanalys & värdering',
   AnalyseEvaluate: 'Analysera & värdera',
   Identify: 'Identifiera',
   RiskManagementPlan: 'Riskhanteringsplan',
   NewRisk: 'Ny risk',
   EditRisk: 'Redigera risk',
   RiskScenario: 'Riskscenario',
   RiskSource: 'Riskkälla',
   IdentifyRisk: 'Identifiera risk',
   WeaknessesOrCurrentProtection: 'Sårbarheter eller nuvarande skydd ',
   UnderlyingMotivationAndStand: 'Underliggande motivering och ställningstagande',
   Consequence: 'Konsekvens',
   NoRisksIdentifiedYet: 'Inga risker har ännu identifierats',
   Risk: 'Risk',
   Title: 'Rubrik',
   CompleteRiskAssessment: 'Avsluta riskbedömning',
   DeleteRiskAssessment: 'Radera riskbedömning',
   CompleteRiskAssessmentConfirmationMessage: 'Är du säker på att du vill avsluta riskbedömningen? Det går inte att ångra sig när den har blivit avslutad.',
   DeleteRiskAssessmentConfirmationMessage: 'Är du säker på att du vill radera riskbedömningen? Det går inte att ångra sig när den har blivit raderad.',
   RiskAssessmentCompleted: 'Riskbedömningen avslutad',
   CouldNotCompleteRiskAssessment: 'Kunde ej avsluta riskbedömningen',
   DetermineRiskAssesmentConfirmationText:
      'Är du säker på att du vill fastställa riskanalysen? Värdering och åtgärder kan ej ändras och åtgärdsplaner skapas upp.',
   DetermineAnalysis: 'Fastställ analys',
   EditRiskAssesment: 'Redigera riskbedömning',
   RiskCriteria: 'Riskkriterier',
   NotAssessed: 'Ej bedömd',
   Unacceptable: 'Oacceptabel risk',
   Questionable: 'Tveksam risk',
   Acceptable: 'Acceptabel risk',
   BigRiskShouldBeAddressed: 'Stor risk, ska åtgärdas',
   UncertaintyAboutRiskShouldBeAddressed: 'Osäkerhet kring risken, bör åtgärdas',
   SmallRiskShouldNotBeAddressed: 'Liten risk, åtgärdas ej',
   RiskLevel: 'Risknivå',
   AnalyseRisk: 'Analysera risk',

   // SWOT
   Strengths: 'Styrkor',
   Weaknesses: 'Svagheter',
   Opportunities: 'Möjligheter',
   Threats: 'Hot',
   Strength: 'Styrka',
   Weakness: 'Svaghet',
   Opportunity: 'Möjlighet',
   Threat: 'Hot',
   SWOT: 'SWOT',
   TimeToAddYourFirstSWOT: 'Dags att lägga till din första SWOT',
   Order: 'Ordning',
   ChangeOrder: 'Ändra ordning',
   ActionProposal: 'Åtgärdsförslag',
   CreateProposedAction: 'Skapa förslag till åtgärd',
   of: 'av',
   ValueInOrderOfImportance: 'Värdera i viktighetsgrad',
   NoSWOTMatchingSelectedFilter: 'Det finns inga SWOTs som matchar vald filtrering',
   Graph: 'Grafisk',
   NoTypeIdentified: 'Inga {{type}} är identifierade',

   // Consequence and probability analysis
   ThreatScenario: 'Hotbild',
   RiskEvaluation: 'Riskvärdering',
   NewThreat: 'Nytt hot',
   EditThreat: 'Redigera hot',
   CurrentThreat: 'Aktuellt hot',
   AnalyseDate: 'Analysdatum',
   NoThreatsIdentifiedYet: 'Inga hot har ännu identifierats',
   SpecifyCurrentThreat: 'Ange aktuellt hot',
   VeryHigh: 'Mycket hög risk',
   High: 'Hög risk',
   Moderate: 'Medelhög risk',
   Low: 'Låg risk',
   VeryLow: 'Mycket låg risk',
   Probable: 'Sannolik',
   Likely: 'Trolig',
   Possible: 'Möjlig',
   LessLikely: 'Mindre trolig',
   Unlikely: 'Osannolik',
   Severe: 'Synnerligen allvarlig',
   Serious: 'Allvarlig',
   Significant: 'Kännbar',
   Mild: 'Lindrig',
   Negligible: 'Försumbar',
   ConsequenceLifeAndHealth: 'Konsekvens liv och hälsa',
   ConsequenceEconomy: 'Konsekvens ekonomi',
   ConsequenceEnvironment: 'Konsekvens miljö',
   ConsequenceBrand: 'Konsekvens varumärke',
   Probability: 'Sannolikhet',
   ProbabilityScale: 'Sannolikhetsskala',
   ProbabilityScaleInfo:
      ' Sannolikhetsskalan består av fem skalsteg och är anpassad till MSB (Myndigheten för Samhällsskydd och Beredskap samt Säkerhetspolisens kriterier.',
   ConsequenceLifeAndHealthScale: 'Konsekvensskala - Liv & hälsa',
   ConsequenceLifeAndHealthScaleInfo:
      'Konsekvens för liv och hälsa kan bedömas ur två perspektiv. Antingen som en avbrottshändelse som får konsekvenser för hela verksamheten. Om en eller flera drabbas allvarligt innebär det att verksamheten får svårigheter att uppnå sina mål. Det är grunden för riskbedömningen enligt denna metod. Ska ni bedöma till exempel arbetsmiljöskador bör skalan innehålla andra typer av skador, till exempel förslitning, buller, vibrationer, social oro och så vidare.',
   ConsequenceEconomyScale: 'Konsekvensskala - Ekonomi',
   ConsequenceEconomyScaleInfo:
      'Konsekvens för ekonomi mäts monetärt och utgår från verksamhetens budgeterade omsättning under det aktuella året. Skalan tar inte hänsyn till ekonomiskt starka ägare, onormalt stor kassa, utan utgår från en sund verksamhetsekonomi. ',
   ConsequenceEnvironmentScale: 'Konsekvensskala - Miljö',
   ConsequenceEnvironmentScaleInfo:
      'I de fall miljöpåverkan ska värderas utgår konsekvensskalan från behov av sanering och skadans utbredning i mark, vatten och luft. Dock rekommenderas samma skala som ekonomi, vilket motsvarar motsvarande kostnader för sanering och skadans utbredning. Ta hänsyn till eventuell försäkringsersättning vid bedömningen.',
   ConsequenceBrandScale: 'Konsekvensskala - Varumärke',
   ConsequenceBrandScaleInfo:
      'Konsekvenshanteringen för varumärket utgår från hur påverkan på varumärket sker gällande allvarlighetsgraden, omfattningen av negativ publicitet, kundernas förtroende, finasiell påverkan och återhämtningsmöjligheten.',

   VeryHighDescription: 'Kan inträffa en eller flera gånger per år.',
   HighDescription: 'Kan inträffa inom 1 till 5 år.',
   ModerateDescription: 'Kan inträffa inom 5 till 10 år.',
   LowDescription: 'Kan inträffa inom 10 till 50 år.',
   VeryLowDescription: ' Kan inträffa mer sällan än var 50 år.',

   OfEstimatedRevenue: 'av uppskattad omsättning',

   ProbableLifeHealthDescription: 'Flera döda och/eller svårt skadade',
   LikelyLifeHealthDescription: 'Skada som kräver avancerad läkarvård. Dödsfall kan förekomma',
   PossibleLifeHealthDescription: 'Skada som kräver läkarvård.',
   LessLikelyLifeHealthDescription: 'Skada som kräver enklare vård.',
   UnlikelyLifeHealthDescription: 'Skada som ej kräver vård.',

   ProbableEnvironmentDescription: 'Svår sanering, stor utbredning > 240 000 000 kr',
   LikelyEnvironmentDescription: 'Svår sanering, liten utbredning < 120 000 000 kr',
   PossibleEnvironmentDescription: 'Enkel sanering, stor utbredning < 60 000 000 kr',
   LessLikelyEnvironmentDescription: 'Enkel sanering, liten utbredning < 24 000 000 kr',
   UnlikelyEnvironmentDescription: 'Ingen sanering, liten utbredning < 12 000 000 kr',

   ProbableBrandDescription:
      'Varaktigt skadat varumärke, omfattande negativ medial uppmärksamhet, stora ekonomiska förluster, hot mot verksamhetens överlevnad.',
   LikelyBrandDescription: 'Risk att tappa flera viktiga kunder, allvarlig inverkan på varumärket, långsiktiga effekter på verksamhetens kundrelationer.',
   PossibleBrandDescription: 'Upprepad negativ uppmärksamhet i media, risk att tappa enstaka större kund, och fler mindre kunder.',
   LessLikelyBrandDescription: 'Dåligt rykte som uppfattas av flera kunder, uppmärksamhet i riksmedia, risk att tappa enstaka mindre kund.',
   UnlikelyBrandDescription: 'Tillfälligt dåligt rykte, kortvarig uppmärksamhet i lokal media. Ingen större ekonomisk påverkan.',

   // Calendar
   AnnualCalendar: 'Årskalender',
   Activities: 'Aktiviteter',
   Activity: 'Aktivitet',
   AreYouSureYouWantToDeleteActivity: 'Är du säker på att du vill radera aktiviteten?',
   DeleteActivity: 'Radera aktivitet',
   EndDate: 'Slutdatum',
   EditActivity: 'Redigera aktivitet',
   PlanActivity: 'Planera aktivitet',
   PlanActivitesMsg: 'Planera aktiviteter för att se dom i årshjulet',
   PlanActivitesPhoneViewMsg: 'Planera aktiviteter för att se dom i listan',
   StartDate: 'Startdatum',
   To: 'till',

   // Settings
   Settings: 'Inställningar',
   SettingsAndRegister: 'Inställningar och register',

   // Risk economy
   RiskEconomy: 'Riskekonomi',
   FinancialYear: 'Räkenskapsår',
   TheFinancialYear: 'Räkenskapsåret',
   NewFinancialYear: 'Nytt räkenskapsår',
   TimeToCreateYourFirstFinancialYear: 'Dags att skapa ditt första räkenskapsår',
   Period: 'Period',
   BudgetedRevenue: 'Budgeterad omsättning',
   IdentifyCosts: 'Identifiera kostnader',
   Budget: 'Budget',
   Result: 'Utfall',
   Cost: 'Kostnad',
   Evaluation: 'Utvärdering',
   DamageCosts: 'Skadekostnader',
   PreventiveCosts: 'Förebyggande kostnader',
   InsuranceCosts: 'Försäkringskostnader',
   SecurityAdministration: 'Säkerhetsadministration',
   AllCosts: 'Alla kostnader',
   Category: 'Kategori',
   Post: 'Post',
   NumberOfPosts: 'Antal poster',
   SeePosts: 'Se poster',
   AssociatedPosts: 'Tillhörande poster',
   'OriginPost/-s': 'Ursprungspost/-er',
   TotalItem: 'Total {{Item}}',
   Deviation: 'Avvikelse',
   SummaryDeviation: 'Summering avvikelse',
   DeleteItemConfirmationMessage: 'Är du säker på att du vill radera {{Item}}? Det går inte att ångra sig när den har blivit raderad.',
   FilterByCategory: 'Filtrera på kategori',
   FilterOnBudgetOrResult: 'Filtrera på budget eller utfall',
   Date: 'Datum',
   TotalNumberOfPosts: 'Totalt antal poster',
   TotalBudget: 'Total budget',
   TotalResult: 'Totalt utfall',
   TimeToAddYourFirstCost: 'Dags att lägga till din första kostnad',
   Costs: 'Kostnader',
   AddCost: 'Ny kostnad',
   EditCost: 'Redigera kostnad',
   Action: 'Åtgärd',
   CompleteFinancialYear: 'Avsluta räkenskapsåret',
   CompleteFinancialYearConfirmationMessage: 'Är du säker på att du vill avsluta räkenskapsåret? Det går inte att ångra sig när den har blivit avslutad.',
   DeleteFinancialYearConfirmationMessage: 'Är du säker på att du vill radera räkenskapsåret? Det går inte att ångra sig när den har blivit raderad.',
   FinancialYearCompleted: 'Räkenskapsåret avslutad',
   CouldNotCompleteFinancialYear: 'Kunde ej avsluta räkenskapsåret',
   CreatingFinancialYear: 'Skapar räkenskapsår',
   FinancialYearCreated: 'Ditt räkenskapsår är nu skapat',
   ClickContinueToGoToFinancialYear: 'Klicka på "Fortsätt" för att öppna ditt räkenskapsår',

   // Date picker strings
   January: 'Januari',
   February: 'Februari',
   March: 'Mars',
   April: 'April',
   May: 'Maj',
   June: 'Juni',
   July: 'Juli',
   August: 'Augusti',
   September: 'September',
   October: 'Oktober',
   November: 'November',
   December: 'December',

   Jan: 'Jan',
   Feb: 'Feb',
   Mar: 'Mar',
   Apr: 'Apr',
   MayShort: 'Maj',
   Jun: 'Jun',
   Jul: 'Jul',
   Aug: 'Aug',
   Sep: 'Sep',
   Oct: 'Okt',
   Nov: 'Nov',
   Dec: 'Dec',

   Monday: 'Måndag',
   Tuesday: 'Tisdag',
   Wednesday: 'Onsdag',
   Thursday: 'Torsdag',
   Friday: 'Fredag',
   Saturday: 'Lördag',
   Sunday: 'Söndag',

   MondayShort: 'M',
   TuesdayShort: 'T',
   WednesdayShort: 'O',
   ThursdayShort: 'T',
   FridayShort: 'F',
   SaturdayShort: 'L',
   SundayShort: 'S',

   GoToToday: 'Gå till idag',

   // Data classification
   OpenInformation: 'Öppen information',
   InternalInformation: 'Intern information',
   SecretInformation: 'Hemlig information',
   QualifiedSecretInformation: 'Kvalificerad hemlig information',

   // Switch organisation
   SwitchOrganisation: 'Byt organisation',
   SelectOrganisation: 'Välj organisation',
   CouldNotSwitchOrganisation: 'Kunde inte byta organisation',

   // Subscription
   Subscription: 'Abonnemang',
   UpgradeSubscription: 'Uppgradera abonnemang',
   ChangeSubscription: 'Ändra abonnemang',
   SystemAdmin: 'Systemadministratör',
   AdditionalModules: 'Tilläggsmoduler',
   SubscriptionUpgradeRequiredForPurchaseOfAdditionalModules: 'Uppgradering av abonnemang krävs för köp av tilläggsmoduler',
   EnableOrDisableAvailableAdditionalModules: 'Aktivera eller inaktivera tillgängliga tilläggsmoduler',
   PurchaseAdditionalModule: 'Köp tilläggsmodul',
   RobustMini: '365Robust Mini',
   RobustBase: '365Robust Bas',
   RobustBig: '365Robust Stor',
   SeparateModules: 'Separata moduler',

   // Demand compliance modules
   WORK_ENVIRONMENT: 'Arbetsmiljö',
   GDPR: 'GDPR',
   ISO27001: 'ISO 27 001',
   ISO28000: 'ISO 28 000',
   ISO31000: 'ISO 31 000',
   ISO45000: 'ISO 45 000',
   SSF_CYBER_SECURITY: 'SSF Cybersäkerhet',
   SYSTEMATIC_FIRE_PROTECTION_WORK: 'Systematiskt brandskyddsarbete',
   SECURITY_PROTECTION: 'Säkerhetsskydds',
   EXTENDED_LEVEL: 'Utökad nivå',

   // Risk management modules
   THREAT_CATALOG: 'Hotkatalog',
   SIMPLIFIED_RISK_ANALYSIS: 'Förenklad riskanalys',
   CONSEQUENCE_AND_PROBABILITY_ANALYSIS: 'Konsekvens- & sannolikhetsanalys',
   SIMPLIFIED_WORK_ENVIROMENT_ANALYSIS: 'Förenklad arbetsmiljöriskanalys',
   SIMPLIFIED_BUSINESS_IMPACT_ANALYSIS: 'Förenklad affärsavbrottsanalys',
   RISK_AND_VULNERABILITY_ANALYSIS: 'Risk- och sårbarhetsanalys',
   SIMPLIFIED_SECURITY_ANALYSIS: 'Förenklad säkerhetsskyddsanalys',
   SECURITY_ANALYSIS: 'Säkerhetsskyddsanalys',
   FAILURE_MODE_AND_EFFECT_ANALYSIS: 'Feleffektsanalys',
   HAZARD_AND_OPERABILITY_ANALYSIS: 'HAZOP',

   // General modules
   WORD_LIST: 'Ordlista',
   INCIDENT_REPORT: 'Händeserapportering',
   WHISTLE_BLOWER: 'Visselblåsning',
   INSURANCE_REGISTER: 'Försäkringsregister',
   PHYSICAL_PLANNING: 'Fysisk planering',
   SECURITY_SYSTEM: 'Säkerhetssystem',
   ROBUSTER: 'Robuster',
   CORPORATE_GROUP: 'Koncern',
   CONTRACT_REGISTER: 'Avtalsregister',
   SELF_AUDIT: 'Egenkontroll och revision',
   SECURITY_ADMINISTRATION: 'Säkerhetsadministration',
   PERSONNEL_ANALYSIS: 'Personalanalys',
   EXPORT_MODULE: 'Exportmodul',
   IMPORT_MODULE: 'Importmodul',
   STATISTICS: 'Statistik',
   SAFETY_GUIDE: 'Säkerhetshandbok',

   // Dashboard
   Overview: 'Översikt',
   OverviewData: 'Översiktsdata',
   DoADemandAssessment: 'Gör en kravbedömning!',
   DoADemandAssessmentDescription: 'Efterlever ni alla krav som ställs på er verksamhet? Ta reda på det genom en kravbedömning!',
   CreateDemandAssessment: 'Skapa kravbedömning',

   PlanYourCalendar: 'Planera er årskalender',
   PlanYourCalendarDescription: 'När ska nästa kravbedömning göras och när är det dags för en riskbedömning? Börja er planering nu!',
   CreateActivity: 'Skapa aktivitet',

   DoARiskAssessment: 'Gör en riskbedömning!',
   DoARiskAssessmentDescription: 'Identifiera era största risker genom en riskbedömning.',
   CreateRiskAssessment: 'Skapa riskbedömning',

   CreateNewFinancialYear: 'Skapa nytt räkenskapsår',
   CreateNewFinancialYearDescription:
      'Riskekonomi modulen saknar ett räkenskapsår och identifierade kostnader för att få ut ett diagram här. Kom igång med riskekonomin direkt!',
   CreateFinancialYear: 'Skapa räkenskapsår',
}
