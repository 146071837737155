import React, { useContext, useEffect, useState } from 'react'
import { t } from '../i18n/i18n'
import { Layout } from './Layout'
import { Navigation } from '../components/common/navigation/Navigation'
import { DataContext } from '../context/DataContext'
import { Activity, DemandAnalysisDashboardDTO, FinancialYear, RiskAnalysis, SubscriptionLevel } from '../api/schemas/schema'
import { DashboardBaseExtended } from '../components/dashboard/DashboardBaseExtended'
import { DashboardMini } from '../components/dashboard/DashboardMini/DashboardMini'
import { IComplianceState } from '../interfaces/IComplianceState'
import { IListState } from '../interfaces/IListState'
import { ModalActivity } from '../components/calendar/ModalActivity'
import { ModalDemandAssessment } from '../components/compliance/ModalDemandAssessment'
import { ModalRiskAssesment } from '../components/riskAnalyses/ModalRiskAssesment'
import { ModalAddFinancialYear } from '../components/riskEconomy/ModalAddFinancialYear'
import { getComplianceRelatedProperties } from '../helpers/demandAssessmentHelper'
import dayjs from 'dayjs'
import { isSubscriptionLevelBaseOrBig } from '../helpers/organisationHelper'
import { getLatestSwotAnalysis } from '../helpers/riskAnalysisHelper'
import { getDemandAnalysisDashboardData, getLatestActivities } from '../helpers/dashboardHelper'

export const Dashboard = () => {
   const dataContext = useContext(DataContext)
   const { accessToken, organisation } = dataContext.state
   const { subscription } = organisation

   // Demand assessment
   const [complianceState, setComplianceState] = useState<IComplianceState>({
      loading: true,
      allDemandAssessments: [],
      filteredDemandAssessments: [],
      open: false,
      fetchDone: false,
      leadershipPerspectives: [],
      processes: [],
      subjects: [],
      demandAnalysisStatuses: [],
   })

   useEffect(() => {
      if (complianceState.open && !complianceState.fetchDone) {
         getComplianceRelatedProperties(accessToken, dataContext, setComplianceState)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [complianceState.open])

   // Activity
   const [activityModalState, setActivityModalState] = useState<{ isOpen: boolean; selectedActivity?: Activity }>({
      isOpen: false,
      selectedActivity: undefined,
   })
   const [latestActivities, setLatestActivities] = useState<Activity[]>([])
   const [dashboardData, setDashboardData] = useState<DemandAnalysisDashboardDTO>()

   useEffect(() => {
      getLatestActivities(dataContext, setLatestActivities)
      getDemandAnalysisDashboardData(dataContext, setDashboardData)

      ;(async () => {
         const latestSwotAnalysis = await getLatestSwotAnalysis(accessToken, organisation.id, dataContext)
         setLatestSwotAnalysis(latestSwotAnalysis)
      })()

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [accessToken, dataContext, organisation.id])

   // Risk management
   const [riskManagementState, setRiskManagementState] = useState<IListState<RiskAnalysis>>({
      loading: true,
      filteredItems: [],
      allItems: [],
      open: false,
   })
   const [latestSwotAnalysis, setLatestSwotAnalysis] = useState<RiskAnalysis | undefined>(undefined)

   // Risk economy
   const [riskEconomyState, setRiskEconomyState] = React.useState<IListState<FinancialYear>>({
      loading: true,
      open: false,
      allItems: [],
      filteredItems: [],
   })

   return (
      <Layout breadCrumbItems={[{ title: t('Overview'), active: true, path: '/' }]}>
         <Navigation activeDesktopTab="/" />
         {subscription === SubscriptionLevel.MINI ? (
            <DashboardMini
               setComplianceState={setComplianceState}
               setActivityModalState={setActivityModalState}
               setRiskManagementState={setRiskManagementState}
               latestSwotAnalysis={latestSwotAnalysis}
               latestActivities={latestActivities}
               dashboardData={dashboardData}
            />
         ) : isSubscriptionLevelBaseOrBig(subscription) ? (
            <DashboardBaseExtended
               setComplianceState={setComplianceState}
               setActivityModalState={setActivityModalState}
               setRiskManagementState={setRiskManagementState}
               setRiskEconomyState={setRiskEconomyState}
               dashboardData={dashboardData}
            />
         ) : null}

         <ModalDemandAssessment complianceState={complianceState} setComplianceState={setComplianceState} />
         <ModalActivity modalState={activityModalState} setActivities={setLatestActivities} setModalState={setActivityModalState} />
         <ModalRiskAssesment riskManagementState={riskManagementState} setRiskManagementState={setRiskManagementState} />
         <ModalAddFinancialYear riskEconomyState={riskEconomyState} setRiskEconomyState={setRiskEconomyState} />
      </Layout>
   )
}
