import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Dropdown, Option } from '@fluentui/react-components'
import { t } from 'i18next'
import { Field } from './Field'

type ControlledSingleDropdownProps = {
   name: string
   control: Control<any>
   label: string
   options: { key: string; text: string }[]
   disabled?: boolean
   required?: boolean
}

export const ControlledSingleDropdown = ({ name, control, label, options, disabled, required }: ControlledSingleDropdownProps) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error }, formState: { disabled: formDisabled } }) => {
            const matchedOption = options.find((opt) => opt.key === value)

            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  <Dropdown
                     value={matchedOption?.text ?? ''}
                     onOptionSelect={(e, d) => onChange(d.optionValue)}
                     selectedOptions={value ? [value] : []}
                     disabled={disabled ?? formDisabled}
                  >
                     {options.map((opt) => (
                        <Option key={opt.key} value={opt.key}>
                           {opt.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
