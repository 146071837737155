import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../common/modal/Modal'
import { Divider, Button } from '@fluentui/react-components'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { t } from '../../i18n/i18n'
import { useForm } from 'react-hook-form'
import { FinancialYear } from '../../api/schemas/schema'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ControlledAccountPicker } from '../common/inputs/ControlledAccountPicker'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { ControlledDropDown } from '../common/inputs/ControlledDropdown'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { DataClassification } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { IItemState } from '../../interfaces/IItemState'
import { updateFinancialYear } from '../../helpers/riskEconomyHelper'

type ModalEditFinancialYearProps = {
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
   financialYear: FinancialYear
   open: boolean
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   disabled?: boolean
}

export const ModalEditFinancialYear = ({ setFinancialYearState, financialYear, open, setOpen, disabled }: ModalEditFinancialYearProps) => {
   const dataContext = useContext(DataContext)
   const {
      control,
      setValue,
      watch,
      reset,
      handleSubmit,
      formState: { isSubmitting },
   } = useForm<FinancialYear>()

   const onSubmit = async (data: FinancialYear) => {
      await updateFinancialYear(setFinancialYearState, dataContext, data)

      reset(financialYear)
      setOpen(false)
   }

   const close = () => {
      reset()
      setOpen(false)
   }

   useEffect(() => {
      reset(financialYear)
   }, [financialYear, reset])

   return (
      <>
         <Modal open={open} title={t('EditItem', { Item: t('FinancialYear').toLowerCase() })}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Grid>
                  <GridItem size="12/12">
                     <Divider appearance="brand" />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('FinancialYear')} control={control} name="name" required />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledAccountPicker label={t('Owner')} control={control} name="owner" watch={watch} setValue={setValue} required />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        required
                        control={control}
                        name={'dataClassification'}
                        label={t('DataClassification')}
                        options={enumValuesToOptions(DataClassification)}
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledMoneyInput label={t('BudgetedRevenue')} control={control} name="budgetedRevenue" />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('Comment')} control={control} name="comment" multiline />
                  </GridItem>
                  <GridItem size="12/12">
                     <ModalActions>
                        <Button
                           onClick={() => {
                              reset()
                              close()
                           }}
                        >
                           {t('Cancel')}
                        </Button>
                        <Button appearance="primary" type="submit" disabled={isSubmitting}>
                           {t('Save')}
                        </Button>
                     </ModalActions>
                  </GridItem>
               </Grid>
            </form>
         </Modal>
      </>
   )
}
