import React, { useContext } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Dashboard } from './pages/Dashboard'
import { Compliance } from './pages/Compliance'
import { SelectedDemandAssessment } from './pages/SelectedDemandAssessment'
import { Settings } from './pages/Settings'
import { RiskManagement } from './pages/RiskManagement'
import { SelectedRiskAnalysis } from './pages/SelectedRiskAnalysis'
import { Calendar } from './pages/Calendar'
import { RiskEconomy } from './pages/RiskEconomy'
import { SelectedFinancialYear } from './pages/SelectedFinancialYear'
import { DataContext } from './context/DataContext'
import { isSubscriptionLevelBaseOrBig } from './helpers/organisationHelper'
import { NavigationLink } from './interfaces/INavigationLink'
import { RootLayout } from './RootLayout'

export const Router = () => {
   const dataContext = useContext(DataContext)
   const { subscription } = dataContext.state.organisation
   const router = createBrowserRouter([
      {
         element: <RootLayout />,
         children: [
            {
               path: NavigationLink.Overview,
               element: <Dashboard />,
            },
            {
               path: NavigationLink.AnnualCalendar,
               element: <Calendar />,
            },
            {
               path: NavigationLink.ComplianceOfDemands,
               element: <Compliance />,
            },
            {
               path: NavigationLink.ComplianceOfDemands + '/:id',
               element: <SelectedDemandAssessment />,
            },

            {
               path: NavigationLink.RiskManagement,
               element: <RiskManagement />,
            },
            {
               path: NavigationLink.RiskManagement + '/:id',
               element: <SelectedRiskAnalysis />,
            },
            {
               path: NavigationLink.RiskEconomy,
               element: isSubscriptionLevelBaseOrBig(subscription) ? <RiskEconomy /> : <Navigate to="/" />,
            },
            {
               path: NavigationLink.RiskEconomy + '/:id',
               element: isSubscriptionLevelBaseOrBig(subscription) ? <SelectedFinancialYear /> : <Navigate to="/" />,
            },
            {
               path: NavigationLink.Settings,
               element: <Settings />,
            },
         ],
      },
   ])
   return <RouterProvider router={router} />
}
