import React from 'react'
import { TableColumnDefinition } from '@fluentui/react-components'
import { RiskAnalysis } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import { UseFormReset } from 'react-hook-form'
import { IListState } from '../interfaces/IListState'
import { getRiskAnalysisTypeString } from './enumHelper'
import { Avatar } from '../components/common/avatar/Avatar'
import { t } from '../i18n/i18n'
import { getFullName } from './accountHelper'
import { MoreOptionsMenuButton } from '../components/common/other/MoreOptionsMenuButton'
import { formatDate } from './stringHelper'

export enum RiskManagementTabs {
   Ongoing = 'ongoing',
   Concluded = 'concluded',
}

export const getRiskManagementColumns = (
   setRiskManagementState: React.Dispatch<React.SetStateAction<IListState<RiskAnalysis>>>
): TableColumnDefinition<RiskAnalysis>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => t('NameOfAssesment'),
      renderCell: (item) => item.name,
      compare: (a, b) => a.name.localeCompare(b.name),
   },
   {
      columnId: 'type',
      renderHeaderCell: () => t('Type'),
      renderCell: (item) => getRiskAnalysisTypeString(item.type),
      compare: (a, b) => getRiskAnalysisTypeString(a.type).localeCompare(getRiskAnalysisTypeString(b.type)),
   },
   {
      columnId: 'modifiedBy',
      renderHeaderCell: () => t('ModifiedBy'),
      renderCell: (item) => <Avatar name={getFullName(item.modifiedBy)} />,
      compare: (a, b) => getFullName(a.modifiedBy).localeCompare(getFullName(b.modifiedBy)),
   },
   {
      columnId: 'modifed',
      renderHeaderCell: () => t('Modified'),
      renderCell: (item) => {
         return formatDate(item.modified, true)
      },
      compare: (a, b) => new Date(a.modified).getTime() - new Date(b.modified).getTime(),
   },
   {
      columnId: 'menu',
      renderHeaderCell: () => <div style={{ width: '35px' }}>&nbsp;</div>,
      renderCell: (item) => (
         <MoreOptionsMenuButton
            item={item}
            deleteFunction={deleteRiskAnalysis}
            setState={setRiskManagementState}
            deleteMessage={t('DeleteRiskAssessment')}
            deleteConfirmationMessage={t('DeleteRiskAssessmentConfirmationMessage')}
         />
      ),
      compare: () => 0,
   },
]

export const addRiskAnalysis = async (
   setRiskManagementState: React.Dispatch<React.SetStateAction<IListState<RiskAnalysis>>>,
   setError: React.Dispatch<React.SetStateAction<string>>,
   reset: UseFormReset<RiskAnalysis>,
   dataContext: IDataContext,
   data: RiskAnalysis
) => {
   try {
      const { userAccount, accessToken, organisation } = dataContext.state
      data.organisationId = organisation.id
      data.modifiedById = userAccount.id
      data.modifiedBy = userAccount
      data.createdBy = userAccount
      data.createdById = userAccount.id
      const response = await api(accessToken).addRiskAnalysis(data)
      const addedAnalysis = response.data
      reset(addedAnalysis, { keepIsSubmitSuccessful: true, keepIsSubmitted: true })
      setRiskManagementState((prev) => ({ ...prev, allItems: [...prev.allItems, addedAnalysis], open: false }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('RiskAssesment') }))
   } catch (error: any) {
      setError(error.error.Message)
      reset()
      setRiskManagementState((prev) => ({ ...prev, open: false }))
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('RiskAssesment') }))
   }
}

export const deleteRiskAnalysis = async (
   id: string,
   dataContext: IDataContext,
   setRiskManagementState: React.Dispatch<React.SetStateAction<IListState<RiskAnalysis>>>
) => {
   try {
      const { accessToken, organisation } = dataContext.state

      await api(accessToken).deleteRiskAnalysis(id)

      const riskAnalysesResponse = await api(accessToken).getRiskAnalysesByOrganisationId(organisation.id)
      const riskAnalyses = riskAnalysesResponse.data

      setRiskManagementState((prev) => ({ ...prev, allItems: riskAnalyses, open: false }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyDeleted', { Item: t('RiskAssesment') }))
   } catch (error: any) {
      setRiskManagementState((prev) => ({ ...prev, open: false }))
      handleMessage(dataContext.setRootState, 'error', t('CouldNotDeleteItem', { Item: t('RiskAssesment') }))
   }
}
