import React from 'react'
import { t } from 'i18next'
import { makeStyles, tokens, Text } from '@fluentui/react-components'
import { ResponsiveContainer, BarChart, XAxis, YAxis, CartesianGrid, Bar, Cell } from 'recharts'
import { GridItem } from '../common/grid/GridItem'
import { Cost, CostCategory } from '../../api/schemas/schema'
import { translateEnumValue } from '../../helpers/enumHelper'
import { getGroupedAndSummarizedCosts } from '../../helpers/riskEconomyHelper'
const useStyles = makeStyles({
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   budgetResultWrapper: {
      width: '255px',
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: '60px',
      marginBottom: tokens.spacingVerticalXXXL,
      marginTop: tokens.spacingVerticalXXXL,
   },
   lineWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
   },
   dashedLine: {
      width: '40px',
      height: '2px',
      borderTop: '3px dashed #244452',
   },
   straightLine: {
      width: '40px',
      height: '2px',
      borderTop: '3px solid #244452',
   },
})

const getBarBorderColor = (category: CostCategory) => {
   switch (category) {
      case CostCategory.DamageCosts:
         return '#FF9797'
      case CostCategory.PreventiveCosts:
         return '#7C93AB'
      case CostCategory.InsuranceCosts:
         return '#242424'
      case CostCategory.SecurityAdministration:
         return '#FFD57F'
   }
}

const getCostCategoryColor = (category: CostCategory) => {
   switch (category) {
      case CostCategory.DamageCosts:
         return '#C50F1F'
      case CostCategory.PreventiveCosts:
         return '#394B5F'
      case CostCategory.InsuranceCosts:
         return '#919191'
      case CostCategory.SecurityAdministration:
         return '#EAA300'
   }
}

const BarWithBorder = (props: any) => {
   const { x, y, width, height, borderColor } = props

   const increasedHeight = height - 4

   return (
      <g>
         <rect x={x} y={y} width={width} height={increasedHeight} stroke={borderColor || '#000'} strokeWidth={5} strokeDasharray="10 4" fillOpacity={0} />
      </g>
   )
}

const BarWithLine = (props: any) => {
   const { y, x, width, borderColor } = props

   return (
      <g>
         <rect x={x} y={y} width={width} height={1} stroke={borderColor || '#000'} strokeWidth={5} strokeDasharray="10 4" fillOpacity={0} />
      </g>
   )
}

const CustomXAxisTick = (props: any) => {
   const { x, y, payload } = props

   return (
      <text x={x} y={y} dy={20} textAnchor="middle" fontSize={tokens.fontSizeBase400} fontWeight={tokens.fontWeightMedium}>
         {translateEnumValue(CostCategory, payload.value)}
      </text>
   )
}

type FinancialYearInformationBarChartProps = {
   costs: Cost[]
}

export const FinancialYearInformationBarChart = ({ costs }: FinancialYearInformationBarChartProps) => {
   const classes = useStyles()

   const groupedCosts = getGroupedAndSummarizedCosts(costs)

   return (
      <GridItem size={'10/12'} className={classes.noneInPhone}>
         <div className={classes.budgetResultWrapper}>
            <div className={classes.lineWrapper}>
               <span className={classes.dashedLine} />
               <Text size={400} weight="semibold">
                  {t('Budget')}
               </Text>
            </div>
            <div className={classes.lineWrapper}>
               <span className={classes.straightLine} />
               <Text size={400} weight="semibold">
                  {t('Result')}
               </Text>
            </div>
         </div>

         <ResponsiveContainer width="100%" height={800}>
            <BarChart data={groupedCosts} margin={{ top: 25, right: 75, left: 0, bottom: 25 }}>
               <XAxis xAxisId={0} dataKey="costCategory" tick={CustomXAxisTick} />
               <XAxis xAxisId={1} hide />
               <XAxis xAxisId={2} hide />
               <YAxis />
               <CartesianGrid vertical={false} stroke={tokens.colorBrandStroke1} />

               <Bar
                  dataKey="budget"
                  xAxisId={0}
                  fillOpacity={0}
                  barSize={144}
                  shape={(props: any) =>
                     groupedCosts[props.index].budget > groupedCosts[props.index].result ? (
                        <BarWithBorder {...props} borderColor={getBarBorderColor(groupedCosts[props.index]?.costCategory)} />
                     ) : null
                  }
               />
               <Bar dataKey="result" xAxisId={1} fill={tokens.colorBrandBackground} barSize={150}>
                  {groupedCosts.map((_, index) => (
                     <Cell key={`cell-${index}`} fill={getCostCategoryColor(groupedCosts[index]?.costCategory)} />
                  ))}
               </Bar>
               <Bar
                  dataKey="budget"
                  xAxisId={2}
                  fillOpacity={0}
                  barSize={144}
                  shape={(props: any) =>
                     groupedCosts[props.index].budget <= groupedCosts[props.index].result ? (
                        <BarWithLine {...props} borderColor={getBarBorderColor(groupedCosts[props.index]?.costCategory)} />
                     ) : null
                  }
               />
            </BarChart>
         </ResponsiveContainer>
      </GridItem>
   )
}
