import React, { useContext, useState } from 'react'
import { Menu, MenuTrigger, Button, mergeClasses, MenuPopover, MenuList, MenuItem, tokens, makeStyles } from '@fluentui/react-components'
import { ChevronDown16Filled, Organization24Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import { InvoiceDetails } from '../../organisation/InvoiceDetails'
import { OrganisationDetails } from '../../organisation/OrganisationDetails'
import { SwitchOrganisation } from '../../organisation/SwitchOrganisation'
import { DataContext } from '../../../context/DataContext'
import { SubscriptionDetails } from '../../organisation/subscription/SubscriptionDetails'

const useStyles = makeStyles({
   colorWhite: {
      color: tokens.colorNeutralForegroundInverted,
      '&:hover': {
         color: tokens.colorBrandForegroundInvertedHover,
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   showOnPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   pointer: {
      cursor: 'pointer',
   },
})

export const OrganisationMenu = () => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { organisation } = dataContext.state
   const toggleModal = (modalName: string, isOpen: boolean) => {
      setModals({
         orgDetails: false,
         subscriptionDetails: false,
         invoiceDetails: false,
         switchOrg: false,
         [modalName]: isOpen,
      })
   }
   const [modals, setModals] = useState({
      orgDetails: false,
      subscriptionDetails: false,
      invoiceDetails: false,
      switchOrg: false,
   })

   const closeNav = () => {
      dataContext.setRootState((prev) => ({
         ...prev,
         navigationExpanded: false,
      }))
   }

   return (
      <div onClick={closeNav}>
         <Menu>
            <MenuTrigger>
               <Button
                  className={mergeClasses(classes.noneInPhone, classes.colorWhite, classes.pointer)}
                  appearance="transparent"
                  icon={<ChevronDown16Filled />}
                  iconPosition="after"
               >
                  {organisation.name}
               </Button>
            </MenuTrigger>
            <MenuPopover>
               <MenuList>
                  <MenuItem onClick={() => toggleModal('orgDetails', true)}>{t('OrganisationDetails')}</MenuItem>
                  <MenuItem onClick={() => toggleModal('subscriptionDetails', true)}>{t('Subscription')}</MenuItem>
                  <MenuItem onClick={() => toggleModal('invoiceDetails', true)}>{t('InvoiceDetails')}</MenuItem>
                  <MenuItem onClick={() => toggleModal('switchOrg', true)}>{t('SwitchOrganisation')}</MenuItem>
               </MenuList>
            </MenuPopover>
         </Menu>
         <Menu>
            <MenuTrigger>
               <Button
                  className={mergeClasses(classes.showOnPhone, classes.colorWhite, classes.pointer)}
                  appearance="transparent"
                  icon={<Organization24Regular />}
               >
                  <ChevronDown16Filled />
               </Button>
            </MenuTrigger>
            <MenuPopover>
               <MenuList>
                  <MenuItem onClick={() => toggleModal('orgDetails', true)}>{t('OrganisationDetails')}</MenuItem>
                  <MenuItem onClick={() => toggleModal('subscriptionDetails', true)}>{t('Subscription')}</MenuItem>
                  <MenuItem onClick={() => toggleModal('invoiceDetails', true)}>{t('InvoiceDetails')}</MenuItem>
                  <MenuItem onClick={() => toggleModal('switchOrg', true)}>{t('SwitchOrganisation')}</MenuItem>
               </MenuList>
            </MenuPopover>
         </Menu>
         <OrganisationDetails
            open={modals.orgDetails}
            closeModal={() => {
               toggleModal('orgDetails', false)
            }}
         />

         <SubscriptionDetails
            open={modals.subscriptionDetails}
            closeModal={() => {
               toggleModal('subscriptionDetails', false)
            }}
         />

         <InvoiceDetails
            open={modals.invoiceDetails}
            closeModal={() => {
               toggleModal('invoiceDetails', false)
            }}
         />
         <SwitchOrganisation
            open={modals.switchOrg}
            closeModal={() => {
               toggleModal('switchOrg', false)
            }}
         />
      </div>
   )
}
