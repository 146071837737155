import React from 'react'
import { Organisation, SubscriptionLevel } from '../../../api/schemas/schema'
import { DataContext } from '../../../context/DataContext'
import { useContext } from 'react'
import { Button, makeStyles, tokens, Text, Divider, Avatar, MessageBar, MessageBarBody, MessageBarTitle, mergeClasses } from '@fluentui/react-components'
import { Premium24Regular, Add24Regular, Edit24Regular } from '@fluentui/react-icons'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { useForm } from 'react-hook-form'
import { IOpenCloseModalProps } from '../../../interfaces/IOpenCloseModalProps'
import { handleMessage } from '../../../helpers/stateHelper'
import { getFullName } from '../../../helpers/accountHelper'
import { SubscriptionIconTitle } from './SubscriptionIconTitle'
import { ControlledSubscriptionAccordion } from './ControlledSubscriptionAccordion'
import { api } from '../../../helpers/apiHelper'
import { isSubscriptionLevelBaseOrBig } from '../../../helpers/organisationHelper'

const useStyles = makeStyles({
   modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: tokens.spacingVerticalM,
   },
   subscriptionPlan: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   alignRight: {
      display: 'flex',
      justifyContent: 'flex-end',
   },
   alignBottom: {
      display: 'flex',
      alignItems: 'flex-end',
   },
   itemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalM,
   },
   itemWrapperNoGap: {
      display: 'flex',
      flexDirection: 'column',
   },
   marginTop: {
      marginTop: tokens.spacingVerticalS,
   },
   avatarSpacer: {
      display: 'flex',
      alignItems: 'center',
      gap: tokens.spacingHorizontalS,
   },
   subscriptionButton: {
      backgroundColor: tokens.colorBrandBackgroundStatic,
      gap: tokens.spacingHorizontalS,
   },
   marginBottom: {
      marginBottom: tokens.spacingVerticalM,
   },
})

export const SubscriptionDetails = ({ open, closeModal }: IOpenCloseModalProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { accessToken, organisation, userAccount } = dataContext.state
   const { subscription } = organisation
   const organisationForm = useForm<Organisation>({ defaultValues: { modules: organisation.modules || [] } })
   const { reset, handleSubmit } = organisationForm

   const handleSaveClick = handleSubmit(async (data: Organisation) => {
      try {
         const organisationData = organisation
         organisationData.modules = data.modules

         const updatedOrganisation = await api(accessToken).updateOrganisation(organisationData)
         setRootState((prev) => ({
            ...prev,
            organisation: updatedOrganisation.data,
         }))

         closeModal()
         handleMessage(setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('Subscription') }))
      } catch (error: any) {
         handleMessage(setRootState, 'error', t('SomethingWentWrong'), error ? error.error : t('CouldNotUpdateItem', { Item: t('Subscription') }))
      }
   })

   const closeAndReset = () => {
      closeModal()
      reset(organisation)
   }

   return (
      <>
         <Modal open={open} title={null}>
            <div className={classes.modalHeader}>
               <Text role="heading" size={500} weight="semibold">
                  {t('Subscription')}
               </Text>
               <Button aria-label="Close" appearance="transparent" icon={<Dismiss24Regular />} onClick={closeAndReset} />
            </div>
            <Divider appearance="brand" className={classes.marginBottom} />
            <form>
               <Grid gap="L">
                  <GridItem size={'12/12'} className={classes.itemWrapper}>
                     <div className={classes.subscriptionPlan}>
                        <SubscriptionIconTitle />

                        <Button className={classes.subscriptionButton} appearance="primary">
                           {subscription === SubscriptionLevel.BIG ? (
                              <>
                                 <Edit24Regular /> {t('ChangeSubscription')}
                              </>
                           ) : (
                              <>
                                 <Premium24Regular /> {t('UpgradeSubscription')}
                              </>
                           )}
                        </Button>
                     </div>
                     <Divider appearance="brand" />
                  </GridItem>

                  <GridItem size={'12/12'} className={classes.itemWrapper}>
                     <Text size={400} weight="semibold">
                        {t('SystemAdmin')}
                     </Text>
                     <div className={classes.avatarSpacer}>
                        <Avatar size={20} name={getFullName(userAccount)}></Avatar>
                        <Text size={400}>{getFullName(userAccount)}</Text>
                     </div>
                     <Divider appearance="brand" />
                  </GridItem>

                  <GridItem size={'12/12'} className={classes.itemWrapperNoGap}>
                     <Text size={400} weight="medium">
                        {t('AdditionalModules')}
                     </Text>

                     {subscription === SubscriptionLevel.MINI ? (
                        <MessageBar intent={'info'} className={classes.marginTop}>
                           <MessageBarBody>
                              <MessageBarTitle>{t('SubscriptionUpgradeRequiredForPurchaseOfAdditionalModules')}</MessageBarTitle>
                           </MessageBarBody>
                        </MessageBar>
                     ) : (
                        <>
                           <div className={mergeClasses(classes.subscriptionPlan, classes.alignBottom)}>
                              <Text>{t('EnableOrDisableAvailableAdditionalModules')}</Text>
                              <Button appearance="primary" className={classes.subscriptionButton}>
                                 <Add24Regular /> {t('PurchaseAdditionalModule')}
                              </Button>
                           </div>

                           <ControlledSubscriptionAccordion organisationForm={organisationForm} />
                        </>
                     )}
                  </GridItem>

                  {isSubscriptionLevelBaseOrBig(subscription) && (
                     <GridItem size={'12/12'} className={classes.alignRight}>
                        <ModalActions>
                           <Button appearance="primary" type="submit" className={classes.subscriptionButton} onClick={handleSaveClick}>
                              {t('Save')}
                           </Button>
                        </ModalActions>
                     </GridItem>
                  )}
               </Grid>
            </form>
         </Modal>
      </>
   )
}
