import React, { useEffect, useState } from 'react'
import { Tab, TabList, makeStyles, mergeClasses, tokens, Text, Button, SelectTabData } from '@fluentui/react-components'
import {
   InfoShield20Regular,
   SearchShield20Regular,
   TaskListSquareLtr20Regular,
   Warning20Regular,
   ClipboardTextLtr20Regular,
   ClipboardPulse20Regular,
   Warning20Filled,
   SearchShield20Filled,
   ClipboardPulse20Filled,
   ClipboardTextLtr20Filled,
   TaskListSquareLtr20Filled,
   InfoShield20Filled,
   LockClosed20Regular,
   LockClosed24Regular,
} from '@fluentui/react-icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ActionPlan, ActionPlanStatus, RiskAnalysis, RiskAnalysisStatus } from '../../../api/schemas/schema'
import { Layout } from '../../../pages/Layout'
import { t } from '../../../i18n/i18n'
import { Navigation } from '../../common/navigation/Navigation'
import { RiskInformation } from '../RiskInformation'
import { RiskIdentifying } from '../RiskIdentifying'
import { ThreatScenario } from './ThreatScenario'
import { RiskAnalyse } from './RiskAnalyse'
import { ConsequenceEvaluation } from './ConsequenceEvaluation'
import { RiskActionPlan } from '../RiskActionPlan'
import {
   ConsequenceAndProbabilityAnalysisTabs,
   findConsequenceRiskParentToActionPlan,
   getConsequenceAndProbabilityRiskActionPlanColumns,
   getConsequenceAndProbabilityRiskActionPlanGroups,
   getConsequenceAndProbabilityRiskActionPlanSummaryFields,
} from '../../../helpers/riskAnalysisHelper'
import { formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { BadgeActionPlanStatus } from '../../demandAnalysis/BadgeActionPlanStatus'
import { ParentConsequencedRiskInformation } from './ParentConsequenceRiskInformation'
import { exportConsequenceAndProbabilityRiskActionPlansToExcel } from '../../../helpers/excelHelper'
import { ModalCompleteRiskAssessment } from '../ModalCompleteRiskAssessment'
import { ConsequenceAndProbabilityNavLinks } from '../../common/navigation/ConsequenceAndProbabilityNavLinks'
import { IItemState } from '../../../interfaces/IItemState'
const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   flexOnlyLarge: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   ellipsis: {
      '> * ': {
         display: '-webkit-box',
         WebkitBoxOrient: 'vertical',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'normal',
         WebkitLineClamp: 3,
      },
   },
   phoneItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalM,
   },
})

type ConsequenceAndProbabilityProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysisState: IItemState<RiskAnalysis>
   analysis: RiskAnalysis
}

export const ConsequenceAndProbabilityAnalysis = ({ setRiskAnalysisState, analysisState, analysis }: ConsequenceAndProbabilityProps) => {
   const classes = useStyles()
   const navigate = useNavigate()
   const [searchParams, setSearchParams] = useSearchParams()
   const [selectedItem, setSelectedItem] = useState<ActionPlan>(null)
   const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false)
   const selectedTab = searchParams.get('tab')
   const setTab = (tab: string) => {
      setSearchParams((prev) => ({ ...prev, tab }))
   }

   useEffect(() => {
      if (!selectedTab) {
         setTab('info')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const consequenceRiskPhoneBody = (item: ActionPlan) => {
      const risk = findConsequenceRiskParentToActionPlan(analysis.consequenceAndProbabilityRisks, item)

      return (
         <div className={classes.phoneItem}>
            <span className={classes.spaceBtw}>
               <Text size={400}>{risk.scenario}</Text>
               <BadgeActionPlanStatus status={item.status} />
            </span>
            <Text size={300}>{risk.estimatedCost || risk.estimatedCost === 0 ? formatToSwedishCrowns(risk.estimatedCost) + ' kr' : null}</Text>
            <Text size={300} className={classes.ellipsis}>
               {risk.consequence}
            </Text>
         </div>
      )
   }

   const completeBtnDisabled =
      analysis.consequenceAndProbabilityRisks.length === 0 ||
      analysis.actionPlans.length === 0 ||
      analysis.actionPlans.some((ap) => ap.status === ActionPlanStatus.NotDecided || analysis.status === RiskAnalysisStatus.Completed)

   const getConsequenceAndProbabilityTitle = (tab: string): string => {
      switch (tab) {
         case ConsequenceAndProbabilityAnalysisTabs.Info:
            return t('Information')
         case ConsequenceAndProbabilityAnalysisTabs.ThreatScenario:
            return t('ThreatScenario')
         case ConsequenceAndProbabilityAnalysisTabs.Identifying:
            return t('RiskIdentifying')
         case ConsequenceAndProbabilityAnalysisTabs.Analysis:
            return t('RiskAnalysis')
         case ConsequenceAndProbabilityAnalysisTabs.Evaluation:
            return t('RiskEvaluation')
         case ConsequenceAndProbabilityAnalysisTabs.ActionPlan:
            return t('RiskManagementPlan')
      }
   }

   return (
      <Layout
         breadCrumbItems={[
            { path: '/risk-management', active: false, title: t('RiskManagement') },
            { path: `/risk-management/${analysis.id}`, active: true, title: analysis.name },
         ]}
      >
         <Navigation
            activeDesktopTab="/risk-management"
            phoneNavLinks={
               <ConsequenceAndProbabilityNavLinks
                  id={analysis.id}
                  active={selectedTab}
                  actionTab={<Tab value={'complete'} icon={<LockClosed24Regular />} disabled={completeBtnDisabled} />}
               />
            }
            customOnTabSelect={(e, data: SelectTabData) => {
               if (data.value === 'complete') setCompleteModalOpen(true)
               else navigate(data.value)
            }}
         />

         <header className={mergeClasses(classes.header, classes.flexOnlyLarge)}>
            <TabList selectedValue={selectedTab} onTabSelect={(e, data) => setTab(data.value as string)}>
               <Tab
                  icon={selectedTab === ConsequenceAndProbabilityAnalysisTabs.Info ? <InfoShield20Filled /> : <InfoShield20Regular />}
                  value={ConsequenceAndProbabilityAnalysisTabs.Info}
               >
                  {getConsequenceAndProbabilityTitle(ConsequenceAndProbabilityAnalysisTabs.Info)}
               </Tab>
               <Tab
                  icon={selectedTab === ConsequenceAndProbabilityAnalysisTabs.ThreatScenario ? <Warning20Filled /> : <Warning20Regular />}
                  value={ConsequenceAndProbabilityAnalysisTabs.ThreatScenario}
               >
                  {getConsequenceAndProbabilityTitle(ConsequenceAndProbabilityAnalysisTabs.ThreatScenario)}
               </Tab>
               <Tab
                  icon={selectedTab === ConsequenceAndProbabilityAnalysisTabs.Identifying ? <SearchShield20Filled /> : <SearchShield20Regular />}
                  value={ConsequenceAndProbabilityAnalysisTabs.Identifying}
               >
                  {getConsequenceAndProbabilityTitle(ConsequenceAndProbabilityAnalysisTabs.Identifying)}
               </Tab>
               <Tab
                  icon={selectedTab === ConsequenceAndProbabilityAnalysisTabs.Analysis ? <ClipboardTextLtr20Filled /> : <ClipboardTextLtr20Regular />}
                  value={ConsequenceAndProbabilityAnalysisTabs.Analysis}
               >
                  {getConsequenceAndProbabilityTitle(ConsequenceAndProbabilityAnalysisTabs.Analysis)}
               </Tab>
               <Tab
                  icon={selectedTab === ConsequenceAndProbabilityAnalysisTabs.Evaluation ? <ClipboardPulse20Filled /> : <ClipboardPulse20Regular />}
                  value={ConsequenceAndProbabilityAnalysisTabs.Evaluation}
               >
                  {getConsequenceAndProbabilityTitle(ConsequenceAndProbabilityAnalysisTabs.Evaluation)}
               </Tab>
               <Tab
                  icon={selectedTab === ConsequenceAndProbabilityAnalysisTabs.ActionPlan ? <TaskListSquareLtr20Filled /> : <TaskListSquareLtr20Regular />}
                  value={ConsequenceAndProbabilityAnalysisTabs.ActionPlan}
               >
                  {getConsequenceAndProbabilityTitle(ConsequenceAndProbabilityAnalysisTabs.ActionPlan)}
               </Tab>
            </TabList>
            <Button disabled={completeBtnDisabled} icon={<LockClosed20Regular />} onClick={() => setCompleteModalOpen(true)} appearance="transparent">
               {t('CompleteRiskAssessment')}
            </Button>
         </header>

         {selectedTab === ConsequenceAndProbabilityAnalysisTabs.Info && <RiskInformation analysis={analysis} setAnalysisState={setRiskAnalysisState} />}
         {selectedTab === ConsequenceAndProbabilityAnalysisTabs.ThreatScenario && (
            <ThreatScenario analysis={analysis} setAnalysisState={setRiskAnalysisState} />
         )}

         {selectedTab === ConsequenceAndProbabilityAnalysisTabs.Identifying && (
            <RiskIdentifying analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />
         )}
         {selectedTab === ConsequenceAndProbabilityAnalysisTabs.Analysis && <RiskAnalyse analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         {selectedTab === ConsequenceAndProbabilityAnalysisTabs.Evaluation && (
            <ConsequenceEvaluation analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />
         )}
         {selectedTab === ConsequenceAndProbabilityAnalysisTabs.ActionPlan && (
            <RiskActionPlan<ActionPlan>
               analysis={analysis}
               actionPlans={analysis.actionPlans}
               title={t('RiskManagementPlan')}
               setRiskAnalysisState={setRiskAnalysisState}
               getItemColumns={getConsequenceAndProbabilityRiskActionPlanColumns(analysis.consequenceAndProbabilityRisks)}
               getItemGroups={getConsequenceAndProbabilityRiskActionPlanGroups(analysis.consequenceAndProbabilityRisks, analysis.actionPlans)}
               getItemSummaryFields={getConsequenceAndProbabilityRiskActionPlanSummaryFields(analysis.actionPlans, analysis.consequenceAndProbabilityRisks)}
               parentRiskInformation={
                  <ParentConsequencedRiskInformation selectedRisk={analysis.consequenceAndProbabilityRisks.find((r) => r.id === selectedItem?.riskId)} />
               }
               getPhoneBody={consequenceRiskPhoneBody}
               selectedItem={selectedItem}
               setSelectedItem={setSelectedItem}
               exportToExcel={(analysis, actionPlans) => exportConsequenceAndProbabilityRiskActionPlansToExcel(analysis, actionPlans)}
            />
         )}

         <ModalCompleteRiskAssessment
            open={completeModalOpen}
            close={() => setCompleteModalOpen(false)}
            riskAnalysisState={analysisState}
            setRiskAnalysisState={setRiskAnalysisState}
         />
      </Layout>
   )
}
