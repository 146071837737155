import React, { useContext, useEffect } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@fluentui/react-components'
import { ModalActions, DialogSurface, DialogBody } from '../../common/modal/Modal'
import { ActionPlan, ActionPlanStatus, SubscriptionLevel } from '../../../api/schemas/schema'
import { useForm } from 'react-hook-form'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { t } from '../../../i18n/i18n'
import { ActionDecision } from './modalContent/ActionDecision'
import { DataContext } from '../../../context/DataContext'
import { handleMessage } from '../../../helpers/stateHelper'

type ModalActionPlanProps = {
   parentInformation: JSX.Element
   selectedItem: ActionPlan
   close: () => void
   disabled: boolean
   onSubmit: (data: ActionPlan) => Promise<boolean>
}
const useStyles = makeStyles({
   dialogSurface: {
      maxWidth: '800px',
   },
})
export const ModalActionPlan = ({ parentInformation, selectedItem, close, disabled, onSubmit }: ModalActionPlanProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { userAccount } = dataContext.state
   const { subscription } = dataContext.state.organisation
   const actionPlanForm = useForm<ActionPlan>({ defaultValues: { actionNeeded: true }, disabled: disabled })
   const { reset, handleSubmit, formState: {isSubmitting} } = actionPlanForm
   useEffect(() => {
      if (selectedItem) reset(selectedItem)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedItem])

   const saveForm = async (data: ActionPlan) => {
      if (data.status === ActionPlanStatus.NotDecided || data.status === ActionPlanStatus.NotToBeAdressed) {
         data.decidedBy = userAccount
         data.decidedById = userAccount.id
      }
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      if (subscription === SubscriptionLevel.MINI) {
         data.responsible = userAccount
         data.responsibleId = userAccount.id
      }

      const success = await onSubmit(data)
      if (success) {
         reset({ actionNeeded: true })
         close()
         handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('ActionPlan') }))
      } else {
         handleMessage(dataContext.setRootState, 'error', t('ValidationFailed', { Item: t('ActionPlan') }))
      }
   }

   return (
      <Dialog open={selectedItem ? true : false}>
         <DialogSurface className={classes.dialogSurface}>
            {selectedItem ? (
               <DialogBody>
                  <DialogTitle>{t('DecisionOnAction')}</DialogTitle>
                  <DialogContent>
                     <form onSubmit={handleSubmit(saveForm)}>
                        <Grid>
                           {parentInformation}
                           <ActionDecision actionPlanForm={actionPlanForm} />
                           <GridItem size="12/12">
                              <ModalActions>
                                 <Button
                                    onClick={() => {
                                       reset({ actionNeeded: true })
                                       close()
                                    }}
                                 >
                                    {disabled ? t('Close') : t('Cancel')}
                                 </Button>
                                 {!disabled ? (
                                    <Button appearance="primary" type="submit" disabled={isSubmitting}>
                                       {t('Save')}
                                    </Button>
                                 ) : null}
                              </ModalActions>
                           </GridItem>
                        </Grid>
                     </form>
                  </DialogContent>
               </DialogBody>
            ) : null}
         </DialogSurface>
      </Dialog>
   )
}
