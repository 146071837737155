import React, { useContext, useEffect, useState } from 'react'
import { Layout } from './Layout'
import { t } from '../i18n/i18n'
import { Navigation } from '../components/common/navigation/Navigation'
import { Button, makeStyles, MessageBar, MessageBarBody, MessageBarTitle, tokens } from '@fluentui/react-components'
import dayjs from 'dayjs'
import { CalendarChart } from '../components/calendar/CalendarChart'
import { ModalActivity } from '../components/calendar/ModalActivity'
import { Activity } from '../api/schemas/schema'
import { DataContext } from '../context/DataContext'
import { getActivities } from '../helpers/calendarHelper'
import { Loader } from '../components/common/spinner/Loader'
import { PhoneList } from '../components/common/list/PhoneList'
import { Text } from '../components/common/text/Text'
import { formatDate } from '../helpers/stringHelper'

const useStyles = makeStyles({
   messageBar: {
      width: '100%',
   },
   btnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 640px)': {
         flexDirection: 'column',
         justifyItems: 'flex-start',
         gap: tokens.spacingVerticalXL,
      },
   },
   activityContainer: {
      borderRadius: '5px',
      boxShadow: tokens.shadow8,
      padding: '5px',
      paddingLeft: '19px',
      paddingRight: '19px',
   },
   calendarView: {
      display: 'none',
      '@media screen and (min-width: 641px)': {
         display: 'flex',
         flexDirection: 'column',
         gap: tokens.spacingVerticalXXL,
      },
   },
   phoneView: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         flexDirection: 'column',
         gap: tokens.spacingVerticalXXL,
      },
   },
   phoneBtn: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         width: `calc(100% - (${tokens.spacingHorizontalL} * 2))`,
         display: 'inline-flex',
         position: 'fixed',
         bottom: '60px',
      },
   },
   displayPhoneView: {
      '@media screen and (min-width: 641px)': {
         display: 'none',
      },
   },
})

export const Calendar = () => {
   const dataContext = useContext(DataContext)
   const classes = useStyles()
   const currentYear = dayjs().year()
   const [loading, setIsLoading] = useState(false)
   const [year, setYear] = useState({ name: currentYear, value: 1 })
   const [activities, setActivities] = useState<Activity[]>([])
   const [modalState, setModalState] = useState<{ isOpen: boolean; selectedActivity?: Activity }>({
      isOpen: false,
      selectedActivity: undefined,
   })
   useEffect(() => {
      getActivities(dataContext, year.name, setActivities, setIsLoading)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [year])

   const onActivitySelect = (activity: Activity) => {
      setModalState({ isOpen: true, selectedActivity: activity })
   }

   return (
      <Layout breadCrumbItems={[{ title: t('AnnualCalendar'), active: true, path: '/calendar' }]}>
         <Navigation activeDesktopTab={'/calendar'} />

         <div className={classes.btnContainer}>
            <Button appearance="primary" onClick={() => setModalState({ isOpen: true })}>
               {t('PlanActivity')}
            </Button>
         </div>
         {activities.length < 1 && (
            <MessageBar className={classes.messageBar}>
               <MessageBarBody>
                  <div className={classes.calendarView}>
                     <MessageBarTitle>{t('PlanActivitesMsg')}</MessageBarTitle>
                  </div>
                  <div className={classes.phoneView}>
                     <MessageBarTitle>{t('PlanActivitesPhoneViewMsg')}</MessageBarTitle>
                  </div>
               </MessageBarBody>
            </MessageBar>
         )}
         <div className={classes.calendarView}>
            <CalendarChart activities={activities} year={year} setYear={setYear} onActivitySelect={onActivitySelect} />
            <ModalActivity modalState={modalState} setActivities={setActivities} setModalState={setModalState} />
         </div>
         <div className={classes.displayPhoneView}>
            {loading ? (
               <Loader />
            ) : (
               <>
                  <PhoneList
                     items={activities}
                     onRowClick={(activity) => onActivitySelect(activity)}
                     renderBodyItem={(item: Activity) => (
                        <div className={classes.activityContainer}>
                           <span>
                              <Text weight="bold">{item.title}</Text>
                           </span>
                           <Text>{`${formatDate(item.startDate)} ${t('To')} ${formatDate(item.endDate)}`}</Text>
                           <Text>{item?.description}</Text>
                        </div>
                     )}
                  />
               </>
            )}
         </div>
      </Layout>
   )
}
