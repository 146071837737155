import React, { useState } from 'react'
import { Text } from '../text/Text'
import { Button, makeStyles, Popover, PopoverSurface, PopoverTrigger, tokens, } from '@fluentui/react-components'
import { Dismiss20Regular } from '@fluentui/react-icons'
type InformationPopoverProps = {
   btnLabel: string
   popoverLabel: string
   regularIcon: JSX.Element
   filledIcon: JSX.Element
   link?: JSX.Element
   input: JSX.Element
}
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   brand: {
      color: tokens.colorNeutralForeground2BrandSelected,
   },
   surface: {
      backgroundColor: tokens.colorBrandBackground2,
      width: '800px',
      maxWidth: '100%',
      boxSizing: 'border-box',
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      '> *': {
         marginBottom: 0,
      },
   },
})
export const InformationPopover = ({ btnLabel, popoverLabel, regularIcon, filledIcon, link, input }: InformationPopoverProps) => {
   const classes = useStyles()
   const [open, setOpen] = useState<boolean>(false)

   return (
      <Popover open={open} onOpenChange={(e, data) => setOpen(data.open ?? false)} size="large">
         <PopoverTrigger>
            <Button className={open ? classes.brand : ''} appearance="transparent" icon={open ? filledIcon : regularIcon}>
               <Text size={200} style={{ margin: '0 auto' }} className={classes.hideInPhone}>
                  {btnLabel}
               </Text>
            </Button>
         </PopoverTrigger>
         <PopoverSurface className={classes.surface}>
            <header className={classes.spaceBtw}>
               <Text as="h4" size={500} weight={'semibold'}>{popoverLabel}</Text>
               <Button className={open ? classes.brand : ''} onClick={() => setOpen(false)} appearance="transparent" icon={<Dismiss20Regular />} />
            </header>
            {input}
            {link ?? null}
         </PopoverSurface>
      </Popover>
   )
}
