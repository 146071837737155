import React from 'react'
import { QuestionCircle20Regular, QuestionCircle20Filled } from '@fluentui/react-icons'
import { DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { t } from '../../../../i18n/i18n'
import { InformationPopover } from '../../../common/popover/InformationPopover'
import { RTFOutput } from '../../../common/outputs/RTFOutput'
type QuestionPopoverProps = {
   item: DemandAnalysisQuestion
}
export const QuestionPopover = ({ item }: QuestionPopoverProps) => {
   return (
      <InformationPopover
         popoverLabel={`${t('Question')} ${item.number}`}
         btnLabel={t('Question')}
         filledIcon={<QuestionCircle20Filled />}
         regularIcon={<QuestionCircle20Regular />}
         input={<RTFOutput text={item.text !== '' ? item.text : t('NoAvailableInformation')} />}
      />
   )
}
