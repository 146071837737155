import React from 'react'
import { Divider, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
   phoneListWrapper: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         flexDirection: 'column',
         gap: tokens.spacingVerticalXXL,
      },
   },
})
type PhoneListProps<T> = {
   items: T[]
   onRowClick?: (item: T) => void
   renderBodyItem: (item: T, index: number) => JSX.Element | JSX.Element[]
   className?: string
}
export const PhoneList = <T extends {}>({ items, onRowClick, renderBodyItem, className }: PhoneListProps<T>) => {
   const classes = useStyles()
   return (
      <article className={mergeClasses(classes.phoneListWrapper, className ?? '')}>
         {items.map((x, i) => {
            return (
               <>
                  <section key={i} onClick={onRowClick ? () => onRowClick(x) : null}>
                     {renderBodyItem(x, i)}
                  </section>
                  {i < items.length - 1 ? <Divider appearance="default" key={`divider-${i}`} /> : null}
               </>
            )
         })}
      </article>
   )
}
