import { Persona, TableColumnDefinition } from '@fluentui/react-components'
import { DemandAnalysis, DemandAnalysisQuestion, ProcessOwner, SubscriptionLevel, AnswerType } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { IAnalysisStatusData } from '../interfaces/IAnalysisStatusData'
import { BadgeAnalysisQuestion } from '../components/demandAnalysis/BadgeAnalysisQuestion'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import React from 'react'
import { findQuestionParentToActionPlan } from './actionPlanHelper'
import { IComplianceState } from '../interfaces/IComplianceState'
import { Avatar } from '../components/common/avatar/Avatar'
import { RTFOutput } from '../components/common/outputs/RTFOutput'
import { t } from '../i18n/i18n'
import { getFullName } from './accountHelper'
import { MoreOptionsMenuButton } from '../components/common/other/MoreOptionsMenuButton'
import { IItemState } from '../interfaces/IItemState'
import { formatDate } from './stringHelper'

export enum ComplianceTabs {
   Ongoing = 'ongoing',
   Concluded = 'concluded',
}

export enum DemandAssessmentTabs {
   Info = 'info',
   DemandAnalysis = 'demand-analysis',
   ActionPlan = 'actionplan',
}

export const getDemandAssessmentsColumns = (
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
): TableColumnDefinition<DemandAnalysis>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => {
         return t('AssessmentName')
      },
      renderCell: (item: DemandAnalysis) => {
         return item.name
      },
      compare: (a, b) => {
         return a.name.localeCompare(b.name)
      },
   },
   {
      columnId: 'modifiedBy',
      renderHeaderCell: () => {
         return t('ModifiedBy')
      },
      renderCell: (item: DemandAnalysis) => {
         return <Avatar name={getFullName(item.modifiedBy)} />
      },
      compare: (a, b) => {
         return getFullName(a.modifiedBy).localeCompare(getFullName(b.modifiedBy))
      },
   },
   {
      columnId: 'modified',
      renderHeaderCell: () => {
         return t('Modified')
      },
      renderCell: (item: DemandAnalysis) => {
         return formatDate(item.modified, true)
      },
      compare: (a, b) => {
         return new Date(a.modified).getTime() - new Date(b.modified).getTime()
      },
   },
   {
      columnId: 'menu',
      renderHeaderCell: () => <div style={{ width: '35px' }}>&nbsp;</div>,
      renderCell: (item) => (
         <MoreOptionsMenuButton
            item={item}
            deleteFunction={deleteDemandAssessment}
            setState={setComplianceState}
            deleteMessage={t('DeleteDemandAssessment')}
            deleteConfirmationMessage={t('DeleteDemandAssessmentConfirmationMessage')}
         />
      ),
      compare: () => 0,
   },
]

export const getDefaultValues = (dataContext: IDataContext, complianceState: IComplianceState): DemandAnalysis => ({
   organisationId: dataContext.state.organisation.id,
   leadershipPerspectives: [],
   branchId: dataContext.state.branch.id,
   processes: [],
   subjects: [],
   analysisOwnerId: dataContext.state.userAccount.id,
   analysisOwner: dataContext.state.userAccount,
   dataClassification: null,
   order: complianceState.allDemandAssessments.length + 1,
   analysisStart: new Date().toISOString(),
   analysisEnd: null,
   name: null,
})

export const getDemandAssessmentStatusColumns = (subscripion: SubscriptionLevel): TableColumnDefinition<IAnalysisStatusData>[] => {
   const columns: TableColumnDefinition<IAnalysisStatusData>[] = [
      {
         columnId: 'process',
         renderHeaderCell: () => {
            return t('Process')
         },
         renderCell: (item) => {
            return item.process
         },
         compare: (a, b) => {
            return a.process.localeCompare(b.process)
         },
      },
      {
         columnId: 'processOwner',
         renderHeaderCell: () => {
            return t('ProcessOwner')
         },
         renderCell: (item) => {
            return <Persona textAlignment="center" name={item.processOwner} />
         },
         compare: (a, b) => {
            return a.processOwner.localeCompare(b.processOwner)
         },
      },
      {
         columnId: 'answered',
         renderHeaderCell: () => {
            return t('AllAnswered')
         },
         renderCell: (item) => {
            return `${item.numberOfAnsweredQuestions} (${item.numberOfTotalQuestions})`
         },
         compare: (a, b) => {
            return 1
         },
      },
      {
         columnId: 'status',
         renderHeaderCell: () => {
            return t('Status')
         },
         renderCell: (item) => {
            return item.status
         },
         compare: (a, b) => {
            return a.status.localeCompare(b.status)
         },
      },
   ]

   return subscripion === SubscriptionLevel.MINI ? columns.filter((col) => col.columnId !== 'processOwner') : columns
}
const getProcessOwners = (processOwners: ProcessOwner[], processes: string[]) => {
   return processes.map((p) => {
      const owner = processOwners.find((po) => po.process.text === p)
      return owner.userAccount ? getFullName(owner.userAccount) : owner.ownerFreeText
   })
}
export const getCurrentAnalysisColumns = (processOwners: ProcessOwner[]): TableColumnDefinition<DemandAnalysisQuestion>[] => [
   {
      columnId: 'number',
      renderHeaderCell: () => t('No.'),
      renderCell: (item) => item.number,
      compare: (a, b) => a.number - b.number,
   },
   {
      columnId: 'question',
      renderHeaderCell: () => t('Question'),
      renderCell: (item) => <RTFOutput text={item.text} fontSize={300} noMargin />,
      compare: (a, b) => a.text.localeCompare(b.text),
   },
   {
      columnId: 'process',
      renderHeaderCell: () => t('Process'),
      renderCell: (item) => item.processes,
      compare: (a, b) => -1,
   },
   {
      columnId: 'processOwner',
      renderHeaderCell: () => t('ProcessOwner'),
      renderCell: (item) => getProcessOwners(processOwners, item.processes),
      compare: (a, b) => -1,
   },
   {
      columnId: 'status',
      renderHeaderCell: () => t('Status'),
      renderCell: (item) => <BadgeAnalysisQuestion status={item.status} />,
      compare: (a, b) => -1,
   },
]

export const completeDemandAnalysis = async (
   dataContext: IDataContext,
   items: DemandAnalysisQuestion[],
   setItems: React.Dispatch<React.SetStateAction<DemandAnalysisQuestion[]>>,
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<DemandAnalysis>>>,
   closeModal: () => void
) => {
   try {
      setAnalysisState((prev) => ({ ...prev, loading: true }))
      const { accessToken, organisation, branch, userAccount } = dataContext.state
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const itemsToConclude = items.map((x) => ({ ...x, modifiedById: userAccount.id, modified: userAccount }))
      const data = (await api(accessToken).concludeCurrentAnalysis(organisation.id, { branchId: branch.id }, items)).data
      const { updatedQuestions, actionPlans } = data
      setItems((prev) => {
         const items = [...prev]
         updatedQuestions.forEach((question) => (items[items.findIndex((x) => x.id === question.id)] = question))
         return items
      })
      setAnalysisState((prev) => {
         const questions = [...prev.item.questions]
         updatedQuestions.forEach((question) => (questions[questions.findIndex((x) => x.id === question.id)] = question))
         return {
            ...prev,
            item: {
               ...prev.item,
               questions: questions,
               actionPlans: [...prev.item.actionPlans, ...actionPlans].sort(
                  (a, b) => findQuestionParentToActionPlan(questions, a).number - findQuestionParentToActionPlan(questions, b).number
               ),
            },
         }
      })
      handleMessage(dataContext.setRootState, 'success', t('DemandAnalysisCompleted'))
      setAnalysisState((prev) => ({ ...prev, loading: false }))
      closeModal()
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'success', t('CouldNotCompleteDemandAnalysis'), error.error)
      setAnalysisState((prev) => ({ ...prev, loading: false }))
   }
}
export const filterQuestions = (selectedProcess: string, questions: DemandAnalysisQuestion[]): DemandAnalysisQuestion[] => {
   const level2Answers = new Map<string, string>()

   questions
      .filter((q) => q.demandLevel === 2)
      .forEach((q) => {
         if (q.answerOptions?.some((opt) => opt.answerType === AnswerType.YesNo || opt.answerType === AnswerType.YesNoDontKnow) && q.id) {
            level2Answers.set(q.demandId, q.answer?.text || '')
         }
      })

   const filteredQuestions = questions.filter((q) => {
      if (q.demandLevel === 3 && q.demandParentId) {
         const parentAnswer = level2Answers.get(q.demandParentId)
         if (parentAnswer !== 'Ja' && parentAnswer !== 'Vet inte') {
            return false
         }
      }
      return true
   })

   let filteredQuestionssByProcess = []
   if (selectedProcess === t('AllProcesses')) filteredQuestionssByProcess = filteredQuestions
   else filteredQuestionssByProcess = filteredQuestions.filter((x) => x.processes.some((p) => p === selectedProcess))

   return filteredQuestionssByProcess
}

export const getDemandAnalysisTitle = (selectedTab: string): string => {
   switch (selectedTab) {
      case DemandAssessmentTabs.Info:
         return t('Information')
      case DemandAssessmentTabs.ActionPlan:
         return t('ActionPlan')
      case DemandAssessmentTabs.DemandAnalysis:
         return null
      // return t('DemandAnalysis')
   }
}

export const deleteDemandAssessment = async (
   id: string,
   dataContext: IDataContext,
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
) => {
   try {
      const { accessToken, organisation } = dataContext.state

      await api(accessToken).deleteDemandAnalysisById(id)

      const response = await api(accessToken).getDemandAnalysisByOrganisationId(organisation.id)
      const demandAssessments = response.data

      setComplianceState((prev) => ({ ...prev, allDemandAssessments: demandAssessments, open: false }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyDeleted', { Item: t('RiskAssesment') }))
   } catch (error: any) {
      setComplianceState((prev) => ({ ...prev, open: false }))
      handleMessage(dataContext.setRootState, 'error', t('CouldNotDeleteItem', { Item: t('RiskAssesment') }))
   }
}

export const getComplianceRelatedProperties = async (
   accessToken: string,
   dataContext: IDataContext,
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
) => {
   try {
      Promise.all([
         (await api(accessToken).getAllLeadershipPerspectives()).data,
         (await api(accessToken).getAllProcesss()).data,
         (await api(accessToken).getAllSubjects()).data,
      ]).then(([leadershipPerspectives, processes, subjects]) => {
         setComplianceState((prev) => ({
            ...prev,
            leadershipPerspectives,
            processes,
            subjects,
         }))
      })
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), error.message)
   }
}
