import React, { useContext, useState } from 'react'
import { Menu, MenuTrigger, Button, MenuPopover, MenuList, MenuItem, Text } from '@fluentui/react-components'
import { MoreVerticalRegular } from '@fluentui/react-icons'
import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import { IDataContext } from '../../../interfaces/IDataContext'
import { Modal, ModalActions } from '../modal/Modal'

type MoreOptionsMenuButtonProps = {
   item: any
   setState: React.Dispatch<React.SetStateAction<any>>
   deleteFunction: (id: string, dataContext: IDataContext, setState: React.Dispatch<React.SetStateAction<any>>) => void
   deleteMessage: string
   deleteConfirmationMessage: string
}

export const MoreOptionsMenuButton = ({ item, setState, deleteFunction, deleteMessage, deleteConfirmationMessage }: MoreOptionsMenuButtonProps) => {
   const dataContext = useContext(DataContext)
   const [openModal, setOpenModal] = useState(false)

   const handleDelete = (e: any) => {
      e.stopPropagation()
      deleteFunction(item.id, dataContext, setState)
      setOpenModal(false)
   }

   return (
      <>
         <Menu positioning="below-start">
            <MenuTrigger disableButtonEnhancement >
               <Button style={{marginLeft: 'auto'}} appearance="transparent" icon={<MoreVerticalRegular />} onClick={(e: any) => e.stopPropagation()} />
            </MenuTrigger>
            <MenuPopover>
               <MenuList>
                  <MenuItem
                     onClick={(e: any) => {
                        e.stopPropagation()
                        setOpenModal(true)
                     }}
                  >
                     {t('Delete')}
                  </MenuItem>
               </MenuList>
            </MenuPopover>
         </Menu>

         <Modal open={openModal} modalType={'modal'} title={deleteMessage}>
            <Text align="center">{deleteConfirmationMessage}</Text>
            <ModalActions>
               <Button
                  onClick={(e: any) => {
                     e.stopPropagation()
                     setOpenModal(false)
                  }}
               >
                  {t('Cancel')}
               </Button>
               <Button appearance="primary" onClick={handleDelete}>
                  {t('Delete')}
               </Button>
            </ModalActions>
         </Modal>
      </>
   )
}
