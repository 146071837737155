import React, { useContext, useState } from 'react'
import { t } from '../../../i18n/i18n'
import { DataClassification, DemandAnalysis, SubscriptionLevel } from '../../../api/schemas/schema'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { Text } from '../../common/text/Text'
import { Persona, makeStyles, tokens } from '@fluentui/react-components'
import { translateEnumValue } from '../../../helpers/enumHelper'
import { getFullName } from '../../../helpers/accountHelper'
import { DataContext } from '../../../context/DataContext'
import { InformationCard } from '../../common/card/InformationCard'
import { ModalEditDemandInformation } from './ModalEditDemandInformation'
import { IItemState } from '../../../interfaces/IItemState'
import { formatDate } from '../../../helpers/stringHelper'
import { isSubscriptionLevelBaseOrBig } from '../../../helpers/organisationHelper'

type DemandAssesmentInformationProps = {
   analysis: DemandAnalysis
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<DemandAnalysis>>>
}
const useStyles = makeStyles({
   infoWrapper: {
      '& > p': {
         marginTop: '0.5em',
         marginBottom: '0.5em',
      },
      '& > :last-child': {
         marginBottom: '1.5em',
         '& > span:last-child': {
            fontSize: tokens.fontSizeBase400,
         },
      },
   },
   processOwnerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   processOwnerTitle: {
      justifySelf: 'center',
      '@media screen and (max-width: 1007px)': {
         justifySelf: 'start',
      },
   },
   processOwnerTextWrapper: {
      width: '48%',
      '& > p': {
         margin: tokens.spacingHorizontalSNudge,
      },
      '@media screen and (max-width: 1007px)': {
         width: 'auto',
      },
   },
   processRightAlign: {
      justifySelf: 'end',
   },
   editButton: {
      display: 'flex',
      alignItems: 'center',
   },
})

export const DemandAssesmentInformation = ({ analysis, setAnalysisState }: DemandAssesmentInformationProps) => {
   const classes = useStyles()
   const { subscription } = useContext(DataContext).state.organisation
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleEditButtonClick = () => {
      setIsModalOpen(true)
   }

   const closeModal = () => {
      setIsModalOpen(false)
   }

   return (
      <>
         <InformationCard setState={setAnalysisState} handleEditButtonClick={handleEditButtonClick}>
            <Grid>
               <GridItem size="3/12">
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('StartOfAssessment')}</Text>
                     {analysis.analysisStart ? <Text>{formatDate(analysis.analysisStart)}</Text> : <Text italic>{t('NoDateSet')}</Text>}
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('EndOfAssessment')}</Text>
                     {analysis.analysisEnd ? <Text>{formatDate(analysis.analysisEnd)}</Text> : <Text italic>{t('NoDateSet')}</Text>}
                  </section>
                  {isSubscriptionLevelBaseOrBig(subscription) && (
                     <>
                        <section className={classes.infoWrapper}>
                           <Text weight="bold">{t('Owner')}</Text>
                           <Persona textAlignment="center" name={getFullName(analysis.analysisOwner)} primaryText={getFullName(analysis.analysisOwner)} />
                        </section>
                        <section className={classes.infoWrapper}>
                           <Text weight="bold">{t('BranchScope')}</Text>
                           <Text>{analysis.branch.name}</Text>
                        </section>
                     </>
                  )}
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('DataClassification')}</Text>
                     {analysis.dataClassification !== undefined ? (
                        <Text>{translateEnumValue(DataClassification, analysis.dataClassification)}</Text>
                     ) : (
                        <Text italic>{t('NoValueSet')}</Text>
                     )}
                  </section>
                  <section className={classes.infoWrapper}>
                     <Text weight="bold">{t('Comment')}</Text>
                     <Text>{analysis.comment}</Text>
                  </section>
               </GridItem>

               {subscription === SubscriptionLevel.BIG && (
                  <GridItem size="3/12">
                     <section className={classes.infoWrapper}>
                        <Text weight="bold"> {t('ExtendedLevel')}</Text>
                        {analysis.demandComplianceModules && analysis.demandComplianceModules.length > 0 ? (
                           <Text>{t('Included')} </Text>
                        ) : (
                           <Text>{t('NotIncluded')} </Text>
                        )}
                     </section>
                  </GridItem>
               )}

               {isSubscriptionLevelBaseOrBig(subscription) && (
                  <>
                     <GridItem size={subscription === SubscriptionLevel.BIG ? '3/12' : '6/12'}>
                        <section className={classes.infoWrapper}>
                           <Text className={classes.processOwnerTitle} weight="bold">
                              {t('ProcessWithProcessowner')}
                           </Text>
                           {analysis.processOwners.map((p, index) => (
                              <div key={index} className={classes.processOwnerWrapper}>
                                 <div className={classes.processOwnerTextWrapper}>
                                    <Text className={classes.processRightAlign}>{p.process.text}</Text>
                                 </div>
                                 <div className={classes.processOwnerTextWrapper}>
                                    <Text>{p.userAccount ? getFullName(p.userAccount) : p.ownerFreeText}</Text>
                                 </div>
                              </div>
                           ))}
                        </section>
                     </GridItem>
                  </>
               )}
            </Grid>
         </InformationCard>
         <ModalEditDemandInformation analysis={analysis} isOpen={isModalOpen} closeModal={closeModal} setAnalysisState={setAnalysisState} />
      </>
   )
}
