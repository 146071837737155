import React from 'react'
import { Input } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { Control, Controller } from 'react-hook-form'
import './resetInput.css'
import { Field } from './Field'

type ControlledZipInputProps = {
   name: any
   control: Control<any>
   label?: string
   disabled?: boolean
   required?: boolean
}
export const ControlledZipInput = ({ name, control, label, disabled, required }: ControlledZipInputProps) => {
   const formatZipCode = (value: string) => {
      if (value.length <= 3) {
         return value
      }
      return `${value.slice(0, 3)} ${value.slice(3, 5)}`
   }

   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false, pattern: /^[0-9]+$/ }}
         render={({ field: { value, onChange }, fieldState: { error, invalid } }) => {
            return (
               <Field label={label} required={required ?? false} validationMessage={error ? error.message : null} validationState={error ? 'error' : null}>
                  <Input
                     type="text"
                     value={value ? formatZipCode(value.toString()) : ''}
                     onChange={(e, data) => {
                        const rawValue = data.value.replace(/\D/g, '')?.slice(0, 5)
                        onChange(rawValue)
                     }}
                     disabled={disabled ?? false}
                  />
               </Field>
            )
         }}
      />
   )
}
