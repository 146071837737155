import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../../../common/modal/Modal'
import { t } from '../../../../i18n/i18n'
import { RiskInfo } from './RiskInfo'
import { ConsequenceAndProbabilityRisk, ConsequenceScale, ProbabilityScale, RiskAnalysis } from '../../../../api/schemas/schema'
import { Grid } from '../../../common/grid/Grid'
import { GridItem } from '../../../common/grid/GridItem'
import { Button, Divider } from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { ControlledDropDown } from '../../../common/inputs/ControlledDropdown'
import { ControlledTextInput } from '../../../common/inputs/TextInput'
import { enumValuesToOptions } from '../../../../helpers/enumHelper'
import { api } from '../../../../helpers/apiHelper'
import { DataContext } from '../../../../context/DataContext'
import { handleMessage } from '../../../../helpers/stateHelper'
import { IItemState } from '../../../../interfaces/IItemState'

type ModalAnalyseRiskProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   selectedRisk: ConsequenceAndProbabilityRisk
   close: () => void
   isDetermined: boolean
}
export const ModalAnalyseRisk = ({ setRiskAnalysisState, selectedRisk, close, isDetermined }: ModalAnalyseRiskProps) => {
   const {
      control,
      handleSubmit,
      reset,
      formState: { isSubmitting },
   } = useForm<ConsequenceAndProbabilityRisk>({ disabled: isDetermined })
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   useEffect(() => {
      reset(selectedRisk)

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedRisk])

   const onSubmit = async (data: ConsequenceAndProbabilityRisk) => {
      try {
         data.analysedBy = userAccount
         data.analysedById = userAccount.id
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id
         const updatedRisk = (await api(accessToken).updateConsequenceAndProbabilityRisk(data)).data
         setRiskAnalysisState((prev) => ({
            ...prev,
            item: {
               ...prev.item,
               consequenceAndProbabilityRisks: prev.item.consequenceAndProbabilityRisks.map((risk) =>
                  risk.id === updatedRisk.id ? { ...risk, ...updatedRisk } : risk
               ),
            },
         }))
         handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Risk') }))
         close()
      } catch (error) {
         handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Risk') }))
      }
   }
   const consequenceOptions = enumValuesToOptions(ConsequenceScale).sort((a, b) => Number(b.key) - Number(a.key))
   const probabilityOptions = enumValuesToOptions(ProbabilityScale).sort((a, b) => Number(b.key) - Number(a.key))
   return (
      <Modal open={selectedRisk ? true : false} title={t('AnalyseRisk')}>
         {selectedRisk ? (
            <form onSubmit={handleSubmit(onSubmit)}>
               <Grid>
                  <RiskInfo selectedRisk={selectedRisk} />
                  <GridItem size="12/12">
                     <Divider appearance="brand" />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        control={control}
                        label={
                           <>
                              {t('ConsequenceLifeAndHealth')} (K<sub>L</sub>)
                           </>
                        }
                        name={'consequenceLifeHealth'}
                        options={consequenceOptions}
                        required
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        control={control}
                        label={
                           <>
                              {t('ConsequenceEconomy')} (K<sub>E</sub>)
                           </>
                        }
                        name={'consequenceEconomy'}
                        options={consequenceOptions}
                        required
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        control={control}
                        label={
                           <>
                              {t('ConsequenceEnvironment')} (K<sub>M</sub>){' '}
                           </>
                        }
                        name={'consequenceEnviroment'}
                        options={consequenceOptions}
                        required
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        control={control}
                        label={
                           <>
                              {t('ConsequenceBrand')} (K<sub>V</sub>)
                           </>
                        }
                        name={'consequenceBrand'}
                        options={consequenceOptions}
                        required
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown control={control} label={`${t('Probability')} (S)`} name={'probability'} options={probabilityOptions} required />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput control={control} label={t('UnderlyingMotivationAndStand')} name="underlyingAnalysisMotivation" multiline />
                  </GridItem>
                  <GridItem size="12/12">
                     <ModalActions>
                        <Button onClick={close}>{isDetermined ? t('Close') : t('Cancel')}</Button>
                        {!isDetermined && (
                           <Button type="submit" appearance="primary" disabled={isSubmitting}>
                              {t('Save')}
                           </Button>
                        )}
                     </ModalActions>
                  </GridItem>
               </Grid>
            </form>
         ) : null}
      </Modal>
   )
}
