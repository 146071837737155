import React from 'react'
import { t } from 'i18next'
import { makeStyles } from '@fluentui/react-components'
import { Text } from '@fluentui/react-components'
import { RiskAnalysis } from '../../../api/schemas/schema'
import { IListState } from '../../../interfaces/IListState'
import { AnalysEvaluateGraphView } from '../../riskAnalyses/SWOT/AnalysEvaluateGraphView'
import { DashboardInfoCard } from '../DashboardInfoCard'
import { formatDate } from '../../../helpers/stringHelper'
const useStyles = makeStyles({
   title: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   nameDateWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
   },
})

type RiskManagementCardProps = {
   latestSwotAnalysis: RiskAnalysis | undefined
   setRiskManagementState: React.Dispatch<React.SetStateAction<IListState<RiskAnalysis>>>
}
export const RiskManagementCard = ({ latestSwotAnalysis, setRiskManagementState }: RiskManagementCardProps) => {
   const classes = useStyles()
   return (
      <>
         {latestSwotAnalysis ? (
            <>
               <article className={classes.title}>
                  <Text size={600} weight="semibold">
                     {t('RiskManagement')}
                  </Text>
                  <div className={classes.nameDateWrapper}>
                     <Text size={600}>{latestSwotAnalysis.name}</Text>
                     <Text size={400}>{latestSwotAnalysis.startDate ? formatDate(latestSwotAnalysis.startDate) : t('NoValueSet')}</Text>
                  </div>
               </article>
               <AnalysEvaluateGraphView swots={latestSwotAnalysis.swoTs} />
            </>
         ) : (
            <DashboardInfoCard
               title={t('DoARiskAssessment')}
               description={t('DoARiskAssessmentDescription')}
               buttonText={t('CreateRiskAssessment')}
               onClick={() => setRiskManagementState((prev) => ({ ...prev, open: true }))}
            />
         )}
      </>
   )
}
