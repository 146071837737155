import React from 'react'
import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { ChevronLeft24Regular, ChevronRight24Regular } from '@fluentui/react-icons'
import { Cell, Label, LabelList, Pie, PieChart, Tooltip } from 'recharts'
import { months, quarters, stackOverlappingActivities, weeks } from '../../helpers/calendarHelper'
import dayjs from 'dayjs'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import { Activity } from '../../api/schemas/schema'
import { ActivityTooltip } from './ActivityTooltip'
dayjs.extend(isoWeeksInYear)
dayjs.extend(dayOfYear)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isoWeek)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isLeapYear)

type CalendarChartProps = {
   activities: Activity[]
   year: {
      name: number
      value: number
   }
   setYear: React.Dispatch<
      React.SetStateAction<{
         name: number
         value: number
      }>
   >
   onActivitySelect: (activity: Activity) => void
}
const useStyles = makeStyles({
   container: {
      display: 'flex',
      justifyContent: 'center',
   },
   year: {
      fontSize: tokens.fontSizeHero800,
      fontWeight: tokens.fontWeightSemibold,
      fontFamily: tokens.fontFamilyBase,
      color: tokens.colorStrokeFocus2,
   },
   quarters: {
      fontSize: tokens.fontSizeBase600,
      fontFamily: tokens.fontFamilyBase,
      fill: tokens.colorNeutralForeground1,
      stroke: tokens.colorNeutralForeground1,
   },
   months: {
      fontSize: tokens.fontSizeBase500,
      fontFamily: tokens.fontFamilyBase,
      fill: tokens.colorNeutralForegroundOnBrand,
   },
   weeks: {
      fontSize: tokens.fontSizeBase400,
      fontFamily: tokens.fontFamilyBase,
      fill: tokens.colorNeutralForegroundOnBrand,
   },
   nonClickable: {
      outline: 'none',
   },
   activityLabel: {
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightMedium,
      fill: 'black',
      stroke: 'none',
   },
})

export const CalendarChart = ({ activities, year, setYear, onActivitySelect }: CalendarChartProps) => {
   const classes = useStyles()
   const { stackedActivities, calendarLayers } = stackOverlappingActivities(activities, year.name)

   const onActivityClick = (activity: any) => {
      const selectedActivity = activities.find((x) => x.id === activity.name)
      if (selectedActivity) {
         onActivitySelect(selectedActivity)
      }
   }

   return (
      <div className={classes.container}>
         <PieChart width={1000 + calendarLayers * 20} height={700 + calendarLayers * 20}>
            <defs>
               <filter id="shadow">
                  <feDropShadow dx="0" dy="0" stdDeviation="3" floodColor="#000" floodOpacity="0.5" />
               </filter>
            </defs>
            <Tooltip content={<ActivityTooltip activities={activities} />} />
            <Pie data={[year]} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#FFFFFF">
               <Cell key={`cell-${year.value}`} fill="#FFFFFF" />
               <Label
                  content={
                     <g>
                        <foreignObject x="40%" y="48%" width="20" height="20" fill="#000000">
                           <Button
                              size="small"
                              appearance="transparent"
                              icon={<ChevronLeft24Regular />}
                              onClick={() => setYear({ name: year.name - 1, value: 1 })}
                           />
                        </foreignObject>
                        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill="#000000" className={classes.year}>
                           {year.name}
                        </text>
                        <foreignObject x="56%" y="48%" width="20" height="20" fill="#000000">
                           <Button
                              size="small"
                              appearance="transparent"
                              icon={<ChevronRight24Regular />}
                              onClick={() => setYear({ name: year.name + 1, value: 1 })}
                           />
                        </foreignObject>
                     </g>
                  }
               />
            </Pie>
            <Pie
               data={quarters(year.name)}
               dataKey="value"
               nameKey="name"
               cx="50%"
               cy="50%"
               innerRadius={100}
               outerRadius={180}
               startAngle={90}
               endAngle={-270}
               fill="#FFDF9E"
               className={classes.nonClickable}
            >
               <LabelList dataKey="name" position="inside" className={classes.quarters} />
            </Pie>
            <Pie
               data={months(year.name)}
               dataKey="value"
               nameKey="name"
               cx="50%"
               cy="50%"
               innerRadius={184}
               outerRadius={260}
               className={classes.nonClickable}
               startAngle={90}
               endAngle={-270}
               fill={tokens.colorBrandBackground3Static}
            >
               <LabelList dataKey="name" position="inside" className={classes.months} />
            </Pie>
            <Pie
               data={weeks(year.name)}
               dataKey="value"
               nameKey="number"
               cx="50%"
               cy="50%"
               innerRadius={262}
               outerRadius={320}
               startAngle={90}
               endAngle={-270}
               fill={tokens.colorBrandBackground3Static}
               className={classes.nonClickable}
            >
               {weeks(year.name).map((week, index) => {
                  const isEmptyCell = week.number === null
                  return (
                     <Cell
                        key={`cell-${index}`}
                        fill={isEmptyCell ? 'transparent' : tokens.colorBrandBackground3Static}
                        stroke={isEmptyCell ? 'transparent' : tokens.colorNeutralForegroundOnBrand}
                     />
                  )
               })}
               <LabelList dataKey="number" position="inside" className={classes.weeks} />
            </Pie>

            {stackedActivities
               .sort((a, b) => b.heightOffset - a.heightOffset)
               .map((entry, index) => {
                  const baseInnerRadius = 320
                  const baseOuterRadius = 340

                  return (
                     <Pie
                        key={`activity-${index}`}
                        data={[{ name: entry.name, value: 1, title: entry.title }]}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={baseInnerRadius - 1}
                        outerRadius={baseOuterRadius + entry.heightOffset}
                        startAngle={entry.startAngle}
                        endAngle={entry.endAngle}
                        fill={entry.color}
                        stroke={entry.color}
                        activeShape={{ stroke: '#000', strokeWidth: 2 }}
                        style={{ cursor: 'pointer', zIndex: entry.zIndex, position: 'relative', filter: 'url(#shadow)' }}
                        className={classes.nonClickable}
                     >
                        <Cell
                           key={`activity-${index}`}
                           onClick={() => {
                              onActivityClick(entry)
                           }}
                        />
                        <LabelList dataKey='title' position="outside"  className={classes.activityLabel}/>
                     </Pie>
                  )
               })}
         </PieChart>
      </div>
   )
}
