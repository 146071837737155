import React, { useState } from 'react'
import { Button, MessageBar, MessageBarBody, MessageBarTitle, TabList, TableColumnDefinition, makeStyles, mergeClasses } from '@fluentui/react-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Loader } from '../spinner/Loader'
import { CustomList } from '../list/CustomList'
import { Add20Regular, AddCircle24Regular, ChevronLeft20Regular, ChevronRight20Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import { Processfilter } from '../../demandAnalysis/ProcessFilter'
import { SettingsTabs } from '../../../helpers/settingsHelper'
import { ModalProtectedAssetGoal } from '../../settings/ModalProtectedAssetGoal'
import { Branch, Process } from '../../../api/schemas/schema'
import { ISettingsState } from '../../../interfaces/ISettingsState'
import { SettingsPhoneHeader } from '../../settings/SettingsPhoneHeader'
import { NavigationLink } from '../../../interfaces/INavigationLink'

const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   width: {
      width: '100%',
   },
})

type SettingsOverviewProps<T extends {}> = {
   setSettingsState: React.Dispatch<React.SetStateAction<ISettingsState>>
   tabs: JSX.Element | JSX.Element[]
   columns: TableColumnDefinition<T>[]
   items: T[]
   title: string
   loading: boolean
   noItemsText: string
   rowClassName: string
   processes: string[]
   allProcesses: Process[]
   allBranches: Branch[]
   setProcess: (process: string) => void
   phoneBody?: (item: T, index: number, onRowClick: (item: T) => void) => JSX.Element
}

export const SettingsOverview = <T extends {}>({
   setSettingsState,
   tabs,
   items,
   columns,
   title,
   loading,
   noItemsText,
   rowClassName,
   processes,
   setProcess,
   allProcesses,
   allBranches,
   phoneBody,
}: SettingsOverviewProps<T>) => {
   const classes = useStyles()
   const navigate = useNavigate()
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const [selectedItem, setSelectedItem] = useState<any>(null)
   const [openModal, setOpenModal] = useState<boolean>(false)

   const onRowClick = (item: T) => {
      setSelectedItem(item)
      setOpenModal(true)
   }

   const onNavClick = () => {
      selectedTab === SettingsTabs.ProtectedAssets
         ? navigate(NavigationLink.Settings + '?tab=organisationalGoals')
         : navigate(NavigationLink.Settings + '?tab=protectedAssets')
   }

   return (
      <>
         <div className={mergeClasses(classes.spaceBtw, classes.hideInPhone)}>
            <TabList selectedValue={selectedTab} onTabSelect={(e, data) => setSearchParams({ tab: data.value as string })}>
               {tabs}
            </TabList>
         </div>

         {loading ? (
            <Loader />
         ) : (
            <>
               <SettingsPhoneHeader
                  title={title}
                  action={
                     <Button className={classes.width} onClick={() => onRowClick(null)} appearance="primary" icon={<Add20Regular />}>
                        {t('Add')}
                     </Button>
                  }
                  navAction={
                     <Button
                        onClick={() => onNavClick()}
                        appearance="transparent"
                        icon={selectedTab === SettingsTabs.ProtectedAssets ? <ChevronRight20Regular /> : <ChevronLeft20Regular />}
                     />
                  }
                  navActionAlign={selectedTab === SettingsTabs.ProtectedAssets ? 'right' : 'left'}
               />
               {(selectedTab === SettingsTabs.ProtectedAssets || selectedTab === SettingsTabs.OrganisationalGoals) && (
                  <Processfilter
                     setProcess={setProcess}
                     processes={processes}
                     title={title}
                     action={
                        <Button onClick={() => onRowClick(null)} appearance="primary" disabled={false} icon={<AddCircle24Regular />}>
                           {t('Add')}
                        </Button>
                     }
                  />
               )}

               {selectedTab !== SettingsTabs.ProtectedAssets && selectedTab !== SettingsTabs.OrganisationalGoals ? (
                  <>Under utveckling...</>
               ) : (
                  <>
                     {items.length > 0 ? (
                        <>
                           <CustomList className={classes.hideInPhone} columns={columns} items={items} onRowClick={onRowClick} rowClassName={rowClassName} />
                           {items.map((item, index) => phoneBody(item, index, onRowClick))}
                        </>
                     ) : (
                        <MessageBar>
                           <MessageBarBody>
                              <MessageBarTitle>{noItemsText}</MessageBarTitle>
                           </MessageBarBody>
                        </MessageBar>
                     )}
                  </>
               )}
            </>
         )}
         <ModalProtectedAssetGoal
            selectedTab={selectedTab}
            setSettingsState={setSettingsState}
            selectedItem={selectedItem}
            open={openModal}
            setOpen={setOpenModal}
            allProcesses={allProcesses}
            allBranches={allBranches}
         />
      </>
   )
}
