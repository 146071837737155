import React, { useState } from 'react'
import { ConsequenceAndProbabilityRisk, RiskAnalysis, RiskAnalysisStatus } from '../../../api/schemas/schema'
import { t } from 'i18next'
import { CardHeader } from '../../common/header/CardHeader'
import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { ClipboardTextLtr20Regular } from '@fluentui/react-icons'
import { List } from '../../common/list/List'
import { getRiskAnalysisColumns } from '../../../helpers/riskAnalysisHelper'
import { AnalyseRiskPhoneItem } from './AnalyseRiskPhoneItem'
import { ModalRiskCriteria } from './modals/ModalRiskCriteria'
import { ModalAnalyseRisk } from './modals/ModalAnalyseRisk'
import { IItemState } from '../../../interfaces/IItemState'

type RiskAnalyseProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
}
const useStyles = makeStyles({
   btn: {
      '@media screen and (max-width: 1007px)': {
         width: `calc(50% - (${tokens.spacingHorizontalM} / 2))`,
         outline: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
         float: 'right',
      },
   },
})
export const RiskAnalyse = ({ setRiskAnalysisState, analysis }: RiskAnalyseProps) => {
   const classes = useStyles()
   const [selectedRisk, setSelectedRisk] = useState<ConsequenceAndProbabilityRisk>(null)
   const [riskCriteriaOpen, setRiskCriteriaOpen] = useState<boolean>(false)

   return (
      <>
         <CardHeader
            title={t('RiskAnalysis')}
            action={
               <Button className={classes.btn} onClick={() => setRiskCriteriaOpen(true)} appearance={'transparent'} icon={<ClipboardTextLtr20Regular />}>
                  {t('RiskCriteria')}
               </Button>
            }
         />
         <List
            columns={getRiskAnalysisColumns()}
            columnSizingOptions={{
               riskScenario: { minWidth: 400, idealWidth: 600 },
               consequense: { minWidth: 400, idealWidth: 2000 },
               consequenseScaleLifeHealth: { idealWidth: 70 },
               consequenseScaleEconomy: { idealWidth: 70 },
               consequenseScaleEnvironment: { idealWidth: 70 },
               consequenseScaleBrand: { minWidth: 100, idealWidth: 100 },
               probability: { idealWidth: 70 },
            }}
            resizableColumns
            items={analysis.consequenceAndProbabilityRisks}
            onRowClick={(item) => setSelectedRisk(item)}
            renderPhoneBodyItem={(item, i) => <AnalyseRiskPhoneItem item={item} />}
         />
         <ModalRiskCriteria open={riskCriteriaOpen} close={() => setRiskCriteriaOpen(false)} />
         <ModalAnalyseRisk
            setRiskAnalysisState={setRiskAnalysisState}
            selectedRisk={selectedRisk}
            close={() => setSelectedRisk(null)}
            isDetermined={(selectedRisk && selectedRisk.determined) || analysis.status !== RiskAnalysisStatus.Ongoing}
         />
      </>
   )
}
