import React from 'react'
import { InformationPopover } from './InformationPopover'
import { Vault20Filled, Vault20Regular } from '@fluentui/react-icons'
import { ProtectedAssetGoal, ProtectedAssetGoalType } from '../../../api/schemas/schema'
import { t } from '../../../i18n/i18n'
import TextOutput from '../inputs/TextOutput'
import { formatDate, formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { makeStyles, tokens } from '@fluentui/react-components'
const useStyles = makeStyles({
   bodyWrapper: {
      marginTop: tokens.spacingVerticalXL,
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXL,
   },
})

type ProtectedAssetGoalPopoverProps = {
   protectedAssetGoal: ProtectedAssetGoal
}

export const ProtectedAssetGoalPopover = ({ protectedAssetGoal }: ProtectedAssetGoalPopoverProps) => {
   const classes = useStyles()

   const body = () => {
      return (
         <div className={classes.bodyWrapper}>
            <TextOutput label={t('Name')} text={protectedAssetGoal.name} />
            <TextOutput label={t('Description')} text={protectedAssetGoal.description} />
            <TextOutput label={t('Valuation')} text={`${formatToSwedishCrowns(protectedAssetGoal.valuation)} kr`} />
            <TextOutput label={t('ValuationDate')} text={formatDate(protectedAssetGoal.valuationDate)} />
            <TextOutput label={t('Process')} text={protectedAssetGoal.process} />
            <TextOutput label={t('Comment')} text={protectedAssetGoal?.comment} />
         </div>
      )
   }

   return (
      <InformationPopover
         btnLabel={protectedAssetGoal?.type === ProtectedAssetGoalType.ProtectedAsset ? t('ProtectedAsset') : t('OrganisationalGoal')}
         popoverLabel={protectedAssetGoal?.type === ProtectedAssetGoalType.ProtectedAsset ? t('ProtectedAsset') : t('OrganisationalGoal')}
         input={body()}
         regularIcon={<Vault20Regular />}
         filledIcon={<Vault20Filled />}
      />
   )
}
