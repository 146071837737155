import React from 'react'
import { MeterChart } from './MeterChart'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
const useStyles = makeStyles({
   meterWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: tokens.spacingHorizontalXXS,
      '@media screen and (max-width: 1007px)': {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'flex-start',
         borderRadius: tokens.borderRadiusXLarge,
         width: '100%',
         minHeight: '52px',
      },
   },
   heading: {
      marginTop: 0,
      marginBottom: tokens.spacingHorizontalXXS,
      color: tokens.colorNeutralForegroundInverted,
   },
   percentage: {
      color: '#FFDF9E',
   },
   differencePositive: {
      color: tokens.colorStatusSuccessBorder1,
   },
   differenceNegative: {
      color: tokens.colorStatusDangerBorder1,
   },
   textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': { display: 'none !important' },
   },
})

type ComplianceMeterProps = {
   title: string
   percentage: number
   difference: number
}
export const ComplianceMeter = ({ title, percentage, difference }: ComplianceMeterProps) => {
   const classes = useStyles()

   const isPositive = (value: number) => {
      return value > 0
   }
   return (
      <div className={classes.meterWrapper}>
         <Text as="h3" weight="semibold" size={600} className={classes.heading}>
            {title}
         </Text>
         <MeterChart needleValue={percentage} className={classes.hideInPhone} />
         <div className={classes.textWrapper}>
            <Text weight="semibold" size={600} className={classes.percentage}>
               {`${percentage}%`}
            </Text>
            {difference !== null && difference !== undefined && difference !== 0 && (
               <Text weight="semibold" size={400} className={isPositive(difference) ? classes.differencePositive : classes.differenceNegative}>{`${
                  isPositive(difference) ? '+' : ''
               }${difference}%`}</Text>
            )}
         </div>
      </div>
   )
}
