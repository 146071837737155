import React from 'react'
import { makeStyles, tokens, Button, Text } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { Pen24Regular } from '@fluentui/react-icons'
import { IItemState } from '../../../interfaces/IItemState'
import { CardHeader } from '../header/CardHeader'

type InformationProps<T> = {
   setState: React.Dispatch<React.SetStateAction<IItemState<T>>>
   children: JSX.Element
   handleEditButtonClick: () => void
   disabled?: boolean
}

const useStyles = makeStyles({
   infoWrapper: {
      '& > p': {
         marginTop: '0.5em',
         marginBottom: '0.5em',
      },
      '& > :last-child': {
         marginBottom: '1.5em',
         '& > span:last-child': {
            fontSize: tokens.fontSizeBase400,
         },
      },
   },
   processOwnerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   processOwnerTitle: {
      justifySelf: 'center',
      '@media screen and (max-width: 1007px)': {
         justifySelf: 'start',
      },
   },
   processOwnerTextWrapper: {
      width: '48%',
      '& > p': {
         margin: tokens.spacingHorizontalSNudge,
      },
      '@media screen and (max-width: 1007px)': {
         width: 'auto',
      },
   },
   processRightAlign: {
      justifySelf: 'end',
   },
   transparentInPhone: {
      padding: `${(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL)}`,
      boxShadow: tokens.shadow8,
      backgroundColor: '#FFFDF5',
      '@media screen and (max-width: 1007px)': {
         boxShadow: 'none',
         padding: 0,
         backgroundColor: 'transparent',
      },
   },
   showOnlyLarge: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   editButton: {
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 1007px)': {
         justifyContent: 'end',
      },
   },
})

export const InformationCard = <T,>({ children, handleEditButtonClick, disabled }: InformationProps<T>) => {
   const classes = useStyles()

   return (
      <>
         <CardHeader
            title={t('Information')}
            secondaryAction
            action={
               <Button className={classes.editButton} appearance="transparent" onClick={handleEditButtonClick} disabled={disabled}>
                  <Pen24Regular />
                  <Text className={classes.noneInPhone} weight="semibold">
                     {t('Edit')}
                  </Text>
               </Button>
            }
         >
            <section className={classes.infoWrapper}>{children}</section>
         </CardHeader>
      </>
   )
}
