import React from 'react'
import { Badge } from '../../common/badge/Badge'
import { makeStyles, tokens, Text } from '@fluentui/react-components'
import { translateEnumValue } from '../../../helpers/enumHelper'
import { ConsequenceScale, ProbabilityScale } from '../../../api/schemas/schema'
import { t } from 'i18next'
const useStyles = makeStyles({
   badge: {
      width: '32px',
   },
   badgeLabel: {
      minWidth: '130px',
      height: '32px',
      fontSize: tokens.fontSizeBase300,
      flex: 'none',
   },
})
type BadgeConsequenceProbabilityProps = {
   valuation?: 1 | 2 | 3 | 4 | 5
}
const getBgColor = (valuation?: 1 | 2 | 3 | 4 | 5): string => {
   switch (valuation) {
      case null:
      case undefined:
         return tokens.colorBrandBackground2
      case 5:
         return tokens.colorStatusDangerBackground3
      case 4:
         return tokens.colorStatusWarningBackground3
      case 3:
         return tokens.colorPaletteMarigoldBackground3
      case 2:
         return tokens.colorPaletteLightGreenBackground3
      case 1:
         return tokens.colorStatusSuccessBackground3

      default:
         break
   }
}
export const BadgeConsequenceProbability = ({ valuation }: BadgeConsequenceProbabilityProps) => {
   const classes = useStyles()
   return <Badge className={classes.badge} shape="rounded" appearance="filled" style={{ backgroundColor: getBgColor(valuation) }} />
}

type BadgeConsequenceProbabilityWithLabelProps = {
   valuation?: 1 | 2 | 3 | 4 | 5
   type: 'consequence' | 'probability'
}

export const BadgeConsequenceProbabilityWithLabel = ({ valuation, type }: BadgeConsequenceProbabilityWithLabelProps) => {
   const classes = useStyles()

   const isConsequence = type === 'consequence'
   if (valuation === null || valuation === undefined) {
      return <Text italic>{t('NoValueSet')}</Text>
   }
   return (
      <Badge
         appearance="filled"
         shape="rounded"
         className={classes.badgeLabel}
         style={{ backgroundColor: getBgColor(valuation), width: isConsequence ? '180px' : '' }}
      >
         {translateEnumValue(isConsequence ? ConsequenceScale : ProbabilityScale, valuation)}
      </Badge>
   )
}
