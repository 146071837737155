import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../../../common/modal/Modal'
import { t } from '../../../../i18n/i18n'
import { RiskAnalysis, Threat } from '../../../../api/schemas/schema'
import { ControlledTextInput } from '../../../common/inputs/TextInput'
import { useForm } from 'react-hook-form'
import { Button, Divider, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { addThreat, updateThreat } from '../../../../helpers/riskAnalysisHelper'
import { DataContext } from '../../../../context/DataContext'
import { IItemState } from '../../../../interfaces/IItemState'
const useStyles = makeStyles({
   form: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalXL),
   },
   divider: {
      marginTop: tokens.spacingVerticalS,
      marginBottom: tokens.spacingVerticalM,
   },
})

type ModalNewThreatProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   open: boolean
   selectedItem: Threat
   disabled?: boolean
}

export const ModalThreat = ({ setRiskAnalysisState, analysis, open, setOpen, selectedItem, disabled }: ModalNewThreatProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const {
      control,
      handleSubmit,
      reset,
      formState: { isSubmitting },
   } = useForm<Threat>()

   const onSubmit = (data: Threat) => {
      if (data.id) {
         updateThreat(setRiskAnalysisState, close, dataContext, data)
         reset(data)
      } else {
         addThreat(setRiskAnalysisState, close, dataContext, data, analysis)
      }
   }
   const close = () => {
      reset()
      setOpen(false)
   }

   useEffect(() => {
      if (selectedItem) {
         reset(selectedItem)
      } else {
         reset({
            name: null,
            description: null,
            underlyingMotivation: null,
         })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedItem])

   return (
      <Modal title={selectedItem ? t('EditThreat') : t('NewThreat')} open={open} modalType="modal">
         <Divider appearance="brand" className={classes.divider} />
         <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextInput control={control} name={'name'} label={t('SpecifyCurrentThreat')} required disabled={disabled} />
            <ControlledTextInput control={control} name={'description'} label={t('Description')} multiline required disabled={disabled} />
            <ControlledTextInput control={control} name={'underlyingMotivation'} label={t('UnderlyingMotivationAndStand')} multiline disabled={disabled} />
            <ModalActions>
               <Button onClick={close}>{disabled ? t('Close') : t('Cancel')}</Button>
               {!disabled && (
                  <Button appearance="primary" type="submit" disabled={isSubmitting}>
                     {t('Save')}
                  </Button>
               )}
            </ModalActions>
         </form>
      </Modal>
   )
}
