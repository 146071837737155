import React, { useContext, useEffect, useState } from 'react'
import { t } from '../../i18n/i18n'
import { ActionPlan, ActionPlanStatus, Cost, FinancialYear, FinancialYearStatus } from '../../api/schemas/schema'
import { CardHeader } from '../common/header/CardHeader'
import { SummaryRow } from '../common/list/SummaryRow'
import { List } from '../common/list/List'
import { getCostActionPlanColumns, getFinancialYearActionPlanDefaultValues, getFinancialYearActionPlanSummaryFields } from '../../helpers/financialYearHelper'
import { BadgeActionPlanStatus } from '../demandAnalysis/BadgeActionPlanStatus'
import { BadgeCostCategory } from './BadgeCostCategory'
import { Text } from '../common/text/Text'
import { formatToSwedishCrowns } from '../../helpers/stringHelper'
import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { IItemState } from '../../interfaces/IItemState'
import { ParentCostInformation } from './ParentCostInformation'
import { ModalActionPlan } from '../demandAnalysis/actionPlan/ModalActionPlan'
import { createOrUpdateFinancialYearActionPlan } from '../../helpers/riskEconomyHelper'
import { DataContext } from '../../context/DataContext'
import { ArrowExport20Regular } from '@fluentui/react-icons'
import { exportFinancialYearActionPlans } from '../../helpers/excelHelper'

const useStyles = makeStyles({
   phoneItemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   exportBtn: {
      float: 'right',
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
})

type FinancialYearActionPlanProps = {
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
   financialYear: FinancialYear
}
export const FinancialYearActionPlan = ({ setFinancialYearState, financialYear }: FinancialYearActionPlanProps) => {
   const { accessToken, userAccount } = useContext(DataContext).state
   const { actionPlans, costs } = financialYear
   const classes = useStyles()
   const filteredCosts = costs.filter((c) => c.actionRequired)
   const [selectedCost, setSelectedCost] = useState<Cost>()
   const [selectedItem, setSelectedItem] = useState<ActionPlan>()

   const onRowClick = (cost: Cost) => {
      setSelectedCost(cost)
      const connectedActionPlan = actionPlans.find((a) => a.costId === cost.id)
      if (connectedActionPlan) {
         setSelectedItem(connectedActionPlan)
      } else setSelectedItem(getFinancialYearActionPlanDefaultValues(cost.id))
   }

   const saveActionPlan = async (data: ActionPlan) => {
      data.branchId = selectedCost.branch.id

      if (!data.id) {
         data.createdBy = userAccount
         data.createdById = userAccount.id
      }

      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      return await createOrUpdateFinancialYearActionPlan(data, accessToken, financialYear, setFinancialYearState)
   }

   useEffect(() => {
      if (selectedCost) {
         setSelectedCost(financialYear.costs.find((c) => c.id === selectedCost.id))
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [financialYear])

   return (
      <>
         <CardHeader title={t('ActionPlan')}>
            {filteredCosts.length > 0 ? (
               <Button
                  appearance="outline"
                  icon={<ArrowExport20Regular />}
                  className={classes.exportBtn}
                  onClick={() => exportFinancialYearActionPlans(filteredCosts, actionPlans, financialYear.name)}
               >
                  {t('ExportToExcel')}
               </Button>
            ) : null}
         </CardHeader>
         <SummaryRow summaryFields={getFinancialYearActionPlanSummaryFields(filteredCosts, actionPlans)} />
         <List
            resizableColumns
            columnSizingOptions={{
               proposedAction: { minWidth: 400, idealWidth: 2000 },
               status: { minWidth: 150, defaultWidth: 150 },
               category: { minWidth: 220, idealWidth: 300 },
               estimatedCost: { minWidth: 300, idealWidth: 300 },
            }}
            onRowClick={onRowClick}
            items={filteredCosts}
            columns={getCostActionPlanColumns(actionPlans)}
            renderPhoneBodyItem={(item) => {
               const actionPlan: ActionPlan = actionPlans.find((a) => a.costId === item.id)

               return (
                  <div className={classes.phoneItemWrapper}>
                     <BadgeCostCategory category={item.post.costCategory} />
                     <div className={classes.spaceBtw}>
                        <Text noMargin>{item.estimatedCost || item.estimatedCost === 0 ? formatToSwedishCrowns(item.estimatedCost) + ' kr' : '-'}</Text>
                        <BadgeActionPlanStatus status={actionPlan ? actionPlan.status : ActionPlanStatus.NotDecided} />
                     </div>
                     <Text noMargin ellipsis style={{ WebkitLineClamp: 3 }}>
                        {item.proposedAction}
                     </Text>
                  </div>
               )
            }}
         />
         <ModalActionPlan
            parentInformation={selectedCost && <ParentCostInformation cost={selectedCost} />}
            selectedItem={selectedItem}
            close={() => {
               setSelectedCost(null)
               setSelectedItem(null)
            }}
            onSubmit={saveActionPlan}
            disabled={financialYear.status === FinancialYearStatus.Completed}
         />
      </>
   )
}
