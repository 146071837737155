import React, { useState } from 'react'
import { Button, makeStyles, MessageBar, MessageBarBody, MessageBarTitle, shorthands, tokens } from '@fluentui/react-components'
import { Add20Regular, AddCircle24Regular } from '@fluentui/react-icons'
import { t } from '../../../i18n/i18n'
import { RiskAnalysis, RiskAnalysisStatus, SWOT } from '../../../api/schemas/schema'
import { List } from '../../common/list/List'
import { getFilteredSWOTs, getSWOTIdentifyColumns } from '../../../helpers/swotHelper'
import { useSearchParams } from 'react-router-dom'
import { TypeFilter } from './TypeFilter'
import { ModalSWOT } from './ModalSWOT'
import { PhoneHeader } from '../PhoneHeader'
import { Text } from '../../common/text/Text'
import { SwotTypeBadge } from '../RiskBadges'
import { IItemState } from '../../../interfaces/IItemState'
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
      },
   },
   text: {
      ...shorthands.margin(tokens.spacingHorizontalS, 0),
   },
   spaceBtw: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
   },
   btnFlex: {
      display: 'flex',
      gap: tokens.spacingHorizontalS,
   },
   phoneItemHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 1,
      '@media screen and (max-width: 640px)': {
         WebkitLineClamp: 2,
      },
   },
})
type IdentifyingProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
}
export const Identifying = ({ setRiskAnalysisState, analysis }: IdentifyingProps) => {
   const [modalOpen, setModalOpen] = useState<boolean>(false)
   const classes = useStyles()
   const [searchParams] = useSearchParams()
   const selectedType = searchParams.get('type')
   const [selectedItem, setSelectedItem] = useState<SWOT>(null)
   const filteredSWOTs = getFilteredSWOTs(analysis.swoTs, selectedType).sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())

   const onRowClick = (item: SWOT) => {
      setSelectedItem(item)
      setModalOpen(true)
   }
   const title = t('Identify')
   return (
      <>
         <PhoneHeader
            title={title}
            action={
               <Button style={{ width: '100%' }} onClick={() => setModalOpen(true)} appearance="primary" icon={<Add20Regular />}>
                  {t('Add')}
               </Button>
            }
         />
         <TypeFilter
            title={title}
            action={
               <Button
                  onClick={() => {
                     setModalOpen(true)
                  }}
                  appearance="primary"
                  disabled={analysis.status === RiskAnalysisStatus.Determined}
                  icon={<AddCircle24Regular />}
               >
                  {t('Add')}
               </Button>
            }
         />
         <ModalSWOT
            selectedItem={selectedItem}
            analysis={analysis}
            open={modalOpen}
            close={() => {
               setModalOpen(false)
               setSelectedItem(null)
            }}
            setRiskAnalysisState={setRiskAnalysisState}
         />
         {analysis.swoTs.length === 0 ? (
            <MessageBar>
               <MessageBarBody>
                  <MessageBarTitle>{t('TimeToAddYourFirstSWOT')}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         ) : (
            <>
               <List
                  columnSizingOptions={{ title: { idealWidth: 400 } }}
                  resizableColumns
                  columns={getSWOTIdentifyColumns(classes.ellipsis)}
                  items={filteredSWOTs}
                  onRowClick={onRowClick}
                  renderPhoneBodyItem={(item: SWOT) => (
                     <>
                        <span className={classes.phoneItemHeader}>
                           <Text weight="bold">{item.title}</Text>
                           <SwotTypeBadge type={item.type} />
                        </span>
                        <Text className={classes.ellipsis}>{item.description}</Text>
                     </>
                  )}
               />
            </>
         )}
      </>
   )
}
