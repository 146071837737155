import React, { useContext, useEffect, useState } from 'react'
import { Layout } from './Layout'
import { t } from '../i18n/i18n'
import { Overview } from '../components/common/overview/Overview'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RiskManagementTabs, deleteRiskAnalysis, getRiskManagementColumns } from '../helpers/riskManagementHelper'
import { ShieldAdd24Regular } from '@fluentui/react-icons'
import { Button, Tab, makeStyles } from '@fluentui/react-components'
import { RiskAnalysis, RiskAnalysisStatus } from '../api/schemas/schema'
import { api } from '../helpers/apiHelper'
import { IListState } from '../interfaces/IListState'
import { Loader } from '../components/common/spinner/Loader'
import { Navigation } from '../components/common/navigation/Navigation'
import { DataContext } from '../context/DataContext'
import { ModalRiskAssesment } from '../components/riskAnalyses/ModalRiskAssesment'
import { MoreOptionsMenuButton } from '../components/common/other/MoreOptionsMenuButton'
const useStyles = makeStyles({
   addBtn: {
      '@media screen and (max-width: 640px)': {
         display: 'inline-flex',
      },
   },
   hiddenInPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'inline',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '50%',
         },
         ':nth-child(2)': {
            width: '20%',
            minWidth: '150px',
         },
         ':nth-child(3)': {
            width: '20%',
            minWidth: '200px',
         },
         ':nth-child(4)': {
            width: '10%',
            minWidth: '150px',
         },
      },
   },
})
export const RiskManagement = () => {
   const dataContext = useContext(DataContext)
   const { accessToken, organisation } = dataContext.state
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const classes = useStyles()
   const navigate = useNavigate()
   const [riskManagementState, setRiskManagementState] = useState<IListState<RiskAnalysis>>({
      loading: true,
      filteredItems: [],
      allItems: [],
      open: false,
   })
   const { loading, filteredItems, allItems } = riskManagementState

   useEffect(() => {
      if (!selectedTab) {
         setSearchParams({ tab: RiskManagementTabs.Ongoing })
      }
      ;(async () => {
         const allRiskAnalyses = (await api(accessToken).getRiskAnalysesByOrganisationId(organisation.id)).data
         setRiskManagementState((prev) => ({ ...prev, loading: false, allItems: allRiskAnalyses }))
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams, dataContext.state.userAccount])

   useEffect(() => {
      if (selectedTab === RiskManagementTabs.Ongoing)
         setRiskManagementState((prev) => ({ ...prev, filteredItems: prev.allItems.filter((x) => x.status !== RiskAnalysisStatus.Completed) }))
      if (selectedTab === RiskManagementTabs.Concluded)
         setRiskManagementState((prev) => ({ ...prev, filteredItems: prev.allItems.filter((x) => x.status === RiskAnalysisStatus.Completed) }))
   }, [selectedTab, allItems.length])

   return (
      <Layout breadCrumbItems={[{ title: t('RiskManagement'), active: true, path: '/risk-management' }]}>
         <Navigation activeDesktopTab="/risk-management" />
         {loading ? (
            <Loader />
         ) : (
            <Overview
               tabs={
                  <>
                     <Tab value={RiskManagementTabs.Ongoing}>{t('Ongoing')}</Tab>
                     <Tab value={RiskManagementTabs.Concluded}>{t('Concluded')}</Tab>
                  </>
               }
               button={
                  <Button
                     className={classes.addBtn}
                     icon={<ShieldAdd24Regular />}
                     appearance="primary"
                     onClick={() => setRiskManagementState((prev) => ({ ...prev, open: true }))}
                  >
                     {t('NewRiskAssesment')}
                  </Button>
               }
               loading={loading}
               columns={getRiskManagementColumns(setRiskManagementState)}
               items={filteredItems}
               onRowClick={(item) => navigate(`/risk-management/${item.id}`)}
               keyValue="name"
               noItemsText={selectedTab === RiskManagementTabs.Ongoing ? t('TimeToCreateYourFirstRiskAssesment') : t('NoConcludedRiskAssesments')}
               title={selectedTab === RiskManagementTabs.Ongoing ? t('OngoingRiskAssesments') : t('ConcludedRiskAssesments')}
               rowClassName={classes.row}
               renderMenuButton={(item) => (
                  <MoreOptionsMenuButton
                     item={item}
                     setState={setRiskManagementState}
                     deleteMessage={t('DeleteRiskAssessment')}
                     deleteConfirmationMessage={t('DeleteRiskAssessmentConfirmationMessage')}
                     deleteFunction={deleteRiskAnalysis}
                  />
               )}
            />
         )}
         <ModalRiskAssesment setRiskManagementState={setRiskManagementState} riskManagementState={riskManagementState} />
      </Layout>
   )
}
