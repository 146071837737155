import React, { useContext } from 'react'
import { DataContext } from '../../context/DataContext'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalActions } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { Button } from '@fluentui/react-components'
import { Text } from '../common/text/Text'
import { FinancialYear, FinancialYearStatus } from '../../api/schemas/schema'
import { api } from '../../helpers/apiHelper'
import { handleMessage } from '../../helpers/stateHelper'
import { IItemState } from '../../interfaces/IItemState'
import { NavigationLink } from '../../interfaces/INavigationLink'

type ModalCompleteFinancialYearProps = {
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
   financialYearState: IItemState<FinancialYear>
   close: () => void
   open: boolean
}
export const ModalCompleteFinancialYear = ({ setFinancialYearState, financialYearState, close, open }: ModalCompleteFinancialYearProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const navigate = useNavigate()
   const { item } = financialYearState
   const onComplete = async () => {
      try {
         setFinancialYearState((prev) => ({ ...prev, loading: true }))
         item.status = FinancialYearStatus.Completed
         item.modifiedBy = userAccount
         item.modifiedById = userAccount.id
         const updatedFinancialyear = (await api(accessToken).updateFinancialYear(item)).data
         setFinancialYearState((prev) => ({ ...prev, loading: false, item: updatedFinancialyear }))
         handleMessage(dataContext.setRootState, 'success', t('FinancialYearCompleted'))
         close()
         navigate(NavigationLink.RiskEconomy + '?tab=Concluded')
      } catch (error: any) {
         setFinancialYearState((prev) => ({ ...prev, loading: false, error: error.error }))
         handleMessage(dataContext.setRootState, 'error', t('CouldNotCompleteFinancialYear'))
         close()
      }
   }
   return (
      <Modal open={open} modalType={'modal'} title={t('CompleteFinancialYear')}>
         <Text align="center">{t('CompleteFinancialYearConfirmationMessage')}</Text>
         <ModalActions>
            <Button onClick={close}>{t('Cancel')}</Button>
            <Button appearance="primary" onClick={onComplete}>
               {t('CompleteFinancialYear')}
            </Button>
         </ModalActions>
      </Modal>
   )
}
