import React from 'react'
import { Avatar, makeStyles, tokens } from '@fluentui/react-components'
import { Text } from '../text/Text'
import { getFullName } from '../../../helpers/accountHelper'
import { UserAccount } from '../../../api/schemas/schema'
const useStyles = makeStyles({
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
   avatarSpacer: {
      display: 'flex',
      alignItems: 'center',
      gap: tokens.spacingHorizontalS,
   },
})

type AvatarAndNameOutputProps = {
   label?: string
   userAccount: UserAccount
}
export const AvatarAndNameOutput = ({ label, userAccount }: AvatarAndNameOutputProps) => {
   const classes = useStyles()
   return (
      <div>
         <Text style={{ margin: 0 }} weight="semibold">
            {label}
         </Text>
         <div className={classes.avatarSpacer}>
            <Avatar name={getFullName(userAccount)} className={classes.marginTopXS} />
            <Text size={400}>
               {getFullName(userAccount)}
            </Text>
         </div>
      </div>
   )
}
