import React from 'react'
import { Button, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { GridItem } from '../common/grid/GridItem'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ModalActions } from '../common/modal/Modal'
import { UseFormReturn } from 'react-hook-form'
import { Activity } from '../../api/schemas/schema'
import { t } from '../../i18n/i18n'
type ActivityFormProps = {
   activityForm: UseFormReturn<Activity, any, undefined>
   modalState: {
      isOpen: boolean
      selectedActivity?: Activity
   }

   setDeleteActivity: React.Dispatch<React.SetStateAction<boolean>>
   onClose: () => void
}
const useStyles = makeStyles({
   marginLeft: {
      '@media screen and (min-width: 641px)': {
         marginLeft: tokens.spacingHorizontalL,
      },
   },
   calendarWheelView: {
      '@media screen and (min-width: 641px)': {
         display: 'flex',
         flexDirection: 'row-reverse',
      },
   },
   phoneBtn: {
      '@media screen and (max-width: 640px)': {
         width: '100%',
         display: 'flex',
         marginBottom: '5px',
      },
   },
})
export const ActivityForm = ({ activityForm, modalState, setDeleteActivity, onClose }: ActivityFormProps) => {
   const classes = useStyles()
   const selectedActivity = modalState?.selectedActivity
   const { control, getValues, formState } = activityForm

   return (
      <>
         <GridItem size="12/12">
            <ControlledTextInput control={control} label={t('Title')} name={'title'} required />
         </GridItem>
         <GridItem size="12/12">
            <ControlledTextInput control={control} label={t('Description')} name={'description'} multiline />
         </GridItem>
         <GridItem size="6/12">
            <ControlledDatePicker required control={control} name={'startDate'} label={t('StartDate')} />
         </GridItem>
         <GridItem size="6/12">
            <ControlledDatePicker control={control} name={'endDate'} label={t('EndDate')} getValues={getValues} compareWith={'startDate'} oneDayActivity />
         </GridItem>
         <GridItem size="12/12">
            <ModalActions>
               <div className={classes.calendarWheelView}>
                  {selectedActivity && (
                     <Button
                        className={mergeClasses(classes.phoneBtn, classes.marginLeft)}
                        onClick={() => {
                           setDeleteActivity(true)
                        }}
                        disabled={formState.isSubmitting}
                     >
                        {t('Delete')}
                     </Button>
                  )}
                  <Button
                     className={classes.phoneBtn}
                     onClick={() => {
                        onClose()
                     }}
                     disabled={formState.isSubmitting}
                  >
                     {t('Cancel')}
                  </Button>
               </div>
               <Button type="submit" appearance="primary" disabled={formState.isSubmitting}>
                  {t(selectedActivity ? 'Update' : 'Save')}
               </Button>
            </ModalActions>
         </GridItem>
      </>
   )
}
