import React from 'react'
import { Outlet } from 'react-router-dom'
import { HotjarProvider } from './HotjarProvider'

export const RootLayout = () => {
   return (
      <>
         <HotjarProvider />
         <Outlet />
      </>
   )
}
