import React from 'react'
import { NavigateFunction } from 'react-router-dom'
import { ActionPlan, ActionPlanStatus, Cost, CostCategory, FinancialYear } from '../api/schemas/schema'
import { CostTypeFilter } from '../components/riskEconomy/CostFilters'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { isValidEnumValue, translateEnumValue } from './enumHelper'
import { handleMessage } from './stateHelper'
import { IItemState } from '../interfaces/IItemState'
import { t } from '../i18n/i18n'
import { TableColumnDefinition } from '@fluentui/react-components'
import { BadgeCostCategory } from '../components/riskEconomy/BadgeCostCategory'
import { formatToSwedishCrowns } from './stringHelper'
import { BadgeActionPlanStatus } from '../components/demandAnalysis/BadgeActionPlanStatus'
import { ISummaryField } from '../interfaces/ISummaryField'
import { NavigationLink } from '../interfaces/INavigationLink'

export const fetchFinancialYear = async (
   dataContext: IDataContext,
   financialYearId: string,
   navigate: NavigateFunction,
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
) => {
   try {
      const { accessToken, organisation } = dataContext.state
      const financialYear = (await api(accessToken).getFinancialYearById(financialYearId, organisation.id)).data

      setFinancialYearState((prev) => ({
         ...prev,
         item: financialYear,
         loading: false,
      }))
   } catch (error: any) {
      setFinancialYearState((prev) => ({ ...prev, loading: false, error: { message: error.error.message, name: error.status } }))
      navigate(NavigationLink.RiskEconomy)
      handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), t('CouldNotFetchItem', { Item: t('FinancialYear').toLowerCase() }))
   }
}
export const filterCosts = (costs: Cost[], selectedCategory: string, selectedCostType: string): Cost[] => {
   let items = costs
   if (selectedCategory && isValidEnumValue(CostCategory, selectedCategory))
      items = costs.filter((c) => translateEnumValue(CostCategory, c.post.costCategory) === selectedCategory)

   if (selectedCostType && isValidEnumValue(CostTypeFilter, selectedCostType))
      switch (selectedCostType) {
         case translateEnumValue(CostTypeFilter, CostTypeFilter.Budget):
            items = items.filter((i) => i.budget > 0)
            break
         case translateEnumValue(CostTypeFilter, CostTypeFilter.Result):
            items = items.filter((i) => i.result > 0)
            break
      }
   return items
}

export const saveCost = async (
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>,
   dataContext: IDataContext,
   cost: Cost,
   financialYearId: string,
   reset: () => void,
   close: () => void
) => {
   try {
      const { accessToken, userAccount, organisation } = dataContext.state

      let updatedFinancialYear: FinancialYear
      cost.modifiedById = userAccount.id
      cost.modifiedBy = userAccount

      if (cost.id) {
         updatedFinancialYear = (await api(accessToken).updateCostOnFinancialYear(financialYearId, cost)).data
      } else {
         cost.organisationId = organisation.id
         cost.createdById = userAccount.id
         cost.createdBy = userAccount
         updatedFinancialYear = (await api(accessToken).addCostToFinancialYear(financialYearId, cost)).data
      }

      setFinancialYearState((prev) => ({ ...prev, item: updatedFinancialYear }))
      reset()
      close()
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyCreated', { Item: t('Cost') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('SomethingWentWrong'), t('CouldNotCreateItem', { Item: t('Cost') }))
   }
}

export const fetchPosts = async (dataContext: IDataContext) => {
   const { accessToken } = dataContext.state

   const posts = (await api(accessToken).getAllPosts()).data

   dataContext.setRootState((prev) => ({ ...prev, posts }))
}

export const getCostActionPlanColumns = (actionPlans: ActionPlan[]): TableColumnDefinition<Cost>[] => [
   {
      columnId: 'proposedAction',
      renderHeaderCell: () => t('ActionProposal'),
      renderCell: (item) => {
         return item.proposedAction
      },
      compare: (a, b) => {
         return a.proposedAction.localeCompare(b.proposedAction)
      },
   },
   {
      columnId: 'category',
      renderHeaderCell: () => t('Category'),
      renderCell: (item) => {
         return <BadgeCostCategory category={item.post.costCategory} />
      },
      compare: (a, b) => {
         return translateEnumValue(CostCategory, a.post.costCategory).localeCompare(translateEnumValue(CostCategory, b.post.costCategory))
      },
   },
   {
      columnId: 'estimatedCost',
      renderHeaderCell: () => t('EstimatedCostShort'),
      renderCell: (item) => {
         return item.estimatedCost || item.estimatedCost === 0 ? formatToSwedishCrowns(item.estimatedCost) + ' kr' : '-'
      },
      compare: (a, b) => {
         return a.estimatedCost - b.estimatedCost
      },
   },
   {
      columnId: 'status',
      renderHeaderCell: () => t('DecisionStatus'),
      renderCell: (item) => {
         const actionPlan = actionPlans.find((a) => a.costId === item.id)
         return <BadgeActionPlanStatus status={actionPlan ? actionPlan.status : ActionPlanStatus.NotDecided} />
      },
      compare: (a, b) => {
         const aStatus: ActionPlanStatus = actionPlans.find((action) => action.costId === a.id).status ?? ActionPlanStatus.NotDecided
         const bStatus: ActionPlanStatus = actionPlans.find((action) => action.costId === b.id).status ?? ActionPlanStatus.NotDecided
         return translateEnumValue(ActionPlanStatus, aStatus).localeCompare(translateEnumValue(ActionPlanStatus, bStatus))
      },
   },
]
export const getFinancialYearActionPlanSummaryFields = (filteredCosts: Cost[], actionPlans: ActionPlan[]): ISummaryField[] => [
   {
      label: t('TotalEstimatedCost'),
      text: `${formatToSwedishCrowns(filteredCosts.reduce((sum, cost) => sum + cost.estimatedCost, 0))} kr`,
   },
   {
      label: t('Decided'),
      text: `${actionPlans.filter((a) => a.status !== ActionPlanStatus.NotDecided).length} / ${filteredCosts.length}`,
   },
]

export const getFinancialYearActionPlanDefaultValues = (costId: string) => ({
   costId,
   status: ActionPlanStatus.NotDecided,
   actionNeeded: true,
})
