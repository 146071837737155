import React, { useContext, useEffect, useRef, useState } from 'react'
import { FinancialYear, FinancialYearStatus } from '../api/schemas/schema'
import { Layout } from './Layout'
import { t } from '../i18n/i18n'
import { Navigation } from '../components/common/navigation/Navigation'
import { Button, makeStyles, mergeClasses, SelectTabData, Tab, TabList } from '@fluentui/react-components'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { DataContext } from '../context/DataContext'
import { Loader } from '../components/common/spinner/Loader'
import { FinancialYearTabs } from '../helpers/riskEconomyHelper'
import {
   Info20Regular,
   MoneyCalculator20Filled,
   DataTrending20Filled,
   DataTrending20Regular,
   Info20Filled,
   MoneyCalculator20Regular,
   TaskListSquareLtr20Filled,
   TaskListSquareLtr20Regular,
   LockClosed20Regular,
   LockClosed24Regular,
} from '@fluentui/react-icons'
import { CostIdentifying } from '../components/riskEconomy/CostIdentifying'
import { IItemState } from '../interfaces/IItemState'
import { FinancialYearNavLinks } from '../components/riskEconomy/FinancialYearNavLinks'
import { FinancialYearInformation } from '../components/riskEconomy/FinancialYearInformation'
import { CostEvaluation } from '../components/riskEconomy/CostEvaluation/CostEvaluation'
import { FinancialYearActionPlan } from '../components/riskEconomy/FinancialYearActionPlan'
import { fetchFinancialYear } from '../helpers/financialYearHelper'
import { ModalCompleteFinancialYear } from '../components/riskEconomy/ModalCompleteFinancialYear'
import dayjs from 'dayjs'
import { NavigationLink } from '../interfaces/INavigationLink'
const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   flexOnlyLarge: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
})

export const SelectedFinancialYear = () => {
   const classes = useStyles()
   const [searchParams, setSearchParams] = useSearchParams()
   const [financialYearState, setFinancialYearState] = useState<IItemState<FinancialYear>>({
      disabled: false,
      loading: true,
      error: undefined,
      item: null,
   })
   const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false)
   const { item: selectedFinancialYear } = financialYearState
   const navigate = useNavigate()
   const dataContext = useContext(DataContext)
   const { id } = useParams()
   const prevId = useRef({})
   const selectedTab = searchParams.get('tab')
   const [completeBtnDisabled, setCompleteBtnDisabled] = useState<boolean>(false)

   useEffect(() => {
      if (prevId.current !== id) {
         prevId.current = id
         fetchFinancialYear(dataContext, id, navigate, setFinancialYearState)
      }
      if (selectedFinancialYear && selectedFinancialYear.organisationId !== dataContext.state.organisation.id) {
         navigate(NavigationLink.RiskEconomy)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, dataContext.state.userAccount])

   useEffect(() => {
      let actionRequiredCosts = []
      if (selectedFinancialYear !== null) {
         actionRequiredCosts = selectedFinancialYear.costs.filter((c) => c.actionRequired)
         if (
            selectedFinancialYear.status === FinancialYearStatus.Completed ||
            selectedFinancialYear.actionPlans.length < actionRequiredCosts.length ||
            dayjs(selectedFinancialYear.endDate).format('YYYY-MM-DD') >= dayjs().format('YYYY-MM-DD')
         ) {
            setCompleteBtnDisabled(true)
         } else {
            setCompleteBtnDisabled(false)
         }
      } else {
         setCompleteBtnDisabled(true)
      }
   }, [selectedFinancialYear])

   const setTab = (tab: string) => {
      setSearchParams((prev) => ({ ...prev, tab }))
   }

   useEffect(() => {
      if (!selectedTab) {
         setTab('info')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <Layout
         breadCrumbItems={[
            { path: '/risk-economy', title: t('RiskEconomy'), active: false },
            { path: `/risk-economy/${selectedFinancialYear?.id}`, title: selectedFinancialYear?.name, active: true },
         ]}
      >
         {financialYearState.loading ? (
            <Loader />
         ) : (
            <>
               <Navigation
                  activeDesktopTab="/risk-economy"
                  phoneNavLinks={
                     <FinancialYearNavLinks
                        id={selectedFinancialYear.id}
                        activeTab={selectedTab}
                        actionTab={<Tab value={'complete'} icon={<LockClosed24Regular />} disabled={completeBtnDisabled} />}
                     />
                  }
                  customOnTabSelect={(e, data: SelectTabData) => {
                     if (data.value === 'complete') setCompleteModalOpen(true)
                     else navigate(data.value)
                  }}
               />
               <header className={mergeClasses(classes.header, classes.flexOnlyLarge)}>
                  <TabList
                     defaultSelectedValue={'info'}
                     selectedValue={selectedTab}
                     onTabSelect={(e, data: SelectTabData) => setSearchParams({ tab: data.value as string })}
                  >
                     <Tab value={FinancialYearTabs.Info} icon={selectedTab === FinancialYearTabs.Info ? <Info20Filled /> : <Info20Regular />}>
                        {t('Information')}
                     </Tab>
                     <Tab
                        value={FinancialYearTabs.IdentifyCosts}
                        icon={selectedTab === FinancialYearTabs.IdentifyCosts ? <MoneyCalculator20Filled /> : <MoneyCalculator20Regular />}
                     >
                        {t('IdentifyCosts')}
                     </Tab>
                     <Tab
                        value={FinancialYearTabs.Evaluation}
                        icon={selectedTab === FinancialYearTabs.Evaluation ? <DataTrending20Filled /> : <DataTrending20Regular />}
                     >
                        {t('Evaluation')}
                     </Tab>
                     <Tab
                        value={FinancialYearTabs.ActionPlan}
                        icon={selectedTab === FinancialYearTabs.ActionPlan ? <TaskListSquareLtr20Filled /> : <TaskListSquareLtr20Regular />}
                     >
                        {t('ActionPlan')}
                     </Tab>
                  </TabList>
                  <Button disabled={completeBtnDisabled} icon={<LockClosed20Regular />} onClick={() => setCompleteModalOpen(true)} appearance="transparent">
                     {t('CompleteFinancialYear')}
                  </Button>
               </header>
               {selectedTab === FinancialYearTabs.Info && (
                  <FinancialYearInformation setFinancialYearState={setFinancialYearState} financialYear={selectedFinancialYear} />
               )}
               {selectedTab === FinancialYearTabs.IdentifyCosts && (
                  <CostIdentifying setFinancialYearState={setFinancialYearState} financialYear={selectedFinancialYear} />
               )}
               {selectedTab === FinancialYearTabs.Evaluation && (
                  <CostEvaluation setFinancialYearState={setFinancialYearState} selectedFinancialYear={selectedFinancialYear} />
               )}
               {selectedTab === FinancialYearTabs.ActionPlan && (
                  <FinancialYearActionPlan setFinancialYearState={setFinancialYearState} financialYear={selectedFinancialYear} />
               )}
            </>
         )}
         <ModalCompleteFinancialYear
            open={completeModalOpen}
            close={() => setCompleteModalOpen(false)}
            financialYearState={financialYearState}
            setFinancialYearState={setFinancialYearState}
         />
      </Layout>
   )
}
