import React from 'react'
import { ActionPlan, Cost, CostCategory, FinancialYear } from '../api/schemas/schema'
import { IListState } from '../interfaces/IListState'
import { Checkbox, TableColumnDefinition } from '@fluentui/react-components'
import { t } from '../i18n/i18n'
import { getFullName } from './accountHelper'
import { IDataContext } from '../interfaces/IDataContext'
import { handleMessage } from './stateHelper'
import { api } from './apiHelper'
import { translateEnumValue } from './enumHelper'
import { formatDate, summarizeFieldValues } from './stringHelper'
import { BadgeCostCategory } from '../components/riskEconomy/BadgeCostCategory'
import { Text } from '../components/common/text/Text'
import { ISummaryField } from '../interfaces/ISummaryField'
import { IItemState } from '../interfaces/IItemState'
import { AvatarAndNameOutput } from '../components/common/inputs/UserAvatarOutput'
import { IGroup } from '../interfaces/IGroup'
import { DeviationSummary } from '../components/riskEconomy/DeviationSummary'
import { IGroupedTableColumnDefintion } from '../interfaces/IGroupedTableColumnDefintion'
import { BadgeAmountCircle } from '../components/riskEconomy/BadgeAmountCircle'
import { FormattedMoneyOutput } from '../components/riskEconomy/FormattedMoneyOutput'
import { MoreOptionsMenuButton } from '../components/common/other/MoreOptionsMenuButton'

export enum RiskEconomyTabs {
   Ongoing = 'Ongoing',
   Concluded = 'Concluded',
}

export enum FinancialYearTabs {
   Info = 'info',
   IdentifyCosts = 'identify-costs',
   Evaluation = 'evaluation',
   ActionPlan = 'actionplan',
}

export const addFinancialYear = async (
   setRiskEconomyState: React.Dispatch<React.SetStateAction<IListState<FinancialYear>>>,
   dataContext: IDataContext,
   data: FinancialYear
) => {
   const { accessToken, userAccount, organisation } = dataContext.state
   try {
      data.createdBy = userAccount
      data.createdById = userAccount.id
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id
      data.organisationId = organisation.id

      const addedFinancialYear = (await api(accessToken).addFinancialYear(data)).data

      setRiskEconomyState((prev) => ({
         ...prev,
         allItems: [...prev.allItems, addedFinancialYear],
      }))

      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyCreated', { Item: t('FinancialYear') }))

      return addedFinancialYear
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('FinancialYear') }), error.error.message)
   }
}

export const updateFinancialYear = async (
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>,
   dataContext: IDataContext,
   data: FinancialYear
) => {
   const { accessToken, userAccount } = dataContext.state
   try {
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      const updatedFinancialYear = (await api(accessToken).updateFinancialYear(data)).data

      setFinancialYearState((prev) => ({
         ...prev,
         item: updatedFinancialYear,
      }))

      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('FinancialYear') }))
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('FinancialYear') }), error.error.message)
   }
}

export const deleteFinancialYear = async (
   id: string,
   dataContext: IDataContext,
   setRiskEconomyState: React.Dispatch<React.SetStateAction<IListState<FinancialYear>>>
) => {
   try {
      const { accessToken } = dataContext.state

      await api(accessToken).deleteFinancialYear(id)

      setRiskEconomyState((prev) => ({
         ...prev,
         allItems: prev.allItems.filter((item) => item.id !== id),
         filteredItems: prev.filteredItems.filter((item) => item.id !== id),
         open: false,
      }))

      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyDeleted', { Item: t('FinancialYear') }))
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotDeleteItem', { Item: t('FinancialYear') }), error.error.message)
   }
}

export const updateCostOnFinancialYear = async (
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>,
   dataContext: IDataContext,
   data: Cost,
   financialYearId: string
) => {
   const { accessToken, userAccount } = dataContext.state
   try {
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      const updatedFinancialYear = (await api(accessToken).updateCostOnFinancialYear(financialYearId, data)).data

      setFinancialYearState((prev) => ({
         ...prev,
         item: updatedFinancialYear,
      }))

      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Cost') }))
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Cost') }), error.error.message)
   }
}

export const createOrUpdateFinancialYearActionPlan = async (
   data: ActionPlan,
   accessToken: string,
   financialYear: FinancialYear,
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
) => {
   try {
      const updatedFinancialYear = (await api(accessToken).createOrUpdateFinancialYearActionPlan(financialYear.id, data)).data

      setFinancialYearState((prev) => ({ ...prev, item: updatedFinancialYear }))
      return true
   } catch (error: any) {
      return false
   }
}

export const getRiskEconomyColumns = (
   setRiskEconomyState: React.Dispatch<React.SetStateAction<IListState<FinancialYear>>>
): TableColumnDefinition<FinancialYear>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => t('Name'),
      renderCell: (item) => item.name,
      compare: (a, b) => a.name.localeCompare(b.name),
   },
   {
      columnId: 'owner',
      renderHeaderCell: () => t('Owner'),
      renderCell: (item) => <AvatarAndNameOutput userAccount={item.owner} />,
      compare: (a, b) => getFullName(a.owner).localeCompare(getFullName(b.owner)),
   },
   {
      columnId: 'period',
      renderHeaderCell: () => t('Period'),
      renderCell: (item) => {
         return `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`
      },
      compare: (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
   },
   {
      columnId: 'menu',
      renderHeaderCell: () => <div style={{ width: '35px' }}>&nbsp;</div>,
      renderCell: (item) => (
         <MoreOptionsMenuButton
            item={item}
            deleteFunction={deleteFinancialYear}
            setState={setRiskEconomyState}
            deleteMessage={t('DeleteItem', { Item: t('FinancialYear').toLowerCase() })}
            deleteConfirmationMessage={t('DeleteItemConfirmationMessage', { Item: t('TheFinancialYear').toLocaleLowerCase() })}
         />
      ),
      compare: () => 0,
   },
]

export const getCostIdentifyingColumns = (): TableColumnDefinition<Cost>[] => [
   {
      columnId: 'date',
      renderHeaderCell: () => t('Date'),
      renderCell: (item) => formatDate(item.created),
      compare: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
   },
   {
      columnId: 'category',
      renderHeaderCell: () => t('Category'),
      renderCell: (item) => <BadgeCostCategory category={item.post.costCategory} />,
      compare: (a, b) => {
         const aCategory = translateEnumValue(CostCategory, a.post.costCategory)
         const bCategory = translateEnumValue(CostCategory, b.post.costCategory)
         return aCategory.localeCompare(bCategory)
      },
   },
   {
      columnId: 'post',
      renderHeaderCell: () => t('Post'),
      renderCell: (item) => item.post.name ?? '-',
      compare: (a, b) => {
         const aValue = a.post.name ? a.post.name : '-'
         const bValue = b.post.name ? b.post.name : '-'
         return aValue.localeCompare(bValue)
      },
   },
   {
      columnId: 'description',
      renderHeaderCell: () => t('Description'),
      renderCell: (item) => (
         <Text ellipsis style={{ WebkitLineClamp: 3 }} size={300}>
            {item.description}
         </Text>
      ),
      compare: (a, b) => a.description.localeCompare(b.description),
   },
   {
      columnId: 'budget',
      renderHeaderCell: () => t('Budget'),
      renderCell: (item) => <FormattedMoneyOutput value={item.budget} />,
      compare: (a, b) => a.budget - b.budget,
   },
   {
      columnId: 'result',
      renderHeaderCell: () => t('Result'),
      renderCell: (item) => <FormattedMoneyOutput value={item.result} />,
      compare: (a, b) => a.result - b.result,
   },
]
export const getCostSummaryFields = (costs: Cost[]): ISummaryField[] => [
   {
      label: t('TotalNumberOfPosts'),
      text: costs.length,
      alignStart: true,
   },
   {
      label: t('TotalBudget'),
      text: <FormattedMoneyOutput value={summarizeFieldValues(costs, 'budget')} />,
   },
   {
      label: t('TotalResult'),
      text: <FormattedMoneyOutput value={summarizeFieldValues(costs, 'result')} />,
   },
]

export const getCostEvaluationSummaryFields = (costs: Cost[]): ISummaryField[] => [
   {
      label: t('TotalNumberOfPosts'),
      text: costs.length,
      alignStart: true,
   },
   {
      label: t('TotalBudget'),
      text: <FormattedMoneyOutput value={summarizeFieldValues(costs, 'budget')} />,
   },
   {
      label: t('TotalResult'),
      text: <FormattedMoneyOutput value={summarizeFieldValues(costs, 'result')} />,
   },
   {
      label: t('SummaryDeviation'),
      text: <DeviationSummary costs={costs} />,
   },
]

export const getCostEvaluationGroups = (costs: Cost[]): IGroup<Cost>[] => {
   const groups: IGroup<Cost>[] = []
   costs.forEach((cost) => {
      const key = cost.post.costCategory.toString()
      const label = translateEnumValue(CostCategory, cost.post.costCategory)

      const group = groups.find((g) => g.key === key)

      if (!group) {
         groups.push({ label, key, order: cost.post.costCategory, hex: '', expanded: true, items: [] })
      }
      groups.find((g) => g.key === key).items.push(cost)
   })
   return groups.sort((a, b) => a.order - b.order)
}

export const getCostEvaluationColumns = (): IGroupedTableColumnDefintion<Cost>[] => [
   {
      columnId: 'post',
      colWidth: '200px',
      renderHeaderCell: () => t('Post'),
      renderGroupHeaderCell: (group) => {
         return <BadgeCostCategory category={Number(group.key)} />
      },
      renderCell: (cost) => {
         return cost.post.name ? cost.post.name : translateEnumValue(CostCategory, cost.post.costCategory)
      },
      compare: (a, b) => {
         const aValue = a.post.name ? a.post.name : translateEnumValue(CostCategory, a.post.costCategory)
         const bValue = b.post.name ? b.post.name : translateEnumValue(CostCategory, b.post.costCategory)
         return aValue.localeCompare(bValue)
      },
   },
   {
      columnId: 'numberOfPosts',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('NumberOfPosts'),
      renderCell: () => {
         return null
      },
      renderGroupHeaderCell: (group) => {
         return <BadgeAmountCircle amount={group.items.length} />
      },
      compare: () => -1,
   },
   {
      columnId: 'budget',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('Budget'),
      renderCell: (item) => <FormattedMoneyOutput value={item.budget} />,
      renderGroupHeaderCell: (group) => <FormattedMoneyOutput value={summarizeFieldValues(group.items, 'budget')} />,
      compare: () => -1,
   },
   {
      columnId: 'result',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('Result'),
      renderCell: (item) => <FormattedMoneyOutput value={item.result} />,
      renderGroupHeaderCell: (group) => <FormattedMoneyOutput value={summarizeFieldValues(group.items, 'result')} />,
      compare: () => -1,
   },
   {
      columnId: 'deviation',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('Deviation'),
      renderCell: (item) => <DeviationSummary cost={item} />,
      renderGroupHeaderCell: (group) => {
         return <DeviationSummary costs={group.items} />
      },
      compare: () => -1,
   },
   {
      columnId: 'actionRequired',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('ProposedAction'),
      renderCell: (item) => {
         return <Checkbox checked={item.actionRequired} />
      },
      renderGroupHeaderCell: () => {
         return null
      },
      compare: () => -1,
   },
]

export const getGroupedAndSummarizedCosts = (costs: Cost[]) => {
   const groupedCosts = {} as { [key: number]: { name: string; costCategory: CostCategory; result: number; budget: number } }

   costs.forEach((cost) => {
      const category = cost.post.costCategory

      if (!groupedCosts[category]) {
         groupedCosts[category] = {
            name: translateEnumValue(CostCategory, category),
            costCategory: category,
            result: cost.result,
            budget: cost.budget,
         }
      } else {
         groupedCosts[category].result += cost.result
         groupedCosts[category].budget += cost.budget
      }
   })

   return Object.values(groupedCosts).sort((a, b) => a.costCategory - b.costCategory)
}
