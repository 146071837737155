import React, { useContext, useMemo } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { t } from '../../i18n/i18n'
import { Dropdown, OptionGroup, Option, makeStyles, tokens } from '@fluentui/react-components'
import { Cost, CostCategory, Post } from '../../api/schemas/schema'
import { translateEnumValue } from '../../helpers/enumHelper'
import { DataContext } from '../../context/DataContext'
import { Field } from '../common/inputs/Field'

const useStyles = makeStyles({
   bold: {
      fontWeight: tokens.fontWeightBold,
   },
})
type PostDropdownProps = {
   costForm: UseFormReturn<Cost, any, undefined>
}

type PostGroup = {
   key: CostCategory
   items: Post[]
}

export const PostDropdown = ({ costForm }: PostDropdownProps) => {
   const { control, setValue } = costForm
   const dataContext = useContext(DataContext)
   const { posts } = dataContext.state
   const classes = useStyles()
   const groupPosts = () => {
      const groups: PostGroup[] = []
      posts.forEach((post) => {
         const key = post.costCategory
         const group = groups.find((g) => g.key === key)
         if (!group) {
            groups.push({ key, items: [] })
         }
         groups.find((g) => g.key === key).items.push(post)
      })
      return groups.sort((a, b) => a.key - b.key)
   }
   const postGroups = useMemo(groupPosts, [posts])
   return (
      <Controller
         name={'post'}
         control={control}
         rules={{ required: t('RequiredField') }}
         render={({ field: { value }, fieldState: { error }, formState: { disabled: formDisabled } }) => {
            return (
               <Field label={t('Post')} required={true} validationMessage={error?.message} validationState={error ? 'error' : null}>
                  <Dropdown
                     disabled={formDisabled}
                     onOptionSelect={(e, data) => {
                        setValue(
                           'post',
                           posts.find((x) => x.id === data.optionValue)
                        )
                     }}
                     selectedOptions={value ? [value.id] : []}
                     style={{ minWidth: 'auto' }}
                     value={value ? value.name ?? translateEnumValue(CostCategory, value.costCategory) : ''}
                  >
                     {postGroups.map((group) => {
                        const categoryOption = posts.find((x) => x.costCategory === group.key && !x.name)
                        const groupItems = group.items
                           .filter((x) => (categoryOption ? x.id !== categoryOption.id : true))
                           .sort((a, b) => {
                              const aValue = a.name ?? translateEnumValue(CostCategory, a.costCategory)
                              const bValue = b.name ?? translateEnumValue(CostCategory, b.costCategory)
                              return aValue.localeCompare(bValue)
                           })

                        return (
                           <OptionGroup label={categoryOption ? null : translateEnumValue(CostCategory, group.key)} key={group.key}>
                              {categoryOption && (
                                 <Option className={classes.bold} key={categoryOption.id} value={categoryOption.id}>
                                    {translateEnumValue(CostCategory, categoryOption.costCategory)}
                                 </Option>
                              )}
                              {groupItems.map((post) => (
                                 <Option onClick={(e) => e.stopPropagation()} key={post.id} value={post.id}>
                                    {post.name}
                                 </Option>
                              ))}
                           </OptionGroup>
                        )
                     })}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
