import React from 'react'
import { Accordion, AccordionToggleEventHandler, makeStyles, tokens } from '@fluentui/react-components'
import { t } from 'i18next'
import { SubscriptionAccordionItem } from './SubscriptionAccordionItem'
import { UseFormReturn } from 'react-hook-form'
import { DemandComplianceModules, GeneralModules, Modules, Organisation, RiskManagementModules } from '../../../api/schemas/schema'
import { getModuleType } from '../../../helpers/subscriptionHelper'

const useStyles = makeStyles({
   marginBottom: {
      marginBottom: tokens.spacingVerticalM,
   },
})

type ControlledSubscriptionAccordionProps = {
   organisationForm: UseFormReturn<Organisation, any, undefined>
}

export const ControlledSubscriptionAccordion = ({ organisationForm }: ControlledSubscriptionAccordionProps) => {
   const classes = useStyles()
   const { control, watch, setValue } = organisationForm
   const [openItems, setOpenItems] = React.useState<string[]>(['demandComplianceModule', 'riskManagementModule', 'generalModule'])

   const selectedModules: Modules[] = watch('modules') || []
   const complianceModules = selectedModules.filter((mod) => getModuleType(mod)?.moduleType === 'demandComplianceModule')
   const riskModules = selectedModules.filter((mod) => getModuleType(mod)?.moduleType === 'riskManagementModule')
   const generalModules = selectedModules.filter((mod) => getModuleType(mod)?.moduleType === 'generalModule')

   const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
      setOpenItems(data.openItems)
   }

   return (
      <Accordion collapsible multiple openItems={openItems} onToggle={handleToggle}>
         <SubscriptionAccordionItem
            title={t('ComplianceOfDemands')}
            enumOptions={DemandComplianceModules}
            filteredModules={complianceModules}
            control={control}
            className={classes.marginBottom}
            watch={watch}
            setValue={setValue}
            value="demandComplianceModule"
         />
         <SubscriptionAccordionItem
            title={t('RiskManagement')}
            enumOptions={RiskManagementModules}
            filteredModules={riskModules}
            control={control}
            className={classes.marginBottom}
            watch={watch}
            setValue={setValue}
            value="riskManagementModule"
         />
         <SubscriptionAccordionItem
            title={t('SeparateModules')}
            enumOptions={GeneralModules}
            filteredModules={generalModules}
            control={control}
            className={classes.marginBottom}
            watch={watch}
            setValue={setValue}
            value="generalModule"
            isSwitch
         />
      </Accordion>
   )
}
