import React from 'react'
import {
   InfoShield20Regular,
   SearchShield20Regular,
   TaskListSquareLtr20Regular,
   TaskListSquareLtr24Filled,
   SearchShield20Filled,
   InfoShield20Filled,
   ClipboardPulse20Filled,
   ClipboardPulse20Regular,
   ClipboardTextLtr20Filled,
   ClipboardTextLtr20Regular,
   Warning20Filled,
   Warning20Regular,
} from '@fluentui/react-icons'
import { Tab } from '@fluentui/react-components'
import { ConsequenceAndProbabilityAnalysisTabs } from '../../../helpers/riskAnalysisHelper'

type ConsequenceAndProbabilityNavLinksProps = {
   id: string
   active: string
   actionTab: JSX.Element
}
export const ConsequenceAndProbabilityNavLinks = ({ active, id, actionTab }: ConsequenceAndProbabilityNavLinksProps) => {
   const baseLink = `/risk-management/${id}`
   return (
      <>
         <Tab
            value={`${baseLink}?tab=${ConsequenceAndProbabilityAnalysisTabs.Info}`}
            icon={active === ConsequenceAndProbabilityAnalysisTabs.Info ? <InfoShield20Filled /> : <InfoShield20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${ConsequenceAndProbabilityAnalysisTabs.ThreatScenario}`}
            icon={active === ConsequenceAndProbabilityAnalysisTabs.ThreatScenario ? <Warning20Filled /> : <Warning20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${ConsequenceAndProbabilityAnalysisTabs.Identifying}`}
            icon={active === ConsequenceAndProbabilityAnalysisTabs.Identifying ? <SearchShield20Filled /> : <SearchShield20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${ConsequenceAndProbabilityAnalysisTabs.Analysis}`}
            icon={active === ConsequenceAndProbabilityAnalysisTabs.Analysis ? <ClipboardTextLtr20Filled /> : <ClipboardTextLtr20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${ConsequenceAndProbabilityAnalysisTabs.Evaluation}`}
            icon={active === ConsequenceAndProbabilityAnalysisTabs.Evaluation ? <ClipboardPulse20Filled /> : <ClipboardPulse20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${ConsequenceAndProbabilityAnalysisTabs.ActionPlan}`}
            icon={active === ConsequenceAndProbabilityAnalysisTabs.ActionPlan ? <TaskListSquareLtr24Filled /> : <TaskListSquareLtr20Regular />}
         />
         {actionTab}
      </>
   )
}
