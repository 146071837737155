import React from 'react'
import { Field, Radio, RadioGroup } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { IRadioOption } from '../../../interfaces/IRadioOptions'

type ControlledRadioGroupProps = {
   name: string
   label: string
   control: Control<any>
   options: IRadioOption[]
   required?: boolean
   disabled?: boolean
   convertValue?: boolean
}

export const ControlledRadioGroup = ({ name, label, control, options, required, disabled, convertValue }: ControlledRadioGroupProps) => {
   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <Field label={label} required={required}>
               <RadioGroup disabled={disabled} required={required} onChange={(e, data) => field.onChange(convertValue ? Number(data.value) : data.value)}>
                  {options.map((option) => (
                     <Radio value={String(option.value)} label={option.label} checked={field.value === option.value} />
                  ))}
               </RadioGroup>
            </Field>
         )}
      />
   )
}
