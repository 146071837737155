/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { DemandAnalysis, DemandAnalysisQuestion, DemandAnalysisQuestionStatus, DemandAnalysisStatus } from '../../../api/schemas/schema'
import { t } from '../../../i18n/i18n'
import { Button, makeStyles, tokens, mergeClasses, Divider } from '@fluentui/react-components'
import { useSearchParams } from 'react-router-dom'
import { filterQuestions, getCurrentAnalysisColumns } from '../../../helpers/demandAssessmentHelper'
import { Text } from '../../common/text/Text'
import { Avatar } from '../../common/avatar/Avatar'
import { BadgeAnalysisQuestion } from '../BadgeAnalysisQuestion'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { ModalAnalysisQuestion } from './ModalAnalysisQuestion'
import { Processfilter } from '../ProcessFilter'
import { Title } from '../../common/text/Title'
import { CustomList } from '../../common/list/CustomList'
import { ModalCompleteCurrentDemandAnalysis } from './ModalCompleteCurrentDemandAnalysis'
import { ModalAnalysisReport } from './ModalAnalysisReport'
import { RTFOutput } from '../../common/outputs/RTFOutput'
import { SummaryRow } from '../../common/list/SummaryRow'
import { getFullName } from '../../../helpers/accountHelper'
import { IItemState } from '../../../interfaces/IItemState'

type CurrentDemandAnalysisProps = {
   analysisState: IItemState<DemandAnalysis>
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<DemandAnalysis>>>
}
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   phoneListWrapper: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'flex',
         flexDirection: 'column',
         gap: tokens.spacingVerticalXXL,
      },
   },

   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalL,
      alignItems: 'center',
   },
   phoneListItem: {
      '& > :last-child': {
         marginTop: tokens.spacingVerticalXS,
      },
      '& p': {
         margin: '0 auto',
      },
   },
   padding: {
      padding: `0 ${tokens.spacingHorizontalXL}`,
   },
   hidden: {
      visibility: 'hidden',
   },
   header: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'block',
         '& section': {
            display: 'flex',
            justifyContent: 'space-between',
            '& h3': {
               margin: 0,
            },
         },
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '5%',
            minWidth: '10px',
         },
         ':nth-child(2)': {
            width: '100%',
         },
         ':nth-child(3)': {
            width: '20%',
         },
         ':nth-child(4)': {
            width: '20%',
         },
         ':nth-child(5)': {
            width: '10%',
            minWidth: '100px',
         },
      },
   },
})
export const CurrentDemandAnalysis = ({ analysisState, setAnalysisState }: CurrentDemandAnalysisProps) => {
   const { item: analysis } = analysisState
   const classes = useStyles()
   const processes: string[] = [t('AllProcesses')]
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')
   const [items, setItems] = useState<DemandAnalysisQuestion[]>(filterQuestions(selectedProcess, analysis.questions))
   const [selectedItem, setSelectedItem] = useState<DemandAnalysisQuestion>()
   const [modalCompleteOpen, setOpenCompleteModal] = useState<boolean>(false)

   const setProcess = (process: string) => {
      searchParams.set('process', process)
      setSearchParams(searchParams)
   }
   useEffect(() => {
      if (searchParams.get('process') === null || !processes.find((x) => x === searchParams.get('process'))) {

         setProcess(processes[0])
      }
   }, [window.location.search])
   useEffect(() => {
      setItems(filterQuestions(selectedProcess, analysis.questions))
   }, [selectedProcess, analysis.questions])

   analysis.questions
      .sort((a, b) => a.processes[0].localeCompare(b.processes[0]))
      .forEach((x) =>
         x.processes.forEach((p) => {
            if (!processes.some((c) => c === p)) {
               processes.push(p)
            }
         })
      )

   const onRowClick = (item: DemandAnalysisQuestion) => {
      setSelectedItem(item)
   }
   const completeBtnDisabled =
      items.length === 0 ||
      !items.every((x) => x.status === DemandAnalysisQuestionStatus.Answered || x.status === DemandAnalysisQuestionStatus.Determined) ||
      analysisState.disabled
   const onCompleteBtnClick = () => setOpenCompleteModal(true)
   const completeBtnVisible = analysis.status === DemandAnalysisStatus.Ongoing && !items.every((x) => x.status === DemandAnalysisQuestionStatus.Determined)

   return (
      <>
         <header className={classes.header}>
            <section>
               <Title as="h3">{t('DemandAnalysis')}</Title>
               <span className={classes.flex}>
                  {completeBtnVisible && (
                     <Button disabled={completeBtnDisabled} appearance="transparent" icon={<LockClosed24Regular />} onClick={onCompleteBtnClick} />
                  )}
               </span>
            </section>
            <Divider appearance="brand" />
         </header>
         <article>
            <Processfilter
               setProcess={setProcess}
               processes={processes}
               title={t('DemandAnalysis')}
               action={
                  <span className={classes.flex}>
                     {completeBtnVisible && (
                        <Button disabled={completeBtnDisabled} appearance="transparent" icon={<LockClosed24Regular />} onClick={onCompleteBtnClick}>
                           {t('DetermineAnalysis')}
                        </Button>
                     )}
                     <ModalAnalysisReport questions={analysis.questions} processOwners={analysis.processOwners} />
                  </span>
               }
            />
            {items.length > 0 ? (
               <>
                  <SummaryRow
                     summaryFields={[
                        {
                           label: t('AllAnswered'),
                           text: `${items.filter((x) => x.status !== DemandAnalysisQuestionStatus.NotAnswered).length} / ${items.length}`,
                        },
                     ]}
                     className={classes.hideInPhone}
                  />
                  <div className={mergeClasses(classes.padding, classes.hideInPhone)}>
                     <CustomList onRowClick={onRowClick} rowClassName={classes.row} columns={getCurrentAnalysisColumns(analysis.processOwners)} items={items} />
                  </div>
                  <section className={classes.phoneListWrapper}>
                     {items.map((x, i) => {
                        const processOwner = analysis.processOwners.find((po) => po.process.text === x.processes[0])
                        return (
                           <div key={i} className={classes.phoneListItem} onClick={() => onRowClick(x)}>
                              <div className={classes.spaceBtw}>
                                 <span className={classes.flex}>
                                    <Text weight="semibold">{x.number}</Text>
                                    <Text>{x.processes}</Text>
                                 </span>
                                 <span className={classes.flex}>
                                    <Avatar
                                       name={processOwner && processOwner.userAccount ? getFullName(processOwner.userAccount) : processOwner.ownerFreeText}
                                       onlyCircle
                                       color="brand"
                                    />
                                    <BadgeAnalysisQuestion status={x.status} />
                                 </span>
                              </div>
                              <RTFOutput text={x.text} fontSize={300} noMargin ellipsis />
                           </div>
                        )
                     })}
                  </section>
               </>
            ) : null}
            <ModalCompleteCurrentDemandAnalysis
               open={modalCompleteOpen}
               close={() => setOpenCompleteModal(false)}
               setItems={setItems}
               setAnalysisState={setAnalysisState}
               items={items.filter((x) => x.status !== DemandAnalysisQuestionStatus.Determined)}
            />
            <ModalAnalysisQuestion
               analysisDisabled={analysisState.disabled}
               item={selectedItem}
               close={() => setSelectedItem(undefined)}
               setItems={setItems}
               setAnalysisState={setAnalysisState}
            />
         </article>
      </>
   )
}
