import React from 'react'
import { t } from 'i18next'
import { makeStyles, tokens, Text } from '@fluentui/react-components'
import { Cost } from '../../../api/schemas/schema'
import { IGroup } from '../../../interfaces/IGroup'
import { BadgeAmountCircle } from '../BadgeAmountCircle'
import { BadgeCostCategory } from '../BadgeCostCategory'
import { DeviationSummary } from '../DeviationSummary'
import { FormattedMoneyOutput } from '../FormattedMoneyOutput'
import { summarizeFieldValues } from '../../../helpers/stringHelper'

const useStyles = makeStyles({
   topWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: tokens.spacingVerticalM,
   },
   checkbox: {
      marginLeft: 'auto',
      flexDirection: 'column',
      marginRight: tokens.spacingHorizontalM,
      '& > *': {
         alignSelf: 'center',
         padding: '0',
      },
   },
   badgeMargin: {
      marginLeft: tokens.spacingHorizontalS,
   },
   phoneTextWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: tokens.spacingVerticalM,
      justifyContent: 'space-between',
   },
   phoneTextSpacer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      minWidth: '150px',
      maxWidth: '190px',
      gap: tokens.spacingVerticalXXS,
   },
})

export const CostEvaluationPhoneHeader = ({ costGroup }: { costGroup: IGroup<Cost> }) => {
   const classes = useStyles()
   return (
      <article>
         <div className={classes.topWrapper}>
            <BadgeCostCategory category={costGroup.items[0].post.costCategory} />
            <BadgeAmountCircle amount={costGroup.items.length} className={classes.badgeMargin} />
         </div>
         <div className={classes.phoneTextSpacer}>
            <div className={classes.phoneTextWrapper}>
               <Text size={400} weight="semibold">
                  {t('Budget')}
               </Text>
               <FormattedMoneyOutput value={summarizeFieldValues(costGroup.items, 'budget')} />
            </div>
            <div className={classes.phoneTextWrapper}>
               <Text size={400} weight="semibold">
                  {t('Result')}
               </Text>
               <FormattedMoneyOutput value={summarizeFieldValues(costGroup.items, 'result')} />
            </div>
            <div className={classes.phoneTextWrapper}>
               <Text size={400} weight="semibold">
                  {t('Deviation')}
               </Text>
               <DeviationSummary costs={costGroup.items} />
            </div>
         </div>
      </article>
   )
}
