import React from 'react'
import { t } from '../i18n/i18n'
import { ActionPlanStatus, DemandAnalysisQuestionStatus, Organisation, RiskAnalysisType, RiskManagementModules } from '../api/schemas/schema'
import { Option } from '@fluentui/react-components'
import { IDropdownOption } from '../interfaces/IDropdownOptions'
import { ILanguage } from '../interfaces/ILanguage'
import { TOptions } from 'i18next'
import { isRiskModuleActivated } from './organisationHelper'

export const demandAnalysisQuestionStausString = (status: DemandAnalysisQuestionStatus) => {
   switch (status) {
      case DemandAnalysisQuestionStatus.NotAnswered:
         return t('NotAnswered')
      case DemandAnalysisQuestionStatus.Answered:
         return t('Answered')
      case DemandAnalysisQuestionStatus.Determined:
         return t('Determined')
   }
}

export const actionPlanStatusString = (status: ActionPlanStatus) => {
   switch (status) {
      case ActionPlanStatus.NotDecided:
         return t('NotDecided')
      case ActionPlanStatus.NotToBeAdressed:
         return t('NotToBeAdressed')
      case ActionPlanStatus.Decided:
         return t('Decided')
      // case ActionPlanStatus.Completed:
      //    return t('Completed')
      // case ActionPlanStatus.NotStarted:
      //    return t('NotStarted')
      // case ActionPlanStatus.Ongoing:
      //    return t('Ongoing')
      // case ActionPlanStatus.Determined:
      //    return t('Determined')
   }
}
export const getRiskAnalysisTypeString = (value: RiskAnalysisType): string => {
   switch (value) {
      case RiskAnalysisType.SWOT:
         return t('SWOTAnalysis')
      case RiskAnalysisType.SimplifiedRiskAnalysis:
         return t('SimplifiedRiskAnalysis')
      case RiskAnalysisType.ConsequenceAndProbabilityAnalysis:
         return t('ConsequenceAndProbabilityAnalysis')
   }
}
export const getOptionsFromEnumValues = (organisation: Organisation) => {
   const options: JSX.Element[] = []
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   for (const [key, value] of Object.entries(RiskAnalysisType)
      .filter(([key, value]) => typeof value === 'number')
      .sort(([aKey, aValue], [bKey, bValue]) =>
         getRiskAnalysisTypeString(aValue as RiskAnalysisType).localeCompare(getRiskAnalysisTypeString(bValue as RiskAnalysisType))
      )) {
      // When subscriptions are correctly implemented we should be able to remove the "isSubscriptionLevel" check and only check if the module is activated
      if (value === RiskAnalysisType.SimplifiedRiskAnalysis && !isRiskModuleActivated(organisation, RiskManagementModules.SIMPLIFIED_RISK_ANALYSIS)) {
         continue
      }
      if (
         value === RiskAnalysisType.ConsequenceAndProbabilityAnalysis &&
         !isRiskModuleActivated(organisation, RiskManagementModules.CONSEQUENCE_AND_PROBABILITY_ANALYSIS)
      ) {
         continue
      }

      options.push(<Option value={value.toString()}>{getRiskAnalysisTypeString(value as RiskAnalysisType)}</Option>)
   }

   return options
}

export const enumValuesToOptions = <T extends {}>(enumObj: T): IDropdownOption[] => {
   const options = []
   for (const [key, value] of Object.entries(enumObj)) {
      if (isNaN(Number(value))) {
         options.push({ key: Number(key), text: `${t(value as keyof ILanguage)}` })
      }
   }
   return options
}

export const translateEnumValue = <T extends {}>(enumObj: T, key: number, tOptions?: TOptions): string => {
   const value = Object.entries(enumObj).find((x) => x[1] === key)?.[0]
   return value ? t(value as keyof ILanguage) : ''
}

export const translateEnumValues = <T extends {}>(enumObj: T): string[] => {
   const translatedValues: string[] = []
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   for (const [key, value] of Object.entries(enumObj)) {
      if (isNaN(Number(value))) {
         translatedValues.push(`${t(value as keyof ILanguage)}`)
      }
   }
   return translatedValues
}

export const isValidEnumValue = <T extends {}>(enumObj: T, text: string) => {
   if (translateEnumValues(enumObj).find((c) => c === text)) return true
   return false
}
