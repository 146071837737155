import { ILanguage } from '../../interfaces/ILanguage'

export const en: ILanguage = {
   '365Robust': '365Robust',
   Of: 'of',

   // Authentication
   WelcomeTo: 'Welcome to',
   SignIn: 'Sign in',
   SignOut: 'Sign out',
   Dashboard: 'Dashboard',
   NotAuthorized: 'Not authorized',

   // Organisation
   OrganisationalData: 'Organisational data',
   OrganisationNumber: 'Organisation number',
   CompanyName: 'Company name',
   OrganisationSize: 'Organisation size',
   OrganisationType: 'Organisation type',
   SNI: 'SNI',
   All: 'All',
   OrganisationDetails: 'Organisation details',
   InvoiceDetails: 'Invoice details',
   Invocing: 'Invoicing',

   // User
   AccountInformation: 'Account information',
   Account: 'Account',
   FirstName: 'First name',
   LastName: 'Last name',
   Email: 'E-mail',
   OrganizationalAffiliation: 'Organizational affiliation',
   JobTitle: 'Job title',
   UserType: 'User type',
   Roles: 'Roles',

   // Button
   Next: 'Next',
   Add: 'Add',
   Finish: 'Finish',
   Cancel: 'Cancel',
   Continue: 'Continue',
   Save: 'Save',
   Create: 'Create',
   Update: 'Update',
   Delete: 'Delete',
   ReadMore: 'Read more',
   Close: 'Close',
   Complete: 'Complete',
   Determine: 'Determine',
   Back: 'Back',
   ExportToExcel: 'Export to excel',

   // Roles
   ProcessOwner: 'Process owner',
   Owner: 'Owner',
   RiskOwner: 'Risk owner',
   AnalysisLeader: 'Analysis leader',

   // Billing
   BillingData: 'Billing data',
   InvoiceReference: 'Invoice reference',
   InvoiceAddress: 'Invoice address',
   PostalCode: 'Postal code',
   County: 'County',
   PhoneNumber: 'Phone number',

   // Branch
   Branch: 'Branch',
   Branches: 'Branches',
   BranchName: 'Name of branch',
   PropertyDesignation: 'Property designation',
   OrganisationForm: 'Organisation type',
   SNICodes: 'SNI codes',
   BranchDescription: 'Description of branch',
   BranchValuation: 'Branch valuation',
   DateForValuation: 'Date for valuation',
   BranchResponsible: 'Responsible of branch',
   VisitingAdress: 'Visiting address',
   KeyFigures: 'Key figures',

   // StartUpWizard
   CheckAndVerifyOrganisationData: 'Check and verify organisation details',
   CheckAndVerifyUserData: 'Kontrollera och verify user details',
   User: 'User',
   OrganisationAffiliation: 'Organisationaffiliation',

   // ProtectedAssets
   ProtectedAssets: 'Protected assets',
   ProtectedAsset: 'Protected asset',
   Asset: 'Asset',
   'ProtectedAsset/OrganisationalGoal': 'Protected asset/Organisational goal',
   BranchAffiliation: 'Branch affiliation',
   DescriptionOfAsset: 'Description of asset',
   Description: 'Description',
   ProtectedAssetValuation: 'Asset valuation',
   AddedAssets: 'Added assets',
   NewDemandAssessment: 'New demand assessment',
   OngoingDemandAssessments: 'Ongoing demand assessments',
   ConcludedDemandAssessments: 'Concluded demand assessments',
   AccessAffectsFollowingProcesses: 'Access affects the following processes',
   MotivationAndStand: 'Motivation and stand',
   TimeToCreateYourFirstItem: 'Time to create your first {{Item}}',
   NoConcludedItems: 'There are no completed {{Item}}',
   OngoingItem: 'Ongoing {{Item}}',
   ConcludedItem: 'Completed {{Item}}',
   NewItem: 'New {{Item}}',
   EditItem: 'Edit {{Item}}',
   DeleteItem: 'Delete {{Item}}',
   NameOfItem: 'Name of {{Item}}',
   DescriptionOfItem: 'Description of {{Item}}',
   ValuationOfItem: 'Valuation of {{Item}}',
   Valuation: 'Valuation',
   ValuationDate: 'Valuation date',
   Name: 'Name',

   // Organisational goals
   NewOrganisationalGoal: 'New organisational goal',
   OrganisationalGoals: 'Organisational goals',
   OrganisationalGoal: 'Organisaaional goal',
   TheGoal: 'The Goal',

   // ComplianceOfDemands
   ComplianceOfDemands: 'Compliance of demands',
   LeadershipPerspective: 'Leadership perspective',
   Subject: 'Subject',
   DataClassification: 'Data classification',
   StartOfAssessment: 'Start of assessment',
   EndOfAssessment: 'End of assessment',
   Comment: 'Comment',
   CreateAssessment: 'Create assessment',
   Process: 'Process',
   ProcessAffiliation: 'Process affiliation',
   CreatingDemandAssessment: 'Creating demand assessment...',
   DemandAssessmentCreated: 'The demand assessment is created',
   ThisCanTakeAMoment: 'This can take a moment. Please wait and keep the device on.',
   ClickContinueToGoToAssessment: "Click 'Continue' to open your assessment",
   Information: 'Information',
   DemandAnalysis: 'Demand analysis',
   ActionPlan: 'Action plan',
   DemandAssessment: 'Demand assessment',
   BranchScope: 'Branch scope',
   CompleteDemandAssessment: 'Complete demand assessment',
   DeleteDemandAssessment: 'Delete demand assessment',
   CompleteDemandAssessmentConfirmationMessage:
      'Are you sure you want to complete the demand assessment? The action cannot be undone when it has been completed.',
   DeleteDemandAssessmentConfirmationMessage: 'Are you sure you want to delete the demand assessment? The action cannot be undone when it has been completed.',
   AllAnswered: 'Answered',
   NoConcludedDemandAssessments: 'There are no concluded demand assessments',
   TimeToCreateYourFirstDemandAssessment: 'Time to create your first demand assessment',
   AllProcesses: 'All processes',
   'No.': 'No.',
   Question: 'Question',
   CompleteDemandAnalysisConfirmationText:
      'Are you sure you want to complete demand analysis for {{process}}? You will not be able to update the answers to the questions and action plans will be created',
   EditDemandAssessment: 'Edit demand assessment',
   Edit: 'Edit',
   ProposedAction: 'Proposed action',
   BackgroundInformationForTheQuestion: 'Background information for the question',
   BackgroundInformation: 'Background information',
   TipFrom365Robust: '365Robust tips',
   OriginDemandForTheQuestion: 'Origin demand for the question',
   OriginDemand: 'Origin demand',
   Source: 'Source',
   DemandAssessmentCompleted: 'Demand assessment successfully completed',
   CouldNotCompleteDemandAssessment: 'Could not complete the demand assessment',
   CouldNotUpdateDemandAssessment: 'Could not update demand assessment',
   AssessmentName: 'Name of assessment',
   IncludeExtendedLevel: 'Include extended level',
   NoAvailableInformation: 'There is no information available at this time',
   EstimatedCost: 'Estimated cost for action',
   EstimatedCostShort: 'Estimated cost',
   TotalEstimatedCost: 'Total estimated cost',
   ProcessOwnersForDemandAssessment: 'Process owners for demand assessment',
   ProcessOwnerForDemandAssessmentText:
      'Select an existing user or enter a custom name. Active users will be able to answer questions. Names without associated users will only be answerable by administrators.',
   ExtendedLevel: 'Extended level',
   NotIncluded: 'Not included',
   Included: 'Included',
   ProcessWithProcessowner: 'Process with processowner',

   // Report
   Report: 'Report',
   AnsweredQuestionReport: 'Answered questions - Report',
   AnsweredQuestions: 'Answered questions',
   TotalNumber: 'Total number',
   NumberOfAnswered: 'Number of answered',
   Chart: 'Chart',
   List: 'List',

   // Actionplans
   NoActionPlansCreatedForDemandAssessment: 'The demand analysis must be determined per process before an action plan is created for the process',
   NoActionPlansCreatedForSWOT: 'A risk management plan will be created when the SWOT analysis is determined.',
   NoActionPlansCreatedForRiskAnalysis: 'A risk management plan will be created when the riskanalysis is determined.',
   NoActionPlansRequiredForThisItem: 'No actions required for this {{Item}}.',
   DemandAssessmentCanBeCompleted: 'The demand assessment can be completed',
   RiskAssessmentCanBeCompleted: 'The risk assessment can be completed',
   ComplianceLevel: 'Level of compliance',
   DecisionOnAction: 'Decision on action',
   NoActionShallBeTaken: 'No actions will be taken',
   ResponsibleOfAction: 'Responsible',
   Priority: 'Priority',
   ID: 'ID',
   AnsweredBy: 'Answered by',
   StartAction: 'Start action',
   CompleteAction: 'Complete action',
   ActionDecidedBy: 'Action decided by',
   NoActionsTaken: 'No actions taken',
   ImplementedActions: 'Implemented actions',
   ComplianceLevelAfterCompletedAction: 'Compliance level after implementing action',
   SpecifyCompliance: 'Specify compliance',
   CalculatedOutcome: 'Calculated outcome',
   DecisionStatus: 'Status',
   DecidedMulti: 'Decided',
   TotalNumberOfActions: 'Total number of actions',
   Deadline: 'Deadline',
   PerProcessAndComplianceLevel: 'Per process & compliance level',
   PerComplianceLevel: 'Per compliance level',
   ActionPlanReport: 'Action plan - Report',
   NumberOfActions: 'Number of items',
   AnalysedBy: 'Analysed by',
   ProposedBy: 'Proposed by',

   // Status
   RequiredField: 'This field is required',
   SomethingWentWrong: 'Something went wrong',
   NoMatchingQuestonsFound: 'No matching questions found',
   FetchFailed: 'Could not retrieve data',
   ModifiedBy: 'Modified by',
   Modified: 'Modified',
   CreatedBy: 'Created by',
   CreatedDate: 'Created',
   ItemSuccessfullyCreated: '{{Item}} successfully created',
   ItemSuccessfullySaved: '{{Item}} successfully saved',
   ItemSuccessfullyUpdated: '{{Item}} successfully updated',
   ItemSuccessfullyDeleted: '{{Item}} successfully deleted',
   CouldNotFetchItem: '{{Item}} could not be fetched',
   CouldNotCreateItem: '{{Item}} could not be created',
   CouldNotUpdateItem: '{{Item}} could not be updated',
   CouldNotDeleteItem: '{{Item}} could not be deleted',
   CouldNotFetchData: 'Data could not be fetched',
   Status: 'Status',
   NoDateSet: 'No date set',
   NoValueSet: 'No value set',
   ValidationFailed: 'Validation failed',
   DemandAnalysisCompleted: 'Demand analysis completed',
   CouldNotCompleteDemandAnalysis: 'Something went wrong, could not complete demand analysis',
   NotAnswered: 'Not answered',
   Answered: 'Answered',
   Determined: 'Determined',
   NotDecided: 'Not decided',
   Decided: 'Decided',
   NotStarted: 'Not started',
   Started: 'Started',
   Completed: 'Completed',
   NotToBeAdressed: 'Not to be adressed',
   ErrorMessage: 'Error message',
   NoDataCreatedYet: 'No data created yet.',
   Ongoing: 'Ongoing',
   Concluded: 'Concluded',
   EndDateMustBeAfterStartDate: 'End date must be after start date.',
   EndDateMustBeAfterOrTheSameAsStartDate: 'End date must be after or the same as start date.',
   AnAccountMustBeSelected: 'An account must be selected',

   // Riskmanagement:
   CreateAssesment: 'Create assesment',
   RiskAssesment: 'Risk assesment',
   RiskAnalysis: 'Risk analysis',
   RiskManagement: 'Risk management',
   NewRiskAssesment: 'New risk assesment',
   TypeOfRiskAssesment: 'Type of risk analysis',
   NameOfAssesment: 'Name of the assesment',
   'Purpose/Scope': 'Purpose/Scope',
   Limitations: 'Limitations',
   StartOfAssesment: 'Start of assesment',
   EndOfAssesment: 'End of assesment',
   SimplifiedRiskAnalysis: 'Simplified risk analysis',
   ConsequenceAndProbabilityAnalysis: 'Consequence & probability analysis',
   SimplifiedConsequenceProbabilityAnalysis: 'Simplified consequence and probablity analysis',
   SWOTAnalysis: 'SWOT analysis',
   Type: 'Type',
   NoConcludedRiskAssesments: 'There are no completed risk assesments',
   TimeToCreateYourFirstRiskAssesment: 'Time to create your first risk assesment',
   OngoingRiskAssesments: 'Ongoing risk assesments',
   ConcludedRiskAssesments: 'Completed risk assesments',
   CreatingRiskAssesment: 'Creating risk assesment',
   RiskAssesmentCreated: 'The risk assesment has been created',
   RiskIdentifying: '*Risk identifying',
   'RiskAnalysis&Evaluation': 'Risk analysis & evaluation',
   AnalyseEvaluate: 'Analyse & evaluate',
   Identify: 'Identify',
   RiskManagementPlan: 'Risk management plan',
   NewRisk: 'New risk',
   EditRisk: 'Edit risk',
   RiskScenario: 'Risk scenario',
   RiskSource: 'Risk source',
   IdentifyRisk: 'Identify risk',
   WeaknessesOrCurrentProtection: 'Weaknesses or current protection ',
   UnderlyingMotivationAndStand: 'Underlying motivation and stand',
   Consequence: 'Consequence',
   NoRisksIdentifiedYet: 'No risks identified yet',
   Risk: 'Risk',
   Title: 'Title',
   CompleteRiskAssessment: 'Complete risk assessment',
   DeleteRiskAssessment: 'Delete riskbedömning',
   CompleteRiskAssessmentConfirmationMessage: 'Are you sure you want to complete the risk assessment? The action cannot be undone when it has been completed.',
   DeleteRiskAssessmentConfirmationMessage: 'Are you sure you want to delete the risk assessment? The action cannot be undone when it has been completed.',
   RiskAssessmentCompleted: 'Risk assessment succesfully completed',
   CouldNotCompleteRiskAssessment: 'Could not complete the risk assessment',
   DetermineRiskAssesmentConfirmationText: 'Are you sure you want to determine the analysis? The action cannot be undone when it has been completed.',
   DetermineAnalysis: 'Determine analysis',
   EditRiskAssesment: 'Edit risk assesment',
   RiskCriteria: 'Risk criteria',
   NotAssessed: 'Not assessed',
   Unacceptable: 'Unacceptable risk',
   Questionable: 'Questionable risk',
   Acceptable: 'Acceptable risk',
   BigRiskShouldBeAddressed: 'Big risk, must be addressed',
   UncertaintyAboutRiskShouldBeAddressed: 'Uncertainty about the risk, should be addressed',
   SmallRiskShouldNotBeAddressed: 'Small risk, not addressed',
   RiskLevel: 'Risk level',
   AnalyseRisk: 'Analyse risk',

   // SWOT
   Strengths: 'Strengths',
   Weaknesses: 'Weaknesses',
   Opportunities: 'Opportunities',
   Threats: 'Threats',
   Strength: 'Strength',
   Weakness: 'Weakness',
   Opportunity: 'Opportunity',
   Threat: 'Threat',
   SWOT: 'SWOT',
   TimeToAddYourFirstSWOT: 'Time to add your first SWOT',
   Order: 'Order',
   ChangeOrder: 'Ändra ordning',
   ActionProposal: 'Action proposal',
   CreateProposedAction: 'Create action proposal',
   of: 'of',
   ValueInOrderOfImportance: 'Value in order of importance',
   NoSWOTMatchingSelectedFilter: 'No SWOTs matching selected filter',
   Graph: 'Graph',
   NoTypeIdentified: 'No {{type}} are identified',

   // Consequece and probability analysis
   ThreatScenario: 'Threat scenario',
   RiskEvaluation: 'Risk evaluation',
   NewThreat: 'New threat',
   EditThreat: 'Edit threat',
   CurrentThreat: 'Current threat',
   AnalyseDate: 'Analyse date',
   NoThreatsIdentifiedYet: 'No threats identified yet',
   SpecifyCurrentThreat: 'Specify current threat',
   VeryHigh: 'Very high risk',
   High: 'High risk',
   Moderate: 'Moderate risk',
   Low: 'Low risk',
   VeryLow: 'Very low risk',
   Probable: 'Probable',
   Likely: 'Likely',
   Possible: 'Possible',
   LessLikely: 'LessLikely',
   Unlikely: 'Unlikely',
   Severe: 'Severe',
   Serious: 'Serious',
   Significant: 'Significant',
   Mild: 'Mild',
   Negligible: 'Negligible',
   ConsequenceLifeAndHealth: 'Consequence life and health',
   ConsequenceEconomy: 'Consequence economy',
   ConsequenceEnvironment: 'Consequence environment',
   ConsequenceBrand: 'Consequence brand',
   Probability: 'Probability',
   ProbabilityScale: 'Probability scale',
   ProbabilityScaleInfo:
      'The probability scale consists of five levels and is adapted to the criteria of the Swedish Civil Contingencies Agency (MSB) and the Swedish Security Service.',
   ConsequenceLifeAndHealthScale: 'Consequence scale - Life & Health',
   ConsequenceLifeAndHealthScaleInfo:
      'The consequence for life and health can be assessed from two perspectives. Either as a disruption event affecting the entire organization, or as an event where one or more individuals are severely impacted, making it difficult for the organization to achieve its goals. This forms the basis for risk assessment according to this method. If you are assessing, for example, workplace injuries, the scale should include other types of damages, such as wear and tear, noise, vibrations, social unrest, and so on.',
   ConsequenceEconomyScale: 'Consequence scale - Economy',
   ConsequenceEconomyScaleInfo:
      "The economic consequence is measured in monetary terms and is based on the organization's budgeted revenue for the current year. The scale does not take into account financially strong owners or an unusually large cash reserve, but assumes a sound business economy.",
   ConsequenceEnvironmentScale: 'Consequence scale - Environment',
   ConsequenceEnvironmentScaleInfo:
      'When assessing environmental impact, the consequence scale is based on the need for remediation and the extent of damage to soil, water, and air. However, it is recommended to use the same scale as for the economy, which corresponds to the costs of remediation and the extent of the damage. Consider any potential insurance compensation when making the assessment.',
   ConsequenceBrandScale: 'Consequence scale - Brand',
   ConsequenceBrandScaleInfo:
      'The consequence assessment for the brand is based on how the brand is affected in terms of severity, the extent of negative publicity, customer trust, financial impact, and the ability to recover.',

   VeryHighDescription: 'May occur one or more times per year.',
   HighDescription: 'May occur within 1 to 5 years.',
   ModerateDescription: 'May occur within 5 to 10 years.',
   LowDescription: 'May occur within 10 to 50 years.',
   VeryLowDescription: 'May occur less frequently than once every 50 years.',

   OfEstimatedRevenue: 'of estimated revenue',

   ProbableLifeHealthDescription: 'Multiple fatalities and/or severe injuries',
   LikelyLifeHealthDescription: 'Injury requiring advanced medical care. Fatalities may occur',
   PossibleLifeHealthDescription: 'Injury requiring medical care',
   LessLikelyLifeHealthDescription: 'Injury requiring basic care',
   UnlikelyLifeHealthDescription: 'Injury not requiring medical care',

   ProbableEnvironmentDescription: 'Severe remediation, large spread > 240,000,000 SEK',
   LikelyEnvironmentDescription: 'Severe remediation, limited spread < 120,000,000 SEK',
   PossibleEnvironmentDescription: 'Simple remediation, large spread < 60,000,000 SEK',
   LessLikelyEnvironmentDescription: 'Simple remediation, limited spread < 24,000,000 SEK',
   UnlikelyEnvironmentDescription: 'No remediation, limited spread < 12,000,000 SEK',

   ProbableBrandDescription: 'Permanently damaged brand, extensive negative media attention, significant financial losses, threat to business survival.',
   LikelyBrandDescription: 'Risk of losing multiple key customers, serious impact on the brand, long-term effects on customer relationships.',
   PossibleBrandDescription: 'Repeated negative media attention, risk of losing a single major customer and several minor customers.',
   LessLikelyBrandDescription: 'Poor reputation perceived by several customers, national media attention, risk of losing a single minor customer.',
   UnlikelyBrandDescription: 'Temporary poor reputation, brief local media attention. No significant financial impact.',

   // Calendar
   AnnualCalendar: 'Annual calendar',
   Activities: 'Activities',
   Activity: 'Activity',
   AreYouSureYouWantToDeleteActivity: 'Are you sure you want to delete the activity?',
   DeleteActivity: 'Delete activity',
   EndDate: 'End date',
   EditActivity: 'Edit activity',
   PlanActivity: ' Plan new activity',
   PlanActivitesMsg: 'Plan acitivities to see activities in the calendar',
   PlanActivitesPhoneViewMsg: 'Plan activities to see activities in the list',
   StartDate: 'Start date',
   To: 'To',

   // Settings
   Settings: 'Settings',
   SettingsAndRegister: 'Settings and register',

   // Risk economy
   RiskEconomy: 'Risc economy',
   FinancialYear: 'Financial year',
   TheFinancialYear: 'The financial year',
   NewFinancialYear: 'New financial year',
   TimeToCreateYourFirstFinancialYear: 'Time to create your first financial year',
   Period: 'Period',
   BudgetedRevenue: 'Budgeted revenue',
   IdentifyCosts: 'Identify costs',
   Budget: 'Budget',
   Result: 'Result',
   Cost: 'Cost',
   Evaluation: 'Evaluation',
   DamageCosts: 'Damage costs',
   PreventiveCosts: 'Preventive costs',
   InsuranceCosts: 'Insurance costs',
   SecurityAdministration: 'Security administration',
   AllCosts: 'All costs',
   Category: 'Category',
   Post: 'Post',
   NumberOfPosts: 'Number of posts',
   SeePosts: 'See posts',
   AssociatedPosts: 'Associated posts',
   'OriginPost/-s': 'Origin post/-s',
   TotalItem: 'Total {{Item}}',
   Deviation: 'Deviation',
   SummaryDeviation: 'Summary deviation',
   DeleteItemConfirmationMessage: 'Are you sure you want to delete the {{Item}}? The action cannot be undone when it has been completed.',
   FilterByCategory: 'Filter by category',
   FilterOnBudgetOrResult: 'Filter on budget or result',
   Date: 'Date',
   TotalNumberOfPosts: 'Total number of posts',
   TotalBudget: 'Total budget',
   TotalResult: 'Total result',
   TimeToAddYourFirstCost: 'Time to add your first cost',
   Costs: 'Costs',
   AddCost: 'Add cost',
   EditCost: 'Edit cost',
   Action: 'Action',
   CompleteFinancialYear: 'Complete financial year',
   CompleteFinancialYearConfirmationMessage: 'Are you sure you want to complete the financial year? The action cannot be undone when it has been completed.',
   DeleteFinancialYearConfirmationMessage: 'Are you sure you want to delete the financial year? The action cannot be undone when it has been completed.',
   FinancialYearCompleted: 'Financial year succesfully completed',
   CouldNotCompleteFinancialYear: 'Could not complete the financial year',
   CreatingFinancialYear: 'Creating financial year',
   FinancialYearCreated: 'The financial year has been created',
   ClickContinueToGoToFinancialYear: "Click 'Continue' to open your financial year",

   // Date picker
   January: 'January',
   February: 'February',
   March: 'March',
   April: 'April',
   May: 'May',
   June: 'June',
   July: 'July',
   August: 'August',
   September: 'September',
   October: 'October',
   November: 'November',
   December: 'December',

   Jan: 'Jan',
   Feb: 'Feb',
   Mar: 'Mar',
   Apr: 'Apr',
   MayShort: 'May',
   Jun: 'Jun',
   Jul: 'Jul',
   Aug: 'Aug',
   Sep: 'Sep',
   Oct: 'Oct',
   Nov: 'Nov',
   Dec: 'Dec',

   Monday: 'Monday',
   Tuesday: 'Tuesday',
   Wednesday: 'Wednesday',
   Thursday: 'Thursday',
   Friday: 'Friday',
   Saturday: 'Saturday',
   Sunday: 'Sunday',

   MondayShort: 'M',
   TuesdayShort: 'T',
   WednesdayShort: 'W',
   ThursdayShort: 'T',
   FridayShort: 'F',
   SaturdayShort: 'S',
   SundayShort: 'S',
   GoToToday: 'Go to today',

   // Data classification
   OpenInformation: 'Open information',
   InternalInformation: 'Internal information',
   SecretInformation: 'Secret information',
   QualifiedSecretInformation: 'Qualified secret information',

   // Switch organisation
   SwitchOrganisation: 'Switch organisation',
   SelectOrganisation: 'Select organisation',
   CouldNotSwitchOrganisation: 'Could not switch organisation',

   // Subscription
   Subscription: 'Subscription',
   UpgradeSubscription: 'Upgrade subscription',
   ChangeSubscription: 'Change subscription',
   SystemAdmin: 'System admin',
   AdditionalModules: 'Additional modules',
   SubscriptionUpgradeRequiredForPurchaseOfAdditionalModules: 'Subscription upgrade required for purchase of additional modules',
   EnableOrDisableAvailableAdditionalModules: 'Enable or disable available additional modules',
   PurchaseAdditionalModule: 'Purchase additional module',
   RobustMini: '365Robust Mini',
   RobustBase: '365Robust Base',
   RobustBig: '365Robust Big',
   SeparateModules: 'Separate modules',

   // Demand compliance modules
   WORK_ENVIRONMENT: 'Work environment',
   GDPR: 'GDPR',
   ISO27001: 'ISO 27 001',
   ISO28000: 'ISO 28 000',
   ISO31000: 'ISo 31 000',
   ISO45000: 'ISO 45 000',
   SSF_CYBER_SECURITY: 'SSF Cyber security',
   SYSTEMATIC_FIRE_PROTECTION_WORK: 'Systematic fire protection work',
   SECURITY_PROTECTION: 'Security protection',
   EXTENDED_LEVEL: 'Extended level',

   // Risk management modules
   THREAT_CATALOG: 'Threat catalog',
   SIMPLIFIED_RISK_ANALYSIS: 'Simplified risk analysis',
   CONSEQUENCE_AND_PROBABILITY_ANALYSIS: 'Consequence and probability analysis',
   SIMPLIFIED_WORK_ENVIROMENT_ANALYSIS: 'Simplified workenviroment analysis',
   SIMPLIFIED_BUSINESS_IMPACT_ANALYSIS: 'Simplified business impact analysis',
   RISK_AND_VULNERABILITY_ANALYSIS: 'Risk and vulnerability analysis',
   SIMPLIFIED_SECURITY_ANALYSIS: 'Simplified security analysis',
   SECURITY_ANALYSIS: 'Security analysis',
   FAILURE_MODE_AND_EFFECT_ANALYSIS: 'Failure mode and effect analysis',
   HAZARD_AND_OPERABILITY_ANALYSIS: 'Hazard and operability ',

   // General modules
   WORD_LIST: 'Word list',
   INCIDENT_REPORT: 'Incident report',
   WHISTLE_BLOWER: 'Whistle blower',
   INSURANCE_REGISTER: 'Insurance register',
   PHYSICAL_PLANNING: 'Physical planning',
   SECURITY_SYSTEM: 'Security system',
   ROBUSTER: 'Robuster',
   CORPORATE_GROUP: 'Corporate group',
   CONTRACT_REGISTER: 'Contract register',
   SELF_AUDIT: 'Self audit',
   SECURITY_ADMINISTRATION: 'Security administration',
   PERSONNEL_ANALYSIS: 'Personnel analysis',
   EXPORT_MODULE: 'Export module',
   IMPORT_MODULE: 'Import module',
   STATISTICS: 'Statistics',
   SAFETY_GUIDE: 'Safety guide',

   // Dashboard
   Overview: 'Overview',
   OverviewData: 'Overview data',
   DoADemandAssessment: 'Do a demand assessment!',
   DoADemandAssessmentDescription: 'Are you complying with all the requirements placed on your business? Find out through a requirements assessment!',
   CreateDemandAssessment: 'Create demand assessment',

   PlanYourCalendar: 'Plan your calendar',
   PlanYourCalendarDescription: 'When should the next requirements assessment be done and when is it time for a risk assessment? Start your planning now!',
   CreateActivity: 'Create activity',

   DoARiskAssessment: 'Do a risk assessment!',
   DoARiskAssessmentDescription: 'Identify your biggest risks through a risk assessment.',
   CreateRiskAssessment: 'Create risk assessment',

   CreateNewFinancialYear: 'Create new financial year',
   CreateNewFinancialYearDescription:
      'The risk economics module lacks a fiscal year and identified costs to produce a diagram here. Get started with risk economics right away!',
   CreateFinancialYear: 'Create financial year',
}
