import React from 'react'
import {
   DataGrid,
   DataGridHeader,
   DataGridRow,
   DataGridHeaderCell,
   DataGridBody,
   DataGridCell,
   shorthands,
   tokens,
   makeStyles,
   TableColumnDefinition,
   mergeClasses,
   TableColumnSizingOptions,
   Divider,
} from '@fluentui/react-components'
import { Title } from '../text/Title'
import { PhoneList } from './PhoneList'

const useStyles = makeStyles({
   content: {
      ...shorthands.padding(tokens.spacingVerticalL, 0, tokens.spacingHorizontalXXL, 0),
   },
   header: {
      ...shorthands.margin(tokens.spacingVerticalS, 0),
   },
   divider: {
      marginBottom: tokens.spacingVerticalXXXL,
   },
   pointer: {
      cursor: 'pointer',
   },
   bold: { '& > *': { fontWeight: tokens.fontWeightBold } },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
})

type ListProps<T extends {}> = {
   columns: TableColumnDefinition<T>[]
   items: T[]
   renderPhoneBodyItem: (item: T, index: number) => JSX.Element
   onRowClick?: (item: T) => void
   onCellClick?: (item: T, columnKey: keyof T) => void
   title?: string
   className?: string
   resizableColumns?: boolean
   columnSizingOptions?: TableColumnSizingOptions
   sortable?: boolean
}
export const List = <T extends {}>({
   columns,
   items,
   renderPhoneBodyItem,
   onRowClick,
   onCellClick,
   title,
   className,
   resizableColumns,
   columnSizingOptions,
   sortable,
}: ListProps<T>) => {
   const classes = useStyles()
   return (
      <>
         <section className={mergeClasses(classes.content, classes.hideInPhone, className ?? '')}>
            {title && (
               <>
                  <header className={classes.header}>
                     <Title as="h3">{title}</Title>
                  </header>
                  <Divider className={classes.divider} appearance="brand" />
               </>
            )}

            <DataGrid
               sortable={sortable ?? false}
               items={items}
               columns={columns}
               resizableColumns={resizableColumns ?? false}
               columnSizingOptions={columnSizingOptions}
            >
               <DataGridHeader>
                  <DataGridRow className={classes.bold}>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
               </DataGridHeader>
               <DataGridBody<T>>
                  {({ item, rowId }) => (
                     <DataGridRow<T>
                        key={rowId}
                        className={onRowClick ? classes.pointer : ''}
                        onClick={() => (onCellClick ? undefined : onRowClick && onRowClick(item))}
                     >
                        {({ renderCell, columnId }) => (
                           <DataGridCell onClick={onCellClick ? () => onCellClick(item, columnId as keyof T) : undefined}>{renderCell(item)}</DataGridCell>
                        )}
                     </DataGridRow>
                  )}
               </DataGridBody>
            </DataGrid>
         </section>
         <PhoneList items={items} onRowClick={onRowClick} renderBodyItem={renderPhoneBodyItem} />
      </>
   )
}
