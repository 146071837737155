import React, { useEffect, useState } from 'react'
import { Button, makeStyles, mergeClasses, TableCell, TableRow, tokens } from '@fluentui/react-components'
import { ChevronDown24Regular, ChevronRight24Regular } from '@fluentui/react-icons'
import { IGroup } from '../../../interfaces/IGroup'
import { IGroupedTableColumnDefintion } from '../../../interfaces/IGroupedTableColumnDefintion'
import { Cost } from '../../../api/schemas/schema'

const useStyles = makeStyles({
   groupRow: {
      height: '75px',
   },
   itemRow: {
      cursor: 'pointer',
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   onlyInPhone: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'table-row',
      },
   },
   phoneCell: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'table-cell',
         padding: `${tokens.spacingHorizontalL} 0`,
      },
   },
   phoneHeader: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'table-cell',
      },
   },
   phoneItemCell: {
      padding: `${tokens.spacingHorizontalS} 0 ${tokens.spacingHorizontalS} 0`,
   },
   autoWidth: {
      '@media screen and (max-width: 640px)': {
         width: 'auto !important',
      },
   },
})

type CostEvaluationListRowProps = {
   group: IGroup<Cost>
   columns: IGroupedTableColumnDefintion<Cost>[]
   allExpanded: boolean
   chevronCellClass: string
   onCellClick?: (item: Cost, columnKey: keyof Cost) => void
   renderPhoneHeader: (item: IGroup<Cost>) => JSX.Element
   renderPhoneRow: (item: Cost) => JSX.Element
}

export const CostEvaluationListRow = ({
   group,
   columns,
   allExpanded,
   chevronCellClass,
   onCellClick,
   renderPhoneHeader,
   renderPhoneRow,
}: CostEvaluationListRowProps) => {
   const [expanded, setExpanded] = useState<boolean>(false)
   const classes = useStyles()
   useEffect(() => {
      if (allExpanded) {
         setExpanded(true)
      } else {
         setExpanded(false)
      }
   }, [allExpanded])

   return (
      <>
         <TableRow key={`group-${group.label}`} className={classes.groupRow}>
            <TableCell className={chevronCellClass}>
               <Button
                  size="large"
                  aria-label={`expand-${group.label}`}
                  appearance="transparent"
                  icon={expanded ? <ChevronDown24Regular /> : <ChevronRight24Regular />}
                  onClick={() => setExpanded((prev) => !prev)}
               />
            </TableCell>
            {columns.map((column) => (
               <TableCell
                  style={{ width: column.colWidth, textAlign: column.align }}
                  key={column.columnId}
                  className={mergeClasses(classes.itemRow, classes.hideInPhone, classes.autoWidth)}
               >
                  {column.renderGroupHeaderCell(group)}
               </TableCell>
            ))}

            <TableCell className={classes.phoneCell} colSpan={columns.length + 1}>
               {renderPhoneHeader(group)}
            </TableCell>
         </TableRow>
         {expanded
            ? group.items.map((item, i) => (
                 <>
                    <TableRow key={i} className={mergeClasses(classes.itemRow, classes.hideInPhone)}>
                       <TableCell />
                       {columns.map((column) => (
                          <TableCell
                             onClick={onCellClick ? () => onCellClick(item, column.columnId as keyof Cost) : undefined}
                             style={{ width: column.colWidth }}
                             key={column.columnId}
                             className={classes.autoWidth}
                          >
                             {column.renderCell(item)}
                          </TableCell>
                       ))}
                    </TableRow>
                    <TableRow key={`${i}-phonerow`} className={mergeClasses(classes.itemRow, classes.onlyInPhone)}>
                       <TableCell className={classes.phoneItemCell} colSpan={columns.length + 2}>
                          {renderPhoneRow(item)}
                       </TableCell>
                    </TableRow>
                 </>
              ))
            : null}
      </>
   )
}
