import React, { useState } from 'react'
import {
   Button,
   Dialog,
   DialogContent,
   DialogTitle,
   DialogTrigger,
   Divider,
   Dropdown,
   makeStyles,
   Tab,
   TabList,
   Option,
   tokens,
} from '@fluentui/react-components'
import { Dismiss24Regular, Status24Regular } from '@fluentui/react-icons'
import { DialogSurface, DialogBody } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { ActionPlan, DemandAnalysisQuestion, DemandAnalysisQuestionStatus } from '../../../api/schemas/schema'
import { ActionPlanComplianceChart } from './modalContent/ActionPlanComplianceChart'
import { ActionPlanProcessChart } from './modalContent/ActionPlanProcessChart'
const enum ReportTabs {
   COMPLIANCE,
   PROCESS,
}

const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${tokens.spacingVerticalL} 0`,
      '@media screen and (max-width: 1007px)': {
         position: 'sticky',
         left: 0,
         flexDirection: 'column',
         alignItems: 'start',
      },
   },
   dialogWidth: {
      width: 'auto',
      maxWidth: '1400px',
   },
   noneInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   dropdown: {
      width: '300px',
      marginLeft: tokens.spacingHorizontalL,
   },
})
type ModalActionPlanReportProps = {
   actionPlans: ActionPlan[]
   questions: DemandAnalysisQuestion[]
}
export const ModalActionPlanReport = ({ actionPlans, questions }: ModalActionPlanReportProps) => {
   const classes = useStyles()
   const [selectedTab, setSelectedTab] = useState<ReportTabs>(ReportTabs.COMPLIANCE)
   const [filteredProcesses, setFilteredProcesses] = useState<string[]>([])
   const [filteredCompliances, setFilteredCompliances] = useState<string[]>([])
   const processes: string[] = []
   let orderedCompliances: any[] = []
   const determinedQuestions = questions.filter((q) => q.status === DemandAnalysisQuestionStatus.Determined)
   determinedQuestions.forEach((question) => {
      const process = question.processes[0]
      const answer = question.answer
      const { text, order } = answer
      if (!orderedCompliances.some((c) => c.text === text)) orderedCompliances.push({ text: text, order })

      if (!processes.includes(process)) processes.push(process)

      orderedCompliances = orderedCompliances.sort((a, b) => a.order - b.order)
   })

   const filterQuestions = (questions: DemandAnalysisQuestion[]): DemandAnalysisQuestion[] => {
      let filteredQuestions: DemandAnalysisQuestion[] = questions
      if (filteredProcesses.length > 0) filteredQuestions = filteredQuestions.filter((q) => filteredProcesses.includes(q.processes[0]))
      if (filteredCompliances.length > 0) filteredQuestions = filteredQuestions.filter((q) => filteredCompliances.includes(q.answer.text))

      return filteredQuestions
   }
   return (
      <Dialog>
         <DialogTrigger>
            <span>
               <Button icon={<Status24Regular />} appearance="primary" className={classes.noneInPhone}>
                  {t('Report')}
               </Button>
            </span>
         </DialogTrigger>
         <DialogSurface className={classes.dialogWidth}>
            <DialogBody>
               <DialogTitle
                  action={
                     <DialogTrigger action="close">
                        <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                     </DialogTrigger>
                  }
               >
                  {t('ActionPlanReport')}
               </DialogTitle>
               <DialogContent>
                  <Divider appearance="brand" className={classes.noneInPhone} />
                  <header className={classes.header}>
                     <TabList selectedValue={selectedTab} onTabSelect={(e, d) => setSelectedTab(d.value as number)}>
                        <Tab value={ReportTabs.COMPLIANCE}>{t('PerComplianceLevel')}</Tab>
                        <Tab value={ReportTabs.PROCESS}>{t('PerProcessAndComplianceLevel')}</Tab>
                     </TabList>
                     {selectedTab === ReportTabs.PROCESS && (
                        <span>
                           <Dropdown
                              className={classes.dropdown}
                              multiselect
                              size="large"
                              placeholder={t('Process')}
                              onOptionSelect={(e, data) => {
                                 setFilteredProcesses(data.selectedOptions)
                              }}
                           >
                              {processes.map((p) => (
                                 <Option key={p}>{p}</Option>
                              ))}
                           </Dropdown>
                           <Dropdown
                              className={classes.dropdown}
                              multiselect
                              size="large"
                              placeholder={t('ComplianceLevel')}
                              onOptionSelect={(e, data) => {
                                 setFilteredCompliances(data.selectedOptions)
                              }}
                           >
                              {orderedCompliances.map((c) => (
                                 <Option key={c.text}>{c.text}</Option>
                              ))}
                           </Dropdown>
                        </span>
                     )}
                  </header>
                  {selectedTab === ReportTabs.COMPLIANCE && <ActionPlanComplianceChart filteredQuestions={determinedQuestions} />}
                  {selectedTab === ReportTabs.PROCESS && <ActionPlanProcessChart filteredQuestions={filterQuestions(determinedQuestions)} />}
               </DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
