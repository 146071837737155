import React, { useContext } from 'react'
import { PersonAccounts24Regular, BuildingPeople24Regular, Premium24Regular } from '@fluentui/react-icons'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { DataContext } from '../../../context/DataContext'
import { SubscriptionLevel } from '../../../api/schemas/schema'

const useStyles = makeStyles({
   alignCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: tokens.spacingHorizontalS,
   },
})

export const SubscriptionIconTitle = () => {
   const dataContext = useContext(DataContext)
   const classes = useStyles()
   const { organisation } = dataContext.state

   return (
      <>
         {organisation.subscription === SubscriptionLevel.MINI ? (
            <div className={classes.alignCenter}>
               <PersonAccounts24Regular />
               <Text size={400} weight="medium">
                  {t('RobustMini')}
               </Text>
            </div>
         ) : organisation.subscription === SubscriptionLevel.BASE ? (
            <div className={classes.alignCenter}>
               <BuildingPeople24Regular />
               <Text size={400} weight="medium">
                  {t('RobustBase')}
               </Text>
            </div>
         ) : organisation.subscription === SubscriptionLevel.BIG ? (
            <div className={classes.alignCenter}>
               <Premium24Regular />
               <Text size={400} weight="medium">
                  {t('RobustBig')}
               </Text>
            </div>
         ) : null}
      </>
   )
}
