import React, { useContext } from 'react'
import { t } from '../../i18n/i18n'
import { GridItem } from '../common/grid/GridItem'
import { ControlledOrgDetailsDropDown } from '../common/inputs/ControlledOrgDetailsDropDown'
import { SniDropdown } from '../common/inputs/SniDropdown'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { UseFormReturn } from 'react-hook-form'
import { Organisation, SNI, SubscriptionLevel } from '../../api/schemas/schema'
import { IDropdownOption } from '../../interfaces/IDropdownOptions'
import { ControlledOrganisationNumberInput } from '../startupWizard/ControlledOrganisationNumberInput'
import { DataContext } from '../../context/DataContext'

type OrganisationDetailsFormProps = {
   organisationForm: UseFormReturn<Organisation, any, undefined>
   orgSizes: IDropdownOption[]
   orgTypes: IDropdownOption[]
   snis: SNI[]
   disabledOrgNumber?: boolean
}

export const OrganisationDetailsForm = ({ organisationForm, orgSizes, orgTypes, snis, disabledOrgNumber }: OrganisationDetailsFormProps) => {
   const { control, setValue } = organisationForm
   const { subscription } = useContext(DataContext)?.state?.organisation ?? {}
   const isMiniSubscription = subscription === SubscriptionLevel.MINI

   return (
      <>
         <GridItem size={'6/12'}>
            <ControlledOrganisationNumberInput
               required
               control={control}
               name={'organisationNumber'}
               label={t('OrganisationNumber')}
               disabled={disabledOrgNumber}
            />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledTextInput required control={control} name={'name'} label={t('CompanyName')} />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledOrgDetailsDropDown
               required
               control={control}
               name={'organisationSize'}
               label={t('OrganisationSize')}
               options={orgSizes}
               setValue={setValue}
               disabled={isMiniSubscription}
            />
         </GridItem>
         <GridItem size={'6/12'}>
            <ControlledOrgDetailsDropDown
               required
               control={control}
               name={'organisationType'}
               label={t('OrganisationType')}
               options={orgTypes}
               setValue={setValue}
            />
         </GridItem>
         <GridItem size={'12/12'}>
            <SniDropdown required organisationForm={organisationForm} label={t('SNI')} items={snis ? snis : []} />
         </GridItem>
      </>
   )
}
