import React, { useEffect, useState } from 'react'
import { Button, Tab, TabList, makeStyles, mergeClasses, Text, tokens, SelectTabData } from '@fluentui/react-components'
import {
   InfoShield20Regular,
   SearchShield20Regular,
   Classification20Regular,
   TaskListSquareLtr20Regular,
   LockClosed24Regular,
   LockClosed20Regular,
   InfoShield20Filled,
   TaskListSquareLtr20Filled,
   Classification20Filled,
   SearchShield20Filled,
} from '@fluentui/react-icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ActionPlan, ActionPlanStatus, RiskAnalysis, RiskAnalysisStatus, RiskStatus } from '../../../api/schemas/schema'
import { RiskIdentifying } from '../RiskIdentifying'
import { Layout } from '../../../pages/Layout'
import { t } from '../../../i18n/i18n'
import { Navigation } from '../../common/navigation/Navigation'
import { RiskInformation } from '../RiskInformation'
import { RiskAnalyseEvaluate } from '../RiskAnalyseEvaluate'
import {
   findSimplifiedRiskParentToActionPlan,
   SimplifiedRiskTabs,
   getSimplifiedRiskActionPlanColumns,
   getSimplifiedRiskActionPlanGroups,
   getSimplifiedRiskActionPlanSummaryFields,
} from '../../../helpers/riskAnalysisHelper'
import { RiskActionPlan } from '../RiskActionPlan'
import { exportSimplifiedRiskActionPlansToExcel } from '../../../helpers/excelHelper'
import { ParentSimplifiedRiskInformation } from './ParentSimplifiedRiskInformation'
import { ModalCompleteRiskAssessment } from '../ModalCompleteRiskAssessment'
import { BadgeActionPlanStatus } from '../../demandAnalysis/BadgeActionPlanStatus'
import { formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { SimplifiedRiskAnalysisNavLinks } from '../../common/navigation/SimplifiedRiskAnalysisNavLinks'
import { IItemState } from '../../../interfaces/IItemState'
const useStyles = makeStyles({
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
   },
   flexOnlyLarge: {
      '@media screen and (max-width: 1007px)': { display: 'none' },
   },
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
   },
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 3,
   },
   phoneItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalM,
   },
})
type SimplifiedRiskAnalysisProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysisState: IItemState<RiskAnalysis>
   analysis: RiskAnalysis
}

export const SimplifiedRiskAnalysis = ({ analysisState, setRiskAnalysisState, analysis }: SimplifiedRiskAnalysisProps) => {
   const classes = useStyles()
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const navigate = useNavigate()
   const [selectedItem, setSelectedItem] = useState<ActionPlan>(null)
   const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false)

   const setTab = (tab: string) => {
      setSearchParams((prev) => ({ ...prev, tab }))
   }

   useEffect(() => {
      if (!selectedTab) {
         setTab('info')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getSimplifiedRiskTitle = (tab: string): string => {
      switch (tab) {
         case SimplifiedRiskTabs.Info:
            return t('Information')
         case SimplifiedRiskTabs.Identifying:
            return t('RiskIdentifying')
         case SimplifiedRiskTabs.Assessment:
            return t('RiskAnalysis&Evaluation')
         case SimplifiedRiskTabs.ActionPlan:
            return t('RiskManagementPlan')
      }
   }

   const simplifiedRiskPhoneBody = (item: ActionPlan) => {
      const risk = findSimplifiedRiskParentToActionPlan(analysis.simplifiedRisks, item)

      return (
         <div className={classes.phoneItem}>
            <span className={classes.spaceBtw}>
               <Text size={400}>{risk.scenario}</Text>
               <BadgeActionPlanStatus status={item.status} />
            </span>
            <Text size={300}>{risk.estimatedCost || risk.estimatedCost === 0 ? formatToSwedishCrowns(risk.estimatedCost) + ' kr' : null}</Text>
            <Text size={300} className={classes.ellipsis}>
               {risk.consequence}
            </Text>
         </div>
      )
   }

   const completeBtnDisabled =
      analysis.simplifiedRisks.length === 0 ||
      analysis.simplifiedRisks.some((r) => r.status === RiskStatus.NotAssessed) ||
      analysis.actionPlans.length === 0 ||
      analysis.actionPlans.some((ap) => ap.status === ActionPlanStatus.NotDecided || analysis.status === RiskAnalysisStatus.Completed)

   return (
      <Layout
         breadCrumbItems={[
            { path: '/risk-management', active: false, title: t('RiskManagement') },
            { path: `/risk-management/${analysis.id}`, active: true, title: analysis.name },
         ]}
      >
         <Navigation
            activeDesktopTab="/risk-management"
            activePhoneTab={`/risk-management/${analysis.id}?tab=${selectedTab}`}
            phoneNavLinks={
               <SimplifiedRiskAnalysisNavLinks
                  id={analysis.id}
                  active={selectedTab}
                  actionTab={<Tab value={'complete'} icon={<LockClosed24Regular />} disabled={completeBtnDisabled} />}
               />
            }
            customOnTabSelect={(e, data: SelectTabData) => {
               if (data.value === 'complete') setCompleteModalOpen(true)
               else navigate(data.value)
            }}
         />

         <header className={mergeClasses(classes.header, classes.flexOnlyLarge)}>
            <TabList selectedValue={selectedTab} onTabSelect={(e, data) => setTab(data.value as string)}>
               <Tab icon={selectedTab === SimplifiedRiskTabs.Info ? <InfoShield20Filled /> : <InfoShield20Regular />} value={SimplifiedRiskTabs.Info}>
                  {getSimplifiedRiskTitle(SimplifiedRiskTabs.Info)}
               </Tab>

               <Tab
                  icon={selectedTab === SimplifiedRiskTabs.Identifying ? <SearchShield20Filled /> : <SearchShield20Regular />}
                  value={SimplifiedRiskTabs.Identifying}
               >
                  {getSimplifiedRiskTitle(SimplifiedRiskTabs.Identifying)}
               </Tab>

               <Tab
                  icon={selectedTab === SimplifiedRiskTabs.Assessment ? <Classification20Filled /> : <Classification20Regular />}
                  value={SimplifiedRiskTabs.Assessment}
               >
                  {getSimplifiedRiskTitle(SimplifiedRiskTabs.Assessment)}
               </Tab>

               <Tab
                  icon={selectedTab === SimplifiedRiskTabs.ActionPlan ? <TaskListSquareLtr20Filled /> : <TaskListSquareLtr20Regular />}
                  value={SimplifiedRiskTabs.ActionPlan}
               >
                  {getSimplifiedRiskTitle(SimplifiedRiskTabs.ActionPlan)}
               </Tab>
            </TabList>
            <Button disabled={completeBtnDisabled} icon={<LockClosed20Regular />} onClick={() => setCompleteModalOpen(true)} appearance="transparent">
               {t('CompleteRiskAssessment')}
            </Button>
         </header>

         {selectedTab === SimplifiedRiskTabs.Info && <RiskInformation analysis={analysis} setAnalysisState={setRiskAnalysisState} />}
         {selectedTab === SimplifiedRiskTabs.Identifying && <RiskIdentifying analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         {selectedTab === SimplifiedRiskTabs.Assessment && <RiskAnalyseEvaluate analysis={analysis} setRiskAnalysisState={setRiskAnalysisState} />}
         {selectedTab === SimplifiedRiskTabs.ActionPlan && (
            <RiskActionPlan<ActionPlan>
               analysis={analysis}
               actionPlans={analysis.actionPlans}
               title={t('RiskManagementPlan')}
               setRiskAnalysisState={setRiskAnalysisState}
               getItemColumns={getSimplifiedRiskActionPlanColumns(analysis.simplifiedRisks)}
               getItemGroups={getSimplifiedRiskActionPlanGroups(analysis.simplifiedRisks, analysis.actionPlans)}
               getItemSummaryFields={getSimplifiedRiskActionPlanSummaryFields(analysis.actionPlans, analysis.simplifiedRisks)}
               parentRiskInformation={<ParentSimplifiedRiskInformation selectedRisk={analysis?.simplifiedRisks?.find((r) => r.id === selectedItem?.riskId)} />}
               getPhoneBody={simplifiedRiskPhoneBody}
               selectedItem={selectedItem}
               setSelectedItem={setSelectedItem}
               exportToExcel={(analysis, actionPlans) => exportSimplifiedRiskActionPlansToExcel(analysis, actionPlans)}
            />
         )}
         <ModalCompleteRiskAssessment
            open={completeModalOpen}
            close={() => setCompleteModalOpen(false)}
            riskAnalysisState={analysisState}
            setRiskAnalysisState={setRiskAnalysisState}
         />
      </Layout>
   )
}
