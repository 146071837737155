import React from 'react'
import { CostCategory } from '../../api/schemas/schema'
import { translateEnumValue } from '../../helpers/enumHelper'
import { Badge } from '@fluentui/react-components'

type BadgeCostCategoryProps = {
   category: CostCategory
}
export const BadgeCostCategory = ({ category }: BadgeCostCategoryProps) => {
   const getBgColor = () => {
      switch (category) {
         case CostCategory.DamageCosts:
            return '#C50F1F'

         case CostCategory.InsuranceCosts:
            return '#919191'

         case CostCategory.PreventiveCosts:
            return '#394B5F'

         case CostCategory.SecurityAdministration:
            return '#EAA300'
      }
   }
   return (
      <Badge appearance="filled" shape="rounded" style={{ backgroundColor: getBgColor(), width: '160px' }} size="large">
         {translateEnumValue(CostCategory, category)}
      </Badge>
   )
}
