import dayjs from 'dayjs'

export const formatToSwedishCrowns = (cost: string | number) => {
   if (cost || cost === 0) return new Intl.NumberFormat('sv-SE').format(Number(cost))
   return null
}

export const formatDate = (date: Date | string, withTime?: boolean) => {
   if (withTime) return dayjs(date).format('YYYY-MM-DD HH:mm')
   return dayjs(date).format('YYYY-MM-DD')
}

export const summarizeFieldValues = (items: any[], field: string) => {
   const values = items.map((item) => (item[field] as number | null) ?? null)

   return values.every((value) => value === null) ? null : values.reduce((acc, curr) => acc + curr, 0)
}
