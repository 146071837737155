import React, { useContext } from 'react'
import { DataContext } from '../../context/DataContext'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalActions } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { Button } from '@fluentui/react-components'
import { Text } from '../common/text/Text'
import { RiskAnalysis, RiskAnalysisStatus } from '../../api/schemas/schema'
import { api } from '../../helpers/apiHelper'
import { handleMessage } from '../../helpers/stateHelper'
import { IItemState } from '../../interfaces/IItemState'
import { NavigationLink } from '../../interfaces/INavigationLink'

type ModalCompleteRiskAssessmentProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   riskAnalysisState: IItemState<RiskAnalysis>
   close: () => void
   open: boolean
}
export const ModalCompleteRiskAssessment = ({ setRiskAnalysisState, riskAnalysisState, close, open }: ModalCompleteRiskAssessmentProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const navigate = useNavigate()
   const { item } = riskAnalysisState
   const onComplete = async () => {
      try {
         setRiskAnalysisState((prev) => ({ ...prev, loading: true }))
         item.status = RiskAnalysisStatus.Completed
         item.modifiedBy = userAccount
         item.modifiedById = userAccount.id
         const updatedAnalysis = (await api(accessToken).updateRiskAnalysis(item)).data
         setRiskAnalysisState((prev) => ({ ...prev, loading: false, item: updatedAnalysis }))
         handleMessage(dataContext.setRootState, 'success', t('RiskAssessmentCompleted'))
         close()
         navigate(NavigationLink.RiskManagement + '?tab=concluded')
      } catch (error: any) {
         setRiskAnalysisState((prev) => ({ ...prev, loading: false, error: error.error }))
         handleMessage(dataContext.setRootState, 'error', t('CouldNotCompleteRiskAssessment'))
         close()
      }
   }
   return (
      <Modal open={open} modalType={'modal'} title={t('CompleteRiskAssessment')}>
         <Text align="center">{t('CompleteRiskAssessmentConfirmationMessage')}</Text>
         <ModalActions>
            <Button onClick={close}>{t('Cancel')}</Button>
            <Button appearance="primary" onClick={onComplete}>
               {t('CompleteRiskAssessment')}
            </Button>
         </ModalActions>
      </Modal>
   )
}
