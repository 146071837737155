import React from 'react'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { IComplianceState } from '../../../interfaces/IComplianceState'
import { Activity, DemandAnalysisDashboardDTO, RiskAnalysis } from '../../../api/schemas/schema'
import { IListState } from '../../../interfaces/IListState'
import { DashboardComplianceCards } from '../DashboardComplianceCards'
import { DashboardCard } from '../DashboardCard'
import { useNavigate } from 'react-router-dom'
import { RiskManagementCard } from './RiskManagementCard'
import { ActivitesCard } from './ActivitesCard'
import { makeStyles, shorthands } from '@fluentui/react-components'
const useStyles = makeStyles({
   overrideCompliance: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 6'),
      '@media screen and (max-width: 1919px) and (min-width: 1451px)': {
         ...shorthands.gridArea('auto', 'auto', 'auto', 'span 8'),
      },
      '@media screen and (max-width: 1450px)': {
         ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12'),
      },
   },
   overrideActivities: {
      ...shorthands.gridArea('auto', 'auto', 'auto', 'span 6'),
      '@media screen and (max-width: 1919px) and (min-width: 1451px)': {
         ...shorthands.gridArea('auto', 'auto', 'auto', 'span 4'),
      },
      '@media screen and (max-width: 1450px)': {
         ...shorthands.gridArea('auto', 'auto', 'auto', 'span 12'),
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   }
})

type DashboardMiniProps = {
   setComplianceState: React.Dispatch<React.SetStateAction<IComplianceState>>
   setActivityModalState: React.Dispatch<
      React.SetStateAction<{
         isOpen: boolean
         selectedActivity?: Activity
      }>
   >
   setRiskManagementState: React.Dispatch<React.SetStateAction<IListState<RiskAnalysis>>>
   latestSwotAnalysis: RiskAnalysis | undefined
   latestActivities: Activity[]
   dashboardData: DemandAnalysisDashboardDTO
}

export const DashboardMini = ({
   setActivityModalState,
   setRiskManagementState,
   setComplianceState,
   latestSwotAnalysis,
   latestActivities,
   dashboardData,
}: DashboardMiniProps) => {
   const classes = useStyles()
   const navigate = useNavigate()

   return (
      <Grid gap="XXXL">
         <GridItem className={classes.overrideCompliance}>
            <DashboardComplianceCards setComplianceState={setComplianceState} dashboardData={dashboardData} />
         </GridItem>

         <GridItem className={classes.overrideActivities}>
            <DashboardCard color="beige" centerChild={latestActivities.length === 0 ? true : false} onClick={() => navigate(`/calendar`)}>
               <ActivitesCard setActivityModalState={setActivityModalState} latestActivities={latestActivities} />
            </DashboardCard>
         </GridItem>

         <GridItem size="12/12" className={classes.noneInPhone}>
            <DashboardCard
               color="beige"
               bigCard
               centerChild={latestSwotAnalysis ? false : true}
               onClick={() => navigate(`/risk-management/${latestSwotAnalysis.id}`)}
            >
               <RiskManagementCard latestSwotAnalysis={latestSwotAnalysis} setRiskManagementState={setRiskManagementState} />
            </DashboardCard>
         </GridItem>
      </Grid>
   )
}
