import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import React from 'react'
import { TooltipProps } from 'recharts'
import { t } from '../../i18n/i18n'
import { Activity } from '../../api/schemas/schema'
import { formatDate } from '../../helpers/stringHelper'
const useStyles = makeStyles({
   toolTip: {
      backgroundColor: tokens.colorNeutralBackground1,
      border: '1px solid #ccc',
      padding: tokens.spacingVerticalMNudge,
      borderRadius: tokens.borderRadiusMedium,
      boxShadow: tokens.shadow2,
   },
   divider: {
      marginTop: tokens.spacingVerticalXS,
   },
})
type ActivityTooltipProps = TooltipProps<any, any> & {
   activities: Activity[]
}
export const ActivityTooltip = ({ active, payload, activities }: ActivityTooltipProps) => {
   const classes = useStyles()
   if (active && payload && payload.length) {
      const activity = activities.find((x) => x.id === payload[0].name)

      if (activity) {
         return (
            <div className={classes.toolTip}>
               <strong>{activity.title}</strong>
               <Divider className={classes.divider} appearance="brand" />
               <p>
                  <b>{t('StartDate')}</b> {formatDate(activity.startDate)}
               </p>
               <p>
                  <b>{t('EndDate')}</b> {formatDate(activity.endDate)}
               </p>
               {activity?.description && (
                  <>
                     <Divider appearance="strong" />
                     <p>{activity.description}</p>
                  </>
               )}
            </div>
         )
      }
   }
   return null
}
