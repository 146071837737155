import React from 'react'
import { t } from '../../../../i18n/i18n'
import { Button, Divider, makeStyles, Subtitle2, tokens, Text } from '@fluentui/react-components'
import { BadgeConsequenceProbabilityWithLabel } from '../BadgeConsequenceProbability'
import { Modal } from '../../../common/modal/Modal'
import { Dismiss24Regular } from '@fluentui/react-icons'

const useStyles = makeStyles({
   wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalL,
   },
   badgeRowWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: tokens.spacingHorizontalXXL,
      '@media screen and (max-width: 641px)': {
         flexDirection: 'column',
         alignItems: 'start',
         gap: 0,
      },
   },
   col: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingHorizontalXS,
   },
   noMargin: {
      margin: 0,
   },
   badgesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalM,
      '@media screen and (max-width: 641px)': {
         gap: tokens.spacingVerticalL,
      },
   },
})
const scalesData: {
   title: string
   info: string
   type: 'probability' | 'consequence'
   itemsInCol?: boolean
   items: {
      valuation: 5 | 4 | 3 | 2 | 1
      label?: string
      description: string
   }[]
}[] = [
   {
      title: t('ProbabilityScale'),
      info: t('ProbabilityScaleInfo'),
      type: 'probability',
      items: [
         { valuation: 5, label: '> 50 %', description: t('VeryHighDescription') },
         { valuation: 4, label: '< 50 %', description: t('HighDescription') },
         { valuation: 3, label: '< 25 %', description: t('ModerateDescription') },
         { valuation: 2, label: '< 5 %', description: t('LowDescription') },
         { valuation: 1, label: '< 1 %', description: t('VeryLowDescription') },
      ],
   },
   {
      title: t('ConsequenceLifeAndHealthScale'),
      info: t('ConsequenceLifeAndHealthScaleInfo'),
      type: 'consequence',
      items: [
         { valuation: 5, description: t('ProbableLifeHealthDescription') },
         { valuation: 4, description: t('LikelyLifeHealthDescription') },
         { valuation: 3, description: t('PossibleLifeHealthDescription') },
         { valuation: 2, description: t('LessLikelyLifeHealthDescription') },
         { valuation: 1, description: t('UnlikelyLifeHealthDescription') },
      ],
   },
   {
      title: t('ConsequenceEconomyScale'),
      info: t('ConsequenceEconomyScaleInfo'),
      type: 'consequence',
      items: [
         { valuation: 5, label: '> 20 % ', description: t('OfEstimatedRevenue') },
         { valuation: 4, label: '> 10 % ', description: t('OfEstimatedRevenue') },
         { valuation: 3, label: '< 5 % ', description: t('OfEstimatedRevenue') },
         { valuation: 2, label: '< 2 % ', description: t('OfEstimatedRevenue') },
         { valuation: 1, label: '< 1 % ', description: t('OfEstimatedRevenue') },
      ],
   },
   {
      title: t('ConsequenceEnvironmentScale'),
      info: t('ConsequenceEnvironmentScaleInfo'),
      type: 'consequence',
      items: [
         { valuation: 5, description: t('ProbableEnvironmentDescription') },
         { valuation: 4, description: t('LikelyEnvironmentDescription') },
         { valuation: 3, description: t('PossibleEnvironmentDescription') },
         { valuation: 2, description: t('LessLikelyEnvironmentDescription') },
         { valuation: 1, description: t('UnlikelyEnvironmentDescription') },
      ],
   },
   {
      title: t('ConsequenceBrandScale'),
      info: t('ConsequenceBrandScaleInfo'),
      type: 'consequence',
      itemsInCol: true,
      items: [
         { valuation: 5, description: t('ProbableBrandDescription') },
         { valuation: 4, description: t('LikelyBrandDescription') },
         { valuation: 3, description: t('PossibleBrandDescription') },
         { valuation: 2, description: t('LessLikelyBrandDescription') },
         { valuation: 1, description: t('UnlikelyBrandDescription') },
      ],
   },
]

type ModalRiskCriteriaProps = {
   open: boolean
   close: () => void
}
export const ModalRiskCriteria = ({ open, close }: ModalRiskCriteriaProps) => {
   const classes = useStyles()
   return (
      <Modal open={open} title={t('RiskCriteria')} action={<Button icon={<Dismiss24Regular />} appearance="transparent" onClick={close}></Button>}>
         <div className={classes.wrapper}>
            {scalesData.map(({ title, info, items, type, itemsInCol }) => (
               <>
                  <Divider appearance="brand" />
                  <section className={classes.wrapper}>
                     <Subtitle2 as="h3" className={classes.noMargin}>
                        {title}
                     </Subtitle2>
                     <Text block size={300}>
                        {info}
                     </Text>
                     <div className={classes.badgesWrapper}>
                        {items.map((item) => (
                           <div className={itemsInCol ? classes.col : classes.badgeRowWrapper} key={item.valuation}>
                              <BadgeConsequenceProbabilityWithLabel valuation={item.valuation} type={type} />
                              {type === 'probability' && (
                                 <Text style={{ width: '50px' }} size={300} weight="bold">
                                    {item.label}
                                 </Text>
                              )}

                              <span>
                                 {item.label && type === 'consequence' && (
                                    <Text style={{ width: '50px' }} size={300} weight="bold">
                                       {item.label}
                                    </Text>
                                 )}
                                 <Text size={300}>{item.description}</Text>
                              </span>
                           </div>
                        ))}
                     </div>
                  </section>
               </>
            ))}
         </div>
      </Modal>
   )
}
