import React, { useState } from 'react'
import { Card, CardHeader, makeStyles, tokens, Text } from '@fluentui/react-components'
import { BarChart, CartesianGrid, YAxis, XAxis, Bar, Legend, Tooltip } from 'recharts'
import { DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { t } from '../../../../i18n/i18n'

const useStyles = makeStyles({
   legend: {
      '& * span': {
         color: 'black',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   chart: {
      left: '-30px',
   },
   flex: {
      display: 'flex',
      gap: tokens.spacingHorizontalS,
   },
})

type ActionPlanProcessChartProps = {
   filteredQuestions: DemandAnalysisQuestion[]
}

export const ActionPlanProcessChart = ({ filteredQuestions }: ActionPlanProcessChartProps) => {
   const [activeIndex, setActiveIndex] = useState<number | null>(null)
   const [activeLabel, setActiveLabel] = useState<string | null>(null)
   const classes = useStyles()

   const groupedData = filteredQuestions.reduce((acc: any, question: DemandAnalysisQuestion) => {
      const process = question.processes[0]
      const answer = question.answer.text

      if (!acc[process]) {
         acc[process] = { name: process }
      }
      acc[process][answer] = (acc[process][answer] || 0) + 1

      return acc
   }, {})

   const chartData = Object.values(groupedData)

   const colorMap = filteredQuestions.reduce(
      (acc, question) => {
         acc[question.answer.text] = question.answer.colorHex
         return acc
      },
      {} as Record<string, string>
   )

   const sortedKeys = Array.from(new Set(filteredQuestions.map((q) => q.answer.text))).sort((a, b) => {
      const answerA = filteredQuestions.find((q) => q.answer.text === a)
      const answerB = filteredQuestions.find((q) => q.answer.text === b)

      if (answerA && answerB) {
         if (answerA.answer.answerType !== answerB.answer.answerType) {
            return answerA.answer.answerType - answerB.answer.answerType
         }
         return answerA.answer.order - answerB.answer.order
      }
      return 0
   })
   return (
      <>
         <BarChart className={classes.noneInPhone} width={1300} height={800} data={chartData} margin={{ top: 20, right: 20, left: 20, bottom: 200 }}>
            <CartesianGrid />
            <YAxis />
            <XAxis dataKey="name" dy={10} />
            {sortedKeys.map((key, index) => (
               <Bar
                  barSize={20}
                  key={index}
                  dataKey={key}
                  fill={colorMap[key] ?? '#808080'}
                  onMouseEnter={(e) => {
                     setActiveIndex(e.activeTooltipIndex)
                     setActiveLabel(key)
                  }}
                  onMouseLeave={() => {
                     setActiveIndex(null)
                     setActiveLabel(null)
                  }}
                  onMouseMove={(e) => {
                     setActiveIndex(e.activeTooltipIndex)
                     setActiveLabel(key)
                  }}
               />
            ))}
            <Legend
               verticalAlign="top"
               align="right"
               iconType="rect"
               className={classes.legend}
               formatter={(value) => {
                  const label = value.includes('kravefterlevnad') ? value.split(' ')[0] : value
                  return <span style={{ color: 'black' }}>{label}</span>
               }}
            />
            <Tooltip active={activeIndex !== null} cursor={false} content={(props) => <CustomTooltip props={props} compliance={activeLabel} />} />
         </BarChart>
      </>
   )
}

const CustomTooltip = ({ props, compliance }: any) => {
   const { active, payload } = props
   if (active && payload && payload.length) {
      const data = payload[0].payload
      let label = data.name
      let number = data[compliance]

      return (
         <Card>
            <CardHeader
               header={
                  <Text weight="semibold">
                     {label} - {compliance}
                  </Text>
               }
            />
            <Text>
               {t('NumberOfActions')}: {number}
            </Text>
         </Card>
      )
   }

   return null
}
