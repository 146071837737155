import React from 'react'
import { ActionPlan, ActionPlanStatus, DemandAnalysis, DemandAnalysisQuestion } from '../api/schemas/schema'
import { t } from '../i18n/i18n'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { RTFOutput } from '../components/common/outputs/RTFOutput'
import { IGroupedTableColumnDefintion } from '../interfaces/IGroupedTableColumnDefintion'
import { GroupHeader } from '../components/common/list/GroupHeader'
import { BadgeActionPlanStatus } from '../components/demandAnalysis/BadgeActionPlanStatus'
import { formatToSwedishCrowns } from './stringHelper'
import { IGroup } from '../interfaces/IGroup'
import { ISummaryField } from '../interfaces/ISummaryField'
import { BadgeComplianceLevel } from '../components/demandAnalysis/BadgeComplianceLevel'
import { IItemState } from '../interfaces/IItemState'

export const filterDemandActionPlansByProcess = (actionPlans: ActionPlan[], questions: DemandAnalysisQuestion[], selectedProcess: string) => {
   if (selectedProcess === t('AllProcesses')) {
      return actionPlans
   } else {
      return actionPlans.filter((x) => findQuestionParentToActionPlan(questions, x).processes.some((p) => p === selectedProcess))
   }
}

export const findQuestionParentToActionPlan = (questions: DemandAnalysisQuestion[], actionPlan: ActionPlan) =>
   questions.find((q) => q.id === actionPlan.demandAnalysisQuestionId)

export const getActionPlanColumns = (questions: DemandAnalysisQuestion[]): IGroupedTableColumnDefintion<ActionPlan>[] => [
   {
      columnId: 'question',
      compare: (a, b) => -1,
      renderCell: (item) => {
         let question = findQuestionParentToActionPlan(questions, item).text
         if (question.startsWith('<p>')) question = question.replace('<p>', `<p>${item.index}. `)
         else question = `${item.index}. ${question}`
         return <RTFOutput text={question} />
      },
      renderHeaderCell: () => t('Question'),
      renderGroupHeaderCell: (group) => <GroupHeader title={t('ComplianceLevel')} badge={<BadgeComplianceLevel text={group.label} bgHexColor={group.hex} />} />,
      colWidth: 'auto',
   },
   {
      columnId: 'priority',
      compare: (a, b) => -1,
      renderHeaderCell: () => t('Priority'),
      renderGroupHeaderCell: (group) => '',
      renderCell: (item) => item.priority,
      colWidth: '120px',
   },
   {
      columnId: 'process',
      compare: (a, b) => -1,
      renderCell: (item) => findQuestionParentToActionPlan(questions, item).processes.join(', '),
      renderGroupHeaderCell: (group) => '',
      renderHeaderCell: () => t('Process'),
      colWidth: '220px',
   },
   {
      columnId: 'estimatedCost',
      compare: (a, b) => -1,
      renderCell: (item) => {
         const question = findQuestionParentToActionPlan(questions, item)
         return question.estimatedCost || question.estimatedCost === 0 ? formatToSwedishCrowns(question.estimatedCost) + ' kr' : null
      },
      renderHeaderCell: () => t('EstimatedCostShort'),
      renderGroupHeaderCell: (group) => {
         if (group.items.every((x) => findQuestionParentToActionPlan(questions, x).estimatedCost === null)) return ''
         let estimatedCostTotal = 0
         group.items.map((x) => (estimatedCostTotal += findQuestionParentToActionPlan(questions, x).estimatedCost))
         return estimatedCostTotal || estimatedCostTotal === 0 ? formatToSwedishCrowns(estimatedCostTotal) + ' kr' : null
      },
      colWidth: '150px',
   },
   {
      columnId: 'decisionStatus',
      compare: (a, b) => -1,
      renderCell: (item) => <BadgeActionPlanStatus status={item.status} />,
      renderHeaderCell: () => t('DecisionStatus'),
      renderGroupHeaderCell: (group) => {
         const totalAnswered = group.items.filter((item) => item.status !== ActionPlanStatus.NotDecided).length
         const totalItems = group.items.length
         // group.items.map((x) => x.status === ActionPlanStatus.Ongoing)
         return `${totalAnswered} / ${totalItems}`
      },
      colWidth: '100px',
   },
]

export const submitDemandActionPlan = async (
   data: ActionPlan,
   dataContext: IDataContext,
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<DemandAnalysis>>>,
   parent: DemandAnalysisQuestion
) => {
   try {
      const { accessToken } = dataContext.state

      const updatedActionPlan = (await api(accessToken).updateActionPlanOnDemandAnalysis({ demandAnalysisId: parent.demandAnalysisId }, data)).data
      setAnalysisState((prev) => {
         const actionPlans = [...prev.item.actionPlans]
         actionPlans[actionPlans.findIndex((x) => x.id === updatedActionPlan.id)] = updatedActionPlan
         const questions = [...prev.item.questions]
         questions[questions.findIndex((x) => x.id === parent.id)] = parent

         return { ...prev, item: { ...prev.item, actionPlans, questions } }
      })

      return true
   } catch (error: any) {
      return false
   }
}

export const groupActionPlans = (actionPlans: ActionPlan[], questions: DemandAnalysisQuestion[]): IGroup<ActionPlan>[] => {
   const groups: IGroup<ActionPlan>[] = []
   actionPlans.forEach((plan) => {
      const question = questions.find((q) => q.id === plan.demandAnalysisQuestionId)
      const key = question.answer.text
      const group = groups.find((g) => g.key === key)
      if (!group) {
         groups.push({ label: key, key: key, order: question.answer.order, hex: question.answer.colorHex, expanded: true, items: [] })
      }
      groups.find((g) => g.key === key).items.push(plan)
   })
   return groups.sort((a, b) => b.order - a.order)
}

export const getActionPlanSummaryFields = (actionPlans: ActionPlan[], questions: DemandAnalysisQuestion[]): ISummaryField[] => {
   let estimatedCost = 0
   actionPlans.forEach((plan) => {
      const question = questions.find((q) => q.id === plan.demandAnalysisQuestionId)

      estimatedCost += question.estimatedCost ?? 0
   })
   const totalAnswered = actionPlans.filter((plan) => plan.status !== ActionPlanStatus.NotDecided).length
   const totalItems = actionPlans.length
   return [
      { label: t('TotalEstimatedCost'), text: `${formatToSwedishCrowns(estimatedCost)} kr` },
      { label: t('DecidedMulti'), text: `${totalAnswered} / ${totalItems}` },
   ]
}
