import React, { useState } from 'react'
import { t } from '../../i18n/i18n'
import { DataClassification, FinancialYear, FinancialYearStatus } from '../../api/schemas/schema'
import { makeStyles, tokens } from '@fluentui/react-components'
import { IItemState } from '../../interfaces/IItemState'
import { GridItem } from '../common/grid/GridItem'
import { Grid } from '../common/grid/Grid'
import TextOutput from '../common/inputs/TextOutput'
import dayjs from 'dayjs'
import { AvatarAndNameOutput } from '../common/inputs/UserAvatarOutput'
import { translateEnumValue } from '../../helpers/enumHelper'
import { ModalEditFinancialYear } from './ModalEditFinancialYear'
import { InformationCard } from '../common/card/InformationCard'
import { FinancialYearInformationBarChart } from './FinancialYearInformationBarChart'
import { formatToSwedishCrowns } from '../../helpers/stringHelper'

const useStyles = makeStyles({
   editButton: {
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 1007px)': {
         justifyContent: 'end',
      },
   },
   infoWrapper: {
      '& > *': {
         marginTop: tokens.spacingVerticalXXXL,
      },
      '@media screen and (max-width: 1007px)': {
         '& > :first-child': {
            marginTop: 0,
         },
      },
   },
})

type InformationProps = {
   financialYear: FinancialYear
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
}
export const FinancialYearInformation = ({ financialYear, setFinancialYearState }: InformationProps) => {
   const classes = useStyles()
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleEditButtonClick = () => {
      setIsModalOpen(true)
   }
   return (
      <>
         <InformationCard
            setState={setFinancialYearState}
            handleEditButtonClick={handleEditButtonClick}
            disabled={financialYear.status !== FinancialYearStatus.Ongoing}
         >
            <Grid>
               <FinancialYearInformationBarChart costs={financialYear.costs} />

               <GridItem size={'2/12'} className={classes.infoWrapper}>
                  <TextOutput label={t('FinancialYear')} text={financialYear.name} />
                  <TextOutput
                     label={t('Period')}
                     text={`${dayjs(financialYear.startDate).format('YYYY-MM-DD')} ${t('To')} ${dayjs(financialYear.endDate).format('YYYY-MM-DD')}`}
                  />
                  <AvatarAndNameOutput label={t('Owner')} userAccount={financialYear.owner} />
                  <TextOutput label={t('DataClassification')} text={translateEnumValue(DataClassification, financialYear.dataClassification)} />
                  <TextOutput
                     label={t('BudgetedRevenue')}
                     text={
                        financialYear.budgetedRevenue || financialYear.budgetedRevenue === 0
                           ? `${formatToSwedishCrowns(financialYear.budgetedRevenue)} kr`
                           : '-'
                     }
                  />
                  <TextOutput label={t('Comment')} text={financialYear.comment || '-'} />
               </GridItem>
            </Grid>
         </InformationCard>
         <ModalEditFinancialYear setFinancialYearState={setFinancialYearState} financialYear={financialYear} open={isModalOpen} setOpen={setIsModalOpen} />
      </>
   )
}
