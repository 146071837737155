import React, { useState } from 'react'
import { ConsequenceAndProbabilityRisk, RiskAnalysis, RiskAnalysisStatus } from '../../../api/schemas/schema'
import { Button, makeStyles, tokens, Text } from '@fluentui/react-components'
import { ClipboardTextLtr20Regular, LockClosed24Regular } from '@fluentui/react-icons'
import { t } from '../../../i18n/i18n'
import { CardHeader } from '../../common/header/CardHeader'
import { List } from '../../common/list/List'
import { getConsequenceProbabilityRiskEvaluateColumns } from '../../../helpers/riskAnalysisHelper'
import { ModalConsequenceEvaluate } from './modals/ModalConsequenceEvaluate'
import { ModalDetermineRiskAnalysis } from '../ModalDetermineRiskAnalysis'
import { RiskLevelBadge } from '../RiskBadges'
import { BadgeConsequenceProbability } from './BadgeConsequenceProbability'
import { ConsequenceScaleLabel } from './ConsequenceScaleLabel'
import { ModalRiskCriteria } from './modals/ModalRiskCriteria'
import { IItemState } from '../../../interfaces/IItemState'

const useStyles = makeStyles({
   buttonWrapper: {
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         justifyContent: 'space-between',
         gap: tokens.spacingHorizontalM,
         '& > button': {
            outline: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
            flexGrow: 1,
            width: '50%',
            padding: tokens.spacingVerticalXS,
         },
      },
   },
   phoneItemWrapper: {
      '& > *': {
         marginBottom: tokens.spacingVerticalMNudge,
      },
      display: 'flex',
      flexDirection: 'column',
      marginBottom: tokens.spacingVerticalM,
   },
   divider: {
      borderBottom: `1px solid ${tokens.colorBrandStroke2}`,
   },
   badgeWrapper: {
      display: 'flex',
      '& > *': {
         paddingRight: tokens.spacingHorizontalL,
      },
      '& > div': {
         display: 'flex',
         gap: tokens.spacingHorizontalS,
      },
   },
})
type ConsequenceEvaluationProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
}
export const ConsequenceEvaluation = ({ analysis, setRiskAnalysisState }: ConsequenceEvaluationProps) => {
   const classes = useStyles()
   const [modals, setModals] = useState({
      determineModal: false,
      riskCriteriaModal: false,
      evaluateModal: false,
   })
   const [checkboxHasLocalState, setCheckboxHasLocalState] = useState<boolean>(false)
   const [selectedRisk, setSelectedRisk] = useState<any>(null)
   const disabled = analysis.status === RiskAnalysisStatus.Determined || analysis.status === RiskAnalysisStatus.Completed

   const toggleModal = (modalName: string, isOpen: boolean) => {
      setModals({
         determineModal: false,
         riskCriteriaModal: false,
         evaluateModal: false,
         [modalName]: isOpen,
      })
   }

   const handleRowClick = (risk: any) => {
      setSelectedRisk(risk)
      toggleModal('evaluateModal', true)
   }

   const onActionRequiredClick = (risk: ConsequenceAndProbabilityRisk, columnKey: keyof ConsequenceAndProbabilityRisk) => {
      if (columnKey === 'proposedAction' && !disabled) {
         risk = toggleCheckbox(risk, true)
      }
      handleRowClick(risk)
   }

   const toggleCheckbox = (risk: ConsequenceAndProbabilityRisk, openModal?: boolean) => {
      risk = { ...risk, actionRequired: !risk.actionRequired }

      const updatedrisks = analysis.consequenceAndProbabilityRisks.map((item) => (item.id === risk.id ? risk : item))

      const updatedAnalysis = { ...analysis, consequenceAndProbabilityRisks: updatedrisks }
      setRiskAnalysisState((prev) => ({
         ...prev,
         item: updatedAnalysis,
      }))
      openModal && setCheckboxHasLocalState(true)
      return risk
   }

   const phoneBody = (risk: ConsequenceAndProbabilityRisk) => {
      return (
         <span>
            <div className={classes.phoneItemWrapper}>
               <RiskLevelBadge largeBadge riskLevel={risk.riskLevel} />
               <div className={classes.badgeWrapper}>
                  <div>
                     <ConsequenceScaleLabel typeLabel="L" />
                     <BadgeConsequenceProbability valuation={risk.consequenceLifeHealth} />
                  </div>

                  <div>
                     <ConsequenceScaleLabel typeLabel="E" />
                     <BadgeConsequenceProbability valuation={risk.consequenceEconomy} />
                  </div>

                  <div>
                     <ConsequenceScaleLabel typeLabel="M" />
                     <BadgeConsequenceProbability valuation={risk.consequenceEnviroment} />
                  </div>

                  <div>
                     <ConsequenceScaleLabel typeLabel="V" />
                     <BadgeConsequenceProbability valuation={risk.consequenceBrand} />
                  </div>

                  <div>
                     <Text weight="bold" size={400}>
                        S
                     </Text>
                     <BadgeConsequenceProbability valuation={risk.probability} />
                  </div>
               </div>
               <Text>{risk.scenario}</Text>
            </div>
         </span>
      )
   }
   return (
      <>
         <CardHeader
            title={t('RiskEvaluation')}
            action={
               <div className={classes.buttonWrapper}>
                  <Button disabled={disabled} onClick={() => toggleModal('determineModal', true)} appearance={'transparent'} icon={<LockClosed24Regular />}>
                     {t('DetermineAnalysis')}
                  </Button>
                  <Button
                     onClick={() => {
                        toggleModal('riskCriteriaModal', true)
                     }}
                     appearance={'transparent'}
                     icon={<ClipboardTextLtr20Regular />}
                  >
                     {t('RiskCriteria')}
                  </Button>
               </div>
            }
         />

         <List
            columns={getConsequenceProbabilityRiskEvaluateColumns()}
            columnSizingOptions={{
               riskScenario: { idealWidth: 450 },
               consequence: { idealWidth: 425 },
               consequenceScaleLifeHealth: { idealWidth: 70 },
               consequenceScaleEconomy: { idealWidth: 70 },
               consequenceScaleEnvironment: { idealWidth: 70 },
               consequenceScaleBrand: { idealWidth: 100 },
               probability: { idealWidth: 125 },
               riskLevel: { idealWidth: 200 },
               actionRequired: { idealWidth: 100 },
            }}
            resizableColumns
            items={analysis.consequenceAndProbabilityRisks}
            onRowClick={handleRowClick}
            onCellClick={onActionRequiredClick}
            renderPhoneBodyItem={phoneBody}
         />

         <ModalConsequenceEvaluate
            setRiskAnalysisState={setRiskAnalysisState}
            analysis={analysis}
            open={modals.evaluateModal}
            checkboxHasLocalState={checkboxHasLocalState}
            close={() => {
               toggleModal('analyseEvaluateModal', false)
               setCheckboxHasLocalState(false)
            }}
            toggleCheckbox={toggleCheckbox}
            selectedRisk={selectedRisk}
            disabled={disabled}
         />
         <ModalDetermineRiskAnalysis
            analysis={analysis}
            setRiskAnalysisState={setRiskAnalysisState}
            open={modals.determineModal}
            close={() => toggleModal('determineModal', false)}
         />
         <ModalRiskCriteria open={modals.riskCriteriaModal} close={() => toggleModal('riskCriteriaModal', false)} />
      </>
   )
}
