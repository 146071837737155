import React, { forwardRef, Ref } from 'react'
import {
   Dialog,
   DialogBody as FluentDialogBody,
   DialogContent,
   DialogSurface as FluentDialogSurface,
   DialogTitle,
   makeStyles,
   mergeClasses,
   tokens,
   Button,
   DialogActions,
} from '@fluentui/react-components'

type ModalProps = {
   open: boolean
   modalType?: 'modal' | 'non-modal' | 'alert'
   children: JSX.Element | JSX.Element[]
   title: string | string[] | JSX.Element
   action?: JSX.Element
}
const useStyles = makeStyles({
   actions: {
      display: 'flex',
      justifyContent: 'space-between',
      gridColumnStart: 1,
      justifySelf: 'auto',
      '@media screen and (max-width: 640px)': {
         flexDirection: 'column-reverse',
         gap: tokens.spacingHorizontalS,
      },
   },
   body: {
      maxHeight: 'calc(-48px + 100dvh)',
   },
   surface: {
      maxHeight: '100dvh',
   },
})
export const Modal = ({ open, modalType, title, children, action }: ModalProps) => {
   return (
      <Dialog open={open} modalType={modalType ?? 'modal'}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle action={action ?? ''} as="h3">
                  {title}
               </DialogTitle>
               <DialogContent>{children}</DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
type ModalActionProps = {
   children: JSX.Element | JSX.Element[]
   className?: string
}
export const ModalActions = ({ children, className }: ModalActionProps) => {
   const classes = useStyles()
   return <div className={mergeClasses(classes.actions, className ?? '')}>{children}</div>
}
type DialogSurfaceProps = {
   children: JSX.Element | JSX.Element[] | any
   className?: string
}

export const DialogSurface = forwardRef(({ children, className }: DialogSurfaceProps, ref: Ref<HTMLDivElement>) => {
   const classes = useStyles()
   return (
      <FluentDialogSurface ref={ref} className={mergeClasses(classes.surface, className ?? '')}>
         {children}
      </FluentDialogSurface>
   )
})
type DialogBodyProps = {
   children: JSX.Element | JSX.Element[] | any
   className?: string
}

export const DialogBody = forwardRef(({ children, className }: DialogBodyProps, ref: Ref<HTMLDivElement>) => {
   const classes = useStyles()
   return (
      <FluentDialogBody ref={ref} className={mergeClasses(classes.body, className ?? '')}>
         {children}
      </FluentDialogBody>
   )
})

type DialogActionButtonsProps = {
   primaryButtonText: string
   secondaryButtonText?: string
   secondaryClick?: () => void
   isSubmitting?: boolean
}

export const DialogActionButtons = forwardRef(
   ({ primaryButtonText, secondaryButtonText, secondaryClick, isSubmitting }: DialogActionButtonsProps, ref: Ref<HTMLDivElement>) => {
      return (
         <>
            {secondaryButtonText && (
               <DialogActions ref={ref} position="start">
                  <Button type={'button'} appearance="secondary" onClick={secondaryClick}>
                     {secondaryButtonText}
                  </Button>
               </DialogActions>
            )}
            <DialogActions>
               <Button type={'submit'} appearance="primary" disabled={isSubmitting ?? false}>
                  {primaryButtonText}
               </Button>
            </DialogActions>
         </>
      )
   }
)
