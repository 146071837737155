import React from 'react'
import { Card } from '../card/Card'
import { PhoneHeader } from '../../riskAnalyses/PhoneHeader'

type CardHeaderProps = {
   title: string
   action?: JSX.Element
   secondaryAction?: boolean
   children?: JSX.Element
}

export const CardHeader = ({ title, action, secondaryAction, children }: CardHeaderProps) => {
   return (
      <>
         <PhoneHeader title={title} action={action} secondaryAction={secondaryAction} />
         <Card title={title} action={action} transparentInPhone>
            <>{children}</>
         </Card>
      </>
   )
}
