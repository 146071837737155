import React, { useEffect, useState } from 'react'
import { RiskAnalysis, RiskAnalysisStatus, RiskStatus } from '../../api/schemas/schema'
import { Button, makeStyles, tokens, Text, MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'
import { List } from '../common/list/List'
import { getSimplifiedRiskAnalyseEvaluateColumns } from '../../helpers/riskAnalysisHelper'
import { ClipboardTextLtr20Regular, LockClosed24Regular } from '@fluentui/react-icons'
import { t } from '../../i18n/i18n'
import { ModalRiskCritera } from './SimplifiedRiskAnalysis/ModalRiskCritera'
import { RiskAssessmentBadge } from './RiskBadges'
import { ModalRiskAnalyseEvaluate } from './SimplifiedRiskAnalysis/ModalRiskAnalyseEvaluate'
import { ModalDetermineRiskAnalysis } from './ModalDetermineRiskAnalysis'
import { CardHeader } from '../common/header/CardHeader'
import { IItemState } from '../../interfaces/IItemState'

const useStyles = makeStyles({
   buttonWrapper: {
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         justifyContent: 'space-between',
         gap: tokens.spacingHorizontalM,
         '& > button': {
            outline: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
            flexGrow: 1,
            width: '50%',
         },
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   phoneItemWrapper: {
      '& > *': {
         marginBottom: tokens.spacingVerticalMNudge,
      },
      display: 'flex',
      flexDirection: 'column',
      marginBottom: tokens.spacingVerticalM,
   },
   phoneButtons: {
      display: 'none',
      justifyContent: 'space-between',
      '& > * ': {
         fontSize: tokens.fontSizeBase200,
         minWidth: '48%',
      },
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
      },
   },
})

type RiskAnalyseEvaluateProps = {
   analysis: RiskAnalysis
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
}

export const RiskAnalyseEvaluate = ({ analysis, setRiskAnalysisState }: RiskAnalyseEvaluateProps) => {
   const classes = useStyles()
   const [risks, setRisks] = useState<any>([])
   const disabled = risks.length === 0 || analysis.status !== RiskAnalysisStatus.Ongoing
   const determinedBtnDisabled = risks.some((risk: any) => risk?.status === RiskStatus.NotAssessed) || disabled
   const [selectedRisk, setSelectedRisk] = useState<any>(null)
   const [modals, setModals] = useState({
      determineModal: false,
      riskCriteriaModal: false,
      analyseEvaluateModal: false,
   })

   const toggleModal = (modalName: string, isOpen: boolean) => {
      setModals({
         determineModal: false,
         riskCriteriaModal: false,
         analyseEvaluateModal: false,
         [modalName]: isOpen,
      })
   }

   const handleRiskClick = (risk: any) => {
      toggleModal('analyseEvaluateModal', true)
      setSelectedRisk(risk)
   }

   const phoneBody = (item: any) => {
      return (
         <>
            <span>
               <div className={classes.phoneItemWrapper}>
                  <RiskAssessmentBadge largeBadge status={item.status} />
                  <Text>{item.scenario}</Text>
               </div>
            </span>
         </>
      )
   }

   useEffect(() => {
      if (analysis.simplifiedRisks.length > 0) {
         setRisks(analysis.simplifiedRisks)
      } else if (analysis.consequenceAndProbabilityRisks.length > 0) {
         setRisks(analysis.consequenceAndProbabilityRisks)
      }
   }, [analysis])

   return (
      <>
         <CardHeader
            title={t('RiskAnalysis&Evaluation')}
            action={
               <div className={classes.buttonWrapper}>
                  <Button
                     disabled={determinedBtnDisabled}
                     onClick={() => toggleModal('determineModal', true)}
                     appearance={'transparent'}
                     icon={<LockClosed24Regular />}
                  >
                     {t('DetermineAnalysis')}
                  </Button>
                  <Button
                     onClick={() => {
                        toggleModal('riskCriteriaModal', true)
                     }}
                     appearance={'transparent'}
                     icon={<ClipboardTextLtr20Regular />}
                  >
                     {t('RiskCriteria')}
                  </Button>
               </div>
            }
         />

         {risks.length === 0 ? (
            <MessageBar intent={'info'}>
               <MessageBarBody>
                  <MessageBarTitle>{t('NoRisksIdentifiedYet')}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         ) : (
            <List columns={getSimplifiedRiskAnalyseEvaluateColumns()} items={risks} onRowClick={handleRiskClick} renderPhoneBodyItem={phoneBody} />
         )}

         <ModalRiskCritera open={modals.riskCriteriaModal} close={() => toggleModal('riskCriteriaModal', false)} />
         <ModalRiskAnalyseEvaluate
            setRiskAnalysisState={setRiskAnalysisState}
            analysis={analysis}
            selectedRisk={selectedRisk}
            open={modals.analyseEvaluateModal}
            close={() => toggleModal('analyseEvaluateModal', false)}
            disabled={disabled}
         />
         <ModalDetermineRiskAnalysis
            analysis={analysis}
            setRiskAnalysisState={setRiskAnalysisState}
            open={modals.determineModal}
            close={() => toggleModal('determineModal', false)}
         />
      </>
   )
}
