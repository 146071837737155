import React, { useContext, useEffect } from 'react'
import { Layout } from './Layout'
import { Navigation } from '../components/common/navigation/Navigation'
import { Overview } from '../components/common/overview/Overview'
import { Loader } from '../components/common/spinner/Loader'
import { Button, makeStyles, Tab } from '@fluentui/react-components'
import { deleteFinancialYear, getRiskEconomyColumns, RiskEconomyTabs } from '../helpers/riskEconomyHelper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { t } from '../i18n/i18n'
import { MoreOptionsMenuButton } from '../components/common/other/MoreOptionsMenuButton'
import { IListState } from '../interfaces/IListState'
import { FinancialYearStatus, FinancialYear } from '../api/schemas/schema'
import { DataContext } from '../context/DataContext'
import { api } from '../helpers/apiHelper'
import { ModalAddFinancialYear } from '../components/riskEconomy/ModalAddFinancialYear'

const useStyles = makeStyles({
   addBtn: {
      '@media screen and (max-width: 640px)': {
         display: 'inline-flex',
      },
   },
   row: {
      '& >': {
         ':nth-child(1)': {
            width: '20%',
         },
         ':nth-child(2)': {
            width: '20%',
         },
         ':nth-child(3)': {
            width: '20%',
         },
         ':nth-child(4)': {
            width: '40%',
         },
      },
   },
})

export const RiskEconomy = () => {
   const classes = useStyles()
   const navigate = useNavigate()
   const dataContext = useContext(DataContext)
   const [searchParams, setSearchParams] = useSearchParams()
   const selectedTab = searchParams.get('tab')
   const [riskEconomyState, setRiskEconomyState] = React.useState<IListState<FinancialYear>>({
      loading: true,
      open: false,
      allItems: [],
      filteredItems: [],
   })
   const { accessToken, organisation } = dataContext.state
   const { loading, allItems, filteredItems } = riskEconomyState

   useEffect(() => {
      if (!selectedTab) {
         setSearchParams({ tab: RiskEconomyTabs.Ongoing })
      }
      ;(async () => {
         const allFinancialYears = (await api(accessToken).getFinancialYearsByOrganisationId(organisation.id)).data
         setRiskEconomyState((prev) => ({ ...prev, loading: false, allItems: allFinancialYears }))
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchParams, dataContext.state.userAccount])

   useEffect(() => {
      if (selectedTab === RiskEconomyTabs.Ongoing)
         setRiskEconomyState((prev) => ({ ...prev, filteredItems: prev.allItems.filter((f) => f.status !== FinancialYearStatus.Completed) }))
      if (selectedTab === RiskEconomyTabs.Concluded)
         setRiskEconomyState((prev) => ({ ...prev, filteredItems: prev.allItems.filter((f) => f.status === FinancialYearStatus.Completed) }))
   }, [selectedTab, allItems.length])

   return (
      <Layout breadCrumbItems={[{ title: t('RiskEconomy'), active: true, path: '/risk-economy' }]}>
         <Navigation activeDesktopTab="/risk-economy" />
         {loading ? (
            <Loader />
         ) : (
            <Overview
               tabs={
                  <>
                     <Tab value={RiskEconomyTabs.Ongoing}>{t('Ongoing')}</Tab>
                     <Tab value={RiskEconomyTabs.Concluded}>{t('Concluded')}</Tab>
                  </>
               }
               button={
                  <Button className={classes.addBtn} appearance="primary" onClick={() => setRiskEconomyState((prev) => ({ ...prev, open: true }))}>
                     {t('NewFinancialYear')}
                  </Button>
               }
               loading={loading}
               columns={getRiskEconomyColumns(setRiskEconomyState)}
               items={filteredItems}
               onRowClick={(item) => navigate(`/risk-economy/${item.id}`)}
               keyValue={'name'}
               noItemsText={
                  selectedTab === RiskEconomyTabs.Ongoing
                     ? t('TimeToCreateYourFirstFinancialYear')
                     : t('NoConcludedItems', { Item: t('FinancialYear').toLowerCase() })
               }
               title={
                  selectedTab === RiskEconomyTabs.Ongoing
                     ? t('OngoingItem', { Item: t('FinancialYear').toLowerCase() })
                     : t('ConcludedItem', { Item: t('FinancialYear').toLowerCase() })
               }
               rowClassName={classes.row}
               renderMenuButton={(item) => (
                  <MoreOptionsMenuButton
                     item={item}
                     deleteFunction={deleteFinancialYear}
                     setState={setRiskEconomyState}
                     deleteMessage={t('DeleteItem', { Item: t('FinancialYear').toLowerCase() })}
                     deleteConfirmationMessage={t('DeleteItemConfirmationMessage', { Item: t('FinancialYear').toLowerCase() })}
                  />
               )}
            />
         )}
         <ModalAddFinancialYear setRiskEconomyState={setRiskEconomyState} riskEconomyState={riskEconomyState} />
      </Layout>
   )
}
