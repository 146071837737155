import React, { useContext, useState } from 'react'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts'
import { IAnalysisStatusData } from '../../../interfaces/IAnalysisStatusData'
import { Card, makeStyles, tokens, Text, CardHeader, Button, mergeClasses } from '@fluentui/react-components'
import { Dismiss16Regular } from '@fluentui/react-icons'
import { t } from '../../../i18n/i18n'
import { DataContext } from '../../../context/DataContext'
import { isSubscriptionLevelBaseOrBig } from '../../../helpers/organisationHelper'
const useStyles = makeStyles({
   legend: {
      '& * span': {
         color: 'black',
      },
   },
   showOnPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   chart: {
      left: '-30px',
   },
})
type QuestionStatusChartProps = {
   analysisStatusData: IAnalysisStatusData[]
}
export const QuestionStatusChart = ({ analysisStatusData }: QuestionStatusChartProps) => {
   const classes = useStyles()
   const [activeIndex, setActiveIndex] = useState<number | null>(null)

   const getData = () =>
      analysisStatusData.map((x) => ({
         name: x.process,
         numberOfQuestionsLeft: x.numberOfTotalQuestions - x.numberOfAnsweredQuestions,
         numberOfAnsweredQuestions: x.numberOfAnsweredQuestions,
         owner: x.processOwner,
      }))

   return (
      <>
         <BarChart className={classes.noneInPhone} width={1300} height={800} data={getData()} margin={{ top: 20, right: 20, left: 20, bottom: 200 }}>
            <CartesianGrid />
            <YAxis />
            <XAxis dataKey="name" angle={-45} textAnchor="end" dy={10} />
            <Bar
               dataKey="numberOfAnsweredQuestions"
               fill={'#453C2A'}
               stackId={'a'}
               onMouseEnter={(e) => setActiveIndex(e.activeTooltipIndex)}
               onMouseLeave={() => setActiveIndex(null)}
               onMouseMove={(e) => setActiveIndex(e.activeTooltipIndex)}
            />
            <Bar
               dataKey="numberOfQuestionsLeft"
               fill={tokens.colorNeutralStroke3}
               stackId={'a'}
               onMouseEnter={(e) => setActiveIndex(e.activeTooltipIndex)}
               onMouseLeave={() => setActiveIndex(null)}
               onMouseMove={(e) => setActiveIndex(e.activeTooltipIndex)}
            />
            <Legend
               verticalAlign="top"
               align="right"
               iconType="rect"
               className={classes.legend}
               formatter={(value) => {
                  let text = ''
                  if (value === 'numberOfAnsweredQuestions') text = t('AnsweredQuestions')
                  if (value === 'numberOfQuestionsLeft') text = t('TotalNumber')
                  return <span style={{ color: 'black' }}>{text}</span>
               }}
            />
            <Tooltip
               active={activeIndex !== null}
               cursor={false}
               content={(props) => <CustomTooltip active={props.active} payload={props.payload} label={props.label} />}
            />
         </BarChart>
         <BarChart
            className={mergeClasses(classes.showOnPhone, classes.chart)}
            width={500}
            height={500}
            data={getData()}
            margin={{ top: 20, right: 0, left: 0, bottom: 200 }}
         >
            <CartesianGrid />
            <YAxis />
            <XAxis dataKey="name" angle={-90} textAnchor="end" dy={10} />
            <Bar dataKey="numberOfAnsweredQuestions" fill={'#453C2A'} stackId={'a'} onClick={(e) => setActiveIndex(e.activeTooltipIndex)} />
            <Bar dataKey="numberOfQuestionsLeft" fill={tokens.colorNeutralStroke3} stackId={'a'} onClick={(e) => setActiveIndex(e.activeTooltipIndex)} />
            <Legend
               verticalAlign="top"
               align="center"
               iconType="rect"
               className={classes.legend}
               formatter={(value) => {
                  let text = ''
                  if (value === 'numberOfAnsweredQuestions') text = t('AnsweredQuestions')
                  if (value === 'numberOfQuestionsLeft') text = t('TotalNumber')
                  return <span style={{ color: 'black' }}>{text}</span>
               }}
            />
            <Tooltip
               active={activeIndex !== null}
               cursor={false}
               content={(props) => <CustomTooltip active={props.active} payload={props.payload} label={props.label} />}
            />
         </BarChart>
      </>
   )
}
const CustomTooltip = ({ active, payload, label }: any) => {
   const { subscription } = useContext(DataContext).state.organisation
   if (active && payload && payload.length) {
      const totalQuestions = payload[0].payload.numberOfQuestionsLeft + payload[0].payload.numberOfAnsweredQuestions
      const answeredQuestions = payload[0].payload.numberOfAnsweredQuestions
      const processOwner = payload[0].payload.owner

      return (
         <Card>
            <CardHeader header={<Text weight="semibold">{label}</Text>} action={<Button appearance="subtle" icon={<Dismiss16Regular />} />} />
            {isSubscriptionLevelBaseOrBig(subscription) && (
               <Text>
                  {t('ProcessOwner')}: {processOwner}
               </Text>
            )}
            <Text>{`${t('NumberOfAnswered')}: ${answeredQuestions}/${totalQuestions}`}</Text>
         </Card>
      )
   }

   return null
}
