import React, { useContext, useEffect } from 'react'
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Divider, makeStyles, tokens } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { useForm } from 'react-hook-form'
import { RiskAnalysis } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { api } from '../../helpers/apiHelper'
import { handleMessage } from '../../helpers/stateHelper'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ModalActions } from '../common/modal/Modal'
import { IItemState } from '../../interfaces/IItemState'

const useStyles = makeStyles({
   formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalS,
   },
   textWrapper: {
      '> p:last-child': {
         marginTop: tokens.spacingVerticalXS,
      },
      '> p:first-child': {
         marginBottom: 0,
      },
   },
   radioToolTip: {
      top: '5px !important',
   },
   marginTop: {
      marginTop: tokens.spacingVerticalM,
   },
})
interface ModalEditRiskInformationProps {
   analysis: RiskAnalysis
   isOpen: boolean
   closeModal: () => void
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
}

export const ModalEditRiskInformation = ({ analysis, isOpen, closeModal, setAnalysisState }: ModalEditRiskInformationProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const { setRootState } = dataContext
   const classes = useStyles()
   const demandAssessmentForm = useForm<RiskAnalysis>({})
   const {
      control,
      reset,
      getValues,
      trigger,
      handleSubmit,
      formState: { isValid, isSubmitting },
   } = demandAssessmentForm

   useEffect(() => {
      if (isOpen) {
         reset(analysis)
      }
   }, [isOpen, analysis, reset])

   const onSubmit = async (data: RiskAnalysis) => {
      trigger()
      if (isValid) {
         try {
            const updatedData = {
               ...data,
               modifiedById: userAccount.id,
            }

            const updatedRiskAnalysis: RiskAnalysis = (await api(accessToken).updateRiskAnalysis(updatedData)).data

            setAnalysisState((prevState) => ({
               ...prevState,
               item: updatedRiskAnalysis,
            }))

            reset(updatedRiskAnalysis, { keepIsSubmitSuccessful: true, keepIsSubmitted: true })
            closeModal()
         } catch (error: any) {
            let errorMessage = t('SomethingWentWrong')
            handleMessage(setRootState, 'error', errorMessage, t('CouldNotUpdateDemandAssessment'))
         }
      }
   }

   return (
      <>
         <Dialog open={analysis && isOpen} modalType={'modal'}>
            <DialogSurface>
               <DialogBody>
                  <DialogTitle>{t('EditRiskAssesment')}</DialogTitle>
                  <DialogContent>
                     <form onSubmit={handleSubmit(onSubmit)} className={classes.formWrapper}>
                        <Divider appearance="brand"></Divider>
                        <section>
                           <ControlledTextInput required control={control} name={'name'} label={t('AssessmentName')} />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledDatePicker required control={control} name={'startDate'} label={t('StartOfAssessment')} />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledDatePicker
                              required
                              control={control}
                              name={'endDate'}
                              label={t('EndOfAssessment')}
                              getValues={getValues}
                              compareWith={'startDate'}
                           />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledTextInput required control={control} name={'purposeScope'} label={t('Purpose/Scope')} />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledTextInput required control={control} name={'limitation'} label={t('Limitations')} />
                        </section>
                        <section className={classes.textWrapper}>
                           <ControlledTextInput control={control} name={'comment'} label={t('Comment')} />
                        </section>
                        <ModalActions className={classes.marginTop}>
                           <Button onClick={closeModal}>{t('Cancel')}</Button>
                           <Button appearance="primary" type="submit" disabled={isSubmitting}>
                              {t('Save')}
                           </Button>
                        </ModalActions>
                     </form>
                  </DialogContent>
               </DialogBody>
            </DialogSurface>
         </Dialog>
      </>
   )
}
