import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { api } from './apiHelper'
import { Activity } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { handleMessage } from './stateHelper'
import { t } from 'i18next'
import { UseFormReset } from 'react-hook-form'
dayjs.extend(isoWeek)
dayjs.extend(dayOfYear)
dayjs.extend(weekOfYear)

export const getDaysInMonth = (year: number, month: any) => dayjs(`${year}-${month}-01`).daysInMonth()

export const quarters = (year: number) => [
   { name: 'Q1', value: getDaysInMonth(year, 1) + getDaysInMonth(year, 2) + getDaysInMonth(year, 3) },
   { name: 'Q2', value: getDaysInMonth(year, 4) + getDaysInMonth(year, 5) + getDaysInMonth(year, 6) },
   { name: 'Q3', value: getDaysInMonth(year, 7) + getDaysInMonth(year, 8) + getDaysInMonth(year, 9) },
   { name: 'Q4', value: getDaysInMonth(year, 10) + getDaysInMonth(year, 11) + getDaysInMonth(year, 12) },
]
export const months = (year: number) => {
   return [
      { name: 'Jan', value: getDaysInMonth(year, 1) },
      { name: 'Feb', value: getDaysInMonth(year, 2) },
      { name: 'Mar', value: getDaysInMonth(year, 3) },
      { name: 'Apr', value: getDaysInMonth(year, 4) },
      { name: 'May', value: getDaysInMonth(year, 5) },
      { name: 'Jun', value: getDaysInMonth(year, 6) },
      { name: 'Jul', value: getDaysInMonth(year, 7) },
      { name: 'Aug', value: getDaysInMonth(year, 8) },
      { name: 'Sep', value: getDaysInMonth(year, 9) },
      { name: 'Oct', value: getDaysInMonth(year, 10) },
      { name: 'Nov', value: getDaysInMonth(year, 11) },
      { name: 'Dec', value: getDaysInMonth(year, 12) },
   ]
}

export const weeks = (year: number) => {
   const weekData = []
   let day = dayjs(`${year}-01-01`)

   while (day.isBefore(dayjs(`${year}-12-31`)) || day.isSame(dayjs(`${year}-12-31:23:59:59:999`))) {
      let endOfWeek = day.endOf('isoWeek')

      if (endOfWeek.year() > year) {
         endOfWeek = dayjs(`${year}-12-31:23:59:59:999`)
      }

      let daysInWeek = endOfWeek.diff(day, 'day') + 1

      if (endOfWeek.isSame(dayjs(`${year}-12-31:23:59:59:999`))) {
         daysInWeek = endOfWeek.diff(day, 'day') + 1
      }
      const weekNumber = dayjs(day).isoWeek()

      weekData.push({
         number: weekNumber,
         value: daysInWeek,
      })

      day = day.endOf('isoWeek').add(1, 'day') // Move to the next week
   }

   if (weekData[0].number === 52 || weekData[0].number === 53) {
      weekData[0].number = null
   }

   if (weekData[weekData.length - 1].number === 1) {
      weekData[weekData.length - 1].number = null
   }
   return weekData
}
export const calendarStartAngle = (year: number) => {
   const firstDayOfYear = dayjs(`${year}-01-01`).day()
   const dayIndex = (firstDayOfYear - 1) * (360 / 365)
   return 90 + dayIndex
}

export const stackOverlappingActivities = (activities: Activity[], year: number) => {
   const daysInYear = dayjs(`${year}`).isLeapYear() ? 366 : 365
   let stackedActivities: { name: string; title: string, startAngle: number; endAngle: number; heightOffset: number; color: string; zIndex: number }[] = []

   let activeLayers: { endDay: number; level: number }[] = []
   let calendarLayers = 0
   activities
      .sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)))
      .forEach((activity) => {
         let startDay = dayjs(activity.startDate).dayOfYear()
         let endDay = dayjs(activity.endDate).dayOfYear()
         if (dayjs(activity.startDate).year() < year) {
            startDay = 1
         }
         if (dayjs(activity.endDate).year() > year) {
            endDay = daysInYear
         }
         if (startDay === endDay || startDay - endDay < 3) {
            endDay = endDay + 1
         }
         const startAngle = calendarStartAngle(year) - (startDay / daysInYear) * 360
         const endAngle = calendarStartAngle(year) - (endDay / daysInYear) * 360

         let level = 0

         // eslint-disable-next-line no-loop-func
         while (activeLayers.some((layer) => layer.level === level && layer.endDay >= startDay)) {
            level++
         }

         calendarLayers = Math.max(calendarLayers, level)

         activeLayers.push({ endDay, level })

         stackedActivities.push({
            name: activity.id,
            title: activity.title,
            startAngle,
            endAngle,
            heightOffset: level * 20,
            color: level % 2 === 0 ? '#C3C8CF' : '#74808F',
            zIndex: -level,
         })
      })
   return { stackedActivities, calendarLayers }
}

export const getActivities = async (
   dataContext: IDataContext,
   year: number,
   setActivities: React.Dispatch<React.SetStateAction<Activity[]>>,
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
   const { accessToken, organisation } = dataContext.state
   setIsLoading(true)
   try {
      const res = (await api(accessToken).getByOrganisationIdAndYear(String(organisation.id), String(year))).data

      if (res) {
         setActivities(res)
      }
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotFetchItem', { Item: t('Activities') }))
   } finally {
      setIsLoading(false)
   }
}

export const onActivitySubmit = async (
   dataContext: IDataContext,
   data: Activity,
   setActivities: React.Dispatch<React.SetStateAction<Activity[]>>,
   onClose: () => void,
   selectedActivity?: Activity
) => {
   const { accessToken, organisation, userAccount } = dataContext.state
   try {
      let activity: Activity
      const isOneDayActivity = data.endDate === ''
      if (isOneDayActivity) {
         data.endDate = data.startDate
      }
      if (selectedActivity) {
         data.id = selectedActivity.id
         data.organisationId = selectedActivity.organisationId
         data.modifiedById = userAccount.id
         activity = (await api(accessToken).updateActivity(data)).data
         setActivities((prev) => prev.map((act) => (act.id === activity.id ? activity : act)))
      } else {
         data.organisationId = organisation.id
         data.modifiedById = userAccount.id
         data.createdById = userAccount.id
         activity = (await api(accessToken).addActivity(data)).data
         setActivities((prev) => [...prev, activity])
      }
      onClose()
      handleMessage(dataContext.setRootState, 'success', t(selectedActivity ? 'ItemSuccessfullyUpdated' : 'ItemSuccessfullySaved', { Item: t('Activity') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t(selectedActivity ? 'CouldNotUpdateItem' : 'CouldNotCreateItem', { Item: t('Activity') }))
   }
}

export const onActivityDelete = async (
   dataContext: IDataContext,
   activityId: string,
   setActivities: React.Dispatch<React.SetStateAction<Activity[]>>,
   onClose: () => void
) => {
   const { accessToken } = dataContext.state
   try {
      await api(accessToken).deleteActivityById(activityId)
      setActivities((prev) => prev.filter((act) => act.id !== activityId))
      onClose()
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyDeleted', { Item: t('Activity') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotDeleteItem', { Item: t('Activity') }))
   }
}

export const getDefaultValues = (reset: UseFormReset<Activity>, activity?: Activity) => {
   reset({
      title: activity?.title || '',
      description: activity?.description || '',
      startDate: activity?.startDate || '',
      endDate: activity?.endDate || '',
   })
}
