import React from 'react'
import { Divider, Label, makeStyles, Text, tokens } from '@fluentui/react-components'
import { t } from 'i18next'
import { RiskStatus, SimplifiedRisk } from '../../../api/schemas/schema'
import { GridItem } from '../../common/grid/GridItem'
import { ControlledRadioGroup } from '../../common/inputs/ControlledRadioGroup'
import { Control, UseFormWatch } from 'react-hook-form'
import { ControlledMoneyInput } from '../../common/inputs/ControlledMoneyInput'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { ProtectedAssetGoalPopover } from '../../common/popover/ProtectedAssetGoalPopover'

const useStyles = makeStyles({
   column: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
   },
   popoverWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: tokens.spacingVerticalM,
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   showInPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
})

type RiskActionPlanInfoProps = {
   control: Control<SimplifiedRisk, any>
   watch: UseFormWatch<SimplifiedRisk>
   risk: SimplifiedRisk
   disabled: boolean
}
export const RiskActionPlanInfo = ({ control, watch, risk, disabled }: RiskActionPlanInfoProps) => {
   const classes = useStyles()

   return (
      <>
         <GridItem size="12/12" className={classes.hideInPhone}>
            <Divider appearance="brand" />
            {risk?.protectedAssetGoal && (
               <>
                  <div className={classes.popoverWrapper}>
                     <ProtectedAssetGoalPopover protectedAssetGoal={risk?.protectedAssetGoal} />
                  </div>
                  <Divider appearance="brand" />
               </>
            )}
         </GridItem>
         <GridItem size="12/12" className={classes.showInPhone}>
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('RiskScenario')}</Label>
               <Text>{risk?.scenario}</Text>
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('RiskSource')}</Label>
               <Text>{risk?.source}</Text>
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('WeaknessesOrCurrentProtection')}</Label>
               <Text>{risk?.currentState}</Text>
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('Consequence')}</Label>
               <Text>{risk?.consequence}</Text>
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('UnderlyingMotivationAndStand')}</Label>
               <Text>{risk?.underlyingMotivation}</Text>
            </div>
         </GridItem>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <ControlledRadioGroup
               control={control}
               name={'status'}
               label={t('RiskLevel')}
               disabled={disabled}
               required
               options={[
                  { label: t('Unacceptable'), value: RiskStatus.Unacceptable },
                  { label: t('Questionable'), value: RiskStatus.Questionable },
                  { label: t('Acceptable'), value: RiskStatus.Acceptable },
               ]}
               convertValue
            />
         </GridItem>
         {(watch('status') === RiskStatus.Unacceptable || watch('status') === RiskStatus.Questionable) && (
            <>
               <GridItem size="12/12">
                  <ControlledTextInput control={control} name={'proposedAction'} label={t('ProposedAction')} multiline required disabled={disabled} />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledMoneyInput control={control} name={'estimatedCost'} label={t('EstimatedCost')} disabled={disabled} />
               </GridItem>
            </>
         )}
         <GridItem size="12/12">
            <ControlledTextInput control={control} name={'comment'} label={t('UnderlyingMotivationAndStand')} multiline disabled={disabled} />
         </GridItem>
      </>
   )
}
