import React from 'react'
import { Button, Dropdown, makeStyles, Option, tokens } from '@fluentui/react-components'
import { ArrowExport20Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import { Card } from '../../common/card/Card'
import { exportDemandActionPlansToExcel } from '../../../helpers/excelHelper'
import { ActionPlan, DemandAnalysis } from '../../../api/schemas/schema'
import { ModalActionPlanReport } from './ModalActionPlanReport'
import { PhoneHeader } from '../../riskAnalyses/PhoneHeader'

type ActionPlanHeaderProps = {
   analysis: DemandAnalysis
   filteredActionPlans: ActionPlan[]
   setProcess: (process: string) => void
   processes: string[]
   selectedProcess: string
}
const useStyles = makeStyles({
   processDropdown: {
      '@media screen and (max-width: 1007px)': {
         width: '100%',
      },
   },
   content: {
      display: 'flex',
      gap: tokens.spacingHorizontalXL,
      '@media screen and (max-width: 1007px)': {
         marginBottom: tokens.spacingVerticalM,
         flexDirection: 'column',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
})
export const ActionPlanHeader = ({ analysis, filteredActionPlans, setProcess, processes, selectedProcess }: ActionPlanHeaderProps) => {
   const classes = useStyles()
   return (
      <>
      <PhoneHeader title={t('ActionPlan')} action={<ModalActionPlanReport actionPlans={analysis.actionPlans} questions={analysis.questions} />} />
         <Card
            title={t('ActionPlan')}
            transparentInPhone
            contentClassName={classes.content}
            action={<ModalActionPlanReport actionPlans={analysis.actionPlans} questions={analysis.questions} />}
         >
            <Dropdown
               className={classes.processDropdown}
               value={selectedProcess}
               selectedOptions={[selectedProcess]}
               onOptionSelect={(e, data) => {
                  setProcess(data.optionValue)
               }}
            >
               {processes.map((x) => {
                  return <Option key={x}>{x}</Option>
               })}
            </Dropdown>
            <Button
               className={classes.noneInPhone}
               icon={<ArrowExport20Regular />}
               onClick={() => exportDemandActionPlansToExcel(analysis, filteredActionPlans)}
            >
               {t('ExportToExcel')}
            </Button>
         </Card>
      </>
   )
}
