/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { DemandAnalysis, DemandAnalysisQuestion, DemandAnalysisQuestionStatus } from '../../../api/schemas/schema'
import { Dialog, DialogTitle, DialogContent, Divider, RadioGroup, Radio, Button, makeStyles, tokens, Tooltip } from '@fluentui/react-components'
import { Text } from '../../common/text/Text'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { useForm } from 'react-hook-form'
import { ModalActions, DialogSurface, DialogBody } from '../../common/modal/Modal'
import { api } from '../../../helpers/apiHelper'
import { DataContext } from '../../../context/DataContext'
import { handleMessage } from '../../../helpers/stateHelper'
import { Field } from '../../common/inputs/Field'
import { RTFOutput } from '../../common/outputs/RTFOutput'
import { t } from '../../../i18n/i18n'
import { ControlledMoneyInput } from '../../common/inputs/ControlledMoneyInput'
import { InformationPopovers } from './DemandInformationPopovers'
import { IItemState } from '../../../interfaces/IItemState'

type ModalAnalysisQuestionProps = {
   item: DemandAnalysisQuestion
   close: () => void
   setItems: React.Dispatch<React.SetStateAction<DemandAnalysisQuestion[]>>
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<DemandAnalysis>>>
   analysisDisabled: boolean
}
const useStyles = makeStyles({
   formWrapper: {
      marginTop: tokens.spacingVerticalM,
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalL,
   },
   textWrapper: {
      '> p:last-child': {
         marginTop: tokens.spacingVerticalXS,
      },
      '> p:first-child': {
         marginBottom: 0,
      },
   },
   radioToolTip: {
      top: '5px !important',
   },
   inputWrapper: {
      '& > :not(:first-child)': {
         marginTop: tokens.spacingVerticalM,
      },
   },
   popoversContainer: {
      justifyContent: 'space-around',
   },
})
export const ModalAnalysisQuestion = ({ item, close, setItems, setAnalysisState, analysisDisabled }: ModalAnalysisQuestionProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount, organisation } = dataContext.state
   const classes = useStyles()
   const { control, setValue, watch, reset, handleSubmit, formState: {isSubmitting} } = useForm<DemandAnalysisQuestion>({ disabled: analysisDisabled })

   useEffect(() => {
      reset(item)
   }, [item])
   const isClosed = item && item.status === DemandAnalysisQuestionStatus.Determined
   const onSubmit = async (data: DemandAnalysisQuestion) => {
      try {
         if (!data.answer.actionRequired) setValue('proposedAction', null)
         if (!data.answeredById) {
            data.answeredById = userAccount.id
            data.answeredBy = userAccount
         }
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id
         const updatedQuestion: any = (await api(accessToken).updateDemandAnalysisQuestion(organisation.id, data)).data

         setItems((prev) => {
            const updatedItems = [...prev]
            updatedItems[updatedItems.findIndex((x) => x.id === updatedQuestion.value.id)] = updatedQuestion.value
            return updatedItems
         })
         setAnalysisState((prev) => {
            const updatedItems = [...prev.item.questions]
            updatedItems[updatedItems.findIndex((x) => x.id === updatedQuestion.value.id)] = updatedQuestion.value
            return { ...prev, item: { ...prev.item, questions: updatedItems } }
         })
         handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Question') }))
         reset()
         close()
      } catch (error: any) {
         handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('Question') }), error.error.message)
      }
   }
   return (
      <Dialog open={item ? true : false} modalType={'modal'}>
         {item ? (
            <DialogSurface>
               <DialogBody>
                  <DialogTitle as="h3">{t('ComplianceOfDemands')}</DialogTitle>

                  <DialogContent>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <InformationPopovers className={classes.popoversContainer} item={item} />

                        <div className={classes.textWrapper}>
                           <Text weight="bold">
                              {t('Question')} {item.number}
                           </Text>
                           <RTFOutput text={item.text} />
                        </div>
                        <Divider appearance="brand" />
                        <section className={classes.formWrapper}>
                           <Field label={t('SpecifyCompliance')} required>
                              <RadioGroup
                                 disabled={isClosed || analysisDisabled}
                                 value={watch('answerId') ?? ''}
                                 onChange={(e, data) => {
                                    setValue('answerId', data.value)
                                    setValue(
                                       'answer',
                                       item.answerOptions.find((x) => x.id === data.value)
                                    )
                                 }}
                              >
                                 {item.answerOptions.map((o) => (
                                    <Tooltip
                                       key={o.id}
                                       showDelay={250}
                                       content={{ children: o.infoLabel, className: classes.radioToolTip }}
                                       relationship="description"
                                       withArrow
                                    >
                                       <Radio value={o.id} label={o.text} />
                                    </Tooltip>
                                 ))}
                              </RadioGroup>
                           </Field>
                           {watch('answer') && watch('answer').actionRequired ? (
                              <div className={classes.inputWrapper}>
                                 <ControlledTextInput
                                    disabled={isClosed}
                                    label={t('ProposedAction')}
                                    control={control}
                                    name={'proposedAction'}
                                    multiline
                                    rows={2}
                                    required
                                 />
                                 <ControlledMoneyInput disabled={isClosed} label={t('EstimatedCost')} name={'estimatedCost'} control={control} />
                              </div>
                           ) : null}
                           <ControlledTextInput disabled={isClosed} label={t('Comment')} control={control} name={'comment'} multiline rows={2} />
                           <ModalActions>
                              <Button
                                 onClick={() => {
                                    reset()
                                    close()
                                 }}
                              >
                                 {!isClosed && !analysisDisabled ? t('Cancel') : t('Close')}
                              </Button>
                              {!isClosed && !analysisDisabled && (
                                 <Button type="submit" appearance="primary" disabled={isSubmitting}>
                                    {t('Save')}
                                 </Button>
                              )}
                           </ModalActions>
                        </section>
                     </form>
                  </DialogContent>
               </DialogBody>
            </DialogSurface>
         ) : null}
      </Dialog>
   )
}
