import React, { Fragment, useContext, useEffect, useId } from 'react'
import {
   tokens,
   shorthands,
   makeStyles,
   Toast,
   Toaster,
   useToastController,
   ToastBody,
   ToastTitle,
   ToastIntent,
   mergeClasses,
   Breadcrumb,
   BreadcrumbButton,
   BreadcrumbDivider,
   BreadcrumbItem,
   Divider,
} from '@fluentui/react-components'
import { Header } from '../components/common/header/Header'
import { Loader } from '../components/common/spinner/Loader'
import { IBreadcrumbInfo } from '../interfaces/IBreadcrumbInfo'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Title } from '../components/common/text/Title'
import { ChevronLeft24Regular } from '@fluentui/react-icons'
import { DataContext } from '../context/DataContext'
type LayoutProps = {
   children: JSX.Element[] | JSX.Element | string | string[]
   breadCrumbItems: IBreadcrumbInfo[]
   mainClassname?: string
   title?: string
}

const useStyles = makeStyles({
   layout: {
      minHeight: '100vh',
   },
   main: {
      marginLeft: '64px',
      paddingTop: '60px',
      backgroundColor: tokens.colorNeutralBackground4,
      minHeight: '100vh',
      '@media screen and (max-width: 1007px)': {
         minHeight: 'calc(100vh - 100px)',
         paddingBottom: '80px',
         marginLeft: 0,
      },
   },
   mainPadding: {
      ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL),
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingVerticalXXXL),
      '@media screen and (max-width: 1007px)': {
         ...shorthands.gap(tokens.spacingVerticalL),
         // ...shorthands.padding(0, tokens.spacingHorizontalL),
      },
   },
   header: {
      display: 'block',
      position: 'sticky',
      top: '60px',
      zIndex: 2,
      width: '100%',
      backgroundColor: tokens.colorNeutralBackground1,
      boxSizing: 'border-box',
      boxShadow: tokens.shadow8,
      ...shorthands.padding(0),
      '@media screen and (max-width: 1007px)': {
         boxShadow: 'none',
         backgroundColor: tokens.colorNeutralBackground4,
      },
   },
   breadCrumbWrapper: {
      ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXXL),
      '@media screen and (max-width: 1007px)': {
         ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalS, 0, tokens.spacingHorizontalS),
      },
   },
   breadCrumbWrapperPadding: {
      paddingLeft: tokens.spacingHorizontalL,
      paddingRight: tokens.spacingHorizontalL,
   },
   hideInPhone: {
      display: 'flex',
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   showOnlyPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   showOnlyPhoneInline: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'inline',
      },
   },
   width40: {
      '@media screen and (max-width: 800px)': {
         width: '40px',
      },
   },
   calcWidth: {
      height: 'auto',
      '@media screen and (max-width: 1007px)': {
         width: 'calc(100% - (2 * 40px))',
         textAlign: 'center',
      },
   },
   padding: {
      ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
   },
})

export const Layout = ({ children, breadCrumbItems, mainClassname, title }: LayoutProps) => {
   const classes = useStyles()
   const toasterId = useId()
   const { dispatchToast } = useToastController(toasterId)
   const dataContext = useContext(DataContext)
   const { message, initialLoading } = dataContext.state
   const navigate = useNavigate()
   const notify = (intent: ToastIntent, title: string, text: string) => {
      dispatchToast(
         <Toast>
            <ToastTitle>{title}</ToastTitle>
            {text && <ToastBody>{text}</ToastBody>}
         </Toast>,
         { intent }
      )
   }
   useEffect(() => {
      if (message) {
         notify(message.intent, message.title, message?.text)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message])
   const generateBreadcrumbs = (items: IBreadcrumbInfo[], navigate: NavigateFunction): JSX.Element[] => {
      return items.map((item, index) => {
         return (
            <Fragment key={item.title}>
               {items.length > 1 && index === items.findIndex((x) => x.active) - 1 && (
                  <BreadcrumbItem key={`${item.title}-path`} className={mergeClasses(classes.showOnlyPhone, classes.width40)} size="large">
                     <BreadcrumbButton
                        onClick={() => {
                           navigate(item.path)
                        }}
                     >
                        <ChevronLeft24Regular />
                     </BreadcrumbButton>
                  </BreadcrumbItem>
               )}

               <BreadcrumbItem
                  key={`${item.title}-title`}
                  className={mergeClasses(!item.active ? classes.hideInPhone : '', items.length > 1 ? classes.calcWidth : '')}
                  size="large"
               >
                  <BreadcrumbButton
                     className={items.length > 1 ? classes.calcWidth : ''}
                     onClick={() => {
                        if (!item.active) navigate(item.path)
                     }}
                     size="large"
                     current={item.active}
                  >
                     <Title as="h2" noMargin weight={item.active ? 'bold' : 'regular'}>
                        {item.title}
                     </Title>
                  </BreadcrumbButton>
               </BreadcrumbItem>
               {index !== items.length - 1 && (
                  <BreadcrumbDivider key={`${item.title}-divider`} className={classes.hideInPhone} style={{ fontSize: tokens.fontSizeBase500 }} />
               )}
               {index === items.findIndex((x) => x.active) && items.length > 1 && (
                  <BreadcrumbItem key={`${item.title}-item`} className={mergeClasses(classes.showOnlyPhone, classes.width40)} size="large" />
               )}
            </Fragment>
         )
      })
   }

   return (
      <div className={classes.layout}>
         <Header />

         <main className={classes.main}>
            <header className={classes.header}>
               <Breadcrumb className={mergeClasses(classes.breadCrumbWrapper, breadCrumbItems.length === 1 ? classes.breadCrumbWrapperPadding : '')}>
                  {generateBreadcrumbs(breadCrumbItems, navigate)}
               </Breadcrumb>
               {title && (
                  <div className={mergeClasses(classes.padding, classes.showOnlyPhone)}>
                     <Title as="h3" className={classes.showOnlyPhoneInline}>
                        {title}
                     </Title>
                     <Divider appearance="brand" />
                  </div>
               )}
            </header>

            <div className={mergeClasses(classes.mainPadding, mainClassname ?? '')}>{initialLoading ? <Loader opacity={false} /> : <>{children}</>}</div>
         </main>
         <Toaster pauseOnHover position="top-end" toasterId={toasterId} />
      </div>
   )
}
