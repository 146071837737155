import React from 'react'
import { Divider, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Title } from '../common/text/Title'
const useStyles = makeStyles({
   alignStart: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
   },
   flex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   divider: {
      marginTop: tokens.spacingVerticalXS,
   },
   action: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: tokens.spacingHorizontalL,
      padding: tokens.spacingVerticalM,
   },
   header: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
})
type SettingsPhoneHeaderProps = {
   title: string
   action?: JSX.Element
   navAction?: JSX.Element
   navActionAlign?: 'left' | 'right'
}
export const SettingsPhoneHeader = ({ title, action, navAction, navActionAlign }: SettingsPhoneHeaderProps) => {
   const classes = useStyles()
   return (
      <header className={classes.header}>
         <div className={mergeClasses(classes.flex , navActionAlign === 'left' ? classes.alignStart : null)}>
            <Title as="h3">{title}</Title>
            <span>{navAction ?? null}</span>
         </div>
         <Divider className={classes.divider} appearance="brand" />
         <span className={classes.action}>{action ?? null}</span>
      </header>
   )
}
