import React from 'react'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
const useStyles = makeStyles({
   textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
      '& > *': {
         margin: 0,
      },
   },
})
type TextOutputProps = {
   label: string
   text?: string
}

const TextOutput = ({ label, text }: TextOutputProps) => {
   const classes = useStyles()
   return (
      <div className={classes.textWrapper}>
         <Text as="p"  block size={400} weight="semibold" aria-label={label}>
            {label}
         </Text>
         <Text italic={text ? false : true} as="p" size={400} >
            {text ?? t('NoValueSet')}
         </Text>
      </div>
   )
}

export default TextOutput
