import React from 'react'

import { Badge, tokens } from '@fluentui/react-components'

type BadgeAmountCircleProps = {
   amount: Number
   className?: string
}
export const BadgeAmountCircle = ({ amount, className }: BadgeAmountCircleProps) => {
   return (
      <Badge
         appearance="outline"
         shape="circular"
         style={{ backgroundColor: 'transparent', color: tokens.colorBrandForeground1 }}
         size="medium"
         className={className ?? ''}
      >
         {amount.toString()}
      </Badge>
   )
}
