import React from 'react'
import { Divider, makeStyles, tokens, Text } from '@fluentui/react-components'
import { Cost, CostCategory } from '../../api/schemas/schema'
import { formatToSwedishCrowns } from '../../helpers/stringHelper'
import { BadgeCostCategory } from './BadgeCostCategory'
import { GridItem } from '../common/grid/GridItem'
import { AvatarAndNameOutput } from '../common/inputs/UserAvatarOutput'
import TextOutput from '../common/inputs/TextOutput'
import { t } from '../../i18n/i18n'
import { translateEnumValue } from '../../helpers/enumHelper'

const useStyles = makeStyles({
   textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
   },
})

type ParentCostInformationProps = {
   cost: Cost
}

export const ParentCostInformation = ({ cost }: ParentCostInformationProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="6/12" className={classes.textWrapper}>
            <Text size={400} weight="semibold">
               {t('Category')}
            </Text>
            <BadgeCostCategory category={cost.post.costCategory} />
         </GridItem>
         <GridItem size="6/12">
            <TextOutput label={t('Post')} text={cost.post.name ?? translateEnumValue(CostCategory, cost.post.costCategory)} />
         </GridItem>
         <GridItem size="6/12">
            <TextOutput label={t('Budget')} text={`${formatToSwedishCrowns(cost.budget ?? 0)} kr`} />
         </GridItem>
         <GridItem size="6/12">
            <TextOutput label={t('Result')} text={`${formatToSwedishCrowns(cost.result ?? 0)} kr`} />
         </GridItem>
         <GridItem size="12/12">
            <TextOutput label={t('Description')} text={cost.description} />
         </GridItem>
         <GridItem size="12/12">
            <TextOutput label={t('ProposedAction')} text={cost.proposedAction} />
         </GridItem>
         <GridItem size="12/12">
            <TextOutput label={t('Comment')} text={cost.comment} />
         </GridItem>
         <GridItem size="6/12">
            <TextOutput label={t('EstimatedCost')} text={`${formatToSwedishCrowns(cost.estimatedCost ?? 0)} kr`} />
         </GridItem>
         <GridItem size="6/12">
            <AvatarAndNameOutput label={t('ProposedBy')} userAccount={cost.proposedBy} />
         </GridItem>
      </>
   )
}
