import { TableColumnDefinition } from '@fluentui/react-components'
import { ProtectedAssetGoal, ProtectedAssetGoalType } from '../api/schemas/schema'
import { t } from 'i18next'
import { ISettingsState } from '../interfaces/ISettingsState'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import { getFullName } from './accountHelper'
import { formatToSwedishCrowns } from './stringHelper'

export const addProtectedAssetGoal = async (
   setSettingsState: React.Dispatch<React.SetStateAction<ISettingsState>>,
   close: () => void,
   dataContext: IDataContext,
   data: ProtectedAssetGoal
) => {
   try {
      const { accessToken, userAccount, organisation } = dataContext.state
      data.organisationId = organisation.id
      data.modifiedBy = getFullName(userAccount)
      data.createdBy = getFullName(userAccount)

      const addedProtectedAsset = (await api(accessToken).addProtectedAssetGoal(data)).data
      dataContext.setRootState((prev) => ({
         ...prev,
         protectedAssetGoals: [...prev.protectedAssetGoals, addedProtectedAsset],
      }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullySaved', { Item: getProtectedAssetGoalName(data.type) }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: getProtectedAssetGoalName(data.type) }))
   } finally {
      close()
   }
}

export const updateProtectedAssetGoal = async (
   setSettingsState: React.Dispatch<React.SetStateAction<ISettingsState>>,
   close: () => void,
   dataContext: IDataContext,
   data: ProtectedAssetGoal
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.modifiedBy = getFullName(userAccount)
      data.createdBy = getFullName(userAccount)

      const updatedProtectedAsset = (await api(accessToken).updateProtectedAssetGoal(data)).data
      dataContext.setRootState((prev) => ({
         ...prev,
         protectedAssetGoals: prev.protectedAssetGoals.map((item) => (item.id === updatedProtectedAsset.id ? updatedProtectedAsset : item)),
      }))

      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: getProtectedAssetGoalName(data.type) }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: getProtectedAssetGoalName(data.type) }))
   } finally {
      close()
   }
}

export const getProtectedAssetGoalName = (type: ProtectedAssetGoalType) => {
   switch (type) {
      case ProtectedAssetGoalType.ProtectedAsset:
         return t('ProtectedAsset')
      case ProtectedAssetGoalType.OrganisationalGoal:
         return t('OrganisationalGoal')
   }
}

export const enum SettingsTabs {
   ProtectedAssets = 'protectedAssets',
   OrganisationalGoals = 'organisationalGoals',
   Branches = 'branches',
   Roles = 'roles',
}

export const getSettingsColumnsByTab = (tab: string) => {
   switch (tab) {
      case SettingsTabs.ProtectedAssets:
      case SettingsTabs.OrganisationalGoals:
         return getProtectedAssetsOrganisationalGoalsColumns()
      case SettingsTabs.Branches:
      case SettingsTabs.Roles:
      default:
         return []
   }
}

export const getSettingsNoItemsTextByTab = (selectedTab: string) => {
   switch (selectedTab) {
      case SettingsTabs.ProtectedAssets:
         return t('TimeToCreateYourFirstItem', { Item: t('ProtectedAsset').toLowerCase() })
      case SettingsTabs.OrganisationalGoals:
         return t('TimeToCreateYourFirstItem', { Item: t('OrganisationalGoal').toLowerCase() })
      case SettingsTabs.Branches:
         return t('TimeToCreateYourFirstItem', { Item: t('Branch').toLowerCase() })
      case SettingsTabs.Roles:
         return t('TimeToCreateYourFirstItem', { Item: t('Roles').toLowerCase() })
      default:
         return
   }
}

export const getSettingsTitleByTab = (selectedTab: string) => {
   switch (selectedTab) {
      case SettingsTabs.ProtectedAssets:
         return t('ProtectedAssets')
      case SettingsTabs.OrganisationalGoals:
         return t('OrganisationalGoals')
      case SettingsTabs.Branches:
         return t('Branches')
      case SettingsTabs.Roles:
         return t('Roles')
      default:
         return
   }
}

export const getProtectedAssetsOrganisationalGoalsColumns = (): TableColumnDefinition<ProtectedAssetGoal>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => t('Name'),
      renderCell: (item) => item.name,
      compare: (a, b) => a.name.localeCompare(b.name),
   },
   {
      columnId: 'description',
      renderHeaderCell: () => t('Description'),
      renderCell: (item) => item.description,
      compare: (a, b) => a.description.localeCompare(b.description),
   },
   {
      columnId: 'valuation',
      renderHeaderCell: () => t('Valuation'),
      renderCell: (item) => `${formatToSwedishCrowns(item.valuation)} kr`,
      compare: (a, b) => a.valuation - b.valuation,
   },
   {
      columnId: 'branch',
      renderHeaderCell: () => t('BranchAffiliation'),
      renderCell: (item) => item?.branch?.name,
      compare: (a, b) => a?.branch?.name.localeCompare(b?.branch.name),
   },
   {
      columnId: 'process',
      renderHeaderCell: () => t('ProcessAffiliation'),
      renderCell: (item) => item.process,
      compare: (a, b) => a.process.localeCompare(b.process),
   },
]

export const isValidSettingsTab = (tab: string) =>
   tab === SettingsTabs.ProtectedAssets || tab === SettingsTabs.OrganisationalGoals || tab === SettingsTabs.Branches || tab === SettingsTabs.Roles
