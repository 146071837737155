import React, { useContext } from 'react'
import { makeStyles, tokens, Image, Button, shorthands, mergeClasses } from '@fluentui/react-components'
import { Dismiss24Filled, Navigation24Filled, Alert20Regular } from '@fluentui/react-icons'
import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import { useNavigate } from 'react-router-dom'
import { OrganisationMenu } from './OrganisationMenu'
import { UserAccountMenu } from './UserAccountMenu'
import { NavigationLink } from '../../../interfaces/INavigationLink'

const useStyles = makeStyles({
   header: {
      ...shorthands.padding(0, tokens.spacingHorizontalM, 0, tokens.spacingHorizontalM),
      width: '100vw',
      position: 'fixed',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: '60px',
      backgroundColor: tokens.colorBrandBackground,
      boxShadow: tokens.shadow2,
      '& > *': {
         width: '33%',
      },
      '@media screen and (max-width: 1007px)': {
         boxShadow: tokens.shadow8Brand,
      },
   },
   image: {
      height: '50px',
      ...shorthands.margin(0, 'auto'),
      display: 'block',
      '@media screen and (max-width: 1007px)': {
         height: '60px',
      },
   },
   colorWhite: {
      color: tokens.colorNeutralForegroundInverted,
      '&:hover': {
         color: tokens.colorBrandForegroundInvertedHover,
      },
   },
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         visibility: 'hidden',
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   showOnPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
   pointer: {
      cursor: 'pointer',
   },
   flex: {
      display: 'flex',
   },
   flexEnd: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      ...shorthands.gap(tokens.spacingHorizontalXXL),
      '@media screen and (max-width: 1007px)': {
         ...shorthands.gap(0),
      },
   },
})
export const Header = () => {
   const navigate = useNavigate()
   const dataContext = useContext(DataContext)
   const { navigationExpanded, organisation } = dataContext.state
   const classes = useStyles()

   const toggleNavigation = () => {
      dataContext.setRootState((prev) => ({
         ...prev,
         navigationExpanded: !prev.navigationExpanded,
      }))
   }

   const navToOverview = () => {
      dataContext.setRootState((prev) => ({
         ...prev,
         navigationExpanded: false,
      }))
      navigate(NavigationLink.Overview)
   }

   const getAlertBtn = (showOnPhone?: boolean): JSX.Element => (
      <Button
         className={mergeClasses(showOnPhone ? classes.showOnPhone : classes.noneInPhone, classes.colorWhite)}
         icon={<Alert20Regular />}
         appearance="transparent"
      />
   )

   return (
      <>
         <header className={classes.header}>
            <div className={classes.flex}>
               <Button
                  className={mergeClasses(classes.colorWhite, classes.pointer)}
                  icon={navigationExpanded ? <Dismiss24Filled /> : <Navigation24Filled />}
                  size="large"
                  appearance="transparent"
                  onClick={() => toggleNavigation()}
               />
               {getAlertBtn(true)}
            </div>
            <div>
               <Image alt={t('365Robust')} src="/365Robust.svg" className={mergeClasses(classes.image, classes.pointer)} onClick={navToOverview} />
            </div>
            <div className={classes.flexEnd}>
               {organisation && <OrganisationMenu />}
               {getAlertBtn(false)}
               <UserAccountMenu />
            </div>
         </header>
      </>
   )
}
