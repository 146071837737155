import React from 'react'
import {
   InfoShield20Regular,
   SearchShield20Regular,
   Scales20Regular,
   TaskListSquareLtr20Regular,
   TaskListSquareLtr24Filled,
   SearchShield20Filled,
   Scales20Filled,
   InfoShield20Filled,
} from '@fluentui/react-icons'
import { Tab } from '@fluentui/react-components'
import { SimplifiedRiskTabs } from '../../../helpers/riskAnalysisHelper'

type SimplifiedRiskNavLinksProps = {
   id: string
   active: string
   actionTab: JSX.Element
}
export const SimplifiedRiskAnalysisNavLinks = ({ active, id, actionTab }: SimplifiedRiskNavLinksProps) => {
   const baseLink = `/risk-management/${id}`
   return (
      <>
         <Tab
            value={`${baseLink}?tab=${SimplifiedRiskTabs.Info}`}
            icon={active === SimplifiedRiskTabs.Info ? <InfoShield20Filled /> : <InfoShield20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${SimplifiedRiskTabs.Identifying}`}
            icon={active === SimplifiedRiskTabs.Identifying ? <SearchShield20Filled /> : <SearchShield20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${SimplifiedRiskTabs.Assessment}`}
            icon={active === SimplifiedRiskTabs.Assessment ? <Scales20Filled /> : <Scales20Regular />}
         />
         <Tab
            value={`${baseLink}?tab=${SimplifiedRiskTabs.ActionPlan}`}
            icon={active === SimplifiedRiskTabs.ActionPlan ? <TaskListSquareLtr24Filled /> : <TaskListSquareLtr20Regular />}
         />
         {actionTab}
      </>
   )
}
