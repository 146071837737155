import React from 'react'
import { Modal } from '../../common/modal/Modal'
import { Grid } from '../../common/grid/Grid'
import { Button, Divider, makeStyles, Text, tokens } from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { GridItem } from '../../common/grid/GridItem'
import { RiskAssessmentBadge } from '../RiskBadges'
import { RiskStatus } from '../../../api/schemas/schema'
import { t } from 'i18next'
const useStyles = makeStyles({
   badge: {
      minWidth: '150px',
      minHeight: '40px',
      fontSize: tokens.fontSizeBase300,
   },
   textStyling: {
      marginLeft: tokens.spacingHorizontalL,
      '@media screen and (max-width: 1007px)': {
         marginLeft: 0,
         marginTop: tokens.spacingVerticalMNudge,
      },
   },
   header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: tokens.spacingVerticalL,
   },
   phoneColumn: {
      '@media screen and (max-width: 1007px)': {
         '& > *': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
         },
      },
   },
})

type ModalRiskCriteraProps = {
   open: boolean
   close: () => void
}

export const ModalRiskCritera = ({ open, close }: ModalRiskCriteraProps) => {
   const classes = useStyles()

   return (
      <Modal open={open} title={t('RiskCriteria')} action={<Button icon={<Dismiss24Regular />} appearance="transparent" onClick={close}></Button>}>
         <form>
            <Grid className={classes.phoneColumn}>
               <GridItem size="12/12">
                  <Divider appearance="brand" />
               </GridItem>

               <GridItem size="12/12">
                  <RiskAssessmentBadge status={RiskStatus.Unacceptable} className={classes.badge} />
                  <Text size={400} className={classes.textStyling}>
                     {t('BigRiskShouldBeAddressed')}
                  </Text>
               </GridItem>

               <GridItem size="12/12">
                  <RiskAssessmentBadge status={RiskStatus.Questionable} className={classes.badge} />
                  <Text size={400} className={classes.textStyling}>
                     {t('UncertaintyAboutRiskShouldBeAddressed')}
                  </Text>
               </GridItem>

               <GridItem size="12/12">
                  <RiskAssessmentBadge status={RiskStatus.Acceptable} className={classes.badge} />
                  <Text size={400} className={classes.textStyling}>
                     {t('SmallRiskShouldNotBeAddressed')}
                  </Text>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
