export enum NavigationLink {
   Overview = '/',
   AnnualCalendar = '/calendar',
   ComplianceOfDemands = '/compliance',
   RiskManagement = '/risk-management',
   RiskEconomy = '/risk-economy',
   Settings = '/settings',
}

export interface INavLink {
   value: NavigationLink
   label: string
   icon: {
      filled: JSX.Element
      regular: JSX.Element
   }
   accessCheck?: boolean
}
