import React, { useContext } from 'react'
import { Modal, ModalActions } from '../common/modal/Modal'
import { Divider, Button, ProgressBar, Text, makeStyles } from '@fluentui/react-components'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { t } from '../../i18n/i18n'
import { IListState } from '../../interfaces/IListState'
import { useForm } from 'react-hook-form'
import { FinancialYear } from '../../api/schemas/schema'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { ControlledAccountPicker } from '../common/inputs/ControlledAccountPicker'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { ControlledDropDown } from '../common/inputs/ControlledDropdown'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { DataClassification } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { addFinancialYear } from '../../helpers/riskEconomyHelper'
import { useNavigate } from 'react-router-dom'
const useStyles = makeStyles({
   btnRight: { justifyContent: 'end' },
})

type ModalAddFinancialYearProps = {
   setRiskEconomyState: React.Dispatch<React.SetStateAction<IListState<FinancialYear>>>
   riskEconomyState: IListState<FinancialYear>
}

export const ModalAddFinancialYear = ({ setRiskEconomyState, riskEconomyState }: ModalAddFinancialYearProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { open } = riskEconomyState
   const {
      control,
      setValue,
      watch,
      reset,
      handleSubmit,
      formState: { isSubmitting, isSubmitSuccessful, isValid },
   } = useForm<FinancialYear>()
   const navigate = useNavigate()
   const [addedFinancialYear, setAddedFinancialYear] = React.useState<FinancialYear>(null)

   const onSubmit = async (data: FinancialYear) => {
      const result = await addFinancialYear(setRiskEconomyState, dataContext, data)
      setAddedFinancialYear(result)

      setRiskEconomyState((prev) => ({ ...prev, open: false }))
   }

   const openItem = () => {
      navigate(`/risk-economy/${addedFinancialYear.id}`)
      reset()
      setAddedFinancialYear(null)
   }

   return (
      <>
         <Modal open={open} title={t('NewFinancialYear')}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Grid>
                  <GridItem size="12/12">
                     <Divider appearance="brand" />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('FinancialYear')} control={control} name="name" required />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDatePicker label={t('StartDate')} control={control} name="startDate" required />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDatePicker label={t('EndDate')} control={control} name="endDate" required />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledAccountPicker label={t('Owner')} control={control} name="owner" watch={watch} setValue={setValue} required />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        required
                        control={control}
                        name={'dataClassification'}
                        label={t('DataClassification')}
                        options={enumValuesToOptions(DataClassification)}
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledMoneyInput label={t('BudgetedRevenue')} control={control} name="budgetedRevenue" />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledTextInput label={t('Comment')} control={control} name="comment" multiline />
                  </GridItem>
                  <GridItem size="12/12">
                     <ModalActions>
                        <Button
                           onClick={() => {
                              reset()
                              setRiskEconomyState((prev) => ({ ...prev, open: false }))
                           }}
                        >
                           {t('Cancel')}
                        </Button>
                        <Button appearance="primary" type="submit" disabled={isSubmitting}>
                           {t('Create')}
                        </Button>
                     </ModalActions>
                  </GridItem>
               </Grid>
            </form>
         </Modal>

         <Modal
            modalType="alert"
            title={isSubmitting ? t('CreatingFinancialYear') : t('FinancialYearCreated')}
            open={isValid && (isSubmitting || isSubmitSuccessful)}
         >
            {isSubmitting ? (
               <>
                  <ProgressBar />
                  <Text>{t('ThisCanTakeAMoment')}</Text>
               </>
            ) : (
               <Text>{t('ClickContinueToGoToFinancialYear')}</Text>
            )}
            <ModalActions className={classes.btnRight}>
               {isSubmitSuccessful ? (
                  <Button appearance="primary" onClick={openItem}>
                     {t('Continue')}
                  </Button>
               ) : null}
            </ModalActions>
         </Modal>
      </>
   )
}
