import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { Button} from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { RiskAnalysis, RiskStatus, SimplifiedRisk } from '../../../api/schemas/schema'
import { GridItem } from '../../common/grid/GridItem'
import { Grid } from '../../common/grid/Grid'
import { api } from '../../../helpers/apiHelper'
import { DataContext } from '../../../context/DataContext'
import { handleMessage } from '../../../helpers/stateHelper'
import { RiskActionPlanInfo } from './RiskActionPlanInfo'
import { IItemState } from '../../../interfaces/IItemState'

type ModalRiskAnalyseEvaluateProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
   open: boolean
   close: () => void
   selectedRisk: SimplifiedRisk
   disabled?: boolean
}

export const ModalRiskAnalyseEvaluate = ({ setRiskAnalysisState, analysis, open, close, selectedRisk, disabled }: ModalRiskAnalyseEvaluateProps) => {
   const {
      control,
      reset,
      handleSubmit,
      watch,
      setValue,
      formState: { isSubmitting },
   } = useForm<SimplifiedRisk>()

   const dataContext = useContext(DataContext)
   const setRootState = dataContext.setRootState
   const { accessToken, userAccount } = dataContext.state

   useEffect(() => {
      if (selectedRisk) {
         reset(selectedRisk)
      }
   }, [selectedRisk, reset])

   useEffect(() => {
      if (watch('status') === RiskStatus.Acceptable) {
         setValue('proposedAction', null)
         setValue('estimatedCost', null)
      }
   })

   const onSubmit = async (data: SimplifiedRisk) => {
      try {
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id
         data.analysedBy = userAccount
         data.analysedById = userAccount.id

         const updatedRiskAnalysis = await api(accessToken).updateSimplifiedRiskForRiskAnalysis(analysis.id, data)
         setRiskAnalysisState((prev) => ({ ...prev, item: updatedRiskAnalysis.data }))
         close()
         handleMessage(setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('SimplifiedRiskAnalysis') }))
      } catch (error) {
         handleMessage(setRootState, 'error', t('CouldNotUpdateItem', { Item: t('SimplifiedRiskAnalysis') }))
      }
   }
   const onClose = () => {
      reset()
      close()
   }

   return (
      <Modal modalType="modal" open={open} title={t('AnalyseEvaluate')}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
               <RiskActionPlanInfo control={control} watch={watch} risk={selectedRisk} disabled={disabled} />
               <GridItem size="12/12">
                  <ModalActions>
                     <Button
                        onClick={() => {
                           onClose()
                        }}
                     >
                        {disabled ? t('Close') : t('Cancel')}
                     </Button>
                     <Button type="submit" appearance="primary" disabled={disabled ?? isSubmitting}>
                        {t('Save')}
                     </Button>
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
