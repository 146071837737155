import React from 'react'
import { AccordionItem, AccordionHeader, AccordionPanel, Switch, makeStyles, tokens, Text } from '@fluentui/react-components'
import { Grid } from '../../common/grid/Grid'
import { GridItem } from '../../common/grid/GridItem'
import { Modules, Organisation } from '../../../api/schemas/schema'
import { t } from 'i18next'
import { ControlledCheckbox } from '../../common/inputs/ControlledCheckbox'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { getModuleName, getModuleType } from '../../../helpers/subscriptionHelper'
const useStyles = makeStyles({
   textAlignCenter: {
      display: 'flex',
      alignItems: 'center',
   },
   marginTop: {
      marginTop: tokens.spacingVerticalXL,
   },
})

type SubscriptionAccordionItemProps = {
   title: string
   enumOptions: any
   filteredModules: Modules[]
   isSwitch?: boolean
   className?: string
   watch: UseFormWatch<Organisation>
   setValue: UseFormSetValue<Organisation>
   control: Control<Organisation>
   value: string
}

export const SubscriptionAccordionItem = ({
   title,
   enumOptions,
   filteredModules,
   isSwitch,
   className,
   watch,
   setValue,
   control,
   value
}: SubscriptionAccordionItemProps) => {
   const classes = useStyles()
   const existingModules = filteredModules || []

   const handleToggleModule = (moduleType: keyof Modules, isChecked: boolean, enumValue: number) => {
      const allModules = watch('modules') || []

      const updatedModules = allModules.map((mod) => {
         const typeInfo = getModuleType(mod)
         if (!typeInfo || typeInfo.moduleType !== moduleType || typeInfo.moduleEnumValue !== enumValue) {
            return mod
         }

         return { ...mod, isActivated: isChecked }
      })

      setValue('modules', updatedModules, { shouldValidate: true, shouldDirty: true })
   }

   return (
      <AccordionItem value={value} className={className}>
         <AccordionHeader className={classes.marginTop}>
            <Text size={400}  weight='semibold'>{title}</Text>
         </AccordionHeader>
         <AccordionPanel>
            <Grid>
               {existingModules.map((module, i) => {
                  const moduleType = getModuleType(module)
                  if (!moduleType) return null

                  return (
                     <GridItem size="6/12" key={i}>
                        <div className={classes.textAlignCenter}>
                           {isSwitch ? (
                              <>
                                 <Switch
                                    checked={module.isActivated}
                                    onChange={(e, data) => {
                                       handleToggleModule(moduleType.moduleType as keyof Modules, data.checked as boolean, moduleType.moduleEnumValue)
                                    }}
                                 />
                                 <Text size={400}>{t(`${getModuleName(module, enumOptions)}`)}</Text>
                              </>
                           ) : (
                              <ControlledCheckbox
                                 control={control}
                                 name={`modules.${moduleType.moduleType}.${moduleType.moduleEnumValue}.isActivated`}
                                 label={t(`${getModuleName(module, enumOptions)}`)}
                                 checked={module.isActivated}
                                 onChange={(e, data) => {
                                    handleToggleModule(moduleType.moduleType as keyof Modules, data.checked as boolean, moduleType.moduleEnumValue)
                                 }}
                              />
                           )}
                        </div>
                     </GridItem>
                  )
               })}
            </Grid>
         </AccordionPanel>
      </AccordionItem>
   )
}
