import React from 'react'
import { Card, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
const useStyles = makeStyles({
   beige: {
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
   },
   blue: {
      backgroundColor: tokens.colorBrandBackground,
   },
   card: {
      boxShadow: tokens.shadow8,
      padding: `${tokens.spacingVerticalXXL}`,
      borderRadius: tokens.borderRadiusXLarge,
      ':hover': {
         backgroundColor: 'none',    
      },
   },
   centerChild: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   bigCard: {
      height: '60vh',
      '@media screen and (max-width: 1007px)': { height: 'auto' },
   },
})

type DashboardCardProps = {
   color: 'beige' | 'blue'
   bigCard?: boolean
   centerChild?: boolean
   children?: JSX.Element
   onClick?: () => void
   className?: string
}
export const DashboardCard = ({ color, bigCard, centerChild, children, onClick, className }: DashboardCardProps) => {
   const classes = useStyles()
   return (
      <Card
         className={mergeClasses(
            color === 'blue' ? classes.blue : classes.beige,
            classes.card,
            centerChild ? classes.centerChild : '',
            bigCard ? classes.bigCard : '',
            className ? className : ''
         )}
         onClick={onClick}
      >
         {children}
      </Card>
   )
}
