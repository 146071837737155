import React from 'react'
import { Checkbox, TableColumnDefinition, tokens } from '@fluentui/react-components'
import { t } from '../i18n/i18n'
import { ActionPlan, ActionPlanStatus, ConsequenceAndProbabilityRisk, RiskAnalysis, RiskLevel, RiskStatus, SimplifiedRisk, Threat } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import { RiskAssessmentBadge, RiskLevelBadge } from '../components/riskAnalyses/RiskBadges'
import { IGroupedTableColumnDefintion } from '../interfaces/IGroupedTableColumnDefintion'
import { GroupHeader } from '../components/common/list/GroupHeader'
import { BadgeActionPlanStatus } from '../components/demandAnalysis/BadgeActionPlanStatus'
import { formatToSwedishCrowns } from './stringHelper'
import { ISummaryField } from '../interfaces/ISummaryField'
import { IGroup } from '../interfaces/IGroup'
import { translateEnumValue } from './enumHelper'
import { ConsequenceScaleLabel } from '../components/riskAnalyses/ConsequenceAndProbabilityAnalysis/ConsequenceScaleLabel'
import { BadgeConsequenceProbability } from '../components/riskAnalyses/ConsequenceAndProbabilityAnalysis/BadgeConsequenceProbability'
import { IItemState } from '../interfaces/IItemState'

export const addThreat = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>,
   close: () => void,
   dataContext: IDataContext,
   data: Threat,
   parent: RiskAnalysis
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id
      data.createdBy = userAccount
      data.createdById = userAccount.id

      const addedThreat = (await api(accessToken).addThreat(parent.id, data)).data
      setRiskAnalysisState((prev) => ({ ...prev, item: { ...prev.item, threats: prev.item.threats ? [...prev.item.threats, addedThreat] : [addedThreat] } }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyCreated', { Item: t('Threat') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('Threat') }))
   } finally {
      close()
   }
}

export const updateThreat = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>,
   close: () => void,
   dataContext: IDataContext,
   data: Threat
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      const updatedThreat = (await api(accessToken).updateThreat(data)).data
      setRiskAnalysisState((prev) => ({
         ...prev,
         item: {
            ...prev.item,
            threats: prev.item.threats.map((t) => (t.id === updatedThreat.id ? updatedThreat : t)),
         },
      }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Threat') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Threat') }))
   } finally {
      close()
   }
}

export const addSimplifiedRisk = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>,
   close: () => void,
   dataContext: IDataContext,
   data: SimplifiedRisk,
   parent: RiskAnalysis
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.riskAnalysisId = parent.id
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id
      data.createdBy = userAccount
      data.createdById = userAccount.id

      const addedRisk = (await api(accessToken).addSimplifiedRisk(data)).data
      setRiskAnalysisState((prev) => ({
         ...prev,
         item: { ...prev.item, simplifiedRisks: prev.item.simplifiedRisks ? [...prev.item.simplifiedRisks, addedRisk] : [addedRisk] },
      }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('Risk') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('Risk') }))
   } finally {
      close()
   }
}

export const updateSimplifiedRisk = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>,
   close: () => void,
   dataContext: IDataContext,
   data: SimplifiedRisk
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      const updatedRisk = (await api(accessToken).updateSimplifiedRisk(data)).data
      setRiskAnalysisState((prev) => ({
         ...prev,
         item: {
            ...prev.item,
            simplifiedRisks: prev.item.simplifiedRisks.map((r) => (r.id === updatedRisk.id ? updatedRisk : r)),
         },
      }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Risk') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Risk') }))
   } finally {
      close()
   }
}

export const addConsequenceAndProbabilityRisk = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>,
   close: () => void,
   dataContext: IDataContext,
   data: ConsequenceAndProbabilityRisk,
   parent: RiskAnalysis
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.riskAnalysisId = parent.id
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id
      data.createdBy = userAccount
      data.createdById = userAccount.id

      const addedRisk = (await api(accessToken).addConsequenceAndProbabilityRisk(data)).data
      setRiskAnalysisState((prev) => ({
         ...prev,
         item: {
            ...prev.item,
            consequenceAndProbabilityRisks: prev.item.consequenceAndProbabilityRisks ? [...prev.item.consequenceAndProbabilityRisks, addedRisk] : [addedRisk],
         },
      }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullySaved', { Item: t('Risk') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('Risk') }))
   } finally {
      close()
   }
}

export const updateConsequenceAndProbabilityRisk = async (
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>,
   close: () => void,
   dataContext: IDataContext,
   data: ConsequenceAndProbabilityRisk
) => {
   try {
      const { accessToken, userAccount } = dataContext.state
      data.modifiedBy = userAccount
      data.modifiedById = userAccount.id

      const updatedRisk = (await api(accessToken).updateConsequenceAndProbabilityRisk(data)).data
      setRiskAnalysisState((prev) => ({
         ...prev,
         item: {
            ...prev.item,
            consequenceAndProbabilityRisks: prev.item.consequenceAndProbabilityRisks.map((r) => (r.id === updatedRisk.id ? updatedRisk : r)),
         },
      }))
      handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Risk') }))
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Risk') }))
   } finally {
      close()
   }
}

export const updateActionPlan = async (
   data: ActionPlan,
   accessToken: string,
   parent: RiskAnalysis,
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
) => {
   try {
      const updatedRiskAnalysis = (await api(accessToken).updateActionPlanOnRiskAnalysis(parent.id, data)).data
      setRiskAnalysisState((prev) => ({ ...prev, item: updatedRiskAnalysis }))
      return true
   } catch (error: any) {
      return false
   }
}

export const getSimplifiedRiskIdentifyingColumns = (): TableColumnDefinition<SimplifiedRisk>[] => [
   {
      columnId: 'scenario',
      renderHeaderCell: () => t('RiskScenario'),
      renderCell: (item) => item.scenario,
      compare: (a, b) => a.scenario.localeCompare(b.scenario),
   },
   {
      columnId: 'consequence',
      renderHeaderCell: () => t('Consequence'),
      renderCell: (item) => item.consequence,
      compare: (a, b) => a.consequence.localeCompare(b.consequence),
   },
   {
      columnId: 'protectedAsset',
      renderHeaderCell: () => t('ProtectedAsset/OrganisationalGoal'),
      renderCell: (item) => item?.protectedAssetGoal?.name,
      compare: (a, b) => a.source.localeCompare(b.source),
   },
]

export enum SimplifiedRiskTabs {
   Info = 'info',
   Identifying = 'identifying',
   Assessment = 'assessment',
   ActionPlan = 'action-plan',
}

export enum ConsequenceAndProbabilityAnalysisTabs {
   Info = 'info',
   ThreatScenario = 'threat-scenario',
   Identifying = 'identifying',
   Analysis = 'analysis',
   Evaluation = 'evaluation',
   ActionPlan = 'action-plan',
}

export const RiskColors = {
   NotAssessed: tokens.colorBrandBackgroundStatic,
   Unacceptable: tokens.colorStatusDangerBackground3,
   Questionable: '#EAA300',
   Acceptable: tokens.colorStatusSuccessBackground3,
}

export const RiskLevelColors = {
   VeryHigh: tokens.colorStatusDangerBackground3,
   High: tokens.colorStatusWarningBackground3,
   Moderate: '#EAA300',
   Low: '#13A10E',
   VeryLow: tokens.colorStatusSuccessBackground3,
}

export const getRiskStatusColor = (status: RiskStatus) => {
   switch (status) {
      case RiskStatus.Unacceptable:
         return RiskColors.Unacceptable
      case RiskStatus.Questionable:
         return RiskColors.Questionable
      case RiskStatus.Acceptable:
         return RiskColors.Acceptable
      default:
         return RiskColors.NotAssessed
   }
}

export const getRiskLevelColor = (riskLevel: RiskLevel) => {
   switch (riskLevel) {
      case RiskLevel.VeryHigh:
         return RiskLevelColors.VeryHigh
      case RiskLevel.High:
         return RiskLevelColors.High
      case RiskLevel.Moderate:
         return RiskLevelColors.Moderate
      case RiskLevel.Low:
         return RiskLevelColors.Low
      case RiskLevel.VeryLow:
         return RiskLevelColors.VeryLow
      default:
         return RiskLevelColors.VeryHigh
   }
}

export const getRiskAssessmentBadgeStyle = (status: RiskStatus) => ({
   backgroundColor: getRiskStatusColor(status),
   color: 'white',
})

export const getRiskLevelBadgeStyle = (riskLevel: RiskLevel) => ({
   backgroundColor: getRiskLevelColor(riskLevel),
   color: 'white',
})

export const getSimplifiedRiskAnalyseEvaluateColumns = (): TableColumnDefinition<SimplifiedRisk>[] => [
   {
      columnId: 'assesment',
      renderHeaderCell: () => t('RiskAssesment'),
      renderCell: (risk) => <RiskAssessmentBadge status={risk.status} largeBadge />,
      compare: (a, b) => a.status.toString().localeCompare(b.status.toString()),
   },
   {
      columnId: 'scenario',
      renderHeaderCell: () => t('RiskScenario'),
      renderCell: (risk) => risk.scenario,
      compare: (a, b) => a.scenario.localeCompare(b.scenario),
   },
   {
      columnId: 'consequence',
      renderHeaderCell: () => t('Consequence'),
      renderCell: (risk) => risk.consequence,
      compare: (a, b) => a.consequence.localeCompare(b.consequence),
   },
   {
      columnId: 'protectedAsset',
      renderHeaderCell: () => t('ProtectedAsset/OrganisationalGoal'),
      renderCell: (risk) => risk?.protectedAssetGoal?.name,
      compare: (a, b) => a.source.localeCompare(b.source),
   },
]

export const getSimplifiedRiskActionPlanGroups = (risks: SimplifiedRisk[], actionPlans: ActionPlan[]): IGroup<ActionPlan>[] => {
   const groups: IGroup<ActionPlan>[] = []
   actionPlans.forEach((plan) => {
      const risk = risks.find((r) => r.id === plan.riskId)
      const key = risk.status.toString()
      const label = translateEnumValue(RiskStatus, risk.status)
      const group = groups.find((g) => g.key === key)
      if (!group) {
         groups.push({ label, key, order: risk.status, hex: '', expanded: true, items: [] })
      }
      groups.find((g) => g.key === key).items.push(plan)
   })
   return groups.sort((a, b) => a.order - b.order)
}

export const getSimplifiedRiskActionPlanSummaryFields = (actionPlans: ActionPlan[], risks: SimplifiedRisk[]): ISummaryField[] => {
   let estimatedCost = 0
   actionPlans.forEach((plan) => {
      const risk = risks.find((r) => r.id === plan.riskId)
      estimatedCost += risk.estimatedCost ?? 0
   })
   const totalAnswered = actionPlans.filter((plan) => plan.status !== ActionPlanStatus.NotDecided).length
   const totalItems = actionPlans.length
   return [
      { label: t('TotalEstimatedCost'), text: `${formatToSwedishCrowns(estimatedCost)} kr` },
      { label: t('DecidedMulti'), text: `${totalAnswered} / ${totalItems}` },
   ]
}

export const findSimplifiedRiskParentToActionPlan = (risks: SimplifiedRisk[], actionPlan: ActionPlan) => risks.find((r) => r.id === actionPlan.riskId)
export const findConsequenceRiskParentToActionPlan = (risks: ConsequenceAndProbabilityRisk[], actionPlan: ActionPlan) =>
   risks.find((r) => r.id === actionPlan.riskId)

export const getSimplifiedRiskActionPlanColumns = (risks: SimplifiedRisk[]): IGroupedTableColumnDefintion<ActionPlan>[] => [
   {
      columnId: 'title',
      colWidth: 'auto',
      renderHeaderCell: () => t('Title'),
      renderGroupHeaderCell: (group) => {
         return <GroupHeader title={t('SimplifiedRiskAnalysis')} badge={<RiskAssessmentBadge largeBadge status={Number(group.key)} />} />
      },
      renderCell: (actionPlan) => {
         const risk = findSimplifiedRiskParentToActionPlan(risks, actionPlan)
         return risk.scenario
      },
      compare: () => -1,
   },
   {
      columnId: 'estimatedCost',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('EstimatedCostShort'),
      renderCell: (actionPlan) => {
         const risk = findSimplifiedRiskParentToActionPlan(risks, actionPlan)
         return risk.estimatedCost || risk.estimatedCost === 0 ? formatToSwedishCrowns(risk.estimatedCost) + ' kr' : null
      },
      renderGroupHeaderCell: (group) => {
         if (group.items.every((x) => findSimplifiedRiskParentToActionPlan(risks, x).estimatedCost === null)) return ''

         let estimatedCostTotal = 0
         group.items.map((x) => (estimatedCostTotal += findSimplifiedRiskParentToActionPlan(risks, x).estimatedCost))
         return formatToSwedishCrowns(estimatedCostTotal) + ' kr'
      },
      compare: () => -1,
   },
   {
      columnId: 'status',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('DecisionStatus'),
      renderCell: (item) => <BadgeActionPlanStatus status={item.status} />,
      renderGroupHeaderCell: (group) => {
         const totalAnswered = group.items.filter((item) => item.status !== ActionPlanStatus.NotDecided).length
         const totalItems = group.items.length
         return `${totalAnswered} / ${totalItems}`
      },
      compare: () => -1,
   },
]

export const getConsequenceAndProbabilityRiskActionPlanColumns = (risks: ConsequenceAndProbabilityRisk[]): IGroupedTableColumnDefintion<ActionPlan>[] => [
   {
      columnId: 'title',
      colWidth: 'auto',
      renderHeaderCell: () => t('Title'),
      renderGroupHeaderCell: (group) => {
         return <GroupHeader title={t('RiskLevel')} badge={<RiskLevelBadge largeBadge riskLevel={Number(group.key)} />} />
      },
      renderCell: (actionPlan) => {
         const risk = findConsequenceRiskParentToActionPlan(risks, actionPlan)
         return risk.scenario
      },
      compare: () => -1,
   },
   {
      columnId: 'estimatedCost',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('EstimatedCostShort'),
      renderCell: (actionPlan) => {
         const risk = findConsequenceRiskParentToActionPlan(risks, actionPlan)
         return risk.estimatedCost || risk.estimatedCost === 0 ? formatToSwedishCrowns(risk.estimatedCost) + ' kr' : null
      },
      renderGroupHeaderCell: (group) => {
         if (group.items.every((x) => findConsequenceRiskParentToActionPlan(risks, x).estimatedCost === null)) return ''

         let estimatedCostTotal = 0
         group.items.map((x) => (estimatedCostTotal += findConsequenceRiskParentToActionPlan(risks, x).estimatedCost))
         return formatToSwedishCrowns(estimatedCostTotal) + ' kr'
      },
      compare: () => -1,
   },
   {
      columnId: 'status',
      colWidth: 'auto',
      align: 'left',
      renderHeaderCell: () => t('DecisionStatus'),
      renderCell: (item) => <BadgeActionPlanStatus status={item.status} />,
      renderGroupHeaderCell: (group) => {
         const totalAnswered = group.items.filter((item) => item.status !== ActionPlanStatus.NotDecided).length
         const totalItems = group.items.length
         return `${totalAnswered} / ${totalItems}`
      },
      compare: () => -1,
   },
]

export const getConsequenceAndProbabilityRiskActionPlanGroups = (risks: ConsequenceAndProbabilityRisk[], actionPlans: ActionPlan[]): IGroup<ActionPlan>[] => {
   const groups: IGroup<ActionPlan>[] = []
   actionPlans.forEach((plan) => {
      const risk = risks.find((r) => r.id === plan.riskId)
      const key = risk.riskLevel.toString()
      const label = translateEnumValue(RiskLevel, risk.riskLevel)
      const group = groups.find((g) => g.key === key)
      if (!group) {
         groups.push({ label, key, order: risk.riskLevel, hex: '', expanded: true, items: [] })
      }
      groups.find((g) => g.key === key).items.push(plan)
   })
   return groups.sort((a, b) => a.order - b.order)
}

export const getConsequenceAndProbabilityRiskActionPlanSummaryFields = (actionPlans: ActionPlan[], risks: ConsequenceAndProbabilityRisk[]): ISummaryField[] => {
   let estimatedCost = 0
   actionPlans.forEach((plan) => {
      const risk = risks.find((r) => r.id === plan.riskId)
      estimatedCost += risk.estimatedCost ?? 0
   })
   const totalAnswered = actionPlans.filter((plan) => plan.status !== ActionPlanStatus.NotDecided).length
   const totalItems = actionPlans.length
   return [
      { label: t('TotalEstimatedCost'), text: `${formatToSwedishCrowns(estimatedCost)} kr` },
      { label: t('DecidedMulti'), text: `${totalAnswered} / ${totalItems}` },
   ]
}

type ThreatColumns = {
   threat: Threat
   analyseDate: string
}

export const getThreatColumns = (): TableColumnDefinition<ThreatColumns>[] => [
   {
      columnId: 'analyseDate',
      renderHeaderCell: () => t('AnalyseDate'),
      renderCell: (item) => new Date(item.analyseDate).toLocaleDateString(),
      compare: (a, b) => a.analyseDate.localeCompare(b.analyseDate),
   },
   {
      columnId: 'threat',
      renderHeaderCell: () => t('CurrentThreat'),
      renderCell: (item) => item.threat.name,
      compare: (a, b) => a.threat.name.localeCompare(b.threat.name),
   },
   {
      columnId: 'description',
      renderHeaderCell: () => t('Description'),
      renderCell: (item) => item.threat.description,
      compare: (a, b) => a.threat.description.localeCompare(b.threat.description),
   },
]

export const getRiskAnalysisColumns = (): TableColumnDefinition<ConsequenceAndProbabilityRisk>[] => [
   {
      columnId: 'riskScenario',
      renderHeaderCell: () => t('RiskScenario'),
      renderCell: (item) => item.scenario,
      compare: (a, b) => a.scenario.localeCompare(b.scenario),
   },
   {
      columnId: 'consequense',
      renderHeaderCell: () => t('Consequence'),
      renderCell: (item) => item.consequence,
      compare: (a, b) => a.consequence.localeCompare(b.consequence),
   },
   {
      columnId: 'consequenseScaleLifeHealth',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="L" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceLifeHealth} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'consequenseScaleEconomy',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="E" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceEconomy} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'consequenseScaleEnvironment',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="M" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceEnviroment} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'consequenseScaleBrand',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="V" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceBrand} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'probability',
      renderHeaderCell: () => 'S',
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.probability} />,
      compare: (a, b) => 0,
   },
]

export const getConsequenceProbabilityRiskEvaluateColumns = (): TableColumnDefinition<ConsequenceAndProbabilityRisk>[] => [
   {
      columnId: 'riskScenario',
      renderHeaderCell: () => t('RiskScenario'),
      renderCell: (item) => item.scenario,
      compare: (a, b) => a.scenario.localeCompare(b.scenario),
   },
   {
      columnId: 'consequence',
      renderHeaderCell: () => t('Consequence'),
      renderCell: (item) => item.consequence,
      compare: (a, b) => a.consequence.localeCompare(b.consequence),
   },
   {
      columnId: 'consequenceScaleLifeHealth',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="L" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceLifeHealth} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'consequenceScaleEconomy',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="E" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceEconomy} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'consequenceScaleEnvironment',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="M" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceEnviroment} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'consequenceScaleBrand',
      renderHeaderCell: () => <ConsequenceScaleLabel typeLabel="V" />,
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.consequenceBrand} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'probability',
      renderHeaderCell: () => 'S',
      renderCell: (item) => <BadgeConsequenceProbability valuation={item.probability} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'riskLevel',
      renderHeaderCell: () => t('RiskLevel'),
      renderCell: (item) => <RiskLevelBadge riskLevel={item.riskLevel} />,
      compare: (a, b) => 0,
   },
   {
      columnId: 'proposedAction',
      renderHeaderCell: () => t('ActionProposal'),
      renderCell: (item) => <Checkbox checked={item.actionRequired} />,
      compare: (a, b) => 0,
   },
]

export const getLatestSwotAnalysis = async (accessToken: string, organisationId: string, dataContext: IDataContext) => {
   try {
      const response = await api(accessToken).getLatestSwotAnalysis(organisationId)
      return response.data
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'error', t('CouldNotFetchItem', { Item: t('SWOT') }))
   }
}
