import React from 'react'
import { Organisation, UserAccount } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { useCallback, useContext, useEffect, useState } from 'react'
import { api } from '../../helpers/apiHelper'
import { Avatar, Button, Card, makeStyles, tokens, Text, mergeClasses } from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { Modal } from '../common/modal/Modal'
import { t } from '../../i18n/i18n'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { IOrganisationUserAccount } from '../../interfaces/IOrganisationUserAccount'
import { IOpenCloseModalProps } from '../../interfaces/IOpenCloseModalProps'
import { handleMessage } from '../../helpers/stateHelper'

const useStyles = makeStyles({
   cardsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingHorizontalL,
      padding: tokens.spacingVerticalM,
   },
   orgWrapper: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      boxShadow: tokens.shadow8,
   },
   orgDetails: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
   },
   modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: tokens.spacingVerticalM,
   },
   modalTitle: {
      margin: 0,
      fontSize: tokens.fontSizeBase500,
      fontWeight: tokens.fontWeightSemibold,
   },
   gridWrapper: {
      '@media screen and (min-width: 1007px)': {
         gap: tokens.spacingHorizontalXXL,
         '& > :nth-last-child(1):nth-child(odd)': {
            gridColumn: 'span 12',
            justifySelf: 'center',
            minWidth: '50%',
         },
      },
   },
   activeOrg: {
      backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
   },
})

export const SwitchOrganisation = ({ open, closeModal }: IOpenCloseModalProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const { accessToken, userAccount } = dataContext.state
   const [organisations, setOrganisations] = useState<Organisation[]>([])
   const [organisationUserAccount, setOrganisationUserAccount] = useState<IOrganisationUserAccount[]>([])

   useEffect(() => {
      const fetchData = async () => {
         try {
            const orgAndUserAcc = (await api(accessToken).getOrganisationsByUserGuid(userAccount.user.guid)) as unknown as { data: IOrganisationUserAccount[] }
            const orgs = orgAndUserAcc.data.map((keyValuePair) => keyValuePair.key)
            setOrganisations(orgs)
            setOrganisationUserAccount(orgAndUserAcc.data)
         } catch (error) {
            handleMessage(setRootState, 'error', t('SomethingWentWrong'), t('CouldNotSwitchOrganisation'))
         }
      }

      fetchData()
   }, [accessToken, setRootState, userAccount])

   const handleSwitchOrganisation = useCallback(
      (selectedOrganisation: Organisation, selectedAccount: UserAccount) => {
         localStorage.setItem('selectedOrganisation', JSON.stringify(selectedOrganisation))
         localStorage.setItem('selectedUserAccount', JSON.stringify(selectedAccount))

         setRootState((prev) => ({
            ...prev,
            organisation: selectedOrganisation,
            userAccount: selectedAccount,
         }))
      },
      [setRootState]
   )

   const handleOrganisationClick = (organisation: Organisation) => {
      const selectedAccount = organisationUserAccount.find((keyValuePair) => keyValuePair.key.id === organisation.id)?.value
      handleSwitchOrganisation(organisation, selectedAccount)
      closeModal()
   }

   return (
      <>
         <Modal open={open} title={null}>
            <div className={classes.modalHeader}>
               <Text role="heading" size={500} weight="semibold">
                  {t('SelectOrganisation')}
               </Text>
               <Button aria-label="Close" appearance="transparent" icon={<Dismiss24Regular />} onClick={closeModal} />
            </div>

            <div className={classes.cardsWrapper}>
               <Grid className={classes.gridWrapper}>
                  {organisations.map((org) => (
                     <GridItem key={org.id} size={'6/12'}>
                        <Card
                           onClick={() => handleOrganisationClick(org)}
                           className={mergeClasses(classes.orgWrapper, org.id === userAccount.organisationId ? classes.activeOrg : '')}
                        >
                           <Avatar name={org.name} size={56} />
                           <div className={classes.orgDetails}>
                              <Text size={400} weight="bold">
                                 {org.name}
                              </Text>
                              <Text size={300}>{org.organisationNumber}</Text>
                           </div>
                        </Card>
                     </GridItem>
                  ))}
               </Grid>
            </div>
         </Modal>
      </>
   )
}
