import React from 'react'
import { makeStyles, Text } from '@fluentui/react-components'
const useStyles = makeStyles({
   type: {
      position: 'relative',
      top: '4px',
   },
})
type ConsequenceScaleLabelProps = {
   typeLabel: 'L' | 'E' | 'M' | 'V'
}
export const ConsequenceScaleLabel = ({ typeLabel }: ConsequenceScaleLabelProps) => {
   const classes = useStyles()
   return (
      <span>
         <Text weight="bold" size={400}>
            K
         </Text>
         <Text weight="bold" size={200} className={classes.type}>
            {typeLabel}
         </Text>
      </span>
   )
}
