import React from 'react'
import { GridItem } from '../../common/grid/GridItem'
import { Text } from '../../common/text/Text'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { ConsequenceAndProbabilityRisk } from '../../../api/schemas/schema'
import { Classification20Regular, Classification20Filled } from '@fluentui/react-icons'
import { InformationPopover } from '../../common/popover/InformationPopover'
import { RiskLevelBadge } from '../RiskBadges'
import { RTFOutput } from '../../common/outputs/RTFOutput'
import { ThreatPopover } from '../../common/popover/ThreatPopover'
import { ProtectedAssetGoalPopover } from '../../common/popover/ProtectedAssetGoalPopover'
const useStyles = makeStyles({
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
   marginTopS: {
      marginTop: tokens.spacingVerticalS,
      marginBottom: tokens.spacingVerticalS,
   },
   popoverWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: tokens.spacingVerticalM,
   },
})
type ParentConsequenceRiskInformationProps = {
   selectedRisk: ConsequenceAndProbabilityRisk
}
export const ParentConsequencedRiskInformation = ({ selectedRisk }: ParentConsequenceRiskInformationProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
            <div className={classes.popoverWrapper}>
               {selectedRisk?.protectedAssetGoal?.name && (
                  <ProtectedAssetGoalPopover protectedAssetGoal={selectedRisk?.protectedAssetGoal} />
               )}
               {selectedRisk?.threat?.name && (
                  <ThreatPopover threat={selectedRisk?.threat} />
               )}
               {selectedRisk?.scenario && (
                  <InformationPopover
                     btnLabel={t('RiskScenario')}
                     popoverLabel={t('RiskScenario')}
                     input={<RTFOutput text={selectedRisk?.scenario} />}
                     regularIcon={<Classification20Regular />}
                     filledIcon={<Classification20Filled />}
                  />
               )}
            </div>
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('RiskLevel')}
            </Text>
            <RiskLevelBadge largeBadge riskLevel={selectedRisk.riskLevel} className={classes.marginTopS} />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('UnderlyingMotivationAndStand')}
            </Text>
            <Text className={classes.marginTopS}>{selectedRisk.underlyingMotivation}</Text>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ProposedAction')}
            </Text>
            <Text className={classes.marginTopS}>{selectedRisk.proposedAction}</Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('EstimatedCost')}
            </Text>
            <Text italic={!selectedRisk.estimatedCost && selectedRisk.estimatedCost !== 0} className={classes.marginTopS}>
               {selectedRisk.estimatedCost || selectedRisk.estimatedCost === 0 ? formatToSwedishCrowns(selectedRisk.estimatedCost) + ' kr' : t('NoValueSet')}
            </Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('AnalysedBy')}
            </Text>
            {/* <Avatar name={getFullName(selectedRisk.analysedBy)} className={classes.marginTopXS}/> */}
         </GridItem>
      </>
   )
}
