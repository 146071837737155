import React from 'react'
import { Info20Regular, Info20Filled } from '@fluentui/react-icons'
import { DemandAnalysisQuestion } from '../../../../api/schemas/schema'
import { t } from '../../../../i18n/i18n'
import { InformationPopover } from '../../../common/popover/InformationPopover'
import { RTFOutput } from '../../../common/outputs/RTFOutput'
type BackgroundInfoPopoverProps = {
   item: DemandAnalysisQuestion
}
export const BackgroundInfoPopover = ({ item }: BackgroundInfoPopoverProps) => {
   return (
      <InformationPopover
         btnLabel={t('BackgroundInformation')}
         popoverLabel={t('BackgroundInformationForTheQuestion')}
         filledIcon={<Info20Filled />}
         regularIcon={<Info20Regular />}
         input={<RTFOutput text={item.backgroundInformation !== '' ? item.backgroundInformation : t('NoAvailableInformation')} />}
      />
   )
}
