import React from 'react'
import { mergeClasses, Button, makeStyles, tokens } from '@fluentui/react-components'
import { Title } from '../common/text/Title'
import { Text } from '@fluentui/react-components'
const useStyles = makeStyles({
   card: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: tokens.borderRadiusXLarge,
      boxShadow: tokens.shadow8,
      backgroundColor: tokens.colorNeutralBackground1,
      padding: `${tokens.spacingVerticalXXL} ${tokens.spacingHorizontalXXXL}`,
      width: '400px',
      height: '200px',
      '@media screen and (max-width: 1007px)': {
         height: 'auto',
         boxSizing: 'border-box',
         width: '100%',
      },
   },
   button: {
      marginTop: tokens.spacingVerticalXXXL,
   },
})

type DashboardInfoCardProps = {
   title: string
   description: string
   buttonText: string
   onClick: () => void
   className?: string
}

export const DashboardInfoCard = ({ title, description, buttonText, onClick, className }: DashboardInfoCardProps) => {
   const classes = useStyles()
   return (
      <article className={mergeClasses(classes.card, className ? className : '')}>
         <Title as="h3" weight="semibold">
            {title}
         </Title>
         <Text size={400}>{description}</Text>
         <Button appearance="primary" className={classes.button} onClick={onClick}>
            {buttonText}
         </Button>
      </article>
   )
}
