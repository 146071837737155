import React, { useContext } from 'react'
import { List } from '../../common/list/List'
import { t } from '../../../i18n/i18n'
import { getDemandAssessmentStatusColumns } from '../../../helpers/demandAssessmentHelper'
import { IAnalysisStatusData } from '../../../interfaces/IAnalysisStatusData'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Text } from '../../common/text/Text'
import { LockClosed20Regular, Subtract20Regular, ArrowClockwise20Regular } from '@fluentui/react-icons'
import { Avatar } from '../../common/avatar/Avatar'
import { Badge } from '../../common/badge/Badge'
import { DataContext } from '../../../context/DataContext'

type QuestionStatusListProps = {
   analysisStatusData: IAnalysisStatusData[]
}
const useStyles = makeStyles({
   spaceBtw: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   column: {
      display: 'flex',
      flexDirection: 'column',

      ...shorthands.gap(tokens.spacingVerticalS),
   },
   mobileContentWrapper: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'flex',
         flexDirection: 'column',
         ...shorthands.gap(tokens.spacingVerticalXXL),
      },
   },
   contentEnd: {
      alignItems: 'end',
   },
})
export const QuestionStatusList = ({ analysisStatusData }: QuestionStatusListProps) => {
   const classes = useStyles()
   const { subscription } = useContext(DataContext).state.organisation

   return (
      <>
         <List
            items={analysisStatusData}
            columns={getDemandAssessmentStatusColumns(subscription)}
            renderPhoneBodyItem={(item) => (
               <div key={item.process} className={classes.spaceBtw}>
                  <span className={classes.column}>
                     <Text style={{ margin: '0' }} weight="bold">
                        {item.process}
                     </Text>
                     <Avatar name={item.processOwner} />
                  </span>
                  <span className={mergeClasses(classes.column, classes.contentEnd)}>
                     <Badge
                        appearance="tint"
                        style={{ minWidth: '40px' }}
                        icon={
                           item.status === t('Completed') ? (
                              <LockClosed20Regular />
                           ) : item.status === t('Started') ? (
                              <ArrowClockwise20Regular />
                           ) : (
                              <Subtract20Regular />
                           )
                        }
                        color={item.status === t('Completed') ? 'informative' : item.status === t('Started') ? 'warning' : 'danger'}
                     />
                     <Text style={{ margin: '0' }}>{`${item.numberOfAnsweredQuestions}/${item.numberOfTotalQuestions}`}</Text>
                  </span>
               </div>
            )}
         />
      </>
   )
}
