import React from 'react'
import { t } from '../../../i18n/i18n'
import { getCostEvaluationColumns, getCostEvaluationGroups, getCostEvaluationSummaryFields } from '../../../helpers/riskEconomyHelper'
import { Cost, FinancialYear } from '../../../api/schemas/schema'
import { makeStyles, tokens } from '@fluentui/react-components'
import { IItemState } from '../../../interfaces/IItemState'
import { CostEvaluationList } from './CostEvaluationList'
import { CostEvaluationPhoneHeader } from './CostEvaluationPhoneHeader'
import { CostEvaluationPhoneRow } from './CostEvaluationPhoneRow'
import { CardHeader } from '../../common/header/CardHeader'
import { ModalEvaluateCost } from './ModalEvaluateCost'

const useStyles = makeStyles({
   summaryRow: {
      '@media screen and (max-width: 640px)': {
         '& > *': {
            gap: tokens.spacingHorizontalS,
         },
      },
   },
})

type CostEvaluationProps = {
   selectedFinancialYear: FinancialYear
   setFinancialYearState: React.Dispatch<React.SetStateAction<IItemState<FinancialYear>>>
}

export const CostEvaluation = ({ selectedFinancialYear, setFinancialYearState }: CostEvaluationProps) => {
   const classes = useStyles()
   const [openModal, setOpenModal] = React.useState<boolean>(false)
   const [checkboxHasLocalState, setCheckboxHasLocalState] = React.useState<boolean>(false)
   const [selectedCost, setSelectedCost] = React.useState<Cost>(null)

   const toggleCheckbox = (cost: Cost, openModal?: boolean) => {
      cost = { ...cost, actionRequired: !cost.actionRequired }

      const updatedCosts = selectedFinancialYear.costs.map((item) => (item.id === cost.id ? cost : item))
      const updatedFinancialYear = { ...selectedFinancialYear, costs: updatedCosts }

      setFinancialYearState((prev) => ({
         ...prev,
         item: updatedFinancialYear,
      }))

      openModal && setCheckboxHasLocalState(true)
      return cost
   }

   const onCellClick = (cost: Cost, columnKey: keyof Cost) => {
      if (columnKey === 'actionRequired') {
         cost = toggleCheckbox(cost, true)
      }

      setSelectedCost(cost)
      setOpenModal(true)
   }

   return (
      <>
         <CardHeader title={t('Evaluation')} />

         <CostEvaluationList
            columns={getCostEvaluationColumns()}
            groups={getCostEvaluationGroups(selectedFinancialYear.costs)}
            summaryFields={getCostEvaluationSummaryFields(selectedFinancialYear.costs)}
            renderPhoneRow={(cost) => <CostEvaluationPhoneRow cost={cost} onCellClick={onCellClick} />}
            summaryRowClassName={classes.summaryRow}
            onCellClick={onCellClick}
            renderPhoneHeader={(costGroup) => <CostEvaluationPhoneHeader costGroup={costGroup} />}
         />

         <ModalEvaluateCost
            setFinancialYearState={setFinancialYearState}
            financialYear={selectedFinancialYear}
            cost={selectedCost}
            isOpen={openModal}
            close={() => {
               setOpenModal(false)
               setCheckboxHasLocalState(false)
            }}
            checkboxHasLocalState={checkboxHasLocalState}
            toggleCheckbox={toggleCheckbox}
         />
      </>
   )
}
