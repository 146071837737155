import React, { useContext } from 'react'
import { ActionPlan, RiskAnalysis, RiskAnalysisStatus } from '../../api/schemas/schema'
import { GroupedList } from '../common/list/GroupedList'
import { IGroupedTableColumnDefintion } from '../../interfaces/IGroupedTableColumnDefintion'
import { IGroup } from '../../interfaces/IGroup'
import { ISummaryField } from '../../interfaces/ISummaryField'
import { Button, makeStyles, MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'
import { ArrowExport20Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import { Card } from '../common/card/Card'
import { ModalActionPlan } from '../demandAnalysis/actionPlan/ModalActionPlan'
import { updateActionPlan } from '../../helpers/riskAnalysisHelper'
import { DataContext } from '../../context/DataContext'
import { IItemState } from '../../interfaces/IItemState'
const useStyles = makeStyles({
   hideInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   wrapText: {
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
   },
   exportBtn: {
      float: 'right',
   },
})

type riskActionPlanProps<T> = {
   analysis: RiskAnalysis
   title: string
   actionPlans: ActionPlan[]
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   getItemColumns: IGroupedTableColumnDefintion<T>[]
   getItemGroups: IGroup<T>[]
   getItemSummaryFields: ISummaryField[]
   parentRiskInformation: JSX.Element
   selectedItem: ActionPlan
   setSelectedItem: React.Dispatch<React.SetStateAction<ActionPlan>>
   getPhoneBody: (item: T) => JSX.Element
   exportToExcel: (analysis: RiskAnalysis, actionPlans: ActionPlan[]) => void
}

export const RiskActionPlan = <T,>({
   analysis,
   title,
   actionPlans,
   setRiskAnalysisState,
   getItemColumns,
   getItemGroups,
   getItemSummaryFields,
   parentRiskInformation,
   getPhoneBody,
   selectedItem,
   setSelectedItem,
   exportToExcel,
}: riskActionPlanProps<T>) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { accessToken } = dataContext.state

   const saveActionPlan = async (data: ActionPlan) => {
      return await updateActionPlan(data, accessToken, analysis, setRiskAnalysisState)
   }

   const onRowClick = (item: ActionPlan) => {
      setSelectedItem(item)
   }

   return (
      <>
         <Card className={classes.hideInPhone} transparentInPhone title={title}>
            <Button icon={<ArrowExport20Regular />} className={classes.exportBtn} onClick={() => exportToExcel(analysis, actionPlans)}>
               {t('ExportToExcel')}
            </Button>
         </Card>
         {analysis?.actionPlans?.length === 0 ? (
            <MessageBar intent={'info'}>
               <MessageBarBody>
                  <MessageBarTitle className={classes.wrapText}>
                     {(analysis?.simplifiedRisks.length > 0 || analysis?.consequenceAndProbabilityRisks.length > 0) &&
                     analysis.status === RiskAnalysisStatus.Ongoing
                        ? t('NoActionPlansCreatedForRiskAnalysis')
                        : analysis.status === RiskAnalysisStatus.Determined
                        ? t('NoActionPlansRequiredForThisItem', { Item: t('RiskAssesment').toLowerCase() })
                        : t('NoRisksIdentifiedYet')}
                  </MessageBarTitle>
                  {analysis.swoTs.length > 0 &&
                     analysis.swoTs.every((x) => !x.actionRequired && analysis.status === RiskAnalysisStatus.Determined) &&
                     t('RiskAssessmentCanBeCompleted')}
               </MessageBarBody>
            </MessageBar>
         ) : (
            <>
               <GroupedList
                  columns={getItemColumns}
                  groups={getItemGroups}
                  summaryFields={getItemSummaryFields}
                  renderPhoneRow={getPhoneBody}
                  onRowClick={onRowClick}
               />

               <ModalActionPlan
                  parentInformation={selectedItem && parentRiskInformation}
                  selectedItem={selectedItem}
                  close={() => {
                     setSelectedItem(undefined)
                  }}
                  disabled={analysis.status === RiskAnalysisStatus.Completed}
                  onSubmit={saveActionPlan}
               />
            </>
         )}
      </>
   )
}
