import React, { useContext, useEffect } from 'react'
import { Modal, ModalActions } from '../../../common/modal/Modal'
import { t } from '../../../../i18n/i18n'
import { Button } from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { ConsequenceAndProbabilityRisk, RiskAnalysis } from '../../../../api/schemas/schema'
import { ControlledTextInput } from '../../../common/inputs/TextInput'
import { GridItem } from '../../../common/grid/GridItem'
import { Grid } from '../../../common/grid/Grid'
import { api } from '../../../../helpers/apiHelper'
import { DataContext } from '../../../../context/DataContext'
import { handleMessage } from '../../../../helpers/stateHelper'
import { ControlledCheckbox } from '../../../common/inputs/ControlledCheckbox'
import { ControlledMoneyInput } from '../../../common/inputs/ControlledMoneyInput'
import { RiskInfo } from './RiskInfo'
import { RiskConsequenceProbabilityInfo } from './RiskConsequenceProbabilityInfo'
import { IItemState } from '../../../../interfaces/IItemState'

type ModalConsequenceEvaluateProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   analysis: RiskAnalysis
   open: boolean
   checkboxHasLocalState: boolean
   close: () => void
   toggleCheckbox: (risk: ConsequenceAndProbabilityRisk, openModal?: boolean) => ConsequenceAndProbabilityRisk
   selectedRisk: ConsequenceAndProbabilityRisk
   disabled?: boolean
}

export const ModalConsequenceEvaluate = ({
   setRiskAnalysisState,
   open,
   checkboxHasLocalState,
   close,
   toggleCheckbox,
   selectedRisk,
   disabled,
}: ModalConsequenceEvaluateProps) => {
   const {
      control,
      reset,
      handleSubmit,
      watch,
      setValue,
      formState: { isSubmitting },
   } = useForm<ConsequenceAndProbabilityRisk>()
   const dataContext = useContext(DataContext)
   const setRootState = dataContext.setRootState
   const { accessToken, userAccount } = dataContext.state

   useEffect(() => {
      if (selectedRisk) {
         reset(selectedRisk)
      }
   }, [selectedRisk, reset])

   const onSubmit = async (data: ConsequenceAndProbabilityRisk) => {
      try {
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id

         if (data.actionRequired === false) {
            data.proposedAction = null
            data.estimatedCost = null
         }

         const updatedRisk = await (await api(accessToken).updateConsequenceAndProbabilityRisk(data)).data
         setRiskAnalysisState((prev) => ({
            ...prev,
            item: {
               ...prev.item,
               consequenceAndProbabilityRisks: prev.item.consequenceAndProbabilityRisks.map((risk) =>
                  risk.id === updatedRisk.id ? { ...risk, ...updatedRisk } : risk
               ),
            },
         }))
         close()
         reset(updatedRisk)
         handleMessage(setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Risk') }))
      } catch (error) {
         handleMessage(setRootState, 'error', t('CouldNotUpdateItem', { Item: t('Risk') }))
      }
   }

   const onClose = () => {
      if (checkboxHasLocalState) {
         toggleCheckbox(selectedRisk)
      }
      reset()
      close()
   }

   return (
      <Modal modalType="modal" open={open} title={t('AnalyseEvaluate')}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
               <RiskInfo selectedRisk={selectedRisk} />
               <RiskConsequenceProbabilityInfo selectedRisk={selectedRisk} />
               <GridItem size="12/12">
                  {watch('actionRequired') !== undefined && (
                     <ControlledCheckbox
                        onChange={(e, data) => {
                           setValue('actionRequired', Boolean(data.checked))
                           if (!data.checked) {
                              setValue('proposedAction', null)
                              setValue('estimatedCost', null)
                           }
                        }}
                        label={t('CreateProposedAction')}
                        control={control}
                        name={'actionRequired'}
                        checked={watch('actionRequired')}
                        disabled={disabled}
                     />
                  )}
               </GridItem>
               {watch('actionRequired') && (
                  <>
                     <GridItem size="12/12">
                        <ControlledTextInput control={control} name={'proposedAction'} label={t('ProposedAction')} multiline required disabled={disabled} />
                     </GridItem>
                     <GridItem size="12/12">
                        <ControlledMoneyInput control={control} name={'estimatedCost'} label={t('EstimatedCost')} disabled={disabled} />
                     </GridItem>
                  </>
               )}
               <GridItem size="12/12">
                  <ControlledTextInput control={control} name={'comment'} label={t('Comment')} multiline disabled={disabled} />
               </GridItem>
               <GridItem size="12/12">
                  <ModalActions>
                     <Button
                        onClick={() => {
                           onClose()
                        }}
                     >
                        {disabled ? t('Close') : t('Cancel')}
                     </Button>
                     {!disabled && (
                        <Button type="submit" appearance="primary" disabled={isSubmitting}>
                           {t('Save')}
                        </Button>
                     )}
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
