import React from 'react'
import { t } from 'i18next'
import { Checkbox, makeStyles, tokens, Text } from '@fluentui/react-components'
import { Cost } from '../../../api/schemas/schema'
import { DeviationSummary } from '../DeviationSummary'
import { FormattedMoneyOutput } from '../FormattedMoneyOutput'

const useStyles = makeStyles({
   topWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
         alignSelf: 'flex-start',
      },
   },
   checkbox: {
      marginLeft: 'auto',
      flexDirection: 'column',
      marginRight: tokens.spacingHorizontalM,
      '& > *': {
         alignSelf: 'center',
         padding: '0',
      },
   },
   phoneTextWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: tokens.spacingVerticalM,
      justifyContent: 'space-between',
   },
   phoneTextSpacer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      minWidth: '150px',
      maxWidth: '190px',
      gap: tokens.spacingVerticalXXS,
   },
})

type CostEvaluationPhoneRowProps = {
   cost: Cost
   onCellClick: (cost: Cost, columnKey: keyof Cost) => void
}

export const CostEvaluationPhoneRow = ({ cost, onCellClick }: CostEvaluationPhoneRowProps) => {
   const classes = useStyles()
   return (
      <article>
         <div className={classes.topWrapper}>
            <Text size={400} onClick={() => onCellClick(cost, null)}>
               {cost.post.name}
            </Text>
            <Checkbox label={t('Action')} checked={cost.actionRequired} className={classes.checkbox} onClick={() => onCellClick(cost, 'actionRequired')} />
         </div>

         <div className={classes.phoneTextSpacer} onClick={() => onCellClick(cost, null)}>
            <div className={classes.phoneTextWrapper}>
               <Text size={400} weight="semibold">
                  {t('Budget')}
               </Text>
               <FormattedMoneyOutput value={cost.budget} />
            </div>

            <div className={classes.phoneTextWrapper}>
               <Text size={400} weight="semibold">
                  {t('Result')}
               </Text>
               <FormattedMoneyOutput value={cost.result} />
            </div>

            <div className={classes.phoneTextWrapper}>
               <Text size={400} weight="semibold">
                  {t('Deviation')}
               </Text>
               <DeviationSummary cost={cost} />
            </div>
         </div>
      </article>
   )
}
