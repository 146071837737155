import React from 'react'
import { GridItem } from '../../common/grid/GridItem'
import { Text } from '../../common/text/Text'
import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import { t } from '../../../i18n/i18n'
import { formatToSwedishCrowns } from '../../../helpers/stringHelper'
import { RiskAssessmentBadge } from '../RiskBadges'
import { SimplifiedRisk } from '../../../api/schemas/schema'
import { Avatar } from '../../common/avatar/Avatar'
import { getFullName } from '../../../helpers/accountHelper'
import { SearchShield20Filled, SearchShield20Regular } from '@fluentui/react-icons'
import { InformationPopover } from '../../common/popover/InformationPopover'
import { ProtectedAssetGoalPopover } from '../../common/popover/ProtectedAssetGoalPopover'
import { RTFOutput } from '../../common/outputs/RTFOutput'
const useStyles = makeStyles({
   marginTopXS: {
      marginTop: tokens.spacingVerticalXS,
   },
   marginTopS: {
      marginTop: tokens.spacingVerticalS,
      marginBottom: tokens.spacingVerticalS,
   },
   popoverWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: tokens.spacingVerticalM,
   },
})
type ParentSimplifiedRiskInformationProps = {
   selectedRisk: SimplifiedRisk
}
export const ParentSimplifiedRiskInformation = ({ selectedRisk }: ParentSimplifiedRiskInformationProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />

            <div className={classes.popoverWrapper}>
            {selectedRisk?.protectedAssetGoal && <ProtectedAssetGoalPopover protectedAssetGoal={selectedRisk?.protectedAssetGoal} />}
               {selectedRisk?.scenario && (
                  <InformationPopover
                     btnLabel={t('RiskScenario')}
                     popoverLabel={t('RiskScenario')}
                     input={<RTFOutput text={selectedRisk?.scenario} />}
                     regularIcon={<SearchShield20Regular />}
                     filledIcon={<SearchShield20Filled />}
                  />
               )}
            </div>
            {(selectedRisk?.protectedAssetGoal || selectedRisk?.scenario) && <Divider appearance="brand" />}
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('RiskLevel')}
            </Text>
            <RiskAssessmentBadge largeBadge status={selectedRisk.status} className={classes.marginTopS} />
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('UnderlyingMotivationAndStand')}
            </Text>
            <Text className={classes.marginTopS}>{selectedRisk.underlyingMotivation}</Text>
         </GridItem>
         <GridItem size="12/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('ProposedAction')}
            </Text>
            <Text className={classes.marginTopS}>{selectedRisk.proposedAction}</Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('EstimatedCost')}
            </Text>
            <Text italic={!selectedRisk.estimatedCost && selectedRisk.estimatedCost !== 0} className={classes.marginTopS}>
               {selectedRisk.estimatedCost || selectedRisk.estimatedCost === 0 ? formatToSwedishCrowns(selectedRisk.estimatedCost) + ' kr' : t('NoValueSet')}
            </Text>
         </GridItem>
         <GridItem size="6/12">
            <Text style={{ margin: 0 }} weight="semibold">
               {t('AnalysedBy')}
            </Text>
            <Avatar name={getFullName(selectedRisk.analysedBy)} className={classes.marginTopXS} />
         </GridItem>
      </>
   )
}
