import React, { useEffect, useState } from 'react'
import { Button, makeStyles, mergeClasses, TableCell, TableRow, tokens } from '@fluentui/react-components'
import { ChevronDown24Regular, ChevronRight24Regular } from '@fluentui/react-icons'
import { IGroup } from '../../../interfaces/IGroup'
import { IGroupedTableColumnDefintion } from '../../../interfaces/IGroupedTableColumnDefintion'

type GroupedListRowProps<T extends {}> = {
   group: IGroup<T>
   columns: IGroupedTableColumnDefintion<T>[]
   allExpanded: boolean
   chevronCellClass: string
   onRowClick: (item: T) => void
   cellClassname: string
   renderPhoneRow: (item: T) => JSX.Element
}
const useStyles = makeStyles({
   groupRow: {
      height: '75px',
   },
   itemRow: {
      cursor: 'pointer',
   },
   desktopRow: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
   phoneRow: {
      display: 'none',
      '@media screen and (max-width: 640px)': {
         display: 'table-row',
      },
   },
   phoneCell: {
      '@media screen and (max-width: 640px)': {
         padding: `${tokens.spacingHorizontalL} 0`,
      },
   },
})
export const GroupedListRow = <T extends {}>({
   group,
   columns,
   allExpanded,
   chevronCellClass,
   onRowClick,
   cellClassname,
   renderPhoneRow,
}: GroupedListRowProps<T>) => {
   const [expanded, setExpanded] = useState<boolean>(false)
   const classes = useStyles()
   useEffect(() => {
      if (allExpanded) {
         setExpanded(true)
      } else {
         setExpanded(false)
      }
   }, [allExpanded])
   return (
      <>
         <TableRow key={`group-${group.label}`} className={classes.groupRow}>
            <TableCell className={chevronCellClass}>
               <Button
                  size="large"
                  aria-label={`expand-${group.label}`}
                  appearance="transparent"
                  icon={expanded ? <ChevronDown24Regular /> : <ChevronRight24Regular />}
                  onClick={() => setExpanded((prev) => !prev)}
               />
            </TableCell>
            {columns.map((column) => (
               <TableCell style={{ width: column.colWidth, textAlign: column.align }} key={column.columnId} className={cellClassname}>
                  {column.renderGroupHeaderCell(group)}
               </TableCell>
            ))}
         </TableRow>
         {expanded
            ? group.items.map((item, i) => (
                 <>
                    <TableRow key={i} className={mergeClasses(classes.itemRow, classes.desktopRow)} onClick={() => onRowClick(item)}>
                       <TableCell className={chevronCellClass} />
                       {columns.map((column) => (
                          <TableCell style={{ width: column.colWidth }} key={column.columnId} className={cellClassname}>
                             {column.renderCell(item)}
                          </TableCell>
                       ))}
                    </TableRow>
                    <TableRow key={`${i}-phonerow`} className={mergeClasses(classes.itemRow, classes.phoneRow)} onClick={() => onRowClick(item)}>
                       <TableCell className={classes.phoneCell} colSpan={columns.length + 1}>
                          {renderPhoneRow(item)}
                       </TableCell>
                    </TableRow>
                 </>
              ))
            : null}
      </>
   )
}
