import React from 'react'
import { Text as FluentText, makeStyles, mergeClasses } from '@fluentui/react-components'
const useStyles = makeStyles({
   ellipsis: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
   },
   noMargin: {
      margin: 0,
   },
})
type TextProps = {
   children: string | string[] | number | JSX.Element | JSX.Element[] | (string | JSX.Element | number)[]
   align?: 'center' | 'end' | 'justify' | 'start'
   onClick?: () => void
   block?: boolean
   className?: string
   italic?: boolean
   weight?: 'regular' | 'bold' | 'semibold'
   size?: 600 | 100 | 200 | 300 | 400 | 500 | 700 | 800 | 900 | 1000
   as?: 'p' | 'span' | 'h4'
   strikeThrough?: boolean
   underline?: boolean
   style?: React.CSSProperties
   truncate?: boolean
   ellipsis?: boolean
   noMargin?: boolean
}

export const Text = ({
   children,
   align,
   onClick,
   block,
   className,
   italic,
   weight,
   size,
   strikeThrough,
   underline,
   style,
   truncate,
   ellipsis,
   noMargin,
}: TextProps) => {
   const classes = useStyles()
   return (
      <FluentText
         weight={weight ?? 'regular'}
         className={mergeClasses(ellipsis ? classes.ellipsis : '', noMargin ? classes.noMargin : '', className ?? '')}
         size={size ?? 400}
         as="p"
         align={align ?? 'start'}
         block={block === undefined || block ? true : false}
         onClick={onClick}
         italic={italic ?? false}
         strikethrough={strikeThrough ?? false}
         underline={underline ?? false}
         style={style ?? {}}
         truncate={truncate}
      >
         {children}
      </FluentText>
   )
}
