import React from 'react'
import { t } from 'i18next'
import { Divider, makeStyles, tokens, Label, Text } from '@fluentui/react-components'
import { GridItem } from '../../../common/grid/GridItem'
import { ConsequenceAndProbabilityRisk } from '../../../../api/schemas/schema'
import { ProtectedAssetGoalPopover } from '../../../common/popover/ProtectedAssetGoalPopover'
import { ThreatPopover } from '../../../common/popover/ThreatPopover'
const useStyles = makeStyles({
   column: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
   },
   popoverWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: tokens.spacingVerticalM,
   },
   hideInPhone: {
      '@media screen and (max-width: 640px)': {
         display: 'none',
      },
   },
})

type ModalConsequenceEvaluateInfoProps = {
   selectedRisk: ConsequenceAndProbabilityRisk
}
export const RiskInfo = ({ selectedRisk }: ModalConsequenceEvaluateInfoProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
            <div className={classes.popoverWrapper}>
               {selectedRisk?.protectedAssetGoal && <ProtectedAssetGoalPopover protectedAssetGoal={selectedRisk?.protectedAssetGoal} />}
               {selectedRisk?.threat && <ThreatPopover threat={selectedRisk?.threat} /> }
            </div>
            {(selectedRisk?.protectedAssetGoal || selectedRisk?.threat) && <Divider appearance="brand" />}
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('RiskScenario')}</Label>
               {selectedRisk.scenario ? <Text>{selectedRisk.scenario}</Text> : <Text italic>{t('NoValueSet')}</Text>}
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('RiskSource')}</Label>
               {selectedRisk.source ? <Text>{selectedRisk.source}</Text> : <Text italic>{t('NoValueSet')}</Text>}
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('WeaknessesOrCurrentProtection')}</Label>
               {selectedRisk.currentState ? <Text>{selectedRisk.currentState}</Text> : <Text italic>{t('NoValueSet')}</Text>}
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('Consequence')}</Label>
               {selectedRisk.consequence ? <Text>{selectedRisk.consequence}</Text> : <Text italic>{t('NoValueSet')}</Text>}
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('UnderlyingMotivationAndStand')}</Label>
               {selectedRisk.underlyingMotivation ? <Text>{selectedRisk.underlyingMotivation}</Text> : <Text italic>{t('NoValueSet')}</Text>}
            </div>
         </GridItem>
      </>
   )
}
