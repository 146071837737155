import React, { useContext, useEffect } from 'react'
import { Field } from '../../common/inputs/Field'
import { Modal, ModalActions } from '../../common/modal/Modal'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { Button, Divider, Dropdown, Option, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Controller, useForm } from 'react-hook-form'
import { RiskAnalysis, RiskAnalysisType } from '../../../api/schemas/schema'
import { DataContext } from '../../../context/DataContext'
import { t } from '../../../i18n/i18n'
import {
   addConsequenceAndProbabilityRisk,
   addSimplifiedRisk,
   updateConsequenceAndProbabilityRisk,
   updateSimplifiedRisk,
} from '../../../helpers/riskAnalysisHelper'
import { IItemState } from '../../../interfaces/IItemState'

const useStyles = makeStyles({
   form: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalXL),
   },
})

type ModalRiskProps = {
   setRiskAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   open: boolean
   analysis: RiskAnalysis
   selectedItem: any
   disabled?: boolean
}
export const ModalRisk = ({ setRiskAnalysisState, setOpen, open, analysis, selectedItem, disabled }: ModalRiskProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { protectedAssetGoals } = dataContext.state
   const { control, setValue, handleSubmit, reset, formState } = useForm<any>()

   const onSubmit = async (data: any) => {
      if (analysis.type === RiskAnalysisType.ConsequenceAndProbabilityAnalysis) {
         if (data.id) {
            await updateConsequenceAndProbabilityRisk(setRiskAnalysisState, close, dataContext, data)
            reset(data)
         } else {
            await addConsequenceAndProbabilityRisk(setRiskAnalysisState, close, dataContext, data, analysis)
         }
      } else if (analysis.type === RiskAnalysisType.SimplifiedRiskAnalysis) {
         if (data.id) {
            await updateSimplifiedRisk(setRiskAnalysisState, close, dataContext, data)
            reset(data)
         } else {
            await addSimplifiedRisk(setRiskAnalysisState, close, dataContext, data, analysis)
         }
      }
   }

   const close = () => {
      reset()
      setOpen(false)
   }

   useEffect(() => {
      if (open) {
         if (selectedItem) {
            reset({
               ...selectedItem,
               protectedAssetGoalId: selectedItem.protectedAssetGoal?.id ?? null,
               threat: selectedItem.threat ?? null,
            })
         } else {
            reset({
               scenario: null,
               protectedAssetGoal: null,
               protectedAssetGoalId: null,
               source: null,
               currentState: null,
               consequence: null,
               underlyingMotivation: null,
               threat: null,
            })
         }
      }
   }, [selectedItem, open, reset])

   return (
      <Modal title={selectedItem ? t('EditRisk') : t('IdentifyRisk')} modalType="modal" open={open}>
         <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Divider appearance="brand" />
            <Controller
               name={'protectedAssetGoal'}
               control={control}
               render={({ field: { value, onChange } }) => {
                  return (
                     <Field label={t('ProtectedAsset/OrganisationalGoal')}>
                        <Dropdown
                           value={value?.name || ''}
                           disabled={disabled}
                           onOptionSelect={(e, data) => {
                              const selectedAsset = protectedAssetGoals.find((p) => p.id === data.optionValue)
                              onChange(selectedAsset)
                              setValue('protectedAssetGoalId', data.optionValue)
                           }}
                        >
                           {protectedAssetGoals.map((p) => (
                              <Option key={p.id} value={p.id}>
                                 {p.name}
                              </Option>
                           ))}
                        </Dropdown>
                     </Field>
                  )
               }}
            />
            <ControlledTextInput control={control} name={'scenario'} label={t('RiskScenario')} multiline required disabled={disabled} />
            {analysis.type === RiskAnalysisType.ConsequenceAndProbabilityAnalysis && (
               <Controller
                  name={'threat'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                     <Field label={t('CurrentThreat')}>
                        <Dropdown
                           value={value?.name || ''}
                           disabled={disabled}
                           onOptionSelect={(e, data) => {
                              onChange(analysis.threats.find((t) => t.id === data.optionValue))
                           }}
                        >
                           {analysis.threats.map((t) => (
                              <Option key={t.id} value={t.id}>
                                 {t.name}
                              </Option>
                           ))}
                        </Dropdown>
                     </Field>
                  )}
               />
            )}
            <ControlledTextInput control={control} name={'source'} label={t('RiskSource')} multiline disabled={disabled} />
            <ControlledTextInput control={control} name={'currentState'} label={t('WeaknessesOrCurrentProtection')} multiline disabled={disabled} />
            <ControlledTextInput control={control} name={'consequence'} label={t('Consequence')} multiline required disabled={disabled} />
            <ControlledTextInput control={control} name={'underlyingMotivation'} label={t('UnderlyingMotivationAndStand')} multiline disabled={disabled} />
            <ModalActions>
               <Button onClick={close}>{disabled ? t('Close') : t('Cancel')}</Button>
               {!disabled && (
                  <Button appearance="primary" type="submit" disabled={formState.isSubmitting}>
                     {t('Save')}
                  </Button>
               )}
            </ModalActions>
         </form>
      </Modal>
   )
}
