import * as XLSX from 'xlsx'
import { t } from '../i18n/i18n'
import { ActionPlan, ActionPlanStatus, Cost, CostCategory, DemandAnalysis, FinancialYear, RiskAnalysis, SWOTType } from '../api/schemas/schema'
import { findQuestionParentToActionPlan } from './actionPlanHelper'
import { actionPlanStatusString, translateEnumValue } from './enumHelper'
import { formatDate, formatToSwedishCrowns } from './stringHelper'
import dayjs from 'dayjs'
import { findSWOTParentToActionPlan } from './swotHelper'
import { getFullName } from './accountHelper'
import { findConsequenceRiskParentToActionPlan, findSimplifiedRiskParentToActionPlan } from './riskAnalysisHelper'

const stripHtmlTags = (input: string) => {
   const div = document.createElement('div')
   div.innerHTML = input
   return div.innerText || div.textContent || ''
}
export const exportDemandActionPlansToExcel = (analysis: DemandAnalysis, filteredActionPlans: ActionPlan[]) => {
   const { questions, name } = analysis
   const rows: any[] = []

   filteredActionPlans.forEach((actionPlan) => {
      const question = findQuestionParentToActionPlan(questions, actionPlan)
      rows.push({
         Nr: actionPlan.index,
         Fråga: stripHtmlTags(question.text),
         'Förslag till åtgärd': question.proposedAction ?? '',
         'Beslut om åtgärd': actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas',
         Beslutskommentar: actionPlan.decidedActionComment ?? '',
         Ansvarig: actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan.priority ?? '',
         Efterlevnadsnivå: question.answer.text,
         'Klart senast': actionPlan.deadline ? formatDate(actionPlan.deadline) : '',
         'Uppskattad kostnad': question.estimatedCost || question.estimatedCost === 0 ? `${formatToSwedishCrowns(question.estimatedCost)} kr` : '',
         Process: question.processes[0],
         Beslutsstatus: actionPlanStatusString(actionPlan.status),
      })
   })
   exportToExcel(rows, t('ActionPlan'), `${t('ActionPlan')}_${name}`)
}

export const exportSWOTActionPlansToExcel = (analysis: RiskAnalysis, actionPlans: ActionPlan[]) => {
   const { swoTs, name } = analysis
   const rows: any[] = []

   actionPlans.forEach((actionPlan) => {
      const swot = findSWOTParentToActionPlan(swoTs, actionPlan)
      rows.push({
         Nr: actionPlan.index,
         'SWOT Typ': translateEnumValue(SWOTType, swot.type),
         Rubrik: swot.title,
         Beskrivning: swot.description,
         'Förslag till åtgärd': swot.proposedAction ?? '',
         'Beslut om åtgärd': actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas',
         Beslutskommentar: actionPlan.decidedActionComment ?? '',
         Ansvarig: actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan.priority ?? '',
         'Klart senast': actionPlan.deadline ? formatDate(actionPlan.deadline) : '',
         'Uppskattad kostnad': swot.estimatedCost || swot.estimatedCost === 0 ? `${formatToSwedishCrowns(swot.estimatedCost)} kr ` : '',
         Beslutsstatus: actionPlanStatusString(actionPlan.status),
      })
   })
   exportToExcel(rows, t('RiskManagementPlan'), `${t('RiskManagementPlan')}_${name}`)
}

export const exportSimplifiedRiskActionPlansToExcel = (analysis: RiskAnalysis, actionPlans: ActionPlan[]) => {
   const { simplifiedRisks, name } = analysis
   const rows: any[] = []

   actionPlans.forEach((actionPlan) => {
      const risk = findSimplifiedRiskParentToActionPlan(simplifiedRisks, actionPlan)
      rows.push({
         Nr: actionPlan.index,
         Risk: risk.scenario,
         Konsekvens: risk.consequence,
         Källa: risk.source,
         'Sårbarheter eller nuvarande skydd': risk.currentState,
         'Underliggande motivering och ställningstagande': risk.comment,
         'Förslag till åtgärd': risk.proposedAction ?? '',
         'Beslut om åtgärd': actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas',
         Beslutskommentar: actionPlan.decidedActionComment ?? '',
         Ansvarig: actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan.priority ?? '',
         'Klart senast': actionPlan.deadline ? formatDate(actionPlan.deadline) : '',
         'Uppskattad kostnad': risk.estimatedCost || risk.estimatedCost === 0 ? `${formatToSwedishCrowns(risk.estimatedCost)} kr` : '',
         Beslutsstatus: actionPlanStatusString(actionPlan.status),
      })
   })
   exportToExcel(rows, t('RiskManagementPlan'), `${t('RiskManagementPlan')}_${name}`)
}
export const exportConsequenceAndProbabilityRiskActionPlansToExcel = (analysis: RiskAnalysis, actionPlans: ActionPlan[]) => {
   const { consequenceAndProbabilityRisks, name } = analysis
   const rows: any[] = []

   actionPlans.forEach((actionPlan) => {
      const risk = findConsequenceRiskParentToActionPlan(consequenceAndProbabilityRisks, actionPlan)
      rows.push({
         Nr: actionPlan.index,
         Risk: risk.scenario,
         Konsekvens: risk.consequence,
         Källa: risk.source,
         'Sårbarheter eller nuvarande skydd': risk.currentState,
         'Underliggande motivering och ställningstagande': risk.comment,
         'Förslag till åtgärd': risk.proposedAction ?? '',
         'Beslut om åtgärd': actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas',
         Beslutskommentar: actionPlan.decidedActionComment ?? '',
         Ansvarig: actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan.priority ?? '',
         'Klart senast': actionPlan.deadline ? formatDate(actionPlan.deadline) : '',
         'Uppskattad kostnad': risk.estimatedCost || risk.estimatedCost === 0 ? `${formatToSwedishCrowns(risk.estimatedCost)} kr` : '',
         Beslutsstatus: actionPlanStatusString(actionPlan.status),
      })
   })
   exportToExcel(rows, t('RiskManagementPlan'), `${t('RiskManagementPlan')}_${name}`)
}

export const exportCostsToExcel = (financialYear: FinancialYear) => {
   const { costs } = financialYear
   const rows: any[] = []

   costs.forEach((cost) => {
      rows.push({
         Räkenskapsår: financialYear.name,
         Kategori: translateEnumValue(CostCategory, cost.post.costCategory),
         Post: cost.post.name,
         Beskrivning: cost.description,
         Datum: cost.created,
         Budget: formatToSwedishCrowns(cost.budget ?? 0) + ' kr',
         Utfall: formatToSwedishCrowns(cost.result ?? 0) + ' kr',
      })
   })
   exportToExcel(rows, t('Costs'), `${t('FinancialYear')}_${financialYear.name}`)
}

export const exportFinancialYearActionPlans = (filteredCosts: Cost[], actionsPlans: ActionPlan[], financialYearName: string) => {
   const rows: any[] = []

   filteredCosts.forEach((cost) => {
      const actionPlan = actionsPlans.find((a) => a.costId === cost.id)
      const decidedAction = !actionPlan ? '' : actionPlan.actionNeeded ? actionPlan.decidedAction : 'Inga åtgärder ska vidtas'
      rows.push({
         Nr: actionPlan ? actionPlan.index : '',
         Kategori: translateEnumValue(CostCategory, cost.post.costCategory),
         Post: cost.post.name ?? translateEnumValue(CostCategory, cost.post.costCategory),
         Budget: formatToSwedishCrowns(cost.budget ?? 0) + ' kr',
         Utfall: formatToSwedishCrowns(cost.result ?? 0) + ' kr',
         'Förslag till åtgärd': cost.proposedAction ?? '',
         Förslagskommentar: cost.comment,
         'Uppskattad kostnad för åtgärd': cost.estimatedCost || cost.estimatedCost === 0 ? formatToSwedishCrowns(cost.estimatedCost) + ' kr' : null,
         'Förslag av': getFullName(cost.proposedBy),
         'Beslut om åtgärd': decidedAction,
         Beslutskommentar: actionPlan && actionPlan.decidedActionComment ? actionPlan.decidedActionComment : '',
         Ansvarig: actionPlan && actionPlan.responsible ? getFullName(actionPlan.responsible) : '',
         Prioritet: actionPlan && actionPlan.priority ? actionPlan.priority : '',
         'Klart senast': actionPlan && actionPlan.deadline ? formatDate(actionPlan.deadline) : '',
         Beslutsstatus: actionPlanStatusString(actionPlan ? actionPlan.status : ActionPlanStatus.NotDecided),
      })
   })
   exportToExcel(rows, t('ActionPlan'), `${financialYearName}_${t('ActionPlan')}`)
}

export const exportToExcel = (rows: any[], sheetName: string, fileName: string) => {
   const workbook = XLSX.utils.book_new()
   const ws1 = XLSX.utils.json_to_sheet(rows)
   XLSX.utils.book_append_sheet(workbook, ws1, sheetName)
   XLSX.writeFile(workbook, `${fileName}_${dayjs().format('YYYYMMDD_HH:mm')}.xlsx`)
}
