import { Modules } from '../api/schemas/schema'

export const getModuleType = (module: Modules) => {
   if (module.demandComplianceModule !== null && module.demandComplianceModule !== undefined) {
      return { moduleType: 'demandComplianceModule', moduleEnumValue: module.demandComplianceModule }
   }
   if (module.riskManagementModule !== null && module.riskManagementModule !== undefined) {
      return { moduleType: 'riskManagementModule', moduleEnumValue: module.riskManagementModule }
   }
   if (module.generalModule !== null && module.generalModule !== undefined) {
      return { moduleType: 'generalModule', moduleEnumValue: module.generalModule }
   }
   return null
}

export const getModuleName = (module: Modules, enumOptions: any) => {
   const moduleType = getModuleType(module)
   return moduleType?.moduleEnumValue !== null ? enumOptions[moduleType.moduleEnumValue] : ''
}
