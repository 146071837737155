import React, { useContext, useEffect } from 'react'
import Hotjar from '@hotjar/browser'
import { useLocation } from 'react-router-dom'
import { DataContext } from './context/DataContext'

export const HotjarProvider = () => {
   const location = useLocation()
   const dataContext = useContext(DataContext)
   const { userAccount } = dataContext.state
   useEffect(() => {
      const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID)
      const hotjarVersion = 6

      if (siteId && userAccount) {
         Hotjar.init(siteId, hotjarVersion)
      }
   }, [userAccount])

   useEffect(() => {
      Hotjar.stateChange(location.pathname)
   }, [location])

   return <></>
}
