import React, { useState } from 'react'
import { CardHeader } from '../../common/header/CardHeader'
import { t } from '../../../i18n/i18n'
import { Button, makeStyles, Text, tokens } from '@fluentui/react-components'
import { AddCircle24Regular } from '@fluentui/react-icons'
import { RiskAnalysis, RiskAnalysisStatus, Threat } from '../../../api/schemas/schema'
import { List } from '../../common/list/List'
import { getThreatColumns } from '../../../helpers/riskAnalysisHelper'
import { InfoMessageBar } from '../../common/other/InfoMessageBar'
import { ModalThreat } from './modals/ModalThreat'
import { IItemState } from '../../../interfaces/IItemState'
const useStyles = makeStyles({
   btn: {
      '@media screen and (max-width: 1007px)': {
         width: '100%',
      },
   },
   phoneItemWrapper: {
      '& > *': {
         marginBottom: tokens.spacingVerticalMNudge,
      },
      display: 'flex',
      flexDirection: 'column',
      marginBottom: tokens.spacingVerticalM,
   },
   divider: {
      borderBottom: `1px solid ${tokens.colorBrandStroke2}`,
   },
})

type ThreatScenarioProps = {
   analysis: RiskAnalysis
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<RiskAnalysis>>>
}

export const ThreatScenario = ({ analysis, setAnalysisState }: ThreatScenarioProps) => {
   const [openThreatModal, setOpenThreatModal] = useState<boolean>(false)
   const [selectedItem, setSelectedItem] = useState<Threat>(null)
   const classes = useStyles()
   const disabled = analysis.status === RiskAnalysisStatus.Determined || analysis.status === RiskAnalysisStatus.Completed

   const handleRowClick = (item: { threat: Threat; analyseDate: string }) => {
      setSelectedItem(item.threat)
      setOpenThreatModal(true)
   }

   const handleNewThreatClick = () => {
      setSelectedItem(null)
      setOpenThreatModal(true)
   }

   const phoneBody = (item: { threat: Threat; analyseDate: string }) => {
      return (
         <span>
            <div className={classes.phoneItemWrapper}>
               <Text weight={'bold'}>{new Date(item.analyseDate).toLocaleDateString()}</Text>
               <Text>{item.threat.name}</Text>
            </div>
         </span>
      )
   }

   const title = t('ThreatScenario')
   return (
      <>
         <CardHeader
            title={title}
            action={
               <Button className={classes.btn} onClick={handleNewThreatClick} appearance="primary" icon={<AddCircle24Regular />} disabled={disabled}>
                  {t('NewThreat')}
               </Button>
            }
         />

         {analysis?.threats?.length === 0 ? (
            <InfoMessageBar title={t('NoThreatsIdentifiedYet')} />
         ) : (
            <List
               columns={getThreatColumns() as any}
               items={analysis.threats.map((threat) => ({ threat, analyseDate: threat.created }))}
               onRowClick={handleRowClick as any}
               renderPhoneBodyItem={phoneBody}
            />
         )}
         <ModalThreat
            open={openThreatModal}
            setRiskAnalysisState={setAnalysisState}
            analysis={analysis}
            setOpen={() => setOpenThreatModal(false)}
            selectedItem={selectedItem}
            disabled={disabled}
         />
      </>
   )
}
