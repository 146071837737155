import React from 'react'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { formatToSwedishCrowns } from '../../helpers/stringHelper'
import { Cost } from '../../api/schemas/schema'
const useStyles = makeStyles({
   negativeValue: {
      color: tokens.colorStatusDangerBackground3,
   },
})

type DeviationSummaryProps = {
   costs?: Cost[]
   cost?: Cost
}

export const DeviationSummary = ({ costs, cost }: DeviationSummaryProps) => {
   const classes = useStyles()
   const value =  costs ? costs.reduce((sum, cost) => sum + cost.budget - cost.result, 0) : cost ? cost.budget - cost.result : 0
   return <Text size={400} className={value < 0 ? classes.negativeValue : ''}> {`${formatToSwedishCrowns(value)} kr`}</Text>
}
