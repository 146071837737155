import React, { useContext, useEffect } from 'react'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { useForm } from 'react-hook-form'
import { t } from '../../i18n/i18n'
import { Modal, ModalActions } from '../common/modal/Modal'
import { ControlledMoneyInput } from '../common/inputs/ControlledMoneyInput'
import { ControlledDatePicker } from '../common/inputs/DatePicker'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { Button, Divider } from '@fluentui/react-components'
import { Branch, Process, ProtectedAssetGoalType } from '../../api/schemas/schema'
import { DataContext } from '../../context/DataContext'
import { addProtectedAssetGoal, SettingsTabs, updateProtectedAssetGoal } from '../../helpers/settingsHelper'
import { ISettingsState } from '../../interfaces/ISettingsState'
import { ControlledSingleDropdown } from '../common/inputs/ControlledSingleDropdown'

type ModalProtectedAssetGoalProps = {
   setSettingsState: React.Dispatch<React.SetStateAction<ISettingsState>>
   selectedItem: any
   open: boolean
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   disabled?: boolean
   allProcesses: Process[]
   allBranches: Branch[]
   selectedTab: string
}
export const ModalProtectedAssetGoal = ({
   setSettingsState,
   selectedItem,
   open,
   setOpen,
   disabled,
   allProcesses,
   allBranches,
   selectedTab,
}: ModalProtectedAssetGoalProps) => {
   const dataContext = useContext(DataContext)
   const { control, reset, handleSubmit, formState } = useForm<any>()
   const itemName = selectedTab === SettingsTabs.ProtectedAssets ? t('ProtectedAsset') : t('OrganisationalGoal')
   const assetOrGoal = selectedTab === SettingsTabs.ProtectedAssets ? t('Asset') : t('TheGoal')

   const close = () => {
      reset()
      setOpen(false)
   }

   useEffect(() => {
      if (selectedItem) {
         reset({
            ...selectedItem,
            process: allProcesses.find((p) => p.text === selectedItem.process)?.id || '',
            branch: selectedItem.branch?.id || (allBranches.length === 1 ? allBranches[0].id : ''),
         })
      } else {
         reset({
            name: null,
            description: null,
            valuation: null,
            branch: allBranches.length === 1 ? allBranches[0].id : '',
            process: '',
            valuationDate: null,
            comment: null,
         })
      }
   }, [allBranches, allProcesses, open, reset, selectedItem])

   const onSubmit = async (data: any) => {
      data.branch = allBranches.find((b) => b.id === data.branch)
      data.process = allProcesses.find((p) => p.id === data.process).text
      if (SettingsTabs.ProtectedAssets === selectedTab) {
         data.type = ProtectedAssetGoalType.ProtectedAsset
      } else {
         data.type = ProtectedAssetGoalType.OrganisationalGoal
      }

      if (data.id) {
         await updateProtectedAssetGoal(setSettingsState, close, dataContext, data)
         reset(data)
      } else {
         await addProtectedAssetGoal(setSettingsState, close, dataContext, data)
      }
   }

   return (
      <Modal
         open={open}
         title={
            selectedItem
               ? t('EditItem', { Item: itemName.toLowerCase() })
               : selectedTab === SettingsTabs.OrganisationalGoals
               ? t('NewOrganisationalGoal')
               : t('NewItem', { Item: itemName.toLowerCase() })
         }
      >
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
               <GridItem size="12/12">
                  <Divider appearance="brand" />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledTextInput
                     label={t('NameOfItem', { Item: assetOrGoal.toLowerCase() })}
                     control={control}
                     name="name"
                     required
                     disabled={disabled}
                  />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledTextInput
                     label={t('DescriptionOfItem', { Item: assetOrGoal.toLowerCase() })}
                     control={control}
                     name="description"
                     multiline
                     required
                     disabled={disabled}
                  />
               </GridItem>
               <GridItem size="6/12">
                  <ControlledMoneyInput
                     label={t('ValuationOfItem', { Item: assetOrGoal.toLowerCase() })}
                     control={control}
                     name="valuation"
                     required
                     disabled={disabled}
                  />
               </GridItem>
               <GridItem size="6/12">
                  <ControlledDatePicker label={t('ValuationDate')} control={control} name="valuationDate" required isDisabled={disabled} />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledSingleDropdown
                     label={t('BranchAffiliation')}
                     control={control}
                     name="branch"
                     options={allBranches.map((b) => ({ key: b.id, text: b.name }))}
                     disabled
                     required
                  />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledSingleDropdown
                     label={t('ProcessAffiliation')}
                     control={control}
                     name="process"
                     options={allProcesses.map((p) => ({ key: p.id, text: p.text }))}
                     required
                  />
               </GridItem>
               <GridItem size="12/12">
                  <ControlledTextInput label={t('Comment')} control={control} name="comment" multiline disabled={disabled} />
               </GridItem>
               <GridItem size="12/12">
                  <ModalActions>
                     <Button onClick={close}>{t('Cancel')}</Button>
                     <Button appearance="primary" type="submit" disabled={disabled || formState.isSubmitting}>
                        {t('Save')}
                     </Button>
                  </ModalActions>
               </GridItem>
            </Grid>
         </form>
      </Modal>
   )
}
