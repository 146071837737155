import React from 'react'
import { t } from 'i18next'
import { Divider, makeStyles, tokens, Label, Text } from '@fluentui/react-components'
import { GridItem } from '../../../common/grid/GridItem'
import { RiskLevelBadge } from '../../RiskBadges'
import { BadgeConsequenceProbabilityWithLabel } from '../BadgeConsequenceProbability'
import { ConsequenceAndProbabilityRisk } from '../../../../api/schemas/schema'
const useStyles = makeStyles({
   column: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXS,
   },
})

type ModalConsequenceEvaluateInfoProps = {
   selectedRisk: ConsequenceAndProbabilityRisk
}
export const RiskConsequenceProbabilityInfo = ({ selectedRisk }: ModalConsequenceEvaluateInfoProps) => {
   const classes = useStyles()
   return (
      <>
         <GridItem size="12/12">
            <Divider appearance="brand" />
         </GridItem>
         <GridItem size="6/12">
            <div className={classes.column}>
               <Label weight="semibold">
                  {t('ConsequenceLifeAndHealth')} (K<sub>L</sub>){' '}
               </Label>
               <BadgeConsequenceProbabilityWithLabel type="consequence" valuation={selectedRisk.consequenceLifeHealth} />
            </div>
         </GridItem>
         <GridItem size="6/12">
            <div className={classes.column}>
               <Label weight="semibold">
                  {t('ConsequenceEconomy')} (K<sub>E</sub>)
               </Label>
               <BadgeConsequenceProbabilityWithLabel type="consequence" valuation={selectedRisk.consequenceEconomy} />
            </div>
         </GridItem>
         <GridItem size="6/12">
            <div className={classes.column}>
               <Label weight="semibold">
                  {t('ConsequenceEnvironment')} (K<sub>M</sub>)
               </Label>

               <BadgeConsequenceProbabilityWithLabel type="consequence" valuation={selectedRisk.consequenceEnviroment} />
            </div>
         </GridItem>
         <GridItem size="6/12">
            <div className={classes.column}>
               <Label weight="semibold">
                  {t('ConsequenceBrand')} (K<sub>V</sub>)
               </Label>
               <BadgeConsequenceProbabilityWithLabel type="consequence" valuation={selectedRisk.consequenceBrand} />
            </div>
         </GridItem>
         <GridItem size="6/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('Probability')} (S)</Label>
               <BadgeConsequenceProbabilityWithLabel type="probability" valuation={selectedRisk.probability} />
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('UnderlyingMotivationAndStand')}</Label>
               {selectedRisk.underlyingAnalysisMotivation ? <Text>{selectedRisk.underlyingAnalysisMotivation}</Text> : <Text italic>{t('NoValueSet')}</Text>}
            </div>
         </GridItem>
         <GridItem size="12/12">
            <div className={classes.column}>
               <Label weight="semibold">{t('RiskLevel')} (RN)</Label>
               <RiskLevelBadge riskLevel={selectedRisk.riskLevel} />
            </div>
         </GridItem>
      </>
   )
}
