import React from 'react'
import { Text } from '@fluentui/react-components'
import { formatToSwedishCrowns } from '../../helpers/stringHelper'

type FormattedMoneyOutputProps<T> = {
   value: number
}

export const FormattedMoneyOutput = <T,>({value }: FormattedMoneyOutputProps<T>) => {
   return <Text size={400}>{`${formatToSwedishCrowns(value ?? 0)} kr`}</Text>
}
