import { Button, Dialog, DialogContent, DialogTitle } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { ModalActions, DialogSurface, DialogBody } from '../../common/modal/Modal'
import { t } from '../../../i18n/i18n'
import { DataContext } from '../../../context/DataContext'
import { LockClosed24Regular } from '@fluentui/react-icons'
import { completeDemandAnalysis } from '../../../helpers/demandAssessmentHelper'
import { DemandAnalysis, DemandAnalysisQuestion } from '../../../api/schemas/schema'
import { Text } from '../../common/text/Text'
import { useSearchParams } from 'react-router-dom'
import { IItemState } from '../../../interfaces/IItemState'

type ModalCompleteCurrentDemandAnalysisProps = {
   open: boolean
   close: () => void
   items: DemandAnalysisQuestion[]
   setItems: React.Dispatch<React.SetStateAction<DemandAnalysisQuestion[]>>
   setAnalysisState: React.Dispatch<React.SetStateAction<IItemState<DemandAnalysis>>>
}
export const ModalCompleteCurrentDemandAnalysis = ({ open, close, items, setItems, setAnalysisState }: ModalCompleteCurrentDemandAnalysisProps) => {
   const dataContext = useContext(DataContext)
   const [searchParams] = useSearchParams()
   const selectedProcess = searchParams.get('process')

   return (
      <Dialog open={open}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle as="h3">{t('DetermineAnalysis')}</DialogTitle>
               <DialogContent>
                  <Text>{t('CompleteDemandAnalysisConfirmationText', { process: selectedProcess })}</Text>
                  <ModalActions>
                     <Button onClick={close}>{t('Cancel')}</Button>
                     <Button
                        appearance="primary"
                        icon={<LockClosed24Regular />}
                        onClick={async () => {
                           await completeDemandAnalysis(dataContext, items, setItems, setAnalysisState, close)
                        }}
                     >
                        {t('Complete')}
                     </Button>
                  </ModalActions>
               </DialogContent>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}
